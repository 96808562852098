import React, { useEffect } from 'react'
import { Helmet } from "react-helmet";
import usePageSEO from './usePageSEO';

const HelmetFyndr = (props) => {
  const { title = "", imgUrl = "", descr = "" } = props;
  useEffect(() => {
    const description = document.querySelector('meta[name="description"]');
    const title = document.querySelector('meta[name="title"]');
    const image = document.querySelector('meta[name="image"]');

    if (description !== null) {
      description.remove();
    }
    if (title !== null) {
      title.remove()
    }

    if (image !== null) {
      image.remove()
    }




  }, [])

  usePageSEO({
    title: title,
    description: descr,
    keywords: ["Fyndr", "Fyndr.us", "fyndr", "marketplace"],
    ogTitle: title,
    ogImage: imgUrl,
    ogUrl: window.location.pathname + window.location.search

  })

  const generateStructuredData = () => {
    return {
      "@context": "https://schema.org",
      "@type": "WebPage",
      "name": title,
      "description": descr,
      "url": window.location.href || window.location.pathname + window.location.search,
    };
  };

  const structuredData = generateStructuredData();

  return (
    <Helmet>

      <meta charSet="utf-8" />
      <title>{title}</title>
      <meta property="og:site_name" content="Fyndr" />

      <meta property="og:title" content={title} data-react-helmet="true" />
      <meta
        name="image"
        property="og:image"
        content={imgUrl}
        data-react-helmet="true"
      />
      <meta
        property="og:url"
        content={window.location.href || window.location.pathname + window.location.search}
        data-react-helmet="true"
      />
      <meta name="keywords" content={`${title}, Fyndr offers`} />
      <link rel="canonical" href={window.location.href || window.location.pathname + window.location.search} />
      <meta name="description" property="og:description" content={descr} data-react-helmet="true" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={descr} />
      <meta name="twitter:image" content={imgUrl} />
      <meta property="og:type" content="website" />
      <meta name='viewport' content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0' />
      <script type="application/ld+json">
        {JSON.stringify(structuredData)}
      </script>
    </Helmet>
  )
}

export default HelmetFyndr;