/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Modal, Button, Row, Divider, Input, Col, Checkbox } from 'antd'
import DropdownComponent from "../../components/Dashboard/DropdownComponent"
import deletBtn from "../../assets/images/deletBtn.svg"
import editBtn from "../../assets/images/editBtn.svg"
import closeBtn from "../../assets/images/closeBtn.svg"
import arrow_icon from "../../assets/images/arrow_icon.svg"
export default function ModalPlatformVariables(props) {
    const [targetuser] = useState(props.targetUserData)
    const [disableCntry, setDisableCntry] = useState([])
    const { counter } = props;
    const closeIcon = <img src={closeBtn} alt="closeBtn" />
    const suffixIcon = <img src={arrow_icon} alt="" />
    const countryData = props.country.filter((item) => item.label
        !==
        "Global")
    useEffect(() => {
        setDisableCntry([]);
    }, [])
    const getCountryName = (value) => {
        let res = props?.country?.filter((item) => item.value === value);
        return res[0]?.label;
    }
    const disabledCountry = (e, i) => {

        let temparr = [...disableCntry]
        temparr.push(e)
        setDisableCntry(temparr)

    }
    const deleteClick = (value) => {
       
       
            const tempValue = props.country.filter((item) => item.label === value && value)
            let index = disableCntry.indexOf(tempValue[0]?.value ? tempValue[0]?.value : value)
            let tempArr = [...disableCntry]

            tempArr.splice(index, 1)
            setDisableCntry(tempArr)
        

    }
    useEffect(() => {
    }, [disableCntry])
    const newfunc = () => {
        let res = props.country.filter(item1 => {
            return counter.some(item2 => {
                return item1.label === item2.country;
            });
        })
        let temparray = [...disableCntry]
        temparray.push(res.map((data) => data.value))
        setDisableCntry(temparray[0])

    }
    useEffect(() => {
        newfunc()
    }, [])
    return (
        <div>
            <Modal
                title={props.title}
                centered
                className='platformVariablesModal'
                visible={props.visible}
                footer={null}
                closeIcon={closeIcon}
                onCancel={props.onCancel}
                width={800}
            >
                <>
                    <div style={{ margin: "1.5rem" }} >
                        <Row style={{ marginBottom: "1.5rem" }}>
                            <Col span={4} style={{ fontSize: "1.25rem", alignSelf: "center", fontWeight: "400" }}>{props.label}</Col>
                            <Col span={20}>
                                <Input value={props.inputValue} style={{ width: "100%", backgroundColor: props.backgroundColor, fontSize: "1.125rem", fontWeight: "400", height:"3.25rem"}} placeholder={props.placeholder} disabled={props.title === "Update Platform Variable" && true} onChange={props.onNameInputChange} /></Col>
                        </Row>
                        <Row style={{ marginBottom: "1.5rem" }}>
                            <Col span={4} style={{ fontSize: "1.25rem", alignSelf: "center", fontWeight: "400" }}>{props.label1}</Col>
                            <Col span={20}>
                                <DropdownComponent newclassnmae="platformVariablesModal_dropdown" suffixIcon={suffixIcon} options={props.datatype} value={props.dropdownValue} style={{ minWidth: "100%", fontSize: "1.125rem", fontWeight: "400" }} onChange={props.datatypeDropdownChange} disabled={props.title === "Update Platform Variable" && true} />
                            </Col>
                        </Row>
                        {props.OptionsValue === "LIST" &&
                            <>
                                <Row style={{ marginBottom: "1rem" }}>
                                    <Col span={4} style={{ fontSize: "1.25rem", alignSelf: "center", fontWeight: "400" }}>{props.label2}</Col>
                                    <Col span={20}>
                                        <DropdownComponent suffixIcon={suffixIcon} newclassnmae="platformVariablesModal_dropdown" style={{ minWidth: "100%", fontSize: "1.125rem", fontWeight: "400" }} options={props.subtypeDropdownOptions} value={props.subtypeValue} onChange={props.onsubtypeDropdownHandleChange} disabled={props.title === "Update Platform Variable" && true} />
                                    </Col>
                                </Row>
                            </>
                        }
                        <div style={{ marginBottom: "1.5rem" }}>
                            <Row justify={"space-between"}>
                                <Col style={{ fontSize: "1.25rem", alignSelf: "center", fontWeight: "400" }} span={4}>Used For</Col>
                                <Col span={20} align="end">
                                    <Checkbox.Group
                                        style={{ width: "100%" }}
                                        onChange={props.onChangeCheck}
                                        defaultValue={targetuser}
                                        className='platformVariablesModal_checkbox'
                                    >
                                        <Row justify={"start"}>
                                            <Col>
                                                <Checkbox value="INDIVIDUAL" style={{ fontSize: "1.125rem", fontWeight: "400", marginRight: "24px" }}>Individual</Checkbox>
                                            </Col>
                                            <Col>
                                                <Checkbox value="BUSINESS" style={{ fontSize: "1.125rem", fontWeight: "400" }}>Business</Checkbox>
                                            </Col>
                                        </Row>

                                    </Checkbox.Group>
                                </Col>
                            </Row>
                        </div>

                    </div>
                    <div style={{ margin: "24px", border: "1px solid #D3D6E1" }}>
                        <Row className='platformVariablesModal_overflow' style={{ padding: "1.5rem" }}>
                            {props.title !== "Update Platform Variable" &&
                                <Row style={{ marginBottom: "1.5rem", width: "100%" }}>
                                    <Col span={4} style={{ fontSize: "1.25rem", alignSelf: "center", fontWeight: "400" }}>Global</Col>

                                    <Col span={19} >
                                        {props.OptionsValue !== "BOOLEAN" ?
                                            <Input style={{ width: "97%", fontSize: "1.125rem", height: "3.25rem", fontWeight: "400" }} placeholder='Enter The Value' onChange={props.onglobalValueChange} value={props.globalValue} onKeyPress={props.onglobalKeyPress} disabled={props.globalDisabled} />
                                            :
                                            <DropdownComponent  suffixIcon={suffixIcon} newclassnmae="platformVariablesModal_dropdown" style={{ minWidth: "97%", fontSize: "1.125rem", fontWeight: "400" }} options={props.globalBooleanDropdown} onChange={props.onglobalBooleanChange} value={props.globalBooleanValue} disabled={props.globalBooleanDisabled} />
                                        }
                                    </Col>
                                </Row>
                            }
                            {Array.isArray(counter) && counter?.map((data, i) => {
                                return (
                                    <>
                                        {data?.country === "Global" ?
                                            <Row style={{ width: "100%", marginBottom: "1.5rem" }}>
                                                <Col span={4} style={{ fontSize: "1.25rem", alignSelf: "center", fontWeight: "400" }}>Global</Col>

                                                <Col span={19} >
                                                    {props.OptionsValue !== "BOOLEAN" ?
                                                        <Input style={{ width: "97%", fontSize: "1.125rem", height: "3.25rem", fontWeight: "400" }} placeholder='Enter The Value' onChange={(e) => props.onInputChange(e, i)
                                                        } value={data?.value} onKeyPress={props.onglobalKeyPress} disabled={props.globalDisabled} />
                                                        :
                                                        <DropdownComponent suffixIcon={suffixIcon} newclassnmae="platformVariablesModal_dropdown" style={{ minWidth: "97%", fontSize: "1.125rem", fontWeight: "400" }} options={props.globalBooleanDropdown} onChange={(e) => props.booleanChange(e, i)} value={data?.value} disabled={props.globalBooleanDisabled} />
                                                    }
                                                </Col>
                                                <Col span={1} align={"right"} style={{ alignSelf: "center" }}>
                                                    <img alt="edit btn" src={editBtn} onClick={props.onEditClick} style={{ cursor: "pointer" }} /></Col>
                                            </Row>

                                            :

                                            <Row style={{ width: "100%", marginBottom: "1.5rem" }} >
                                                    <Col span={4} style={{ fontSize: "1.25rem", alignSelf: "center", fontWeight: "400" }}>{props.label6}</Col>
                                                {
                                                    data?.country === "" ?
                                                        <Col span={8} style={{ marginRight: "1rem" }}>
                                                            <DropdownComponent disabled={props.title !== "Update Platform Variable"? props.globalDisabled : false} showSearch={true} disabledKeys={disableCntry} suffixIcon={suffixIcon} style={{ minWidth: "100%", width: "8rem", fontSize: "1.125rem", fontWeight: "400" }} newclassnmae="platformVariablesModal_dropdown" options={countryData} value={data?.country} onChange={(e) => {
                                                                props.onDropdownChange(e, i)
                                                                disabledCountry(e, i)
                                                            }
                                                            } key={i} />
                                                        </Col>
                                                        :
                                                        <Col span={8} style={{ marginRight: "1rem" }}  >
                                                            <Input  readOnly={data?.country !== ""} value={parseInt(data?.country) ? getCountryName(data?.country) : data?.country} style={{ height: "3.25rem", fontSize: "1.125rem", fontWeight: "400" }} />
                                                        </Col>

                                                }
                                                <Col style={{ fontSize: "1.25rem", alignSelf: "center", fontWeight: "400" }} span={2}>{props.label5}</Col>

                                                <Col span={8} >
                                                    {props.OptionsValue !== "BOOLEAN" ?
                                                        <Input  style={{ width: "100%", fontSize: "1.125rem", height: "3.25rem", fontWeight: "400" }} placeholder='Enter The value' disabled={props.valueDisabled} value={data?.value} onChange={(e) => props.onInputChange(e, i)} onKeyPress={props.onKeyPress} />
                                                        :
                                                        <DropdownComponent suffixIcon={suffixIcon} newclassnmae="platformVariablesModal_dropdown" style={{ minWidth: "100%", fontSize: "1.125rem", fontWeight: "400" }} options={props.booleanOptions} onChange={(e) => props.booleanChange(e, i)} value={data?.value} disabled={props.booleanDisabled} />
                                                    }
                                                </Col>

                                                {i !== counter?.length - 1 &&
                                                    <Col span={1} align={"right"} style={{ marginLeft: "12px", alignSelf: "center", fontWeight: "400" }}>
                                                        <img alt="dlt btn" src={deletBtn} onClick={() => {
                                                            props.onDeleteClick(i, data?.objId)
                                                            deleteClick(data?.country)


                                                        }} style={{ cursor: "pointer" }} />

                                                    </Col>
                                                }


                                            </Row>
                                        }
                                    </>
                                )
                            }
                            )}
                        </Row>
                    </div>
                    <div style={{ margin: "1.5rem" }}>
                        <Row >
                            <Col span={4} style={{ fontSize: "1.25rem", fontWeight: "400" }}>{props.label4}</Col>
                            <Col span={20}>
                                <Input.TextArea
                                    style={{ fontSize: "20px", fontWeight: "400" }}
                                    onChange={props.onDescChange}
                                    rows={5}
                                    value={props.DescValue}
                                />
                            </Col>
                        </Row>
                    </div>
                    <Divider />
                    <Row justify={"space-evenly"}>
                        <Button type='primary' className='platformVariablesModal_button' onClick={props.onBtn1click}>{props.btn1}</Button>
                        <Button type='primary' className='platformVariablesModal_button' onClick={props.onBtn2click}>{props.btn2}</Button>
                    </Row>
                </>
            </Modal >
        </div >
    )
}
