import React, { useState, useEffect } from "react";
import { Row, Form, Col, Input, Space, Button, Modal } from "antd";
import { BsImage } from "react-icons/bs";
import ImageUploader from "./ImageUploader";
import PropType from "prop-types";
import { Controller } from "react-hook-form";
import { getCroppedImg } from "../../common/util.js";
import { RichTextEditor } from "..";
import Crop from "../Crop";
import { Theme } from "../../common";

const CatalogueForm = (props) => {
  const {
    nameRef,
    descRef,
    imageCallback,
    descError,
    getMarkdownValue,
    desc,
    name,
    isMulti,
    imgs,

    formHook: { errors, control },
  } = props;
  useEffect(() => {
    setImages(imgs);
  }, [imgs]);
  const [images, setImages] = useState(imgs);
  const getLength = () => {
    if (images?.length > 0) {
      let items = images.map((row) => row.index);
      return items;
    } else {
      return [0];
    }
  };
  const FormItem = Form.Item;
  const [index, setIndex] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [imagesArray, setImageArray] = useState(getLength());
  const [showImageModal, setShowImageModal] = useState(false);
  const [groupImage, setGroupImage] = useState(
    images ? images[0]?.img || images[0]?.img_url : null
  );
  const [isCrop, setIsCrop] = useState(false);
  const [fileType, setFileType] = useState("")

  const saveImage = async (croppedAreaPixels,rotation) => {
    try {
      const croppedBase64 = await getCroppedImg(groupImage, croppedAreaPixels, fileType,rotation);
      const imgBase64 = croppedBase64.substring(croppedBase64.indexOf('base64,') + 7);
      const payload = {
        index,
        img: imgBase64,
        imgUri: croppedBase64,
      };
      setGroupImage(croppedBase64);
      imageCallback({
        img: payload.img,
        index: index,
        extn: fileType,
        thumbnail: imgBase64,
      });

      setIsCrop(false)
      setModalVisible(false);
    } catch (error) {
      console.error('Error saving cropped image:', error);
    }
  };

  return (
    <>
      <Row >
        <Col >
          <Row >
            <FormItem
              validateStatus={errors.name ? "error" : "success"}
              help={errors.name ? "Please enter name" : ""}
              label="Name"
              style={{ width: "100%" }}
            >
              <Controller
                as={<Input ref={nameRef} />}
                control={control}
                name="name"
                defaultValue={name}
              />
            </FormItem>
          </Row>
          <Row >
            <FormItem
              validateStatus={descError.status ? "error" : "success"}
              help={descError.status ? descError.message : ""}
              label="Description "
            >
              <RichTextEditor
                ref={descRef}
                getMarkdownValue={(value) => {
                  getMarkdownValue(value);
                }}
                desc={desc}
              />
            </FormItem>
          </Row>
          <Row justify={"start"} style={{ minWidth: "100%" }}>
            <Col span={12} offset={4} >
              <div key={index} style={{ marginBottom: "1rem" }}>
                {isMulti === true ? (
                  <div>
                    {imagesArray.map((index) => {
                      let imgUri;
                      try {
                        imgUri =
                          images.filter((row) => row.index === index)[0]?.imgUri ||
                          images.filter((row) => row.index === index)[0]?.img_url;
                      } catch (error) {
                        console.error(error);
                      }
                      return (
                        <>
                          <Space direction="horizontal">
                            {imgUri ? (
                              <div style={{ position: "relative", display: "inline-block" }}>
                                <img
                                  alt=""
                                  src={imgUri}
                                  className="richform_img-uploading"
                                />
                                <i
                                  onClick={() => {
                                    setIsCrop(true);
                                    setIndex(index);
                                    setModalVisible(true);
                                  }}
                                  style={{
                                    position: "absolute",
                                    top: "-5px",
                                    right: "20px",
                                    background: Theme.blue,
                                    height: "1.8rem",
                                    width: "1.8rem",
                                    borderRadius: "50%",
                                    color: "white",
                                    cursor: "pointer",
                                    textAlign: "center",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                  className="fa fa-crop"
                                  aria-hidden="true"
                                />
                              </div>
                            ) : (
                              <BsImage
                                className="richform_placeholder-img"

                              />
                            )}
                            <Button
                              type="primary"
                              onClick={() => {
                                setIndex(index);
                                setModalVisible(true);
                              }}

                            >
                              Upload Image
                            </Button>
                          </Space>
                          <br />
                          <br />
                        </>
                      );
                    })}
                    {imagesArray.length <= 4 && (
                      <Button
                        type="primary"
                        onClick={() => {
                          setImageArray([...imagesArray, imagesArray.length + 1]);
                        }}
                      >
                        Add More
                      </Button>
                    )}
                  </div>
                ) : (
                  <div>
                    <Space direction="horizontal">
                      {groupImage ? (
                        <div style={{ position: "relative", display: "inline-block" }}>
                          <img
                            onClick={() => {
                              setShowImageModal(true);
                            }}
                            alt=""
                            src={groupImage}
                            className="richform_uploading-img"
                          />
                          <i
                            onClick={() => {
                              setIsCrop(true);
                              setIndex(index);
                              setModalVisible(true);
                            }}
                            style={{
                              position: "absolute",
                              top: "-5px",
                              right: "20px",
                              background: Theme.blue,
                              height: "1.8rem",
                              width: "1.8rem",
                              borderRadius: "50%",
                              color: "white",
                              cursor: "pointer",
                              textAlign: "center",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                            className="fa fa-crop"
                            aria-hidden="true"
                          />
                        </div>
                      ) : (
                        <BsImage
                          className="richform_img"
                        />
                      )}
                      <Button
                        type="primary"
                        onClick={() => {
                          setIndex(index);
                          setModalVisible(true);
                        }}
                      >
                        Upload Image
                      </Button>
                      {groupImage && (
                        <Button
                          type="warning"
                          onClick={() => {
                            setGroupImage(null);
                            imageCallback({
                              img: null,
                              index: index,
                              extn: null,
                              thumbnail: null,
                            });
                          }}
                        >
                          Remove
                        </Button>
                      )}
                    </Space>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Col>
      </Row >
      <Modal
        visible={modalVisible}
        footer={null}
        onCancel={() => {
          setModalVisible(false);
          setIsCrop(false)
        }}
        title={!isCrop ? "Upload Image" : "Crop Image"}
        width="40%"
      >
        {
          !isCrop ?
            <ImageUploader
              index={index}
              callback={(image, type) => {
                setModalVisible(false);
                setFileType(type);
                if (image) {
                  setGroupImage(image.imgUri);
                  imageCallback({
                    img: image.img,
                    index: index,
                    extn: image.type,
                    thumbnail: image.thumbnail,
                  });
                }
              }}
            />
            :
            <Crop
              src={groupImage}
              index={index}
              handleSaveClick={(croppedAreaPixels,rotation) => saveImage(croppedAreaPixels,rotation)}
            />
        }
      </Modal>
      <Modal
        visible={showImageModal}
        onCancel={() => setShowImageModal(false)}
        onOk={() => setShowImageModal(false)}
        width={"65%"}
      >
        <img
          alt="business"
          className="richform_bussiness-img"
          src={groupImage}
        />
      </Modal>
    </>
  );
};
CatalogueForm.propType = {
  nameRef: PropType.object,
  descRef: PropType.object,
};
export default CatalogueForm;
