import React, { useEffect, useState } from "react";
import "../../sass/styles/fyndr/_promocodes.scss";
import { Col, List, Row, Typography, Card, message, Button } from "antd";
import promogif from "../../assets/images/promogif.gif";
import actions from "../../actions";
import { useHistory } from "react-router-dom";
import { getFormattedDtNew, isMobile } from "../../common/util";
import { CopyOutlined } from "@ant-design/icons";

const { Text } = Typography;
const AllPromo = () => {
  const history = useHistory();
  const [, setLoading] = useState(false);
  const [activePromoCodes, setActivePromoCodes] = useState();
  const [selectedPromo, setSelectedPromo] = useState(null);

  const getActivePromos = async () => {
    setLoading(true);
    let resp = await actions.FetchPromoCodes({ status: "ACTIVE" });
    
    if (resp.success === true) {
      const filteredPromos = resp.resp.filter(
        (promo) => !promo.private && promo.promoCodeType === "REGISTRATION"
      );
      setActivePromoCodes(filteredPromos);
      
    }
    setLoading(false);
  };

  useEffect(() => {
    getActivePromos();
  }, []);

  return (
    <>
      {!isMobile() ? (
        <div className="allpromo-outercontainer">
          <div className="all-promo-container">
            <Row style={{ height: "90vh" }}>
              <Col span={12} style={{ padding: "24px" }}>
                <div
                  className="promoscroll-container"
                  style={{ height: "76vh", overflowY: "scroll" }}
                >
                  <List
                    itemLayout="horizontal"
                    className="list-history"
                    dataSource={activePromoCodes}
                    renderItem={(item) => (
                      <List.Item
                        style={{ cursor: "pointer", padding:"4px 0" }}
                        onClick={() => {
                          setSelectedPromo(item);
                        }}
                      >
                        <Card
                          className="history-card"
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "1px solid #D3D6E1",
                          }}
                        >
                          <List.Item.Meta
                            avatar={
                              <img
                                src={item.imageUrl}
                                alt="promo"
                                className="list-image"
                              />
                            }
                            description={
                              <div className="list-inner-div">
                                <Row style={{ marginBottom: "5px" }}>
                                  <Col span={24}>
                                    <Text
                                      style={{ color: "#257cdb" }}
                                      className="active-promo-heading"
                                    >
                                      {item.promoCode}
                                    </Text>
                                  </Col>
                                </Row>
                                <Row style={{ marginBottom: "5px" }}>
                                  <Col span={24}>
                                    <Text className="active-promo-date">
                                      Valid till :{" "}
                                      {getFormattedDtNew(item.endDate)}
                                    </Text>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col span={24}>
                                    <Text className="history-content">
                                      {item.description}
                                    </Text>
                                  </Col>
                                </Row>
                              </div>
                            }
                          />
                        </Card>
                      </List.Item>
                    )}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    style={{
                      background: "#223369",
                      height: "46px",
                      width: "125px",
                      borderRadius: "10px",
                    }}
                    type="primary"
                    onClick={() => {
                      history.push(
                        `/register?referralCode=${selectedPromo?.promoCode}`,
                        {
                          promoCode: selectedPromo?.promoCode,
                        }
                      );
                    }}
                  >
                    Signup
                  </Button>
                </div>
              </Col>
              {selectedPromo === null && (
                <Col
                  style={{
                    background: "black",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  span={12}
                >
                  <img src={promogif} style={{ width: "100%" }} alt="promogif" />
                </Col>
              )}
              {selectedPromo !== null && (
                <Col span={12} style={{ background: "#000" }}>
                  <div className="right-image-container">
                    <img
                      src={selectedPromo?.imageUrl}
                      style={{ height: "100%", width: "100%" }}
                      alt="selectedPromo"
                    />
                    <div id="content-right-container">
                      <Text className="side-text-desc">
                        {selectedPromo.description}
                      </Text>
                      <div>
                        <Button
                          icon={<CopyOutlined />}
                          className="copy-to-clipboard"
                          onClick={() => {
                            navigator.clipboard.writeText(
                              selectedPromo.promoCode
                            );
                            message.success("Promo code copied to clipboard!");
                          }}
                        >
                          <Text className="copy-text">
                            {" "}
                            {selectedPromo.promoCode}
                          </Text>
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>
              )}
            </Row>
          </div>
        </div>
      ) : (
        <div
          style={{
            padding: "10px",
            height: "90vh",
            overflowY: "scroll",
            position: "relative",
          }}
        >
          <img style={{ width: "100%" }} src={promogif} alt="promogif" />
          <List
            itemLayout="horizontal"
            className="list-history"
            dataSource={activePromoCodes}
            renderItem={(item) => (
              <List.Item
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setSelectedPromo(item);
                }}
              >
                <Card
                  className="history-card"
                  style={{
                    width: "100%",
                    borderRadius: "10px",
                    border: "1px solid #D3D6E1",
                  }}
                >
                  <List.Item.Meta
                    avatar={
                      <img
                        src={item.imageUrl}
                        alt="promo"
                        style={{
                          width: "105px",
                          height: "105px",
                          borderRadius: "10px",
                        }}
                      />
                    }
                    description={
                      <div className="list-inner-div">
                        <Row style={{ marginBottom: "5px" }}>
                          <Col span={24}>
                            <Text
                              style={{ color: "#257cdb" }}
                              className="active-promo-heading"
                            >
                              {item.promoCode}
                            </Text>
                          </Col>
                        </Row>
                        <Row style={{ marginBottom: "5px" }}>
                          <Col span={24}>
                            <Text className="active-promo-date">
                              Valid till : {getFormattedDtNew(item.endDate)}
                            </Text>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={24}>
                            <Text className="history-content">
                              {item.description}
                            </Text>
                          </Col>
                        </Row>
                      </div>
                    }
                  />
                </Card>
              </List.Item>
            )}
          />
          <div className="mobile-button-div">
            <Button
              style={{
                background: "#223369",
                height: "46px",
                width: "125px",
                borderRadius: "10px",
              }}
              type="primary"
              onClick={() => {
                history.push(
                  `/register?referralCode=${selectedPromo?.promoCode}`,
                  {
                    promoCode: selectedPromo?.promoCode,
                  }
                );
              }}
            >
              Signup
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default AllPromo;
