import { Col, Progress, Row } from "antd";
import { isMobile } from "../../common/util";

const RatingChart = (props) => {
  const totalRating = Object.values(props?.ratingMap).reduce(
    (partialSum, a) => partialSum + a,
    0
  );

  const kFormatter = (num) => {
    return Math.abs(num) > 999
      ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
      : Math.sign(num) * Math.abs(num);
  };

  const changeChartColor = (rating) => {
    // eslint-disable-next-line default-case
    switch (parseInt(rating)) {
      case 5:
        return "#008B0E";

      case 4:
        return "#00CC14";
      case 3:
        return "#FFD600";
      case 2:
        return "#FF8A00";
      case 1:
        return "#F10000";

      // default:
      //   return "black";
    }
  };

  return (
    <>
      {!isMobile() ? (
        <>
          <Row>
            {[5, 4, 3, 2, 1]?.map((e) => {
              return (
                <Col span={24}>
                  <Row>
                    <Col lg={3} xxl={2}>
                      <span className="reviewrating_histo-star">
                        {e}
                        <i
                          class="active icon-star"
                          
                          style={{ fontSize: "1rem", paddingLeft:"2px"}}
                        ></i>
                      </span>
                    </Col>
                    <Col lg={19} xxl={20} md={18} xl={19} >
                      <Progress
                        className="reviewrating_chartProgressBar"
                        showInfo={false}
                        strokeColor={changeChartColor(e)}
                        percent={(props?.ratingMap[e] / totalRating) * 100}
                        size="small"
                      />
                    </Col>
                    <Col style={{ marginLeft: "0.5rem" }} />
                    <Col lg={1} xxl={1}>
                      <div className="count_star">
                        {kFormatter(
                          props?.ratingMap[e] ? props?.ratingMap[e] : 0
                        )}
                      </div>
                    </Col>
                  </Row>
                </Col>
              );
            })}
          </Row>
        </>
      ) : (
        <>
          {[5, 4, 3, 2, 1]?.map((e, i) => {
            return (
              <div className="five histo-rate">
                <span className="histo-star-mobileView">
                  {e}
                  <i
                    className="active icon-star"
                    style={{ fontSize: "10px" }}
                  ></i>
                </span>
                <span class="bar-block-mobileView">
                  <Progress
                    showInfo={false}
                    strokeColor={changeChartColor(e)}
                    percent={(props?.ratingMap[e] / totalRating) * 100}
                    size="small"
                  />
                </span>
                <div className="count_star-mobileView">
                  {kFormatter(props?.ratingMap[e] ? props?.ratingMap[e] : 0)}
                </div>
              </div>
            );
          })}
        </>
      )}
    </>
  );
};

export default RatingChart;
