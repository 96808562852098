/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Table,
  Breadcrumb,
  Divider,
  Button,
  Image,
  message,
} from "antd";
import DropdownComponent from "../../../components/Dashboard/DropdownComponent";
import PulseLoader from "react-spinners/PulseLoader";
import blackArrow from "../../../assets/images/blackArrow.svg";
import CustomizedModal from "../../../components/CustomizedModal/CustomizedModal";
import sortBy from "../../../assets/images/sortBy.svg";
import actions from "../../../actions";
import ReviewStars from "../../../components/ReviewStars/ReviewStars";
import AllReviewCard from "../../../components/AllReviewCard/AllReviewCard";
import TextArea from "antd/lib/input/TextArea";
import dropdown from "../../../assets/images/filled_dropdown.svg"
import moment from "moment";
import { useSelector } from "react-redux";

export default function CommentsReports() {
  const {
    // eslint-disable-next-line no-unused-vars
    indv: { userTimeZone },
  } = useSelector(({ auth }) => auth);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [orderBy, setOrderBy] = useState([]);
  const [reviewComment, setReviewComment] = useState({});
  const [commentId, setcommentId] = useState();
  const [reportedCommentVisible, setReportedCommentVisible] = useState(false);
  const [isIgnoreVisible, setIsIgnoreVisible] = useState(false);
  const [ignoreOptions, setIgnoreOptions] = useState([])
  const [isDeleteVisible, setIsDeleteVisible] = useState(false);
  const [additionalComment, setAdditionalComment] = useState(null)
  const [reason, setReason] = useState("")
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const tableColumn = [
    {
      title: "Comment ID",
      dataIndex: "commentId",
      key: "id",
    },
    {
      title: "Business Name ",
      key: "name",
      render: (data) => {
        return (
          <>
            <p>{data.businessName}</p>
            <p>{data?.businessEmail}</p>
          </>
        );
      },
    },
    {
      title: "Commented by",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Rating",
      dataIndex: "rating",
      key: "rating",
    },
    {
      title: "Date",
      dataIndex: "reportedDt",
      key: "date",
      width: 260,
      render: (data) => {
        return <p>{moment.utc(data).local().format("YYYY-MM-DD h:mm A")}</p>;

      },
    },
    {
      title: "Actions",
      key: "action",

      render: (_, record) => {
        return (
          <Button
            onClick={() => {
              setcommentId(record.commentId);
              fetchById(record.commentId);
            }}
            type="link"
          >
            View
          </Button>
        );
      },
    },
  ];
  const options = [
    { value: "DESC", label: "Newest First" },
    { value: "ASC", label: "Oldest First" },
  ];
  const handleTableChange = (pagination) => {
    setPagination({
      ...pagination,
    });
  };


  const listReasons = async (value) => {
    let resp = await actions.ListReasons({ action: value });
    let dropOptions = resp?.data?.map((option) => ({
      value: option.reason,
      label: option.reason
    }));
    setIgnoreOptions(dropOptions)
  }

  const ignoreReportedComment = async () => {
    let resp = await actions.UpdateReportedComment({
      reason: reason,
      additionalComment: additionalComment,
      commentId: commentId
    });
    if (resp.success === true) {
      message.success(resp?.msg);
      setIsIgnoreVisible(false);
      setReportedCommentVisible(false)
      reportListingApi();
    } else {
      message.error(resp?.msg);
      setIsIgnoreVisible(false);
    }
  };
  const deleteReportedComment = async () => {
    let resp = await actions.DeleteComment({
      reason: reason,
      additionalComment: additionalComment,
      commentId: commentId
    });
    if (resp.success === true) {
      message.success(resp?.msg)
      setIsDeleteVisible(false);
      setReportedCommentVisible(false)
      reportListingApi();
    } else {
      message.error(resp?.msg);
      setIsDeleteVisible(false);
    }
  }
  const handleDeleteClick = (e) => {
    if (reason === "") {
      message.error("Please select the reason from the dropdown")
    }
    else if (reason === "Others" && (additionalComment === null || additionalComment === "")) {
      message.error("Please add additional comments to delete the comment ")
    } else {
      deleteReportedComment(e)
    }
  }
  const handleIgnoreClick = (e) => {
    if (reason === "") {
      message.error("Please select the reason from the dropdown")
    }
    else if (reason === "Others" && (additionalComment === null || additionalComment === "" )) {
      message.error("Please add additional comments to ignore the comment ")
    } else {
      ignoreReportedComment(e)
    }
  }
  const reportListingApi = async () => {
    setLoading(true);
    const pgstart = orderBy.pgstart || pagination.current;
    let response = await actions.Reportlistings({
      orderBy: orderBy,
      pgstart,
      pgSize: pagination.pageSize,
    });
    setPagination({
      ...pagination,
      total: response?.count,
    });
    if (response?.success === true) {
      setLoading(false);
      setData(response?.reportCommentOutDTO);
    }
  };
  useEffect(() => {
    reportListingApi();
  }, [orderBy, pagination.current, pagination.total, pagination.pageSize]);

  const fetchById = async (commentId) => {
    setLoading(true);
    const resp = await actions.fetchCommentsByIdForAdmin({
      commentId: commentId,
    });
    if (resp.success) {
      setLoading(false);
      setReviewComment(resp?.data?.data);
      setReportedCommentVisible(true);
    }
  };

  return (
    <div className="admin_container no-breadcrumb container-mw-xl chapter">
      <div className="quickLoader">
        <PulseLoader color={"#3F9AF7"} loading={loading} size={10} />
      </div>
      <article className="article">
        <Row justify="space-between">
          <Col>
            <Breadcrumb>
              <Breadcrumb.Item>Admin</Breadcrumb.Item>
              <Breadcrumb.Item>Reviews Reports</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <h2 className="article-title comment_heading ">Reviews Reports</h2>
        <Divider />
        <div style={{ marginBottom: "24px" }}>
          <Row>
            <Col style={{ width: "100%" }}>
              <Row justify="start">
                <Col style={{ width: "22%" }}>
                  <DropdownComponent
                    defaultValue={"Newest First"}
                    newclassnmae="report_dropdown"
                    textStyle={{ textAlign: "center" }}
                    placeholder={
                      <Row className="report">
                        <Col span={4}>
                          <img src={sortBy} alt="Filter" />
                        </Col>
                        <Col span={20} className="report_dropdownCol">
                          Sort By
                        </Col>
                      </Row>
                    }
                    options={options}
                    suffixIcon={<img src={blackArrow} alt="aroow" />}
                    onChange={(e) => {
                      setPagination({
                        ...pagination,
                        current: 1,
                      });
                      setOrderBy(e);
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div>
          <Table
            className="admin-table"
            bordered
            columns={tableColumn}
            dataSource={data}
            size="medium"
            pagination={pagination}
            onChange={handleTableChange}
          />
        </div>
      </article>

      <CustomizedModal
        visible={reportedCommentVisible}
        title={null}
        destroyOnClose={true}
        modalWidth={"1012px"}
        btn1Type="primary"
        onBtn1Click={() => {
          setcommentId(reviewComment?.commentId)
          setAdditionalComment(null)
          setReason("")
          listReasons("DELETE")
          setIsDeleteVisible(true)
        }}
        btn1Style={{
          display: "flex",
          width: "141px",
          background: "#3F9AF8",
        }}
        btn1="Delete"
        btn2="Ignore"
        onBtn2Click={() => {
          setcommentId(reviewComment?.commentId)
          setAdditionalComment(null)
          setReason("")
          listReasons("IGNORE")
          setIsIgnoreVisible(true)
        }}
        btn2Style={{
          backgroundColor: "white",
          color: "#257CDB",
          width: "141px",
          border: "1px solid #257CDB",
          padding: "0px",
        }}
        onCancel={() => setReportedCommentVisible(false)}
        content={
          <div className="particular-review_mainDiv">
            <div className="quickLoader">
              <PulseLoader color={"#3F9AF7"} loading={loading} size={10} />
            </div>
            <Row style={{ padding: " 0px 16px" }} justify={"center"}>
              <Col className="particular-review_leftSideCol1" span={14}>
                <Row className="particular-review_row2nd">
                  <Col>
                    <Row>
                      <div className="particular-review_username">
                        {`${reviewComment?.user?.firstName} ${reviewComment?.user?.lastName}`}
                      </div>
                      <div className="particular-review_time">
                        {moment
                          .utc(reviewComment?.createdDt)
                          .local()
                          .format("YYYY-MM-DD HH:mm A")}
                      </div>
                    </Row>
                    <div className="particular-review_reviewStarDiv">
                      <ReviewStars
                        rating={reviewComment.rating}
                        disabled={true}
                      />
                    </div>
                  </Col>
                </Row>

                <Row
                  style={{ padding: "12px 12px 0px 12px" }}
                  gutter={[12, 12]}
                >
                  {[...Array(6)].map((_, i) => {
                    const image = reviewComment?.images?.[i];
                    return (
                      <Col key={i} xs={24} sm={12} md={8} lg={8} xl={8} xxl={8}>
                        <div>
                          {image ? (
                            <Image
                              style={{
                                width: "157px",
                                height: "134px",
                                borderRadius: "10px",
                                objectFit: "fill",
                              }}
                              resizeMode={"contain"}
                              preview={false}
                              src={image}
                              alt="img"
                            />
                          ) : (
                            <div
                              style={{
                                padding: "12px",
                                width: "157px",
                                backgroundColor: "#FFF",
                                height: "134px",
                                borderRadius: "10px",
                              }}
                            >
                              <p
                                style={{
                                  fontWeight: "400",
                                  fontSize: "16px",
                                  textAlign: "center",
                                  lineHeight: "normal",
                                  fontStyle: "normal",
                                  color: "#CCC",
                                  margin: "40px 3px",
                                }}
                              >
                                No Image Added
                              </p>
                            </div>
                          )}
                        </div>
                      </Col>
                    );
                  })}
                </Row>

                <Col span={24}>
                  <div style={{ padding: "0px 12px 0px 12px" }}>
                    <AllReviewCard
                      className="particular-review_textArea"
                      bordered={false}
                      span={reviewComment?.review}
                    />
                  </div>
                </Col>
              </Col>
              <Col>
                <Divider
                  type={"vertical"}
                  style={{
                    background: "#D3D6E1",
                    height: "100%",
                    margin: "0px 12px",
                  }}
                />
              </Col>

              <Col span={9} className="particular-review_rightCol">
                <Row align={"center"}>
                  <Col span={24}>
                    <div className="particular-review_rightColCardDiv">
                      <AllReviewCard
                        textClassNameForPopUpText=" textClassNameForPopUpText"
                        className="particular-review_cardPopUp card1"
                        textClassNameForPopUp="particular-review_cardPopUpText textcard1"
                        overflowClassName="particular-review_replyCard"
                        bordered={false}
                        style={{ marginBottom: "12px" }}
                        title="Biz Replies"
                        reviewComment={reviewComment?.commentThread}
                        
                      />
                      <AllReviewCard
                        className="particular-review_cardPopUp card1"
                        textClassNameForPopUpText=" textClassNameForPopUpText"
                        textClassNameForPopUp="particular-review_cardPopUpText textcard1"
                        overflowClassName="particular-review_replyCard"
                        title="Report History"
                        reviewComment={reviewComment?.reportedComments}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        }
      />
      <CustomizedModal
        visible={isIgnoreVisible}
        modalWidth={"636px"}
        title={"Select a reason"}
        destroyOnClose={true}
        btn1Type="primary"
        onBtn1Click={() => {
          handleIgnoreClick(commentId)
        }}
        btn1Style={{
          display: "flex",
          width: "141px",
          background: "#3F9AF8",
        }}
        btn2="Back"
        onBtn2Click={() => setIsIgnoreVisible(false)}
        btn1="Ignore"
        btn2Style={{
          backgroundColor: "white",
          color: "#257CDB",
          width: "141px",
          border: "1px solid #257CDB",
          padding: "0px",
        }}
        onCancel={() => setIsIgnoreVisible(false)}
        content={
          <div className="ignore">
            <Row className="ignore_content">
              <Col style={{ width: "100%" }}>
                <DropdownComponent defaultValue={"Select reason"} style={{ width: "100%" }} newclassnmae="ignore_dropdown" options={ignoreOptions} onChange={(e) => {
                  setReason(e)
                }} suffixIcon={
                  <img src={dropdown} alt="Sliders" />
                } />
                <TextArea className="ignore_textarea" onChange={(e) => {
                  setAdditionalComment(e.target.value)
                }} value={additionalComment} />
              </Col>
            </Row>
          </div>
        }
      />
      <CustomizedModal
        visible={isDeleteVisible}
        modalWidth={"636px"}
        title={"Select a reason"}
        destroyOnClose={true}
        btn1Type="primary"
        btn1Style={{
          display: "flex",
          width: "141px",
          background: "#3F9AF8",
        }}
        btn2="Back"
        onBtn2Click={() => setIsDeleteVisible(false)}
        btn1="Delete"
        onBtn1Click={() => {
          handleDeleteClick(commentId)
        }}
        btn2Style={{
          backgroundColor: "white",
          color: "#257CDB",
          width: "141px",
          border: "1px solid #257CDB",
          padding: "0px",
        }}
        onCancel={() => setIsDeleteVisible(false)}
        content={
          <div className="ignore">
            <Row className="ignore_content">
              <Col style={{ width: "100%" }}>
                <DropdownComponent defaultValue={"Select reason"} style={{ width: "100%" }}
                  onChange={(e) => {
                    setReason(e)
                  }}
                  newclassnmae="ignore_dropdown" options={ignoreOptions} suffixIcon={
                    <img src={dropdown} alt="Sliders" />
                  } />
                <TextArea className="ignore_textarea" onChange={(e) => {
                  setAdditionalComment(e.target.value)
                }} value={additionalComment} />
              </Col>
            </Row>
          </div>
        }
      />
    </div>
  );
}