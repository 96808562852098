import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Row, Space, Col } from "antd";
import Resizer from "react-image-file-resizer";
import { Uploader } from "../../components";
import { Actions } from "../../common";
const { UPDT_CMPN_IMG, UPDT_CMPN_VIDEO } = Actions;
const ImageUploader = (props) => {
  const { index, callback, imgUrl, videoIndex } = props;
  const [img, setImg] = useState();
  const [file, setFile] = useState(null);
  const [isUp, setIsUp] = useState(false);
  const [fileType, setFileType] = useState("");
  const [thumbnail] = useState("")
  const dispatch = useDispatch();

  const saveImage = async () => {

    if (fileType.startsWith("image")) {
      const thumbnail = await resize(file, 200, 200);
      const payload = {
        index,
        img: img.substring(img.indexOf("base64,") + 7),
        imgUri: img,
        thumbnail: thumbnail.substring(thumbnail.indexOf("base64,") + 7),
        thumbnailUri: thumbnail,
      };
      await dispatch({ type: UPDT_CMPN_IMG, payload });
    } else {
      const payload = {
        index: videoIndex,
        video: img.substring(img.indexOf("base64,") + 7),
        videoUri: img,
        thumbnail: thumbnail.substring(thumbnail.indexOf("base64,") + 7),
        thumbnailUri: thumbnail
      };
      await dispatch({ type: UPDT_CMPN_VIDEO, payload });
    }
    callback(fileType);
    setIsUp(false);
  };

  const resize = (file, width, height) => {
    return new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        width,
        height,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });
  };

  return (
    <>
    <Row>
    <span >The maximum upload size is 5 MB.</span>
    </Row>
      <Row justify="center" style={{ width: "100%"}}>
        <Col>
          <Uploader
            allowedTypes={videoIndex !== -1 ? ["video"] : ["image"]}
            callback={({ name, img, file, type, video }) => {
              setFileType(type);
              setImg(img);
              setFile(file);
              setTimeout(() => {
                setIsUp(true);
              }, 2000);
            }}
            aspect={2 / 1}
            imgUrl={imgUrl}
          />
        </Col>
        {/* added code for video thumbnail */}
        
      </Row>
      <Row justify="center">
        <Space direction="horizontal">
          <Button
            size="large"
            type="primary"
            onClick={() => saveImage()}
            disabled={!isUp}
          >
            Save
          </Button>
          <Button
            size="large"
            type="warning"
            onClick={() => {
              callback();
              setIsUp(false);
            }}
          >
            Cancel
          </Button>
        </Space>
      </Row>
    </>
  );
};
export default ImageUploader;
