import {
  fetch_retry_post,
  fetch_retry_put,
  fetch_retry_get,
} from "../network/ApiManager";

import { Actions } from "../common";
import getEnvVars from "../environment";
import { debug } from "./index";

const {
  PAYABLE_UPSERTED,
  RECEIVABLE_UPSERTED,
  INVOICE_PAID,
  UPDT_ONBOARDED,
  VOUCHER_UPDATED,
  RECEIVABLES_FETCHED,
  PAYABLES_FETCHED,
  STRIPE_DETAILS_UPDATE,
} = Actions;
const { base, payment, promo } = getEnvVars();

export const getTax = (data) => async (dispatch) => {
  const endpoint = `${base}/invoice/getTax`;

  try {
    const response = await fetch_retry_post(endpoint, data);
    const resp = await response.json();
    if (response.ok) {
      return { success: true, data: resp };
    }
    return {
      success: false,
      msg: resp.message ? resp.message : "Error getting tax",
    };
  } catch ({ message }) {
    return { success: false, msg: message };
  }
};

export const voucherUpdate = (data) => async (dispatch) => {
  const endpoint = `${base}/invoice/voucher/update`;
  debug && console.log("voucherUpdate", endpoint, data);
  const response = await fetch_retry_post(endpoint, data);
  try {
    const resp = await response.json();
    if (response.ok) {
      await dispatch({ type: VOUCHER_UPDATED });
      return { success: true };
    } else {
      return {
        success: false,
        message: resp.message ? resp.message : "Error updating voucher",
      };
    }
  } catch ({ message }) {
    return { success: false, message };
  }
};
export const saveInvoice = (data, type) => async (dispatch) => {
  const endpoint = data.objid
    ? `${base}/invoice/update`
    : `${base}/invoice/create`;
  debug && console.log("saveInvoice", endpoint, data);
  try {
    const response = data.objid
      ? await fetch_retry_put(endpoint, data)
      : await fetch_retry_post(endpoint, data);
    const resp = await response.json();

    if (response.ok) {
      dispatch({
        type: type === "rcpt" ? RECEIVABLE_UPSERTED : PAYABLE_UPSERTED,
        payload: resp,
      });
      return { success: true, invoice: resp, msg: null };
    }
    return {
      success: false,
      msg: resp.message ? resp.message : "Error processing invoice",
    };
  } catch ({ message }) {
    return { success: false, msg: message };
  }
};

export const stripePay = (data) => async (dispatch) => {
  const endpoint = `${payment}/secure/payV2`;
  debug && console.log("stripePay", endpoint, data);
  const response = await fetch_retry_post(endpoint, data);
  try {
    const resp = await response.json();
    if (resp.success) {
      dispatch({ type: INVOICE_PAID, payload: data.invoiceId });
      return { success: true , data : resp };
    } else {
      return {
        success: false,
        message: resp.message ? resp.message : "Error processing payment",
      };
    }
  } catch ({ message }) {
    return { success: false, message: message };
  }
};

export const stripeConnectComplete = (data) => async (dispatch) => {
  const endpoint = `${payment}/secure/connect`;
  debug && console.log("stripeConnectComplete", endpoint, data);
  const response = await fetch_retry_post(endpoint, data);
  try {
    const resp = await response.json();

    if (resp.success) {
      const { info } = resp;
      await dispatch({ type: UPDT_ONBOARDED, payload: info });
      return { success: true };
    } else {
      return { success: false, message: "Error completing stripe connect" };
    }
  } catch ({ message }) {
    return { success: false, message };
  }
};

export const fetchReceivables = (data) => async (dispatch) => {
  const endpoint = `${base}/invoice/fetch?pgStart=0&pgSize=500`;
  debug && console.log("fetchReceivables", endpoint, data);
  try {
    const response = await fetch_retry_post(endpoint, data);
    const resp = await response.json();

    if (response.ok) {
      dispatch({ type: RECEIVABLES_FETCHED, payload: resp.content });
      return { success: true, result: resp.content };
    }
    return {
      success: false,
      msg: resp.message ? resp.message : "Error saving campaign",
    };
  } catch ({ message }) {
    return { success: false, msg: message };
  }
};
export const fetchPayables = (data) => async (dispatch) => {
  const endpoint = `${base}/invoice/fetch?pgStart=0&pgSize=500`;
  debug && console.log("fetchPayables", endpoint, data);
  try {
    const response = await fetch_retry_post(endpoint, data);
    const resp = await response.json();
    if (response.ok) {
      dispatch({ type: PAYABLES_FETCHED, payload: resp.content });
      return { success: true, result: resp.content };
    }
    return {
      success: false,
      msg: resp.message ? resp.message : "Error saving campaign",
    };
  } catch ({ message }) {
    return { success: false, msg: message };
  }
};

export const getInvoiceDetails = (data) => async (dispatch) => {
  const endpoint = `${base}/invoice/v2/details`;
  debug && console.log("getInvoiceDetails", endpoint, data);
  const response = await fetch_retry_post(endpoint, data);
  try {
    const resp = await response.json();
    if (response.ok) {
      return { success: true, resp };
    } else {
      return {
        success: false,
        message: resp.message ? resp.message : "Error getting invoice details",
      };
    }
  } catch ({ message }) {
    return { success: false, message };
  }
};

export const getInvoiceSummary = (data) => async (dispatch) => {
  const endpoint = `${base}/invoice/summary`;
  debug && console.log("getInvoiceSummary", endpoint, data);
  const response = await fetch_retry_post(endpoint, data);
  try {
    const resp = await response.json();
    if (response.ok) {
      return { success: true, resp };
    } else {
      return {
        success: false,
        message: resp.message ? resp.message : "Error getting summary",
      };
    }
  } catch ({ message }) {
    return { success: false, message };
  }
};

export const stripeConnect = (qrcode) => async (dispatch) => {
  const endpoint = `${payment}/secure/connect?from_web=true&qrcode=${qrcode}`;
  debug && console.log("stripeConnect", endpoint, qrcode);
  try {
    const response = await fetch_retry_get(endpoint);
    const resp = await response.json();
    if (resp.resp && resp.resp.success) {
      return { success: true, url: resp.resp.accountLinks.url };
    } else {
      return { success: false, message: "Error connecting to stripe" };
    }
  } catch ({ message }) {
    return { success: false, message };
  }
};

export const verifyStripeRegistration = (id) => async (dispatch) => {
  const endpoint = `${payment}/secure/verifyStripeRegistration?&indvid=${id}`;
  debug && console.log("stripeConnect", endpoint, id);
  try {
    const response = await fetch_retry_get(endpoint);
    const resp = await response.json();
    if (resp.success === true) {
      if (resp.info) {
        dispatch({
          type: STRIPE_DETAILS_UPDATE,
          payload: resp.info,
        });
      }
      return { success: true, response: resp };
    } else {
      return { success: false, message: "Error connecting to stripe" };
    }
  } catch (error) {
    if (error?.response?.data?.info){
       dispatch({
         type: STRIPE_DETAILS_UPDATE,
         payload: error?.response?.data?.info,
       });

    }
      return { success: false, message: error?.response?.data?.message };
  }
};
export const sendPreview = (data) => async (dispatch) => {
  const endpoint = `${promo}/secure/promo/preview`;
  debug && console.log("sendPreview", endpoint, data);
  const response = await fetch_retry_post(endpoint, data);

  try {
    const resp = await response.json();
    if (response.ok) {
      return { success: true };
    } else {
      return {
        success: false,
        message: resp.message ? resp.message : "Error sending mail",
      };
    }
  } catch ({ message }) {
    return { success: false, message };
  }
};
export const updatePaymentStatus = (payload, invoiceId) => async () => {
  const endpoint = `${base}/order/paymentStatus/${invoiceId}`;
  try {
    let response = await fetch_retry_put(endpoint, payload);
    const resp = await response.json();

    if (response.ok) {
      return { success: true, msg: resp.message };
    } else {
      return { success: false, msg: "error updating payment status" };
    }
  } catch (error) {
    return { success: false, msg: error.message };
  }
};
export const updateDeliveryStatus = (payload, invoiceId) => async () => {
  const endpoint = `${base}/order/deliveryStatus/${invoiceId}`;
  try {
    let response = await fetch_retry_put(endpoint, payload);
    const resp = await response.json();
    if (response.ok) {
      return { ...resp };
    } else {
      return { success: false, msg: "error updating payment status" };
    }
  } catch (error) {
    return { success: false, msg: error.message };
  }
};

export const finActions = {
  getTax,
  saveInvoice,
  stripePay,
  stripeConnectComplete,
  stripeConnect,
  fetchReceivables,
  fetchPayables,
  getInvoiceSummary,
  getInvoiceDetails,
  voucherUpdate,
  sendPreview,
  updatePaymentStatus,
  updateDeliveryStatus,
  verifyStripeRegistration,
};
