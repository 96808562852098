import { Actions } from '../common';

const { SET_IS_BOTTOM } = Actions;

const initialState = {
  isBottom: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export  default function (state = initialState, action) {
  switch (action.type) {
    case SET_IS_BOTTOM:
      return {
        ...state,
        isBottom: action.payload,
      };
    default:
      return state;
  }
};
