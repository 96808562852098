/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Actions } from "../../common";
import { Row, Col, Button, Image } from "antd";
import gif from "../../assets/images/error_gifnew.gif";
import { useHistory } from 'react-router-dom';
const { LOADER_VISIBLE } = Actions;

const NotFoundPage = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    useEffect(() => {
        dispatch({
            type: LOADER_VISIBLE,
            payload: false,
        });
    }, [])
    return (
        <div style={{ justifyContent: 'center', height: "100%" }} >
            
            <Row justify={"center"}>
                <Col xs={0} sm={0} md={0} lg={12} xl={10} xxl={12} style={{textAlign:"start",paddingLeft:"6rem",alignSelf:"center"}}>
                    <p className='notfound_para' style={{ marginBottom: "1.2rem" }}>Something went <strong className='notfound_strong' >Wrong.</strong></p>
                    <p className='notfound_para' style={{ marginBottom: "2rem" }}>The page you are looking for does not exist.</p>
                    <Button type='primary' size='large' style={{ borderRadius: "10px", width: "200px", fontSize: "20px", fontWeight: "400", padding: "0", backgroundColor: "#257CDB", height: "46px" }}
                        onClick={() => {
                            history.push("/");
                        }}>Go to home</Button>
                </Col>
                <Col xs={0} sm={0} md={0} lg={12} xl={14} xxl={12}  style={{textAlign:"center"}}>
                    <Image src={gif} preview={false} alt='' />
                </Col>



                <Col xs={24} sm={24} md={24} lg={0} xl={0} style={{textAlign:"center"}}>
                    <Image src={gif} preview={false} alt='' />
                </Col>
                <Col xs={24} sm={24} md={24} lg={0} xl={0} xxl={0} style={{textAlign:"center"}}>
                    <p className='notfound_para1'>Something went <strong className='notfound_strong'>Wrong.</strong></p>
                    <p className='notfound_para2'>The page you are looking for does not exist.</p>
                    <Button type='primary' size='large' className='notfound_btn' style={{ fontWeight: "400", padding: "0", backgroundColor: "#257CDB", height: "46px" }}
                        onClick={() => {
                            history.push("/");
                        }}>Go to home</Button>
                </Col>
            </Row>
        </div >
    );
}

export default NotFoundPage;

