/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Breadcrumb,
  Table,
  Col,
  Button,
  Input,
  Divider,
  Row,
  Typography,
  message,
  Checkbox,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import PulseLoader from "react-spinners/PulseLoader";
import DropdownComponent from "../../../components/Dashboard/DropdownComponent";
import ModalPlatformVariables from "../../../components/ModalPlatformVariables/ModalPlatformVariables";
import plusSign from "../../../assets/images/plusSign.svg";
import actions from "../../../actions";
import CustomizedModal from "../../../components/CustomizedModal/CustomizedModal";
var typingTimer;   
export default function Platformvariables() {
  const { Text } = Typography;
  const [loading, setLoading] = useState(false);
  const [newVarVisible, setNewVarVisible] = useState(false);
  const [updateVisible, setUpdateVisible] = useState(false);
  const [deleteCountry, setDeleteCountry] = useState([]);
  const [data, setData] = useState([]);
  const [text, setText] = useState("");
  const [inputFields, setInputFields] = useState([
    { country: "", value: "", objId: "" },
  ]);


  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageSize] = useState(10);
  const [currentPageTotal, setCurrentPageTotal] = useState(0);


  const [countryId, setCountryId] = useState(-2);
  const [countryList, setCountryList] = useState([]);
  const [defaultValue, setDefaultValue] = useState("");
  const [description, setDescription] = useState("");
  const [name, setName] = useState("");
  const [datatype, setDatatype] = useState("");
  const [subtype, setSubtype] = useState(null);
  const [selectedPlatformUser, setSelectedPlatformUser] = useState({});
  const [paraVisible, setParaVisible] = useState(false);
  const [targetUserData, setTargetUserData] = useState([]);
  const [checked, setChecked] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [globalValue, setGlobalValue] = useState("");
  const [title, setTitle] = useState("");
  const [, setCountryValueMap] = useState();
  const Datatype = [
    { value: "LIST", label: "List" },
    { value: "DECIMAL", label: "Decimal" },
    { value: "INTEGER", label: "Integer" },
    { value: "TEXT", label: "Text" },
    { value: "BOOLEAN", label: "Boolean" },
  ];
  const SubtypeDropdownOptions = [
    { value: "TEXT", label: "Text" },
    { value: "INTEGER", label: "Integer" },
    { value: "DECIMAL", label: "Decimal" },
  ];
  const BooleanOptions = [
    { value: "true", label: "true" },
    { value: "false", label: "false" },
  ];

  const global = countryList.map((row) => {
    return { value: row.objId, label: row.name };
  });

  useEffect(() => {
    CountryList();
  }, []);

  const getListData = async () => {
    setLoading(true);

    let response = await actions.PlatformVariablesListings({
      countryId: countryId,
      variableName: text,
      pgstart: currentPage,
      pgSize: currentPageSize,
    });
    if (response?.success === true) {
      setCurrentPageTotal(response?.count)
      setLoading(false);
      setData(response?.listPlatformVariableOutDTO);
    }
  }

  useEffect(() => {
    setCurrentPage(1)
    typingTimer = setTimeout(function () {
      setCurrentPage(1)
      getListData();
    }, 1500);
    return () => clearTimeout(typingTimer);
  }, [text])


  useEffect(() => {
    getListData();
  }, [countryId, checked])


  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current)
  };

  useEffect(() => {
    if (currentPage !== 1) { getListData() };
  }, [
    currentPage,
    currentPageSize,
  ]);

  const newObject = { isoCode: "Gl", name: "Global", objId: -1 };
  let newobj = {};
  const result = [];

  const CountryList = async () => {
    let response = await actions.GetCountryList();
    for (let value of response) {
      newobj = { ...value };
      result.push(newobj);
    }
    result.unshift(newObject);
    const newArrayOfObj = result.map(({ 0: objId, 1: name, ...rest }) => ({
      objId,
      name,
      ...rest,
    }));
    setCountryList(newArrayOfObj);
  };
  const AddNewPlatformVariables = async (text) => {
    const objectPayload = {}

    const newObj = inputFields.filter((data) =>
      Object.values(data).every((value) => value !== "")
    );

    newObj.forEach(({ objId, value }) => {
      objectPayload[objId] = value;
    });


    let response = await actions.AddPlatformVariables({
      name: name,
      datatype: datatype,
      targetUser: targetUserData.length > 1 ? "ALL" : targetUserData.toString(),
      defaultValue: globalValue,
      subtype: subtype,
      countryValueMap: objectPayload,
      description: description,
    });
    if (response?.success === true) {
      getListData();
      message.success(response?.msg);
      setNewVarVisible(false);
      setInputFields([{ country: "", value: "", objId: "" }]);
    } else {
      message.error(response?.msg);
    }

  };
   
    
  let platformVariableId = selectedPlatformUser?.id;
  const UpdatePlatformvariables = async () => {
    const objectPayload = {}
    const newObj = inputFields.filter((data) =>
      Object.values(data).every((value) => value !== "")
    );

    newObj.forEach(({ objId, value }) => {
      objectPayload[objId] = value;
    });

    let payload = {
      targetUser: targetUserData.length > 1 ? "ALL" : targetUserData.toString(),
      description: description,
      countryValueMap: objectPayload,
      deletedCountryIdList: deleteCountry
    };
    setLoading(true);
    let resp = await actions.UpdatePlatformVariables(payload, platformVariableId)
    if (resp.success === true) {
      getListData();
      message.success(resp.msg);
      setUpdateVisible(false);
      setDeleteCountry([])
    } else {
      message.error(resp.msg);
      setUpdateVisible(false);
      setDeleteCountry([])
    }
    setLoading(false);
  };


  const handledeleteClick = (index) => {
    const list = [...inputFields];
    list.splice(index, 1);
    if (inputFields.length !== 1) {
      setInputFields(list);
    }
  };

  const handleonInputChange = (value, index) => {
    const updatedRows = [...inputFields];
    updatedRows[index].value = value;
    setInputFields(updatedRows);
  };

  const handleDropdownChange = (value, index) => {
    const updatedRows = [...inputFields];
    updatedRows[index].country = value;
    updatedRows[index].objId = value;
    setInputFields(updatedRows);
    if (value !== "" && index === inputFields.length - 1) {
      setInputFields([...updatedRows, { country: "", value: "", objId: "" }]);
    }
  };
  const onChangeCheck = (value) => {
    setTargetUserData(value);
  };
  const updateemptyvalues = inputFields && inputFields.filter(
    (data) => data.country && !data.value
  );

  const handleAddBtnClick = () => {
    let regex = new RegExp(/^[a-zA-Z0-9-_]+$/);
    if (name === "") {
      message.error("The platform variable name cannot be null/empty");
    } else if (!regex.test(name)) {
      alert("error");
      message.error("Space and Special Characeters not allowed");
    } else if (datatype === "") {
      message.error(
        "The datatype of a platform variable cannot be null. Please select a datatype."
      );
    } else if (datatype === "LIST" && subtype === null) {
      message.error(
        "The subtype of a platform variable cannot be null. Please select a subtype."
      );
    } else if (globalValue === "") {
      message.error(
        "The Global value for a platform variable cannot be null. Please insert value."
      );
    } else if (description === "") {

      message.error(
        "The Description value for a platform variable cannot be null. Please insert value."
      );
    }
    else if (targetUserData.length < 1) {
      message.error("Please Select Target User");
    } else {
      AddNewPlatformVariables();
    }
  };

  const LongText = (text, modalTitle) => {
    const MAX_LENGTH = 50;
    const isLongText = text?.length > MAX_LENGTH;
    const displayText = isLongText
      ? `${text.substring(0, MAX_LENGTH)}...`
      : text;

    return (
      <Text>
        {displayText ? displayText : "-"}
        {isLongText && (
          <Text
            style={{ color: "#1890ff", cursor: "pointer" }}
            onClick={() => {
              setTitle(modalTitle);
              setParaVisible(true);
              setSelectedPlatformUser(text);
            }}
          >
            Read More
          </Text>
        )}
      </Text>
    );
  };

  const handleCheckChange = (e) => {
    setChecked(e.target.checked);
    if (e.target.checked !== true) {
      setCountryId(-1);
    } else {
      setCountryId(-2);
    }
  };

  useEffect(() => {
    if (datatype !== "LIST") {
      setSubtype(null)
    }
  }, [datatype, inputFields]);  
  

  const tableColumn = [
    {
      title: "Platform Variable Name",
      dataIndex: "name",
      key: "name",
      render: (data, key) => {
        return <p>{data ? data : "-"}</p>;
      },
    },
    {
      title: "Datatype",
      dataIndex: "datatype",
      key: "datatype",
      render: (data, key) => {
        return (
          <p>
            {data
              ? data.charAt(0).toUpperCase() + data.slice(1).toLowerCase()
              : "-"}
          </p>
        );
      },
    },
    {
      title: "Subtype",
      dataIndex: "subtype",
      key: "subtype",
      render: (data, key) => {
        return (
          <p>
            {data
              ? data.charAt(0).toUpperCase() + data.slice(1).toLowerCase()
              : "-"}
          </p>
        );
      },
    },
    {
      title: "Value",
      children: [
        {
          title: "Country",
          dataIndex: "country",
          key: "country",
          width: 150,
          render: (_, record) =>
            record.value.map((country, index, array) => (
              <>
                <Text
                  key={country.countryId}
                  style={{
                    padding: "0px 14px",
                    fontWeight: country === "Global" && "600",
                    color: "#333333",
                  }}
                >
                  {country.countryName}
                </Text>
                {index !== array.length - 1 && (
                  <Divider className="platformVariables_divider" />
                )}{" "}
              </>
            )),
        },
        {
          title: "Value",
          dataIndex: "value",
          key: "value",
          width: 190,
          className: "platformVariables_valueCol",
          render: (_, record) =>
            record.value.map((value, index, array) => (
              <>
                <Text key={value.countryValue} style={{ padding: "0px 14px" }}>
                  {LongText(value.countryValue, "Value")}
                </Text>
                {index !== array.length - 1 && (
                  <Divider className="platformVariables_divider" />
                )}
              </>
            )),
        },
      ],
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: 190,
      render: (data, key) => {
        return LongText(data, "Description");
      },
    },
    {
      title: "Used For",
      dataIndex: "targetUser",
      key: "targetUser",
      render: (data, key) => {
        return (
          <p>
            {data
              ? data.charAt(0).toUpperCase() + data.slice(1).toLowerCase()
              : "-"}
          </p>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => {
        return (
          <Button
            type="primary"
            style={{ backgroundColor: "#3F9AF8" }}
            onClick={() => {
              setUpdateVisible(true);
              setSelectedPlatformUser(record);
              setDescription(record.description);
              setTargetUserData(
                record.targetUser === "ALL"
                  ? ["INDIVIDUAL", "BUSINESS"]
                  : record.targetUser
              );
              setIsEdit(false);
              let res = record?.value?.map((item) => item)
              const newArrayOfObj = res.map(({
                countryName: country,
                countryValue: value,
                countryId: objId,
                ...rest
              }) => ({
                country,
                value,
                objId,
                ...rest
              }));

              let tempArr = [...newArrayOfObj];

              tempArr.push({ country: "", value: "", objId: "" });
              setInputFields(tempArr);
            }}
          >
            Update
          </Button>
        );
      },
    },
  ];
  return (
    <div className="admin_container no-breadcrumb container-mw-xl chapter">
      <div className="quickLoader">
        <PulseLoader color={"#3F9AF7"} loading={loading} size={10} />
      </div>
      <article className="article">
        <Breadcrumb>
          <Breadcrumb.Item>Admin</Breadcrumb.Item>
          <Breadcrumb.Item>Platform Variables</Breadcrumb.Item>
        </Breadcrumb>
        <h2
          className="article-title"
          style={{
            marginBottom: "-2rem",
            marginTop: "-2rem",
            paddingTop: "3rem",
            paddingBottom: "2rem",
          }}
        >
          Platform Variables
        </h2>
        <Divider />

        <div style={{ marginBottom: "2rem" }}>
          <Row justify={"space-between"} gutter={[4, 8]}>
            <Col style={{ width: "35%" }} span={8}>
              <Input
                placeholder="Search"
                type="search"
                style={{ fontSize: "1.125rem", height: "36px" }}
                suffix={<SearchOutlined />}
                onChange={(e) => {
                  setText(e.target.value)
                }}
              />
            </Col>
            <Col>
              <Checkbox
                checked={checked}
                onChange={handleCheckChange}
                style={{ fontSize: "18px" }}
                className="platformVariablesModal_checkbox"
              >
                All
              </Checkbox>
            </Col>
            <Col style={{ width: "35%" }} span={8}>
              <DropdownComponent
                placeholder="Global"
                style={{ minWidth: "100%", fontSize: "1.125rem" }}
                defaultValue={"Global"}
                options={global}
                allowClear={true}
                value={countryId === -2 ? "Please uncheck All to get countries" : countryId}
                disabled={checked ? true : false}
                onChange={(e) => {
                  setCountryId(e);
                }}
                dropdownRender={(menu) => (
                  <>
                    {menu}
                  </>
                )}
              />
            </Col>
            <Col align={"right"}>
              <Button
                type="primary"
                style={{ backgroundColor: "#3F9AF8", height: "36px", fontSize: "1.125rem" }}
                icon={
                  <img
                    src={plusSign}
                    alt="plusSign"
                    style={{ marginRight: ".5rem" }}
                  />
                }
                onClick={() => {
                  setTargetUserData([]);
                  setName("");
                  setDatatype("");
                  setSubtype(null);
                  setDefaultValue("");
                  setDescription("");
                  setInputFields([{ country: "", value: "", objId: "" }]);
                  setNewVarVisible(true);
                  setGlobalValue("");
                }}
              >
                Add
              </Button>
            </Col>
          </Row>
        </div>
        <Table
          className="platformVariable-table"
          bordered
          columns={tableColumn}
          pagination={{
            current: currentPage,
            pageSize: currentPageSize,
            total: currentPageTotal,
          }}
          dataSource={data}
          size="medium"
          onChange={handleTableChange}
        />
      </article>
      {updateVisible && (
        <ModalPlatformVariables
          title="Update Platform Variable"
          visible={updateVisible}
          btn1="Update"
          btn2="Back"
          existingCountry={selectedPlatformUser?.value}
          inputValue={selectedPlatformUser?.name}
          dropdownValue={selectedPlatformUser?.datatype}
          subtypeValue={selectedPlatformUser?.subtype}
          onBtn2click={() => {
            setUpdateVisible(false);
            setTargetUserData([]);
            setDeleteCountry([])
          }}
          onBtn1click={() => {
            if (updateemptyvalues.length > 0) {
              message.error("Value cannot be empty . Please fill the value");
            }
            else if (targetUserData.length < 1) {
              message.error("Please Select Target User");
            } else {
              UpdatePlatformvariables();
            }

            const object = {};
            const newObj = inputFields.filter((data) =>
              Object.values(data).every((value) => value !== "")
            );
            newObj.forEach(({ country, value }) => {
              object[country] = value;
            });

            let countryValueMap = [];
            countryValueMap.push(object);
            setCountryValueMap(countryValueMap);
          }}
          label={"Name"}
          label1={"Data Type"}
          label2={"Sub Type"}
          backgroundColor="#f2f2f2"
          label5={"Value"}
          label4={"Description"}
          label6={"Country"}
          BooleanValue="Value"
          countryDropdown={global}
          subtypeDropdownOptions={SubtypeDropdownOptions}
          booleanOptions={BooleanOptions}
          DescValue={description}
          onDescChange={(e) => setDescription(e.target.value)}
          onKeyPress={(e) => {
            if (
              (selectedPlatformUser?.datatype === "INTEGER" ||
                selectedPlatformUser?.subtype === "INTEGER") &&
              !/^[0-9\b]+$/.test(e.key)
            ) {
              e.preventDefault();
            }
            if (
              (selectedPlatformUser?.datatype === "DECIMAL" ||
                selectedPlatformUser?.subtype === "DECIMAL") &&
              !/^[0-9\b.]+$/.test(e.key)
            ) {
              e.preventDefault();
            }
          }}
          onglobalKeyPress={(e) => {
            if (
              (selectedPlatformUser?.datatype === "INTEGER" ||
                selectedPlatformUser?.subtype === "INTEGER") &&
              !/^[0-9\b]+$/.test(e.key)
            ) {
              e.preventDefault();
            }
            if (
              (selectedPlatformUser?.datatype === "DECIMAL" ||
                selectedPlatformUser?.subtype === "DECIMAL") &&
              !/^[0-9\b.]+$/.test(e.key)
            ) {
              e.preventDefault();
            }
          }}
          OptionsValue={selectedPlatformUser?.datatype}
          targetUserData={targetUserData}
          onChangeCheck={onChangeCheck}
          onCancel={() => {
            setUpdateVisible(false);
            setTargetUserData([]);
            setDeleteCountry([])
          }}
          counter={inputFields}
          country={global}
          onDeleteClick={(i, id) => {
            let result = selectedPlatformUser?.value.some(function (el) {
              return el.countryId === id;
            })
            if (result) {
              let tempDeleteCountry = [...deleteCountry];
              tempDeleteCountry.push(id);
              setDeleteCountry(tempDeleteCountry);
            }
            handledeleteClick(i);
          }}
          onEditClick={() => {
            setIsEdit(true);
          }}
          onInputChange={(e, i) => {
            handleonInputChange(e.target.value, i);
          }}
          onDropdownChange={(value, i) => {
            handleDropdownChange(value, i);
          }}
          globalDisabled={!isEdit ? true : false}
          globalBooleanDisabled={!isEdit ? true : false}
          booleanChange={(value, i) => handleonInputChange(value, i)}
          globalBooleanDropdown={BooleanOptions}
        />
      )}
      {newVarVisible && (
        <ModalPlatformVariables
          title="Add Platform Variable"
          visible={newVarVisible}
          onCancel={() => {
            setNewVarVisible(false);
            setInputFields([{ country: "", value: "", objId: "" }]);
            setTargetUserData([]);
          }}
          placeholder="Enter the name of the platform variable"
          btn1="Add"
          btn2="Back"
          onBtn2click={() => {
            setNewVarVisible(false);
            setInputFields([{ country: "", value: "", objId: "" }]);
            setTargetUserData([]);
          }}
          label={"Name"}
          label1={"Data Type"}
          label2={"Sub Type"}
          label5={"Value"}
          label4={"Description"}
          label6={"Country"}
          BooleanValue={"Value"}
          countryDropdown={global}
          datatype={Datatype}
          dropdownValue={datatype}
          DescValue={description}
          onDescChange={(e) => setDescription(e.target.value)}
          counter={inputFields}
          subtypeValue={subtype}
          listValue={defaultValue}
          onKeyPress={(e) => {
            if (datatype === "INTEGER" && !/^[\d]+$/.test(e.key)) {
              e.preventDefault();
            }
            if (datatype === "DECIMAL" && !/^[0-9\b.]+$/.test(e.key)) {
              e.preventDefault();
            }
            if (
              datatype === "INTEGER" &&
              subtype === "INTEGER" &&
              !/^[\d,]+$/.test(e.key)
            ) {
              e.preventDefault();
            }
            if (
              datatype === "DECIMAL" &&
              subtype === "DECIMAL" &&
              !/^[0-9\b.,]+$/.test(e.key)
            ) {
              e.preventDefault();
            }
          }}
          onglobalKeyPress={(e) => {
            if (datatype === "INTEGER" && !/^[\d]+$/.test(e.key)) {
              e.preventDefault();
            }
            if (datatype === "DECIMAL" && !/^[0-9\b.]+$/.test(e.key)) {
              e.preventDefault();
            }
            if (
              (datatype === "INTEGER" || subtype === "INTEGER") &&
              !/^[\d,]+$/.test(e.key)
            ) {
              e.preventDefault();
            }
            if (
              (datatype === "DECIMAL" || subtype === "DECIMAL") &&
              !/^[0-9\b.,]+$/.test(e.key)
            ) {
              e.preventDefault();
            }
          }}
          OptionsValue={datatype}
          subtypeDropdownOptions={SubtypeDropdownOptions}
          datatypeDropdownChange={(value) => {
            setDatatype(value);
          }}
          onsubtypeDropdownHandleChange={(value) => {
            if(datatype === "LIST") {
            setSubtype(value);
            }else{
              setSubtype(null)
            }
          }}
          inputValue={name}
          onNameInputChange={(e) => setName(e.target.value)}
          onBtn1click={() => {
            handleAddBtnClick();
          }}
          onChangeCheck={onChangeCheck}
          targetUserData={targetUserData}
          onglobalValueChange={(e) => {
            setGlobalValue(e.target.value);
          }}
          globalValue={globalValue}
          globalDisabled={(datatype === "") || (datatype === "LIST" && subtype === null) ? true : false}
          globalBooleanDropdown={BooleanOptions}
          onglobalBooleanChange={(value) => setGlobalValue(value)}
          globalBooleanValue={globalValue}
          globalBooleanDisabled={datatype === "" ? true : false}
          valueDisabled={(datatype === "") || (datatype === "LIST" && subtype === null) ? true : false}
          booleanOptions={BooleanOptions}
          booleanChange={(value, i) => handleonInputChange(value, i)}
          booleanDisabled={datatype === "" ? true : false}
          country={global}
          onDeleteClick={(i) => {
            handledeleteClick(i);
          }}
          onDropdownChange={(value, i) => {
            handleDropdownChange(value, i);
          }}
          onInputChange={(e, i) => {
            handleonInputChange(e.target.value, i);
          }}
        />
      )}
      <CustomizedModal
        visible={paraVisible}
        title={title}
        onCancel={() => setParaVisible(false)}
        footer={null}
        content={
          <Text style={{ fontSize: "16px", lineHeight: "13px" }}>
            {selectedPlatformUser}
          </Text>
          }
      />
    </div>
  );
}
