import { Breadcrumb, Col, Row, message } from "antd";
import React, { useEffect, useState } from "react";
import PulseLoader from "react-spinners/PulseLoader";
import { getStatesOfCountry } from "country-state-city/lib/state";
import AdminBarChart from "../../../components/AdminBarChart";
import DonutChart from "../../../components/DonutChart";
import DropdownComponent from "../../../components/Dashboard/DropdownComponent";
import actions from "../../../actions";

const RegistrationRecords = () => {
  const [loading] = useState(false);
  const [findUsOptionList, setFindUsOptionList] = useState([]);
  const [stateData, setStateData] = useState([]);

  const [selectedCountry, setSelectedCountry] = useState();
  const [selectedState, setSelectedState] = useState();
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedUser, setSelectedUSer] = useState();
  const [selectedStatus, setSelectedStatus] = useState();


  const customerType = [
    { value: "BUSINESS", label: "BUSINESS" },
    { value: "INDIVIDUAL", label: "INDIVIDUAL" },
  ];

  const currentStatus = [
    { value: "ACTIVE", label: "ACTIVE", color: "dot-status-active" },
    { value: "INACTIVE", label: "INACTIVE", color: "dot-status-inactive" },
    { value: "SUSPENDED", label: "SUSPENDED", color: "dot-status-suspended" },
    { value: "DELETED", label: "DELETED", color: "dot-status-deleted" },
  ];

  const getStateData = async (value) => {
    let getStateByCode = await getStatesOfCountry(value);
    const newArrayOfObj = getStateByCode?.map(
      ({ isoCode: value, name: label, ...rest }) => ({
        value,
        label,
        ...rest,
      })
    );
    if (value === "US") {
      let countiesToRemove = [
        "American Samoa",
        "Baker Island",
        "Guam",
        "Howland Island",
        "Jarvis Island",
        "Johnston Atoll",
        "Kingman Reef",
        "Midway Atoll",
        "Navassa Island",
        "Northern Mariana Islands",
        "Palmyra Atoll",
        "Puerto Rico",
        "United States Minor Outlying Islands",
        "United States Virgin Islands",
        "Wake Island",
      ];
      let counties = newArrayOfObj?.filter(
        (county) => !countiesToRemove?.includes(county?.label)
      );
      setStateData(counties);
    } else {
      setStateData(newArrayOfObj);
    }
  };
  const findUsOptions = async () => {
    let resp = await actions.fetchFindUsOption();
    if (resp.success) {
      setFindUsOptionList(resp.data);
    } else {
      message.error(resp?.msg);
    }
  };

  useEffect(() => {
    findUsOptions();
    getStateData();
  }, []);

  const barChartOptions = {
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
        beginAtZero: true,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const countryOptions = [
    { value: "US", label: "United States" },
    { value: "IN", label: "India" },
    { value: "AU", label: "Australia" },
    { value: "CA", label: "Canada" },
    { value: "GB", label: "United Kingdom" },
    { value: "NZ", label: "New Zealand" },
  ];

  function generateYearOptions(startYear = 2018) {
    const currentYear = new Date().getFullYear();
    const yearOptions = [];

    for (let year = startYear; year <= currentYear; year++) {
      yearOptions.push({ value: year.toString(), label: year.toString() });
    }

    return yearOptions;
  }

  const yearOptions = generateYearOptions();

  return (
    <div className="admin_container no-breadcrumb container-mw-xl chapter">
      <div className="quickLoader">
        <PulseLoader color={"#3F9AF7"} loading={loading} size={10} />
      </div>

      <article className="article">
        <Row justify="space-between" gutter={32}>
          <Col>
            <Breadcrumb>
              <Breadcrumb.Item>Admin</Breadcrumb.Item>
              <Breadcrumb.Item>Promo Code</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <div style={{ display: "flex", gap: "1rem" }}>
          <DropdownComponent
            placeholder="Country"
            defaultValue={selectedCountry}
            options={countryOptions}
            onChange={(value) => {
              setSelectedState();
              getStateData(value);
              setSelectedCountry(value);
            }}
            style={{ width: "200px", borderRadius: "5px" }}
          />
          <DropdownComponent
            defaultValue={selectedState}
            placeholder="State"
            options={stateData}
            onChange={setSelectedState}
            style={{ width: "200px", borderRadius: "5px" }}
          />
          <DropdownComponent
            defaultValue={selectedYear}
            placeholder="Year"
            options={yearOptions}
            onChange={setSelectedYear}
            style={{ width: "200px", borderRadius: "5px" }}
          />
          <DropdownComponent
          defaultValue={selectedStatus}
          placeholder="Status"
          options={currentStatus}
          onChange={setSelectedStatus}
          style={{ width: "200px", borderRadius: "5px" }}
          />
          <DropdownComponent
          defaultValue={selectedUser}
          placeholder="Customer type" 
          options={customerType}
          onChange={setSelectedUSer}
          style={{ width: "200px", borderRadius: "5px" }}
          />
        </div>
        <Row gutter={16}>
          <Col span={16}>
            <AdminBarChart
              height="300px"
              country={selectedCountry}
              state={selectedState}
              year={selectedYear}
              user={selectedUser}
              status={selectedStatus}
              entry={{ options: "Total Registratons" }}
              type="totalUsers"
              options={barChartOptions}
            />
          </Col>
          <Col span={8}>
            <DonutChart
              country={selectedCountry}
              state={selectedState}
              year={selectedYear}
              user={selectedUser}
              label={findUsOptionList}
              status={selectedStatus}
            />
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          {findUsOptionList?.map((entry, index) => {
            return (
              <Col key={index} span={8}>
                <AdminBarChart
                  entry={entry}
                  country={selectedCountry}
                  state={selectedState}
                  year={selectedYear}
                  user={selectedUser}
                  status={selectedStatus}
                  height="300px"
                  options={barChartOptions}
                />
              </Col>
            );
          })}
        </Row>
      </article>
    </div>
  );
};

export default RegistrationRecords;
