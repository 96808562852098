/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import ReactFlagsSelect from "react-flags-select";
import Select from "react-dropdown-select";
import { Actions, FormSchema } from "../../common";
import { useSelector, useDispatch } from "react-redux";
import { FiAtSign } from "react-icons/fi";

import { DownOutlined, ReloadOutlined } from "@ant-design/icons";
import getEnvVars from "../../environment";
import { CheckCircleOutlined } from "@ant-design/icons";
import ModalVerifyMobileUI from "./../../components//ModalVerifyPhone/ModalVerifyMobileUI";
import {
  Input,
  Form,
  Row,
  Col,
  Menu,
  Dropdown,
  Button,
  message,
  Typography,
  Checkbox,
  Space,
  Tag,
  Card,
  Radio,
} from "antd";

import UserActions from "../../actions";
import Loader from "../../components/Loading/Loader";
import ContainerWrapper from "../../components/ContainerWrapper";
import { isMobile, postalPattern } from "../../common/util";
import { fetch_retry_post } from "../../network/ApiManager";
import actions from "../../actions";
import { Switch } from "antd";
import { Alert } from "antd";
import { Modal } from "antd";
import HelmetFyndr from "../../components/HelmetFyndr";
import { setEmailSubscribe } from "../../actions/emailSubscribedActions";
const { ROLE_SUPPORT } = Actions;

const FormItem = Form.Item;
const { web, base } = getEnvVars();

const FormContainer = (props) => {
  const { ProfileForm, UserProfileForm, RegisterForm, UserRegisterForm } =
    FormSchema;
  const { Text } = Typography;
  const {
    roleType,
    indv,
    indv: { accountStatus, isSubscribedToFyndrPromoEmails },
    linkrefferalcode,
    isEmailSubscribe,
  } = useSelector(({ auth }) => auth);
  const dispatch = useDispatch();
  const refTag = useRef(null);

  const [inputValue, setInputValue] = useState("");

  let regMode, password, isBiz;
  let mode = "update";

  if (props?.history?.location?.state) {
    const {
      history: {
        location: { state },
      },
    } = props;
    regMode = state.regMode;
    mode = state.mode;
    password = state.password;
    isBiz = state.isBiz;
  }
  const { history } = props;
  const email =
    mode === "add" && regMode === "classic"
      ? history?.location.state.email
      : indv.email;

  const [biz] = useState(mode === "add" ? isBiz : indv.biz);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
    },
  };

  const Ref = useRef(null);
  const {
    register,
    handleSubmit,
    errors,
    setError,
    clearErrors,
    control,
    getValues,
    setValue,
  } = useForm({
    mode: "onChange",
    shouldUnregister: false,
    resolver: yupResolver(
      biz
        ? mode === "add"
          ? RegisterForm
          : ProfileForm
        : mode === "add"
        ? UserRegisterForm
        : UserProfileForm
    ),
  });
  const [timer, setTimer] = useState("00:00");

  const [country, setCountry] = useState(indv?.address?.country || "US");
  const [countryError, setCountryError] = useState(null);
  const [industryTypeError, setIndustryTypeError] = useState(null);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [tags, setTags] = useState(indv.tags ? indv.tags.split(", ") : []);
  const [modalVisibleSuccess, setModalVisibleSuccess] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [industryType, setIndustryType] = useState([]);
  const [verified, setVerified] = useState(mode === "update" ? true : false);
  const [refVerify, setRefVerify] = useState(false);
  const [setTokenError] = useState(null);
  const [cntryObjId, setCntryObjId] = useState();
  const [token, setToken] = useState("");
  const [viewDeleteModal, setDeleteModal] = useState(false);
  const [isDerivedChecked, setIsDerivedChecked] = useState(
    isSubscribedToFyndrPromoEmails
  );
  const [isPromocode, setIsPromocode] = useState();
  const [findUsOptionList, setFindUsOptionList] = useState([]);
  const [findUsId, setFindUsId] = useState(8);
  const [formData, setFormData] = useState({
    fname: indv.fname,
    lname: indv.lname,
    bizid: indv.bizid,
    bizName: indv.bizName,
    postalCode: indv.address?.postalCode,
    addressLine1: indv.address?.addressLine1,
    addressLine2: indv.address?.addressLine2,
    city: indv.address?.city,
    state: indv.address?.state,
    country: indv.address?.country,
    ctryCode: indv.address?.ctryCode,
    phone: indv.address?.phone,
    handle: indv?.fyndrHandle,
    isSubscribedToFyndrPromoEmails: indv?.isSubscribedToFyndrPromoEmails,
    referralCode: indv?.referralCode,
    industryvalue: [{ value: indv?.bizType, label: indv?.bizType }],
    yob: indv.yob,
    gender: indv.gender,
    accountStatus:
      accountStatus === true
        ? "ACTIVE"
        : accountStatus === "ACTIVE"
        ? "ACTIVE"
        : "INACTIVE",
  });
  const [additionData] = useState({
    website: indv.website,
    addonUrl: indv.addonUrl,
    taxnbr: indv.taxnbr,
  });
  const [isloading, setIsLoading] = useState(false);
  const getGeoLocation = async (payload) => {
    setIsLoading(true);
    const resp = await UserActions.getGeoLocation(payload);
    setIsLoading(false);
    if (resp.success) {
      return { addr: { lat: resp.addr.lat, lng: resp.addr.lng } };
    }
    return { addr: { lat: indv?.lat, lng: indv?.lng } };
  };

  const ifAddressModified = (payload) => {
    if (indv) {
      const { address } = indv;
      return (
        address.addressLine1 !== payload.addressLine1 ||
        address.addressLine2 !== payload.addressLine2 ||
        address.postalCode !== payload.postalCode ||
        address.city !== payload.city ||
        address.country !== payload.country
      );
    } else return true;
  };

  const submitForm = async (payload) => {
    if (!termsAccepted && mode === "add") {
      message.error(
        "You have to agree to Fyndr's terms of use & privacy policy"
      );
      return false;
    }
    if (biz && !formData.industryvalue[0]["value"]) {
      setIndustryTypeError("Business Type is required");
      return false;
    } else {
      setIndustryTypeError(null);
    }

    if (!country) {
      setCountryError("Country is required");
      return false;
    } else {
      setCountryError(null);
    }

    if (payload.phone && !payload.ctryCode) {
      setError("ctryCode", "Country code is required");
      return false;
    }
    let latNew = indv?.lat;
    let lngNew = indv?.lng;

    if (mode === "add" || ifAddressModified(payload)) {
      const { addr } = await getGeoLocation({
        addressLine1: payload.addressLine1,
        addressLine2: payload.addressLine2,
        city: payload.city,
        state: payload.state,
        postalCode: payload.postalCode,
        country: country,
      });
      latNew = addr.lat;
      lngNew = addr.lng;
    }

    let finalObject = {
      email,
      firstName: indv.fname ? indv.fname : payload.fname,
      lastName: indv.fname ? indv.lname : payload.lname,
      ctryCode: payload.ctryCode,
      phone: payload.phone,
      country,
      postalCode: payload.postalcode,
      addressLine1: payload.addressLine1.trim(),
      addressLine2: payload.addressLine2.trim(),
      city: payload.city,
      state: payload.state,
      referralCode:
        payload.referralCode && !isPromocode
          ? payload.referralCode.trim()
          : null,
      promoCode:
        payload.referralCode && isPromocode
          ? payload.referralCode.trim()
          : null,
      isBusiness: true,
      bizInfo: {
        bizName: indv.bizName ? indv.bizName : payload.bizName,
        bizType: formData.industryvalue[0].value,
        website: payload.website ? payload.website : "",
        tags: tags.join(", "),
      },
      lat: latNew,
      lng: lngNew,
      accountStatus: mode === "add" ? "ACTIVE" : formData.accountStatus,
    };

    let finalobjectUser = {
      email,
      firstName: indv.fname ? indv.fname : payload.fname,
      lastName: indv.fname ? indv.lname : payload.lname,
      ctryCode: payload.ctryCode,
      phone: payload?.phone,
      country,
      postalCode: payload.postalcode,
      addressLine1: payload.addressLine1.trim(),
      addressLine2: payload.addressLine2.trim(),
      city: payload.city,
      state: payload.state,
      isBusiness: false,
      referralCode:
        payload.referralCode && !isPromocode
          ? payload.referralCode.trim()
          : null,
      promoCode:
        payload.referralCode && isPromocode
          ? payload.referralCode.trim()
          : null,
      lat: latNew,
      lng: lngNew,
    };
    if (mode === "add") {
      if (biz) {
        finalObject["pwd"] = password ? password : null;
        finalObject["regMode"] = regMode;
        finalObject["findUsId"] = findUsId;
      } else {
        finalobjectUser["pwd"] = password ? password : null;
        finalobjectUser["regMode"] = regMode;
        finalobjectUser["addressLine1"] = payload.addressLine1.trim();
        finalobjectUser["addressLine2"] = payload.addressLine2.trim();
        finalobjectUser["yob"] = payload.yob;
        finalobjectUser["gender"] = payload.gender;
        finalobjectUser["findUsId"] = findUsId;
      }
    } else {
      if (biz) {
        finalObject["objid"] = indv.indvid;
        finalObject["bizInfo"]["objid"] = indv.bizid;
        finalObject["bizInfo"]["addonUrl"] = payload.addOnUrl;
        finalObject["bizInfo"]["taxnbr"] = payload.taxnbr;
        finalObject["fyndrHandle"] = payload.handleName;
        finalObject["isSubscribedToFyndrPromoEmails"] =
          indv.isSubscribedToFyndrPromoEmails;
      } else {
        finalobjectUser["objid"] = indv.indvid;
        finalobjectUser["addressLine1"] = payload.addressLine1.trim();
        finalobjectUser["addressLine2"] = payload.addressLine2.trim();
        finalobjectUser["yob"] = payload.yob;
        finalobjectUser["gender"] = payload.gender;
        finalobjectUser["fyndrHandle"] = payload.handleName;
        finalobjectUser["isSubscribedToFyndrPromoEmails"] =
          indv.isSubscribedToFyndrPromoEmails;
      }
    }

    setIsLoading(true);

    let response;

    if (mode === "add") {
      response = await UserActions.register(
        biz ? finalObject : finalobjectUser
      );
    } else {
      response = await UserActions.updateProfile(
        biz ? finalObject : finalobjectUser
      );
    }

    setIsLoading(false);
    if (response.success) {
      if (mode === "add") {
        message.success("Registration completed");
        setTimeout(() => {
          history.replace("/billing/transactions");
        }, 1500);
      } else {
        message.success("Profile updated");
      }
    } else {
      message.error(response.msg);
    }
  };
  const countryCode = (value) => {
    switch (value) {
      case "US":
        setFormData({ ...formData, ctryCode: "+1" });
        setValue("ctryCode", "+1");
        break;
      case "IN":
        setFormData({ ...formData, ctryCode: "+91" });
        setValue("ctryCode", "+91");
        break;
      case "AU":
        setFormData({ ...formData, ctryCode: "+61" });
        setValue("ctryCode", "+61");
        break;
      case "GB":
        setFormData({ ...formData, ctryCode: "+44" });
        setValue("ctryCode", "+44");
        break;

      case "CA":
        setFormData({ ...formData, ctryCode: "+1" });
        setValue("ctryCode", "+1");
        break;

      case "NZ":
        setFormData({ ...formData, ctryCode: "+64" });
        setValue("ctryCode", "+64");
        break;

      default:
        setFormData({ ...formData, ctryCode: "+1" });
        setValue("ctryCode", "+1");
    }
  };
  const newObject = { isoCode: "Gl", name: "Global", objId: -1 };
  let newobjCntry = {};
  const resultCntry = [];
  const CountryList = async () => {
    let response = await UserActions.GetCountryList();
    for (let value of response) {
      newobjCntry = { ...value };
      resultCntry.push(newobjCntry);
    }
    resultCntry.unshift(newObject);
    const newArrayOfObj = resultCntry.map(({ 0: objId, 1: name, ...rest }) => ({
      objId,
      name,
      ...rest,
    }));
    const filterArray = newArrayOfObj.filter(
      (item) => item.isoCode === country
    );
    if (filterArray.length > 0) {
      const obj = filterArray[0].objId;
      setCntryObjId(obj);
    }
  };

  useEffect(() => {
    if (
      isSubscribedToFyndrPromoEmails &&
      !isEmailSubscribe &&
      isEmailSubscribe !== undefined
    ) {
      setIsDerivedChecked(false);
    } else {
      setIsDerivedChecked(isSubscribedToFyndrPromoEmails || isEmailSubscribe);
    }
  }, [isEmailSubscribe]);

  useEffect(() => {
    if (cntryObjId === undefined) {
      const fetchData = async () => {
        await CountryList();
      };
      fetchData();
    }
    setRefVerify(false);
  }, [country, cntryObjId]);
  useEffect(() => {
    if (cntryObjId !== undefined && mode !== "update" && linkrefferalcode) {
      verifyRefCode();
    }
  }, [cntryObjId, linkrefferalcode]);

  const verifyRefCode = async (payload) => {
    let dataObj = getValues(["referralCode", "country"]);
    if (dataObj.referralCode !== undefined && dataObj.referralCode !== "") {
      const data = {
        isBusiness: biz ? true : false,
        code: decodeURIComponent(dataObj.referralCode).trim(),
        countryId: cntryObjId,
        codeType: "REGISTRATION",
      };
      setIsLoading(true);
      let response = await UserActions.verifyCode(data);
      if (response.success === true) {
        message.success(response.message);
        setIsPromocode(response.promoCode);
        setRefVerify(true);
      } else {
        message.error(response.message);
      }
      setIsLoading(false);
    } else {
      message.error("Please enter Referral Code.");
    }
  };

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor(((total / 1000) * 60 * 60) % 24);
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };

  const startTimer = (e) => {
    let { total, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      // update the timer
      // check if less than 10 then we need to
      // add '0' at the begining of the variable
      setTimer(
        (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      );
    }
  };

  const clearTimer = (e) => {
    // If you adjust it you should also need to
    // adjust the Endtime formula we are about
    // to code next
    setTimer("00:00");

    // If you try to remove this line the
    // updating of timer Variable will be
    // after 1000ms or 1sec
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();

    // This is where you need to adjust if
    // you entend to add more time
    deadline.setSeconds(deadline.getSeconds() + 45);
    return deadline;
  };
  useEffect(() => {
    if (mode === "add") {
      countryCode(country);
    }
  }, []);
  // We can use useEffect so that when the component
  // mount the timer will start as soon as possible

  // We put empty array to act as componentDid
  // mount only
  useEffect(() => {
    clearTimer(getDeadTime());
    getIndustryType();
  }, []);

  // Another way to call the clearTimer() to start
  // the countdown is via action event from the
  // button first we create function to be called
  // by the button
  const onClickReset = () => {
    clearTimer(getDeadTime());
  };
  const indstry = industryType.map((row) => {
    return { value: row.name, label: row.name };
  });
  const validatePostalAddress = async (value, country) => {
    const pattern = new RegExp("^" + postalPattern[country] + "$");

    if (pattern.test(value)) return true;
    else return false;
  };
  const checkPostalCode = async () => {
    setIsLoading(true);
    let value = getValues("postalcode");
    let response = await validatePostalAddress(value, country);
    setIsLoading(false);
    if (!response) {
      setError("postalcode", {
        types: {
          required: "This is required",
          minLength: "This is minLength",
        },
        message:
          value.length < 1
            ? "Postal Code can not be blank"
            : "Postal code is Invalid",
      });
    } else {
      clearErrors("postalcode");
      setValue("postalcode", value);

      const resp = await UserActions.getLocationFromPostalCode(
        `${value},${country}`
      );

      if (resp.success) {
        setValue("state", resp.state);
        setValue("city", resp.city);
        clearErrors("state");
        clearErrors("city");
        // setFormData({ ...formData, city: resp.city });
        // setFormData({ ...formData, state: resp.state });
        setFormData({
          ...formData,
          city: resp.city,
          state: resp.state,
        });
      }
    }
  };

  const handleGenderClick = (e) => {
    setFormData({ ...formData, gender: e.key });
    setValue("gender", e.key);
  };

  const gender_list = [
    { key: "", value: "" },
    { key: "M", value: "Male" },
    { key: "F", value: "Female" },
    { key: "OT", value: "Other" },
    { key: "ND", value: "Do not want to disclose" },
  ];

  const genderMenu = (
    <Menu onClick={handleGenderClick}>
      {gender_list.map((row) => {
        const { key, value } = row;
        return <Menu.Item key={key}>{value}</Menu.Item>;
      })}
    </Menu>
  );

  useEffect(() => {
    //facebook or google login
    if (indv.fname) {
      setValue("fname", indv.fname);
      setValue("lname", indv.lname);
    }
  });
  useEffect(() => {}, [tags]);
  const removeforTags = (index) => {
    let values = tags.filter((item, key) => key !== index);

    setTags(values);
  };
  const addTag = (event) => {
    if (tags.includes(event?.target?.value)) {
      message.error("Tag already added.");
      return;
    } else {
      setTags([...tags, event?.target?.value?.trim()]);
      refTag.current.value = "";
      setInputValue("");
    }
  };

  const sendCode = async () => {
    setToken("");

    let dataObj = getValues(["phone", "ctryCode"]);
    if (
      dataObj.phone !== undefined &&
      dataObj.ctryCode !== undefined &&
      dataObj.phone !== "" &&
      dataObj.ctryCode !== ""
    ) {
      setIsLoading(true);
      const endpoint = `${base}/identity/verify/sendVerificationCode?type=phone`;
      const payload = {
        email: email,
        countryCode: dataObj.ctryCode,
        phone: dataObj.phone,
        registerMode: regMode,
      };
      try {
        const response = await fetch_retry_post(endpoint, payload);
        if (response.ok) {
          setIsLoading(false);
          setModalVisible(true);
          onClickReset();
        }
      } catch (err) {
        setIsLoading(false);
        message.error(err.message);
      }
    } else {
      message.error("Please enter country code and phone number");
    }
  };
  const resendCode = async () => {
    setToken("");
    setIsLoading(true);
    let dataObj = getValues(["phone", "ctryCode"]);
    const endpoint = `${base}/identity/verify/resendVerificationCode?type=phone`;
    const payload = {
      email: email,
      countryCode: dataObj.ctryCode,
      phone: dataObj.phone,
      registerMode: regMode,
    };

    try {
      const response = await fetch_retry_post(endpoint, payload);
      if (response.ok) {
        setIsLoading(false);
        message.success(response.data.message);
        onClickReset();
      }
    } catch (err) {
      message.error(err.message);
    }
  };

  useEffect(() => {
    findUsOptions();
  }, []);

  const findUsOptions = async () => {
    let resp = await actions.fetchFindUsOption();
    if (resp.success) {
      setFindUsOptionList(resp.data);
    } else {
      message.error(resp?.msg);
    }
  };

  const handleRadioChange = (e) => {
    setFindUsId(e.target.value);
  };

  const emailSubscription = async () => {
    const params = {
      emailId: email,
    };

    let resp = await actions.emailSubscribe(params);

    if (resp?.success) {
      message.success("Thank you. You have been added to our mailing list.");
      dispatch(setEmailSubscribe(true));
    } else {
      message.error(resp?.msg);
    }
  };

  const handleChangeToken = () => {
    let phoneno = /^\d+$/;
    if (!phoneno.test(token)) {
      message.error("Verification code should be in numbers");
    } else {
      verifyCode();
    }
  };
  const verifyCode = async () => {
    setIsLoading(true);
    let dataObj = getValues(["phone", "ctryCode"]);
    const endpoint = `${base}/identity/verify/verifyVerificationCode?type=phone`;
    const payload = {
      email: email,
      countryCode: dataObj.ctryCode,
      phone: dataObj.phone,
      verificationCode: token,
    };
    try {
      const response = await fetch_retry_post(endpoint, payload);
      if (response.ok) {
        setIsLoading(false);
        setVerified(true);
        setModalVisible(false);
        setModalVisibleSuccess(true);
        message.success(response.data.message);
        setToken("");
      }
    } catch (err) {
      setIsLoading(false);
      setTokenError(err.message);
      message.error(err.message);

    }
  };
  let newobj = {};
  const result = [];
  const getIndustryType = async () => {
    let response = await UserActions.fetchIndustryType();
    for (let value of response) {
      newobj = { ...value };
      result.push(newobj);
    }
    const newArrayOfObj = result.map(({ 0: objid, 1: name, ...rest }) => ({
      objid,
      name,
      ...rest,
    }));
    setIndustryType(newArrayOfObj);
  };
  //delete user Function
  const deleteUserAccountFunction = async () => {
    setIsLoading(true);

    let response = await UserActions.deleteUserAccount(indv.indvid);
    if (response.success === true) {
      message.success(response.message);
      UserActions.logout(dispatch);
    } 
    setIsLoading(false);
  };
  return (
    <ContainerWrapper title={mode === "add" ? "Register" : "Update Profile"}>
      {
        <HelmetFyndr
          title="Profile"
          imgUrl={""}
          url={`${web}/preferences/profile`}
          descr="Profile"
        />
      }
      {biz ? (
        <Form>
          <Row gutter={[16, 0]}>
            {mode === "add" && (
              <>
                <Col xs={24} sm={12}>
                  <FormItem
                    {...formItemLayout}
                    name="business name"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                    help={errors.bizName?.message}
                    validateStatus={errors.bizName ? "error" : "success"}
                  >
                    <Controller
                      as={
                        <div
                          style={{
                            position: "relative",
                            height: "46px",
                          }}
                        >
                          <p
                            className="loc_p"
                            style={{ top: -10, left: "24px" }}
                          >
                            <span>
                              Business Name{" "}
                              {mode === "add" && (
                                <span style={{ color: "#ff4d4f" }}>*</span>
                              )}
                            </span>
                          </p>
                          <Input
                            type="text"
                            style={{
                              backgroundColor: "inherit",
                              height: "46px",
                              borderRadius: "10px",
                              width: "100%",
                              border: "1px solid rgba(230, 230, 230, 1)",
                              padding: "0px 24px",
                            }}
                          />
                        </div>
                      }
                      control={control}
                      defaultValue={formData.bizName}
                      name="bizName"
                    />
                  </FormItem>
                </Col>
                {mode === "add" && !indv.fname && (
                  <>
                    <Col xs={24} sm={12}>
                      <FormItem
                        {...formItemLayout}
                        name="first name"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                        help={errors.fname?.message}
                        validateStatus={errors.fname ? "error" : "success"}
                      >
                        <Controller
                          as={
                            <div
                              style={{
                                position: "relative",
                                height: "46px",
                              }}
                            >
                              <p
                                className="loc_p"
                                style={{
                                  top: -10,
                                  left: "24px",
                                }}
                              >
                                {" "}
                                <span>
                                  First Name{" "}
                                  {mode === "add" && (
                                    <span style={{ color: "#ff4d4f" }}>*</span>
                                  )}
                                </span>
                              </p>
                              <Input
                                type="text"
                                style={{
                                  height: "46px",
                                  borderRadius: "10px",
                                  width: "100%",
                                  border: "1px solid rgba(230, 230, 230, 1)",
                                  padding: "0px 24px",
                                }}
                              />
                            </div>
                          }
                          control={control}
                          defaultValue={formData.fname}
                          name="fname"
                        />
                      </FormItem>
                    </Col>
                    <Col xs={24} sm={12}>
                      <FormItem
                        {...formItemLayout}
                        name="last  name"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                        help={errors.lname?.message}
                        validateStatus={errors.lname ? "error" : "success"}
                      >
                        <Controller
                          as={
                            <div
                              style={{
                                position: "relative",
                                height: "46px",
                              }}
                            >
                              <p
                                className="loc_p"
                                style={{
                                  top: -10,
                                  left: "24px",
                                }}
                              >
                                <span>
                                  Last Name{" "}
                                  {mode === "add" && (
                                    <span style={{ color: "#ff4d4f" }}>*</span>
                                  )}
                                </span>
                              </p>
                              <Input
                                type="text"
                                style={{
                                  height: "46px",
                                  borderRadius: "10px",
                                  width: "100%",
                                  border: "1px solid rgba(230, 230, 230, 1)",
                                  padding: "0px 24px",
                                }}
                              />
                            </div>
                          }
                          control={control}
                          defaultValue={formData.lname}
                          name="lname"
                        />
                      </FormItem>
                    </Col>
                  </>
                )}
              </>
            )}

            {mode === "update" && (
              <>
                <Col xs={24} sm={12}>
                  <FormItem {...formItemLayout}>
                    <div
                      style={{
                        position: "relative",
                        height: "46px",
                      }}
                    >
                      <p
                        className="loc_p"
                        style={{
                          top: -10,
                          left: "24px",
                        }}
                      >
                        <span>Business Name</span>
                      </p>
                      <Input
                        type="text"
                        disabled={true}
                        defaultValue={formData.bizName}
                        style={{
                          backgroundColor: "inherit",
                          height: "46px",
                          borderRadius: "10px",
                          width: "100%",
                          border: "1px solid rgba(230, 230, 230, 1)",
                          padding: "0px 24px",
                        }}
                      />
                    </div>
                  </FormItem>
                </Col>
                <Col xs={24} sm={12}>
                  <FormItem {...formItemLayout}>
                    <div
                      style={{
                        position: "relative",
                        height: "46px",
                      }}
                    >
                      <p
                        className="loc_p"
                        style={{
                          top: -10,
                          left: "24px",
                        }}
                      >
                        <span>Email</span>
                      </p>
                      <Input
                        type="text"
                        disabled={true}
                        defaultValue={email}
                        style={{
                          backgroundColor: "inherit",
                          height: "46px",
                          borderRadius: "10px",
                          width: "100%",
                          border: "1px solid rgba(230, 230, 230, 1)",
                          padding: "0px 24px",
                        }}
                      />
                    </div>
                  </FormItem>
                </Col>
                <Col xs={24} sm={12}>
                  <FormItem
                    {...formItemLayout}
                    help={errors.handleName?.message}
                    validateStatus={errors.handleName ? "error" : "success"}
                  >
                    <Controller
                      as={
                        <div
                          style={{
                            position: "relative",
                            height: "46px",
                          }}
                        >
                          <p
                            className="loc_p"
                            style={{
                              top: -10,
                              left: "24px",
                            }}
                          >
                            <span>Fyndr Handle</span>
                          </p>
                          <Input
                            type="text"
                            prefix={<FiAtSign className="prefix" />}
                            defaultValue={formData.handle}
                            style={{
                              height: "46px",
                              borderRadius: "10px",
                              width: "100%",
                              border: "1px solid rgba(230, 230, 230, 1)",
                              padding: "0px 24px",
                            }}
                          />
                        </div>
                      }
                      control={control}
                      defaultValue={formData.handle}
                      name="handleName"
                    />
                  </FormItem>
                </Col>
              </>
            )}
            <Col xs={24} sm={12}>
              <FormItem
                {...formItemLayout}
                name="industry"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <div
                  style={{
                    position: "relative",
                    height: "46px",
                  }}
                >
                  <p
                    className="loc_p"
                    style={{
                      top: -10,
                      left: "24px",
                    }}
                  >
                    <span>
                      Industry{" "}
                      {mode === "add" && (
                        <span style={{ color: "#ff4d4f" }}>*</span>
                      )}
                    </span>
                  </p>
                  <Select
                    values={formData.industryvalue}
                    valueField="value"
                    labelField="label"
                    searchable
                    options={indstry}
                    onChange={(values) => {
                      values && setIndustryTypeError(null);
                      setFormData({ ...formData, industryvalue: values });
                    }}
                    style={{
                      width: "100%",
                      position: "absolute",
                      top: "0px",
                      left: 0,
                      height: "46px",
                      border: "1px solid rgba(230, 230, 230, 1)",
                      borderRadius: "10px",
                      marginRight: "8px",
                    }}
                    className="country-code-dropdown"
                    dropdownGap={2}
                  />
                  <Text type="danger">{industryTypeError}</Text>
                </div>
              </FormItem>
            </Col>
            <Col xs={24} sm={12}>
              <FormItem
                {...formItemLayout}
                help={errors.website?.message}
                validateStatus={errors.website ? "error" : "success"}
              >
                <Controller
                  as={
                    <div
                      style={{
                        position: "relative",
                        height: "46px",
                      }}
                    >
                      <p
                        className="loc_p"
                        style={{
                          top: -10,
                          left: "24px",
                        }}
                      >
                        <span>Website</span>
                      </p>

                      <Input
                        type="text"
                        defaultValue={additionData.website}
                        style={{
                          height: "46px",
                          borderRadius: "10px",
                          width: "100%",
                          border: "1px solid rgba(230, 230, 230, 1)",
                          padding: "0px 24px",
                        }}
                      />
                    </div>
                  }
                  control={control}
                  defaultValue={additionData.website}
                  name="website"
                />
              </FormItem>
            </Col>
            <Col xs={24} sm={12}>
              <FormItem
                {...formItemLayout}
                requiredMark="true"
                name="country"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <div
                  style={{
                    position: "relative",
                    height: "46px",
                  }}
                >
                  <p
                    className="loc_p"
                    style={{
                      top: -10,
                      left: "24px",
                    }}
                  >
                    <span>
                      Country{" "}
                      {mode === "add" && (
                        <span style={{ color: "#ff4d4f" }}>*</span>
                      )}
                    </span>
                  </p>
                  <ReactFlagsSelect
                    disabled={mode === "update"}
                    selectedSize={14}
                    optionsSize={14}
                    countries={
                      biz ? ["US"] : ["US", "AU", "CA", "GB", "IN", "NZ"]
                    }
                    selected={country}
                    onSelect={(code) => {
                      code && setCountryError(null);
                      setCountry(code);

                      setFormData({ ...formData, postalCode: "" });
                      setFormData({ ...formData, city: "" });
                      setFormData({ ...formData, state: "" });
                      setValue("state", "");
                      setValue("city", "");
                      setValue("postalCode", "");
                    }}
                    className="profile-custom-select"
                  />
                  <Text type="danger">{countryError}</Text>
                </div>
              </FormItem>
            </Col>
            <Col xs={24} sm={12}>
              <FormItem
                {...formItemLayout}
                name="phone number"
                rules={[
                  {
                    required: true,
                  },
                ]}
                help={errors.phone?.message || errors.ctryCode?.message}
                validateStatus={
                  errors.phone || errors.ctryCode ? "error" : "success"
                }
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    height: "46px",
                    overflow: "hidden",
                  }}
                >
                  <p
                    className="loc_p"
                    style={{
                      top: -10,
                      left: "24px",
                    }}
                  >
                    <span>
                      Phone Number{" "}
                      {mode === "add" && (
                        <span style={{ color: "#ff4d4f" }}>*</span>
                      )}
                    </span>
                  </p>
                  <Controller
                    as={
                      <input
                        type="text"
                        disabled
                        style={{
                          width: "58px",
                          border: "1px solid rgba(230, 230, 230, 1)",
                          backgroundColor: "inherit",
                          textAlign: "center",
                          height: "100%",
                          marginRight: "12px",
                          borderRadius: "10px",
                          borderTopLeftRadius: "10px",
                          borderBottomLeftRadius: "10px",
                        }}
                        placeholder="+1"
                      />
                    }
                    name="ctryCode"
                    control={control}
                    defaultValue={formData.ctryCode}
                  />
                  <Controller
                    as={
                      <input
                        type="text"
                        style={{
                          flex: 1,
                          border: `1px solid ${(verified && mode==="add") ? "#32871E" : "rgba(230, 230, 230, 1)"}`,                            height: "100%",
                          height: "100%",
                          paddingLeft: "8px",
                          backgroundColor: "inherit",
                          borderTopLeftRadius: "10px",
                          borderBottomLeftRadius: "10px",
                          padding: "0px 24px",
                          borderBottomRightRadius : mode==="update" ? "10px" : "",
                          borderTopRightRadius : mode === "update" ? "10px" : "",
                        }}
                        disabled={mode === "update" && verified}
                      />
                    }
                    name="phone"
                    control={control}
                    defaultValue={formData.phone}
                  />
                  {mode === "add" && (
                    <>
                      {!verified ? (
                        <button
                          type="button"
                          onClick={() => !verified && sendCode()}
                          style={{
                            backgroundColor: "#007EFF",
                            color: "white",
                            border: "none",
                            borderBottomRightRadius: "10px",
                            borderTopRightRadius: "10px",
                            padding: "0 20px",
                            cursor: "pointer",
                            height: "100%",
                            fontWeight: "bold",
                          }}
                        >
                          Verify
                        </button>
                      ) : (
                        <button
                          type="button"
                          onClick={() => {
                            setValue("phone", null);
                            setValue("ctryCode", null);
                            setVerified(false);
                          }}
                          style={{
                            backgroundColor: "#007EFF",
                            color: "white",
                            border: "none",
                            borderBottomRightRadius: "10px",
                            borderTopRightRadius: "10px",
                            padding: "0 20px",
                            cursor: "pointer",
                            height: "100%",
                            fontWeight: "bold",
                          }}
                        >
                          <ReloadOutlined />
                        </button>
                      )}
                    </>
                  )}
                </div>
              </FormItem>
            </Col>
            <Col xs={24} sm={12}>
              <FormItem
                {...formItemLayout}
                name="postal code"
                rules={[
                  {
                    required: true,
                  },
                ]}
                help={errors.postalcode?.message}
                validateStatus={errors.postalcode ? "error" : "success"}
              >
                <div
                  style={{
                    position: "relative",
                    height: "46px",
                  }}
                >
                  <p
                    className="loc_p"
                    style={{
                      top: -10,
                      left: "24px",
                    }}
                  >
                    <span>
                      Postal code{" "}
                      {mode === "add" && (
                        <span style={{ color: "#ff4d4f" }}>*</span>
                      )}
                    </span>
                  </p>

                  <input
                    type="text"
                    ref={register}
                    className={`form-control`}
                    name="postalcode"
                    defaultValue={formData.postalCode}
                    onChange={(e) => {
                      setFormData({ ...formData, postalCode: e.target.value });
                      setFormData({ ...formData, city: "" });
                      setFormData({ ...formData, state: "" });
                      setValue("state", "");
                      setValue("city", "");
                    }}
                    onBlur={checkPostalCode}
                    style={{
                      height: "46px",
                      borderRadius: "10px",
                      width: "100%",
                      border: "1px solid rgba(230, 230, 230, 1)",
                      padding: "0px 24px",
                    }}
                  />
                </div>
              </FormItem>
            </Col>
            <Col xs={24} sm={12}>
              <FormItem
                {...formItemLayout}
                name="address1"
                rules={[
                  {
                    required: true,
                  },
                ]}
                help={errors.addressLine1?.message}
                validateStatus={errors.addressLine1 ? "error" : "success"}
              >
                <div
                  style={{
                    position: "relative",
                    height: "46px",
                  }}
                >
                  <p
                    className="loc_p"
                    style={{
                      top: -10,
                      left: "24px",
                    }}
                  >
                    <span>
                      {`${biz && "Business "}Address 1 `}{" "}
                      {mode === "add" && (
                        <span style={{ color: "#ff4d4f" }}>*</span>
                      )}
                    </span>
                  </p>
                  <input
                    ref={register}
                    type="text"
                    className={`form-control`}
                    name="addressLine1"
                    defaultValue={formData.addressLine1}
                    style={{
                      height: "46px",
                      borderRadius: "10px",
                      width: "100%",
                      border: "1px solid rgba(230, 230, 230, 1)",
                      padding: "0px 24px",
                    }}
                  />
                </div>
              </FormItem>
            </Col>
            <Col xs={24} sm={12}>
              <FormItem
                {...formItemLayout}
                help={errors.addressLine2?.message}
                validateStatus={errors.addressLine2 ? "error" : "success"}
              >
                <div
                  style={{
                    position: "relative",
                    height: "46px",
                  }}
                >
                  <p
                    className="loc_p"
                    style={{
                      top: -10,
                      left: "24px",
                    }}
                  >
                    <span>Address 2</span>
                  </p>

                  <input
                    ref={register}
                    type="text"
                    style={{
                      height: "46px",
                      borderRadius: "10px",
                      width: "100%",
                      border: "1px solid rgba(230, 230, 230, 1)",
                      padding: "0px 24px",
                    }}
                    className="form-control  inputQuick"
                    name="addressLine2"
                    defaultValue={formData.addressLine2}
                  />
                </div>
              </FormItem>
            </Col>
            <Col xs={24} sm={12}>
              <FormItem
                {...formItemLayout}
                name="city"
                rules={[
                  {
                    required: true,
                  },
                ]}
                help={errors.city?.message}
                validateStatus={errors.city ? "error" : "success"}
              >
                <Controller
                  name="city"
                  control={control}
                  defaultValue={formData.city}
                  as={
                    <div
                      style={{
                        position: "relative",
                        height: "46px",
                      }}
                    >
                      <p
                        className="loc_p"
                        style={{
                          top: -10,
                          left: "24px",
                        }}
                      >
                        <span>
                          City{" "}
                          {mode === "add" && (
                            <span style={{ color: "#ff4d4f" }}>*</span>
                          )}
                        </span>
                      </p>

                      <Input
                        disabled={true}
                        type="text"
                        value={formData.city}
                        style={{
                          backgroundColor: "inherit",
                          height: "46px",
                          borderRadius: "10px",
                          width: "100%",
                          border: "1px solid rgba(230, 230, 230, 1)",
                          padding: "0px 24px",
                        }}
                      />
                    </div>
                  }
                />
              </FormItem>
            </Col>
            <Col xs={24} sm={12}>
              <FormItem
                {...formItemLayout}
                name="state"
                rules={[
                  {
                    required: true,
                  },
                ]}
                help={errors.state?.message}
                validateStatus={errors.state ? "error" : "success"}
              >
                <Controller
                  name="state"
                  control={control}
                  defaultValue={formData.state}
                  as={
                    <div
                      style={{
                        position: "relative",
                        height: "46px",
                      }}
                    >
                      <p
                        className="loc_p"
                        style={{
                          top: -10,
                          left: "24px",
                        }}
                      >
                        <span>
                          State{" "}
                          {mode === "add" && (
                            <span style={{ color: "#ff4d4f" }}>*</span>
                          )}
                        </span>
                      </p>

                      <Input
                        disabled={true}
                        type="text"
                        value={formData.state}
                        style={{
                          backgroundColor: "inherit",
                          height: "46px",
                          borderRadius: "10px",
                          width: "100%",
                          border: "1px solid rgba(230, 230, 230, 1)",
                          padding: "0px 24px",
                        }}
                      />
                    </div>
                  }
                />
              </FormItem>
            </Col>
            <Col span={24} style={{ height: tags.length > 0 ? "8rem" : "" }}>
              <FormItem
                {...formItemLayout}
                help={errors.tags?.message}
                validateStatus={errors.tags ? "error" : "success"}
              >
                <div
                  style={{
                    position: "relative",
                    height: "46px",
                  }}
                >
                  <p
                    className="loc_p"
                    style={{
                      top: -10,
                      left: "24px",
                    }}
                  >
                    <span>Tags</span>
                  </p>

                  <Input
                    ref={refTag}
                    type="text"
                    placeholder="Tags will be used to search your business"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onPressEnter={(event) => addTag(event)}
                    style={{
                      height: "46px",
                      borderRadius: "10px",
                      width: "100%",
                      border: "1px solid rgba(230, 230, 230, 1)",
                      padding: "0px 24px",
                    }}
                  />
                  {tags.map((item, key) => {
                    return (
                      <Tag
                        closable
                        onClose={() => removeforTags(key)}
                        key={item.toString()}
                        style={{
                          padding: ".5rem 1rem ",
                          borderRadius: "2rem",
                          margin: "12px 0px",
                        }}
                      >
                        {item}
                      </Tag>
                    );
                  })}
                </div>
              </FormItem>
            </Col>
            {mode === "add" && (
              <Col span={24}>
                <FormItem {...formItemLayout}>
                  <Controller
                    as={
                      <div
                        style={{
                          position: "relative",
                          height: "46px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <p
                          className="loc_p"
                          style={{
                            top: -10,
                            left: "24px",
                          }}
                        >
                          <span>Referral Code/Promo Code</span>
                        </p>
                        <Input
                          disabled={refVerify ? true : false}
                          type="text"
                          style={{
                            flex: 1,
                            border: "1px solid rgba(230, 230, 230, 1)",
                            height: "100%",
                            paddingLeft: "8px",
                            borderBottomLeftRadius: "10px",
                            borderTopLeftRadius: "10px",
                            padding: "0px 24px",
                          }}
                        />
                        <button
                          type="button"
                          disabled={isloading}
                          onClick={() => {
                            if (!refVerify) verifyRefCode();
                            else {
                              setValue("referralCode", null);
                              setRefVerify(false);
                            }
                          }}
                          style={{
                            backgroundColor: "#007EFF",
                            color: "white",
                            border: "none",
                            borderBottomRightRadius: "10px",
                            borderTopRightRadius: "10px",
                            padding: "0 20px",
                            cursor: "pointer",
                            height: "100%",
                            fontWeight: "bold",
                          }}
                        >
                          {!refVerify ? "Verify" : <ReloadOutlined />}{" "}
                          {isloading && <Loader />}
                        </button>
                      </div>
                    }
                    name="referralCode"
                    defaultValue={linkrefferalcode}
                    control={control}
                  />
                </FormItem>
              </Col>
            )}

            {mode === "add" && (
              <Col span={24}>
                <Form.Item name="findUsId" style={{ marginBottom: "30px" }}>
                  <div style={{ position: "relative", width: "100%" }}>
                    <p
                      style={{
                        position: "absolute",
                        top: -10,
                        left: "10px",
                        backgroundColor: "white",
                        padding: "0 4px",
                        zIndex: 999,
                      }}
                    >
                      Where did you find us?
                    </p>
                    <Card
                      style={{
                        borderRadius: "10px",
                        border: "1px solid #D3D6E1",
                        width: "100%",
                      }}
                    >
                      <Radio.Group
                        onChange={handleRadioChange}
                        value={findUsId}
                      >
                        <Row gutter={[0, 0]}>
                          {findUsOptionList.map(
                            (item) =>
                              item.active && (
                                <Col key={item.id} span={6}>
                                  <Radio value={item.id}>{item.options}</Radio>
                                </Col>
                              )
                          )}
                        </Row>
                      </Radio.Group>
                    </Card>
                  </div>
                </Form.Item>
              </Col>
            )}

            {mode !== "add" && (
              <Col span={24}>
                <FormItem
                  label="Status"
                  help={errors.tags?.message}
                  validateStatus={errors.tags ? "error" : "success"}
                >
                  <Switch
                    title="Profile Status"
                    defaultChecked={
                      formData.accountStatus === "ACTIVE" ? true : false
                    }
                    disabled={roleType === ROLE_SUPPORT ? true : false}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        accountStatus: e === true ? "ACTIVE" : "INACTIVE",
                      })
                    }
                    size="large"
                    checkedChildren="Active"
                    unCheckedChildren="Inactive"
                  />
                  {formData.accountStatus === "INACTIVE" && (
                    <Alert
                      message="By inactivating the account, User will not be able to search your business on Fyndr website or Mobile application."
                      banner
                      closable
                    />
                  )}
                </FormItem>
              </Col>
            )}
            {mode === "add" && (
              <Col span={24}>
                <Space direction="horizontal">
                  <Checkbox
                    checked={termsAccepted}
                    onChange={(e) => setTermsAccepted(e.target.checked)}
                  />
                  <Text>I agree with</Text>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={`${web}/legal/terms.html`}
                  >
                    Fyndr's terms of use,
                  </a>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={`${web}/legal/privacy.html`}
                  >
                    Privacy Policy,
                  </a>
                  &
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={`${web}/legal/agreement.html`}
                  >
                    Business Terms & Conditions
                  </a>
                </Space>
              </Col>
            )}

            {mode !== "add" && (
              <Col span={24}>
                <Space direction="horizontal">
                  <Checkbox
                    onChange={() => emailSubscription()}
                    checked={isDerivedChecked}
                    disabled={isDerivedChecked}
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "#257CDB",
                    }}
                  >
                    Subscribe to promotional email
                  </Checkbox>
                </Space>
              </Col>
            )}

            <Col
              span={24}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FormItem>
                <Button
                  disabled={mode === "add" && !verified ? true : false}
                  onClick={() => {
                    checkPostalCode();
                    if (!errors.state?.message && !errors.city?.message) {
                      handleSubmit(
                        (data) => {
                          if (data.referralCode === null) {
                            submitForm(data);
                          } else if (data?.referralCode !== null && refVerify) {
                            submitForm(data);
                          } else {
                            if (
                              mode !== "update" &&
                              data.referralCode !== null &&
                              !refVerify &&
                              data.referralCode !== ""
                            ) {
                              message.error("Please verify code to proceed");
                            } else {
                              submitForm(data);
                            }
                          }
                        },
                        (err) => console.log(err)
                      )();
                    }
                  }}
                  type="primary"
                  size="large"
                  style={{
                    marginTop: "2rem",
                    height: "46px",
                    width: "300px",
                    background: "#223369",
                    borderRadius: "10px",
                  }}
                >
                  {mode === "add" ? "Register" : "Update"}{" "}
                  {isloading && <Loader />}
                </Button>
              </FormItem>
            </Col>
          </Row>
        </Form>
      ) : (
        <Form>
          <Row gutter={[16, 0]}>
            {mode === "add" && !indv.fname && (
              <>
                <Col span={12}>
                  <FormItem
                    {...formItemLayout}
                    name="first name"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                    help={errors.fname?.message}
                    validateStatus={errors.fname ? "error" : "success"}
                  >
                    <Controller
                      as={
                        <div
                          style={{
                            position: "relative",
                            height: "46px",
                          }}
                        >
                          <p
                            className="loc_p"
                            style={{
                              top: -10,
                              left: "24px",
                            }}
                          >
                            <span>
                              First Name{" "}
                              {mode === "add" && (
                                <span style={{ color: "#ff4d4f" }}>*</span>
                              )}
                            </span>
                          </p>
                          <Input
                            type="text"
                            style={{
                              height: "46px",
                              borderRadius: "10px",
                              width: "100%",
                              border: "1px solid rgba(230, 230, 230, 1)",
                              padding: "0px 24px",
                            }}
                          />
                        </div>
                      }
                      control={control}
                      defaultValue={formData.fname}
                      name="fname"
                    />
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem
                    {...formItemLayout}
                    name="last  name"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                    help={errors.lname?.message}
                    validateStatus={errors.lname ? "error" : "success"}
                  >
                    <Controller
                      as={
                        <div
                          style={{
                            position: "relative",
                            height: "46px",
                          }}
                        >
                          <p
                            className="loc_p"
                            style={{
                              top: -10,
                              left: "24px",
                            }}
                          >
                            <span>
                              Last Name{" "}
                              {mode === "add" && (
                                <span style={{ color: "#ff4d4f" }}>*</span>
                              )}
                            </span>
                          </p>

                          <Input
                            type="text"
                            style={{
                              height: "46px",
                              borderRadius: "10px",
                              width: "100%",
                              border: "1px solid rgba(230, 230, 230, 1)",
                              padding: "0px 24px",
                            }}
                          />
                        </div>
                      }
                      control={control}
                      defaultValue={formData.lname}
                      name="lname"
                    />
                  </FormItem>
                </Col>
              </>
            )}

            {mode !== "add" && (
              <>
                <Col span={12}>
                  <FormItem {...formItemLayout}>
                    <div
                      style={{
                        position: "relative",
                        height: "46px",
                      }}
                    >
                      <p
                        className="loc_p"
                        style={{
                          top: -10,
                          left: "24px",
                        }}
                      >
                        <span>Name</span>
                      </p>
                      <Input
                        disabled={true}
                        defaultValue={`${formData.fname} ${formData.lname}`}
                        style={{
                          height: "46px",
                          backgroundColor: "inherit",
                          borderRadius: "10px",
                          width: "100%",
                          border: "1px solid rgba(230, 230, 230, 1)",
                          padding: "0px 24px",
                        }}
                      />
                    </div>
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem {...formItemLayout}>
                    <div
                      style={{
                        position: "relative",
                        height: "46px",
                      }}
                    >
                      <p
                        className="loc_p"
                        style={{
                          top: -10,
                          left: "24px",
                        }}
                      >
                        <span>Email</span>
                      </p>
                      <Input
                        disabled={true}
                        defaultValue={email}
                        style={{
                          height: "46px",
                          backgroundColor: "inherit",
                          borderRadius: "10px",
                          width: "100%",
                          border: "1px solid rgba(230, 230, 230, 1)",
                          padding: "0px 24px",
                        }}
                      />
                    </div>
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem
                    {...formItemLayout}
                    help={errors.handleName?.message}
                    validateStatus={errors.handleName ? "error" : "success"}
                  >
                    <Controller
                      as={
                        <div
                          style={{
                            position: "relative",
                            height: "46px",
                          }}
                        >
                          <p
                            className="loc_p"
                            style={{
                              top: -10,
                              left: "24px",
                            }}
                          >
                            <span>Fyndr Handle</span>
                          </p>

                          <Input
                            type="text"
                            prefix={<FiAtSign className="prefix" />}
                            style={{
                              height: "46px",
                              borderRadius: "10px",
                              width: "100%",
                              border: "1px solid rgba(230, 230, 230, 1)",
                              padding: "0px 24px",
                            }}
                            defaultValue={formData.handle}
                          />
                        </div>
                      }
                      control={control}
                      defaultValue={formData.handle}
                      name="handleName"
                    />
                  </FormItem>
                </Col>
              </>
            )}
            <Col span={12}>
              <FormItem
                {...formItemLayout}
                help={errors.yob?.message}
                validateStatus={errors.yob ? "error" : "success"}
              >
                <Controller
                  as={
                    <div
                      style={{
                        position: "relative",
                        height: "46px",
                      }}
                    >
                      <p
                        className="loc_p"
                        style={{
                          top: -10,
                          left: "24px",
                        }}
                      >
                        <span>Year of birth</span>
                      </p>
                      <Input
                        type="text"
                        defaultValue={formData.yob}
                        style={{
                          height: "46px",
                          borderRadius: "10px",
                          width: "100%",
                          border: "1px solid rgba(230, 230, 230, 1)",
                          padding: "0px 24px",
                        }}
                      />{" "}
                    </div>
                  }
                  control={control}
                  defaultValue={formData.yob}
                  name="yob"
                />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                {...formItemLayout}
                help={errors.gender?.message}
                validateStatus={errors.gender ? "error" : "success"}
              >
                <div
                  style={{
                    position: "relative",
                    height: "46px",
                  }}
                >
                  <p
                    className="loc_p"
                    style={{
                      top: -10,
                      left: "24px",
                    }}
                  >
                    <span>Gender</span>
                  </p>

                  <Dropdown
                    overlay={genderMenu}
                    style={{
                      height: "46px",
                      borderRadius: "10px",
                      width: "100%",
                      border: "1px solid rgba(230, 230, 230, 1)",
                      padding: "0px 24px",
                    }}
                  >
                    <Button
                      style={{
                        height: "46px",
                        borderRadius: "10px",
                        width: "100%",
                        border: "1px solid rgba(230, 230, 230, 1)",
                        padding: "0px 24px",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      {`${
                        formData.gender
                          ? gender_list.filter(
                              (row) => row.key === formData.gender
                            )[0]["value"]
                          : "Select Gender"
                      }`}{" "}
                      <DownOutlined />
                    </Button>
                  </Dropdown>
                </div>
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                {...formItemLayout}
                name="country"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <div
                  style={{
                    position: "relative",
                    height: "46px",
                  }}
                >
                  <p
                    className="loc_p"
                    style={{
                      top: -10,
                      left: "24px",
                    }}
                  >
                    <span>
                      Country{" "}
                      {mode === "add" && (
                        <span style={{ color: "#ff4d4f" }}>*</span>
                      )}
                    </span>
                  </p>

                  <ReactFlagsSelect
                    className="profile-custom-select"
                    style={{
                      height: "46px",
                      borderRadius: "10px",
                      width: "100%",
                      border: "1px solid rgba(230, 230, 230, 1)",
                      padding: "0px 24px",
                    }}
                    selectedSize={14}
                    optionsSize={14}
                    countries={
                      biz ? ["US"] : ["US", "AU", "CA", "GB", "IN", "NZ"]
                    }
                    selected={country}
                    onSelect={(code) => {
                      if (mode === "add") {
                        countryCode(code);
                      }

                      setCountry(code);
                      setFormData({ ...formData, postalCode: "" });
                      setFormData({ ...formData, city: "" });
                      setFormData({ ...formData, state: "" });
                      setValue("state", "");
                      setValue("city", "");
                      setValue("postalCode", "");
                    }}
                  />
                </div>
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                {...formItemLayout}
                name="phone number"
                rules={[
                  {
                    required: true,
                  },
                ]}
                help={errors.phone?.message || errors.ctryCode?.message}
                validateStatus={
                  errors.phone || errors.ctryCode ? "error" : "success"
                }
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    height: "46px",
                    overflow: "hidden",
                  }}
                >
                  <p
                    className="loc_p"
                    style={{
                      top: -10,
                      left: "24px",
                    }}
                  >
                    <span>
                      Phone Number{" "}
                      {mode === "add" && (
                        <span style={{ color: "#ff4d4f" }}>*</span>
                      )}
                    </span>
                  </p>
                  <Controller
                    as={
                      <Input
                        disabled={true}
                        type="text"
                        style={{
                          width: "58px",
                          backgroundColor: "inherit",
                          border: "1px solid rgba(230, 230, 230, 1)",                            height: "100%",
                          textAlign: "center",
                          height: "100%",
                          marginRight: "12px",
                          borderRadius: "10px",
                          borderTopLeftRadius: "10px",
                          borderBottomLeftRadius: "10px",
                        }}
                        placeholder="+ 1"
                      />
                    }
                    control={control}
                    defaultValue={formData.ctryCode}
                    name="ctryCode"
                  />
                  <Controller
                    as={
                      <Input
                        disabled={mode === "update" && verified ? true : false}
                        style={{
                          flex: 1,
                          border: `1px solid ${(verified && mode==="add") ? "#32871E" : "rgba(230, 230, 230, 1)"}`,                            height: "100%",
                          backgroundColor: "inherit",
                          height: "100%",
                          paddingLeft: "8px",
                          borderTopLeftRadius: "10px",
                          borderBottomLeftRadius: "10px",
                          padding: "0px 24px",
                          borderBottomRightRadius : mode==="update" ? "10px" : "",
                          borderTopRightRadius : mode === "update" ? "10px" : "",
                        }}
                        type="text"
                        placeholder="Phone Number"
                      />
                    }
                    name="phone"
                    defaultValue={formData.phone}
                    control={control}
                  />
                  {mode === "add" && (
                    <>
                      {!verified ? (
                        <button
                          type="button"
                          onClick={() => !verified && sendCode()}
                          style={{
                            backgroundColor: "#007EFF",
                            color: "white",
                            border: "none",
                            borderBottomRightRadius: "10px",
                            borderTopRightRadius: "10px",
                            padding: "0 20px",
                            cursor: "pointer",
                            height: "100%",
                            fontWeight: "bold",
                          }}
                        >
                          Verify
                        </button>
                      ) : (
                        <button
                          type="button"
                          onClick={() => {
                            setValue("phone", null);
                            setValue("ctryCode", null);
                            setVerified(false);
                          }}
                          style={{
                            backgroundColor: "#007EFF",
                            color: "white",
                            border: "none",
                            borderBottomRightRadius: "10px",
                            borderTopRightRadius: "10px",
                            padding: "0 20px",
                            cursor: "pointer",
                            height: "100%",
                            fontWeight: "bold",
                          }}
                        >
                          <ReloadOutlined />
                        </button>
                      )}
                    </>
                  )}
                </div>
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                {...formItemLayout}
                name="postal code"
                rules={[
                  {
                    required: true,
                  },
                ]}
                help={errors.postalcode?.message}
                validateStatus={errors.postalcode ? "error" : "success"}
              >
                <div
                  style={{
                    position: "relative",
                    height: "46px",
                  }}
                >
                  <p
                    className="loc_p"
                    style={{
                      top: -10,
                      left: "24px",
                    }}
                  >
                    <span>
                      Postal Code{" "}
                      {mode === "add" && (
                        <span style={{ color: "#ff4d4f" }}>*</span>
                      )}
                    </span>
                  </p>

                  <input
                    type="text"
                    ref={register}
                    style={{
                      height: "46px",
                      borderRadius: "10px",
                      width: "100%",
                      border: "1px solid rgba(230, 230, 230, 1)",
                      padding: "0px 24px",
                    }}
                    className={`form-control`}
                    name="postalcode"
                    defaultValue={formData.postalCode}
                    onChange={(e) => {
                      setFormData({ ...formData, postalCode: e.target.value });
                    }}
                    onBlur={checkPostalCode}
                  />
                </div>
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                {...formItemLayout}
                help={errors.addressLine1?.message}
                validateStatus={errors.addressLine1 ? "error" : "success"}
              >
                <div
                  style={{
                    position: "relative",
                    height: "46px",
                  }}
                >
                  <p
                    className="loc_p"
                    style={{
                      top: -10,
                      left: "24px",
                    }}
                  >
                    <span>Address 1 </span>
                  </p>

                  <input
                    ref={register}
                    type="text"
                    style={{
                      height: "46px",
                      borderRadius: "10px",
                      width: "100%",
                      border: "1px solid rgba(230, 230, 230, 1)",
                      padding: "0px 24px",
                    }}
                    className={`form-control`}
                    name="addressLine1"
                    defaultValue={formData.addressLine1}
                  />
                </div>
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                {...formItemLayout}
                help={errors.addressLine2?.message}
                validateStatus={errors.addressLine2 ? "error" : "success"}
              >
                <div
                  style={{
                    position: "relative",
                    height: "46px",
                  }}
                >
                  <p
                    className="loc_p"
                    style={{
                      top: -10,
                      left: "24px",
                    }}
                  >
                    <span>Address 2</span>
                  </p>

                  <input
                    ref={register}
                    type="text"
                    className="form-control  inputQuick"
                    name="addressLine2"
                    style={{
                      height: "46px",
                      borderRadius: "10px",
                      width: "100%",
                      border: "1px solid rgba(230, 230, 230, 1)",
                      padding: "0px 24px",
                    }}
                    defaultValue={formData.addressLine2}
                  />
                </div>
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                {...formItemLayout}
                name="city"
                rules={[
                  {
                    required: true,
                  },
                ]}
                help={errors.city?.message}
                validateStatus={errors.city ? "error" : "success"}
              >
                <Controller
                  name="city"
                  control={control}
                  defaultValue={formData.city}
                  as={
                    <div
                      style={{
                        position: "relative",
                        height: "46px",
                      }}
                    >
                      <p
                        className="loc_p"
                        style={{
                          top: -10,
                          left: "24px",
                        }}
                      >
                        <span>
                          City{" "}
                          {mode === "add" && (
                            <span style={{ color: "#ff4d4f" }}>*</span>
                          )}
                        </span>
                      </p>

                      <Input
                        disabled={true}
                        type="text"
                        style={{
                          height: "46px",
                          borderRadius: "10px",
                          backgroundColor: "inherit",
                          width: "100%",
                          border: "1px solid rgba(230, 230, 230, 1)",
                          padding: "0px 24px",
                        }}
                        value={formData.city}
                      />
                    </div>
                  }
                />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                {...formItemLayout}
                name="state"
                rules={[
                  {
                    required: true,
                  },
                ]}
                help={errors.state?.message}
                validateStatus={errors.state ? "error" : "success"}
              >
                <Controller
                  as={
                    <div
                      style={{
                        position: "relative",
                        height: "46px",
                      }}
                    >
                      <p
                        className="loc_p"
                        style={{
                          top: -10,
                          left: "24px",
                        }}
                      >
                        <span>
                          State{" "}
                          {mode === "add" && (
                            <span style={{ color: "#ff4d4f" }}>*</span>
                          )}
                        </span>
                      </p>

                      <Input
                        disabled={true}
                        type="text"
                        style={{
                          backgroundColor: "inherit",
                          height: "46px",
                          borderRadius: "10px",
                          width: "100%",
                          border: "1px solid rgba(230, 230, 230, 1)",
                          padding: "0px 24px",
                        }}
                        value={formData.state}
                      />
                    </div>
                  }
                  control={control}
                  name="state"
                  defaultValue={formData.state}
                />
              </FormItem>
            </Col>
            {mode === "add" && (
              <Col span={12}>
                <FormItem {...formItemLayout}>
                  <Controller
                    as={
                      <div
                        style={{
                          position: "relative",
                          height: "46px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <p
                          className="loc_p"
                          style={{
                            top: -10,
                            left: "24px",
                          }}
                        >
                          <span>Referral Code/Promo Code</span>
                        </p>
                        <Input
                          disabled={refVerify ? true : false}
                          style={{
                            flex: 1,
                            border: `1px solid ${refVerify ? "#32871E" : "rgba(230, 230, 230, 1)"}`,                            height: "100%",
                            paddingLeft: "8px",
                            borderBottomLeftRadius: "10px",
                            borderTopLeftRadius: "10px",
                            padding: "0px 24px",
                            backgroundColor:'inherit',
                          }}
                          type="text"
                        />
                        <button
                          type="button"
                          disabled={isloading}
                          onClick={() => {
                            if (!refVerify) verifyRefCode();
                            else {
                              setValue("referralCode", null);
                              setRefVerify(false);
                            }
                          }}
                          style={{
                            backgroundColor: "#007EFF",
                            color: "white",
                            border: "none",
                            
                            borderBottomRightRadius: "10px",
                            borderTopRightRadius: "10px",
                            padding: "0 20px",
                            cursor: "pointer",
                            height: "100%",
                            fontWeight: "bold",
                          }}
                        >
                          {!refVerify ? "Verify" : <ReloadOutlined />}{" "}
                          {isloading && <Loader />}
                        </button>
                      </div>
                    }
                    name="referralCode"
                    defaultValue={linkrefferalcode}
                    control={control}
                  />
                </FormItem>
              </Col>
            )}
            {mode === "add" && (
              <Col span={24}>
                <Form.Item name="findUsId" style={{ marginBottom: "30px" }}>
                  <div style={{ position: "relative" }}>
                    <p
                      style={{
                        position: "absolute",
                        top: -10,
                        left: "10px",
                        backgroundColor: "white",
                        padding: "0 8px",
                        zIndex: 999,
                        left:'24px',
                      }}
                    >
                      Where did you find us?
                    </p>
                    <Card
                      style={{
                        borderRadius: "8px",
                        border: "1px solid #D3D6E1",
                      }}
                    >
                      <Radio.Group
                        onChange={handleRadioChange}
                        value={findUsId}
                      >
                        <Row gutter={[16, 16]}>
                          {findUsOptionList.map(
                            (item) =>
                              item.active && (
                                <Col key={item.id} span={6}>
                                  <Radio value={item.id}>{item.options}</Radio>
                                </Col>
                              )
                          )}
                        </Row>
                      </Radio.Group>
                    </Card>
                  </div>
                </Form.Item>
              </Col>
            )}
            {mode === "add" && (
              <Col span={12}>
                <Space direction="horizontal">
                  <Checkbox
                    checked={termsAccepted}
                    onChange={(e) => setTermsAccepted(e.target.checked)}
                  />
                  <Text>I agree with </Text>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={`${web}/legal/terms.html`}
                  >
                    Fyndr's terms of use
                  </a>
                  &
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={`${web}/legal/privacy.html`}
                  >
                    Privacy Policy
                  </a>
                </Space>
              </Col>
            )}

            {mode !== "add" && (
              <Col span={12}>
                <Space direction="horizontal">
                  <Checkbox
                    onChange={() => emailSubscription()}
                    checked={isDerivedChecked}
                    disabled={isDerivedChecked}
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "#257CDB",
                    }}
                  >
                    Subscribe to promotional email
                  </Checkbox>
                </Space>
              </Col>
            )}

            <Col
              span={24}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FormItem>
                <Button
                  disabled={mode === "add" && !verified ? true : false}
                  onClick={() => {
                    checkPostalCode();
                    if (!errors.state?.message && !errors.city?.message) {
                      handleSubmit(
                        (data) => {
                          if (data.referralCode === null) {
                            submitForm(data);
                          } else if (data?.referralCode !== null && refVerify) {
                            submitForm(data);
                          } else {
                            if (
                              mode !== "update" &&
                              data.referralCode !== null &&
                              !refVerify &&
                              data.referralCode !== ""
                            ) {
                              message.error(
                                "Please verify referral code to proceed"
                              );
                            } else {
                              submitForm(data);
                            }
                          }
                        },
                        (err) => console.log(err)
                      )();
                    }
                  }}
                  type="primary"
                  size="large"
                  style={{
                    height: "46px",
                    width: "300px",
                    borderRadius: "10px",
                    backgroundColor: "#223369",
                  }}
                >
                  {mode === "add" ? "Register" : "Update"}{" "}
                  {isloading && <Loader />}
                </Button>
              </FormItem>
            </Col>
          </Row>
        </Form>
      )}
      <ModalVerifyMobileUI
        title="Enter Token"
        width="300"
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        onOk={() => {
          handleChangeToken();
        }}
        modalText="Enter the verification token received on your mobile number"
        secondModalText={getValues("ctryCode")}
        thirdModaltext={getValues("phone")}
        input={
          <Row
            align={"middle"}
            justify={isMobile() ? "space-between" : "space-around"}
          >
            <Col xl={16} xs={14} xxl={14} lg={16} md={16}>
              <Input
                maxLength={6}
                type="text"
                value={token}
                onChange={(e) => {
                  setToken(e.target.value);
                }}
                placeholder="Enter Verification Code"
                className="verifymobilemodal_input"
              />
            </Col>
            <Col xl={6} xs={8} sm={24} lg={6} xxl={6} md={6}>
              <Button
                className="verifymobilemodal_resend_button"
                disabled={timer === "00:00" ? false : true}
                type="primary"
                size="large"
                onClick={() => timer === "00:00" && resendCode()}
              >
                {timer !== "00:00" ? `Resend (${timer})` : `Resend`}{" "}
              </Button>
            </Col>
          </Row>
        }
      />
      <ModalVerifyMobileUI
        visible={modalVisibleSuccess}
        width="50%"
        footer={null}
        onCancel={() => setModalVisibleSuccess(false)}
        icon={<CheckCircleOutlined className="verifymobilemodal_icon" />}
        text="Verification Complete"
        button={
          <Button
            onClick={() => setModalVisibleSuccess(false)}
            className="verifymobilemodal_button"
          >
            Back
          </Button>
        }
      />

      <Modal
        title="Delete Account"
        okText="Agree & delete account"
        visible={viewDeleteModal}
        onCancel={() => setDeleteModal(false)}
        onOk={() => deleteUserAccountFunction()}
        confirmLoading={isloading}
      >
        <h6>Term & Condition for Deleting Account</h6>
        <br />
        <br />
        <div>
          <ol>
            <li>
              {" "}
              Business shall honor and fulfill all sold offers up to date.{" "}
            </li>
            <br />
            <li>
              Business shall be solely responsible for all disputes and
              non-fulfillment of all goods and services sold on Fyndr.
            </li>
            <br />
            <li>
              Business agrees to settle any law suites arising as a result of
              non-fulfillment of goods and services sold entirely at their cost
              and will hold Fyndr and its officials harmless of any liabilities
              whatsoever.
            </li>

            <br />
          </ol>
        </div>
      </Modal>
      {mode === "update" && (
        <Alert
          style={{ opacity: 0.8 }}
          message="Delete Account"
          // showIcon
          description="Once you delete an account, there is no going back. Please be certain."
          type="error"
          action={
            <Button
              onClick={() => setDeleteModal(true)}
              size="middle"
              danger
              disabled={roleType === ROLE_SUPPORT ? true : false}
            >
              Delete Account
            </Button>
          }
        />
      )}
    </ContainerWrapper>
  );
};
export default FormContainer;
