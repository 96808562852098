import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { Button, Row, Col, Typography, Space } from "antd";
import Axios from "axios";
import { FcPrevious, FcNext, FcDownload } from "react-icons/fc";
import ImageViewer from "react-simple-image-viewer";

const { Text } = Typography;

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const DocViewer = (props) => {
  const { uri, title, width } = props;
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  /*When document gets loaded successfully*/
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  const download = async (uri, title) => {
    let fileDownload = require("js-file-download");
    const res = await Axios.get(uri, {
      responseType: "blob",
    });
    fileDownload(res.data, `${title}.pdf`);
  };

  return (
    <div style={{ width: "100%" }}>
      {uri !== null &&
        uri !== undefined &&
        (uri.indexOf(".pdf") !== -1 ||
          uri.indexOf("data:application/pdf") !== -1) && (
          <>
            <Row justify="center">
              <Col align="center" span={24}>
                <Document
                  onLoadError={(error) => {
                    console.log(error);
                  }}
                  onSourceError={(error) => {
                    console.log(error);
                  }}
                  file={uri}
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  <Page width={width || 300} pageNumber={pageNumber} />
                </Document>
              </Col>
            </Row>
            <Row justify="center" style={{ width: "100%" }}>
              <Col span={24} align="center">
                <Space direction="horizontal" size="large">
                  <Text strong>
                    Page {pageNumber || (numPages ? 1 : "--")} of{" "}
                    {numPages || "--"}
                  </Text>

                  <Button
                    type="text"
                    disabled={pageNumber <= 1}
                    onClick={previousPage}
                    className="Pre"
                  >
                    <FcPrevious style={{ fontSize: "20px" }} />
                  </Button>

                  <Button
                    type="text"
                    disabled={pageNumber >= numPages}
                    onClick={nextPage}
                  >
                    <FcNext style={{ fontSize: "20px" }} />
                  </Button>

                  <Button type="text" onClick={() => download(uri, title)}>
                    <FcDownload style={{ fontSize: "26px" }} />
                  </Button>
                </Space>
              </Col>
            </Row>
          </>
        )}
      {uri !== null &&
        uri !== undefined &&
        uri.indexOf(".pdf") === -1 &&
        uri.indexOf("data:application/pdf") === -1 && (
          <>
              <img
                src={uri}
                onClick={() => setIsViewerOpen(true)}
                alt="img"
                style={{
                  width: "100%",
                  borderRadius: "10px",
                }}
                className={props.className}
              />
          </>
        )}
      {isViewerOpen && (
        <ImageViewer
          onClose={() => {
            setIsViewerOpen(false);
          }}
          activeIndex={0}
          src={[uri]}
        />
      )}
    </div>
  );
};

export default DocViewer;
