/* eslint-disable import/no-anonymous-default-export */
const data = [
  "business_terms_header",
  "business_terms_footer",
  "covid19_waiver",
  "facility_access",
  "interaction_covid19_business_message_a",
  "interaction_covid19_business_message_b",
  "interaction_covid19_label_a",
  "interaction_covid19_label_b",
  "interaction_covid19_message_a",
  "interaction_covid19_message_b",
  "business_referral_amount",
  "customer_referral_amount",
  "fyndr_cashback_percent",
  "wallet_percent_available",
  "fyndr_cash_offer_fee",
  "instore",
  "online",
  "all",
  "campaign_online_delivery_type_value",
  "campaign_instore_delivery_type_value",
  "business_slides_background_color_one",
  "business_slides_background_color_two",
  "business_slides_background_color_three",
  "business_slides_background_color_four",
  "business_slides_background_color_six",
  "business_slides_image_one",
  "business_slides_image_two",
  "business_slides_image_three",
  "business_slides_image_four",
  "business_slides_image_six",
  "business_slides_description_one",
  "business_slides_description_two",
  "business_slides_description_three",
  "business_slides_description_four",
  "business_slides_description_six",
  "business_slides_title_one",
  "business_slides_title_two",
  "business_slides_title_three",
  "business_slides_title_four",
  "business_slides_title_six",
  "individual_slides_image_one",
  "individual_slides_image_two",
  "individual_slides_image_four",
  "individual_slides_background_color_one",
  "individual_slides_background_color_two",
  "individual_slides_background_color_four",
  "individual_slides_title_one",
  "individual_slides_title_two",
  "individual_slides_title_four",
  "individual_slides_description_one",
  "individual_slides_description_two",
  "individual_slides_description_four",
  "campaign_search_page_size",

  "fyndr_offer_fee_percent_value",
  "fyndr_invoice_fee_percent_value",
  "fyndr_invoice_fee_flat_value",
  "fyndr_invoice_fee_selection_criteria",
  "stripe_fee_percent_value",
  "stripe_fee_flat_value",
  "stripe_fee_criteria",
  "campaign_promotion_fee_base_value",
  "campaign_promotion_free_count",
  "campaign_promotion_fee_per_email",
  "campaign_promotion_fee_per_push_notification",

  "business_directory_search_keyword_1",
  "business_directory_search_title_1",
  "business_directory_search_keyword_2",
  "business_directory_search_title_2",

  "fyndr_account_name",
  "fyndr_account_country",
  "fyndr_account_business_id",
  "fyndr_account_merchant_id",
  "fyndr_account_currency",
  "fyndr_account_currency_symbol",

  "featured_cmpn_one_month_fee",
  "featured_cmpn_three_month_fee",
  "featured_cmpn_six_month_fee"
];

export default {
  data,
};
