/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Loader from "../Loading/Loader";
import { Button, Col, List, Row, Table } from "antd";
import actions from "../../actions";
import CustomizedModal from "../CustomizedModal";
import moment from "moment";
import { useSelector } from "react-redux";

const UpcomingFeatured = (props) => {
  const { cmpn_id } = props;
  const [spinnerVisible, setSpinnerVisible] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [columns, setColumns] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalLocations, setModalLocations] = useState([]);

  const {
    indv: { userTimeZone },
  } = useSelector(({ auth }) => auth);

  useEffect(() => {
    upcomingPromotion();
  }, []);

  const capitalizeWords = (str) => {
    return str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const formatChannels = (channels) => {
    return channels
      .split(",")
      .filter((channel) => channel !== "in_app")
      .map((channel) => (channel === "mobile_push" ? "Phone" : channel))
      .map(capitalizeWords)
      .join(", ");
  };

  const showModal = (location) => {
    setModalLocations(location);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setModalLocations([]);
  };

  const dynamicColumns = [
    {
      title: "Promotion Type",
      dataIndex: "promotionType",
      key: "promotionType",
      width: 200,
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      render: (text, record) =>
        record.locationsList.length > 1 ? (
          <Button type="link" onClick={() => showModal(record.locationsList)}>
            {text}
          </Button>
        ) : (
          text
        ),
      width: 300,
    },
    {
      title: "Promotion Date",
      dataIndex: "promotionDate",
      key: "promotionDate",
      width: 150,
    },
    {
      title: "Promotion Time",
      dataIndex: "promotionTime",
      key: "promotionTime",
      width: 150,
    },
    {
      title: "Radius",
      dataIndex: "radius",
      key: "radius",
      render: (text, record) =>
        record.locationsList.length > 1 ? (
          <Button type="link" onClick={() => showModal(record.locationsList)}>
            {text}
          </Button>
        ) : (
          text
        ),
      width: 150,
    },
    {
      title: "Recipient",
      dataIndex: "recipient",
      key: "recipient",
      render: (text, record) =>
        record.locationsList.length > 1 ? (
          <Button type="link" onClick={() => showModal(record.locationsList)}>
            {text}
          </Button>
        ) : (
          text
        ),
      width: 150,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      width: 100,
    },
  ];

  const upcomingPromotion = async () => {
    setSpinnerVisible(true);

    const resp = await actions.fetchUpcomingPromotions(cmpn_id);
    if (resp.success && resp?.data) {
      const formattedDataSource = Object.keys(resp.data).map((key, index) => {
        const item = resp.data[key];

        const locationsList = Object.values(item.searchCriteria.locList);
        const locationSummary =
          locationsList.length > 1
            ? `${locationsList.length} Locations`
            : locationsList[0]?.addressName
                ?.split(",")
                .filter((part) => part.trim() && part.trim() !== "undefined")
                .join(",");
        const radiusSummary =
          locationsList.length > 1
            ? `${locationsList.length} Radius`
            : `${locationsList[0]?.radius} Mile`;
        const recipientSummary =
          locationsList.length > 1
            ? `${locationsList.length} Recipients`
            : locationsList[0]?.total;

        const promotionDate = new Date(item.promotionDateAndTime);
        return {
          key: index,
          promotionType: formatChannels(item.promotionType),
          location: locationSummary,
          promotionDate: promotionDate
            ? moment.tz(promotionDate , userTimeZone).format("MMM DD, YYYY")
            : "N/A",
          promotionTime: moment.tz(promotionDate, userTimeZone).format("HH:MM:SS A"),
          radius: radiusSummary,
          recipient: recipientSummary,
          amount: `${item.currencySymbol}${item.amount.toFixed(2)}`,
          locationsList: locationsList,
        };
      });

      setDataSource(formattedDataSource);
      setSpinnerVisible(false);
      setColumns(dynamicColumns);
    } else {
      setSpinnerVisible(false);
      setDataSource([]);
    }
  };

  return (
    <>
      {spinnerVisible ? (
        <Row
          style={{ height: "20rem", alignContent: "center" }}
          justify={"center"}
        >
          <Loader />
        </Row>
      ) : (
        <Table
          className="featuredSubs_table"
          columns={columns}
          dataSource={dataSource}
          pagination={false}
        />
      )}
      <CustomizedModal
        title="All Locations" 
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        modalWidth="800px"
        className="location-modal"
        content={
          <>
            <Row
              style={{
                width: "100%",
                fontWeight: "500",
                marginBottom: "10px",
                color: "#257CDB",
                fontSize: "16px"
              }}
            >
              <Col span={12}>Locations</Col>
              <Col span={2}></Col>
              <Col span={4} style={{ textAlign: "right" }}>
                Radius
              </Col>
              <Col span={2}></Col>
              <Col span={4} style={{ textAlign: "right" }}>
                Recipients
              </Col>
            </Row>
            <List
              dataSource={modalLocations}
              renderItem={(item) => (
                <List.Item>
                  <Row
                    style={{
                      width: "100%",
                      color: "#333333",
                      fontWeight: "400",
                      fontSize: "14px"
                    }}
                  >
                    <Col span={12}>{item.addressName}</Col>
                    <Col span={2}></Col>
                    <Col span={4} style={{ textAlign: "right" }}>
                      {item.radius} Miles
                    </Col>
                    <Col span={2}></Col>
                    <Col span={4} style={{ textAlign: "right" }}>
                      {item.total}
                    </Col>
                  </Row>
                </List.Item>
              )}
            />
          </>
        }
      />
    </>
  );
};

export default UpcomingFeatured;
