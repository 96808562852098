import { Button, Col, Divider, Row } from "antd";
import React, { useEffect, useState } from "react";

import { isMobile } from "../../common/util";
import AppointmentCard from "../AppointmentCard/AppointmentCard";
function AllAppointment(props) {
  const [, setAppointments] = useState(props.data || []);
  const [, setTimeZone] = useState("");
  useEffect(() => {
    setAppointments(props?.data || []);
    getTimeZone();
  }, [props?.data]);
  const getTimeZone = async () => {
    let timeZoneValue = await localStorage.getItem("timeZoneString");
    setTimeZone(timeZoneValue);
  };
  return (
    <div>
     
      {props?.data &&
        props?.data
          .filter((row) => row?.status === "active")
          .sort((a, b) => b.offerLimit - a.offerLimit)
          .map((item, key) => {
            
            return (
              <>
                {props.onSave ? (
                  <>
                  <AppointmentCard item={item} isBookingEnabled={item.isBookingEnabled}
                  editClick={props.editClick}
                  />
                  </>
                ) : (
                  <AppointmentCard
                    item={item}
                    isBookingEnabled={item.isBookingEnabled}
                    editClick={props.editClick}
                  />
                )}
              </>
            );
          })}

      {props.btn2Name && (
        <>
          <Divider className="customize_divider" style={{ marginTop: "0px" }} />
          <Row justify={"space-between"} style={{ padding: "24px" }}>
            <Col
              span={20}
              style={{
                alignSelf: "center",
                paddingBottom: isMobile() && "12px",
              }}
              xs={18}
              sm={20}
              md={14}
            ></Col>

            <Col>
              <Button type="primary" onClick={props.SaveOnClick}>
                {props.btn2Name}
              </Button>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
}

export default AllAppointment;
