import React from "react";
import { Row, Col, Card } from "antd";
import moment from "moment/moment";
import { isMobile } from "../../common/util";
import EditIcon from "../../assets/images/EditIcon.png";

const AppointmentCard = (props) => {
  const { item, isBookingEnabled, editClick } = props;

  if (isBookingEnabled) {
    if(item.qty>0){
    return (
     
      <div style={{marginTop:"12px"}}>
        {(item?.appointment?.length || item?.isBookingEnabled) > 0 && (
          <Card
            className="card-head"
            title={<span className="heading-business-name">{item.title}</span>}
            style={{
              borderColor: "#D3D6E1",
              borderRadius: "10px",
              border: "1px solid #D3D6E1",
              boxShadow: "0px 4px 4px 0px #0000001A",
            }}
          >
            {item?.appointment?.map((dateObject, i) => (
              <>
                {item?.appointment?.length > 0 && (
                  <Card
                    key={i}
                    style={{
                      border: "0px",
                      overflow: "hidden",
                    }}
                    size="small"
                  >
                    <Row gutter={[0, 40]}>
                      {Object.entries(dateObject).map(([date, timeSlots]) => (
                        <React.Fragment key={date}>
                          <Col
                            lg={14}
                            md={17}
                            xs={16}
                            style={{ margin: "10px 0" }}
                          >
                            <div className="text-card-appointments">
                              {date}
                              {"   "}
                              {`${moment(
                                timeSlots.startTime.slice(0, 5),
                                "HH:mm"
                              ).format("hh:mm A")} - ${moment(
                                timeSlots.endTime.slice(0, 5),
                                "HH:mm"
                              ).format("hh:mm A")} `}
                            </div>
                          </Col>
                          <Col
                            style={{
                              textAlign: "center",
                              margin: "10px 0",
                              paddingLeft: "0px",
                            }}
                            xs={2}
                            md={3}
                            lg={4}
                          >
                            <div
                              className="text-card-appointments"
                              style={{
                                border: "1px solid #00000033",
                                height: "20px",
                                width: "40px",
                                borderRadius: "5px",
                              }}
                            >
                              1
                            </div>
                          </Col>
                          <Col
                            style={{ textAlign: "right", margin: "10px 0" }}
                            xs={6}
                            md={4}
                            lg={6}
                          >
                            <div className="text-card-appointments">
                              {`${item.currencySymbol}${item?.offerPrice} `}
                              <img
                                src={EditIcon}
                                onClick={() => editClick(item, i)}
                                style={{
                                  height: isMobile() ? "1.1rem" : "0.9rem",
                                  width: isMobile() ? "1.1rem" : "0.9rem",
                                  cursor: "pointer",
                                }}
                                alt="EditIcon"
                              />
                            </div>
                          </Col>
                        </React.Fragment>
                      ))}
                    </Row>
                  </Card>
                )}
              </>
            ))}
            {item?.qty - (item?.appointment?.length || 0) > 0 && (
              <div
                style={{
                  marginBottom: "8px",
                }}
              >
                <Card className="inner-card" size="small">
                  <Row style={{ margin: "12px 12px 12px 12px" }}>
                    <Col
                      className="text-card-appointment-innerCard"
                      lg={14}
                      md={17}
                      xs={16}
                    >
                      {" "}
                      {item.title}
                    </Col>
                    <Col
                      className="text-card-appointment-innerCard"
                      style={{ textAlign: "center" }}
                      lg={4}
                      md={3}
                      xs={4}
                    >
                      <div
                        style={{
                          border: "1px solid #00000033",
                          height: "20px",
                          width: "40px",
                          borderRadius: "5px",
                        }}
                      >
                        {item?.qty - (item?.appointment?.length || 0)}
                      </div>
                    </Col>
                    <Col
                      className="text-card-appointment-innerCard"
                      style={{ textAlign: "right" }}
                      lg={6}
                      md={4}
                      xs={4}
                    >
                      {item.isBookingEnabled && (
                        <>{`${item.currencySymbol}${item?.offerPrice} `}</>
                      )}
                    </Col>
                  </Row>
                </Card>
                <Row>
                  <div className="lower-banner">
                    <span style={{ color: "#666666", marginTop: "5px" }}>
                      Schedule later
                    </span>
                  </div>
                </Row>
              </div>
            )}
          </Card>
        )}
      </div>
    );
  }
  else{
    return (<></>)
  }
  } else {
    if (item.qty > 0) {
      return (
        <div style={{ marginBottom: "8px" }}>
          <Card
            className="card-head"
            title={<span className="heading-business-name">{item.title}</span>}
            style={{
              borderColor: "#D3D6E1",
              fontSize: "12px",
              marginTop: "10px",
              borderRadius: "10px",
              border: "1px solid #D3D6E1",
              boxShadow: "0px 4px 4px 0px #0000001A",
            }}
          >
            <div style={{ marginBottom: "8px" }}>
              <Card className="inner-card" size="small">
                <Row style={{ margin: "12px 12px 12px 12px" }}>
                  <Col
                    className="text-card-appointment-innerCard"
                    lg={14}
                    md={17}
                    xs={16}
                  >
                    {" "}
                    {item.title}
                  </Col>
                  <Col
                    className="text-card-appointment-innerCard"
                    align={"center"}
                    style={{ textAlign: "center" }}
                    lg={4}
                    md={3}
                    xs={4}
                  >
                    <div
                      style={{
                        border: "1px solid #00000033",
                        height: "20px",
                        width: "40px",
                        borderRadius: "5px",
                      }}
                    >
                      {item?.qty - (item?.appointment?.length || 0)}
                    </div>
                  </Col>
                  <Col
                    className="text-card-appointment-innerCard"
                    style={{ textAlign: "right" }}
                    lg={6}
                    md={4}
                    xs={4}
                  >
                    <text>{`${item.currencySymbol}${item?.offerPrice} `}</text>
                  </Col>
                </Row>
              </Card>

              <Row>
                <div className="lower-banner">
                  <span style={{ color: "#666666", marginTop: "2rem" }}>
                    Item Purchased
                  </span>
                </div>
              </Row>
            </div>
          </Card>
        </div>
      );
    } else {
      return <></>;
    }
  }
};

export default AppointmentCard;