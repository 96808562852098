const usePageSEO = ({
    title,
    description,
    keywords = [],
    ogTitle,
    ogDescription,
    ogImage,
    ogUrl,
    twitterCard = "summary_large",
}) => {
}

export default usePageSEO;