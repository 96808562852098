import md5 from "react-native-md5";

export const encryptPassword = async (email, password) => {
  const pwd = md5.str_md5(email.toLowerCase().substring(0, 5) + password);
  return pwd;
};
export const toDateTime = (secs) => {
  var t = new Date(1970, 0, 1); // Epoch
  t.setSeconds(secs);
  return t;
};

//for the details page restriction of max limit
export const getMaxLimit = (item) => {
 let remainingOffers = getRemainingOffer(item.offerLimit,item.offerSold)
 let usageLimit= item.usageLimit

  //in case of unlimted
 
  if (remainingOffers === null && usageLimit!==-1) {
    return usageLimit;
  }

  if (remainingOffers === null && usageLimit === -1) {
    return 1000;
  }
 
  //when useage limit is not unlimited and offer remaining are less than usage limit
  if (usageLimit !== -1 && remainingOffers <= usageLimit) {
    return remainingOffers;
  }
  //when useage limit is not unlimited and offer remaining are more than usage limit
  else if (usageLimit !== -1 && remainingOffers >= usageLimit) {
    return usageLimit;
  } else {
    //when useage limit is  unlimited

    return Number(remainingOffers);
  }
};

export const getRemainingOffer =(offerLimit,offerSold)=>{
if(offerLimit === null){
return null
}
return Number(offerLimit-offerSold);
}