/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Row, Col, Button, Divider, Space, message, Modal } from "antd";
import Calendar from "react-calendar";
import calendarOutlineIcon from "../../assets/images/calendarOutlineIcon.png";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import { motion } from "framer-motion";
import moment from "moment";
import actions from "../../actions";
import UserActions from "../../actions";

import { useHistory } from "react-router-dom";
import { isMobile } from "../../common/util";
import { useGoogleLogin } from "react-google-login";

import { gapi } from 'gapi-script';

function SlotBooking(props) {
  const [showCalendar, setShowCalendar] = useState(false);
  const [showMsg, setShowMsg] = useState(false);
  const [selectedTime, setSelectedTime] = useState(null);
  const [infoToken, setInfoToken] = useState(null);
  const [selectedData, setSelectedData] = useState([]);
  const [selectedDate, setSelectedDate] = useState([new Date()]);
  const [, setSelectedDay] = useState("");
  const [, setFormatteddate] = useState();
  const [defTime, setDefTime] = useState([]);
  const [timeZone, setTimeZone] = useState("");
  const [isLocationTimezonePresent, setisLocationTimezonePresent] = useState(false);
  const [, setAppointmnets] = useState();
  const history = useHistory();
  const locId = props.locId || history?.location?.state?.catalogueDetail?.locId;
  const objId = props?.objId || history?.location?.state?.catalogueDetail?.objId;
  const CLIENT_ID = '283240579326-2jp6j8jvekme9o4ddcsesa8rl81evidf.apps.googleusercontent.com';

  // const CLIENT_ID = '1068896131755-vckn3h3sgl50i1or7ohn2m2hpfqco5dg.apps.googleusercontent.com';

  const [consentVisible, setConsentVisible] = useState(false)


  useEffect(() => {

    if (gapi?.auth2 && gapi?.auth2?.getAuthInstance()) {
      // Use the existing instance
      var authInstance = gapi?.auth2?.getAuthInstance();
      console.log("authInstance 1",authInstance);
      // Now you can use authInstance for authentication
    } else {
      // Initialize gapi.auth2 with your desired options
      gapi?.auth2?.init({
        client_id: CLIENT_ID,

      }).then(function (authInstance) {
        console.log("authInstance 2",authInstance);
        // Now you can use authInstance for authentication
      }).catch(function (error) {
        console.error('Error initializing Google Auth: ', error);
      });
    }
  }, [])
  useEffect(() => {
    if (props?.selectedData?.length > 0) {
      setSelectedData(props?.selectedData)
    }
  }, [props?.selectedData, objId])

  function findArrayLength(data, targetLocId, targetStartTime, targetEndTime, targetDate) {
    if (data[0]?.mitem) {
      const currentDate = new Date(targetDate);
      const options = {
        weekday: "short",
      };
      const dayName = `${currentDate.toLocaleDateString("en-US", {
        day: "numeric",
        month: "short",
      })} (${currentDate.toLocaleDateString("en-US", options)})`;
      const days = dayName.replace(",", "");
      let count = 0;
      let dataNew = data?.filter((item) => item?.mitem?.appointment?.length > 0)
      if (dataNew?.length > 0) {

        data?.forEach(item => {
          item?.mitem?.appointment?.forEach(appointment => {
            const dateKey = Object.keys(appointment)[0];
            const details = appointment[dateKey];
            if (details.locId === targetLocId &&
              details.startTime === targetStartTime &&
              details.endTime === targetEndTime &&
              dateKey === days) {
              count++;
            }
          });
        });
        return count;
      }


    } else {
      const currentDate = new Date(targetDate);
      const options = {
        weekday: "short",
      };
      const dayName = `${currentDate.toLocaleDateString("en-US", {
        day: "numeric",
        month: "short",
      })} (${currentDate.toLocaleDateString("en-US", options)})`;
      const days = dayName.replace(",", "");
      let count = 0;
      let dataNew = data?.filter((item) => item?.appointment?.length > 0)
      if (dataNew?.length > 0) {

        data?.forEach(item => {
          item?.appointment?.forEach(appointment => {
            const dateKey = Object.keys(appointment)[0];
            const details = appointment[dateKey];
            if (details.locId === targetLocId &&
              details.startTime === targetStartTime &&
              details.endTime === targetEndTime &&
              dateKey === days) {
              count++;
            }
          });
        });
        return count;
      }
    }
  }
  const { signIn } = useGoogleLogin({
    onSuccess: (codeResponse) => {
      localStorage.setItem("googleAccessToken", codeResponse?.accessToken);
      getGooglePermission()
    },
    onFailure: (error) => console.log('Login Failed:', error),
    clientId: CLIENT_ID,
    redirectUri: "https://api.dev.fyndr.us/appointment/googleCalendar/permission",
    scope: "profile https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events",
    accessType: "offline",
    responseType: "token",
    prompt: "consent",
    cookiePolicy: 'single_host_origin',
  })

  const formatTodayTomorrow = (date, daysToAdd) => {
    date.setDate(date.getDate() + daysToAdd);
    return `${date.toLocaleDateString("en-US", {
      day: "numeric",
      month: "short",
      year: "numeric",
    })} (${daysToAdd === 0 ? "Today" : "Tomorrow"})`;
  };

  const info = async () => {
    let token = await localStorage.getItem("googleAccessToken");
    setInfoToken(token)

  }
  const getGooglePermission = async () => {
    let result = await UserActions.GooglePermission();
    if (result.success) {
      const newData = organizeSelectedData();
      setSelectedData(newData);
      props.onNextClick(newData);
      setSelectedTime(null)

    } else {
      localStorage.removeItem("googleAccessToken")
      message.error(result?.msg)
    }

  }

  const todayDateString = formatTodayTomorrow(new Date(), 0);
  const [dateSelected, setDateSelected] = useState(todayDateString);
  const handleIconClick = () => {
    setShowCalendar(!showCalendar);
  };
  useEffect(() => {
    setTimeout(() => {
      setShowMsg(false);
    }, [8000]);
  }, [showMsg]);
  useEffect(() => {
    setInterval(() => {
      info()
    }, 1000)
  }, [props.appointments]);

  const handleDateChange = (date) => {
    const formatDate = moment(date).format().split("T")[0];
    const selectedDay = moment(date).format("dddd").toUpperCase();
    setDateSelected(date);
    setSelectedDay(selectedDay);
    setFormatteddate(formatDate);
    if (selectedData.length === props.qty) {
      // message.error("Please go and edit your date on checkout page");
    }
  };
  const handleTimeChange = (startTime, endTime, availableAppointments) => {
    if (props.qty <= 1) {
      setSelectedTime(
        {
          startTime: startTime,
          endTime: endTime,
          availableAppointments: availableAppointments,
        },
      );
    }
    else if (props.qty > 1) {
      const slotIndex = defTime.findIndex(
        (item) => item.startTime === startTime && item.endTime === endTime
      );
      if (slotIndex !== -1 && defTime[slotIndex].availableAppointments > 0) {
        const updatedDefTime = [...defTime];
        updatedDefTime[slotIndex] = {
          ...updatedDefTime[slotIndex],
          availableAppointments: defTime[slotIndex].availableAppointments,
        };
        setDefTime(updatedDefTime);
        setAppointmnets(availableAppointments)
        setSelectedTime(
          {
            startTime: startTime,
            endTime: endTime,
            availableAppointments: availableAppointments,
          },
        );
      } else {
        message.error(
          `No available appointments for ${startTime} - ${endTime}. Please choose another slot.`
        );
      }
    }
  };
  const getWeekDates = () => {
    const currentDate = selectedDate ? new Date(selectedDate) : new Date();
    const weekDaysWithDate = [];
    for (let i = 0; i < 7; i++) {
      const nextDate = new Date(currentDate);
      nextDate.setDate(currentDate.getDate() + i);
      weekDaysWithDate.push(new Date(nextDate));
    }
    return weekDaysWithDate;
  };

  const organizeSelectedData = () => {
    const currentDate = new Date(dateSelected);
    const options = {
      weekday: "short",
    };
    const dayName = `${currentDate.toLocaleDateString("en-US", {
      day: "numeric",
      month: "short",
    })} (${currentDate.toLocaleDateString("en-US", options)})`;
    const days = dayName.replace(",", "");
    if (selectedTime) {
      selectedTime.locId = locId;
      selectedTime.objId = objId;
      const newDataItem = {
        [days]: selectedTime,
      };
      let updatedData;
      if(props.catalogueAppointmentType === "APPOINTMENT_PER_CART") {
        updatedData = [newDataItem];
      } else {
        updatedData = [...selectedData, newDataItem];
      }
      return updatedData;
    }
    else {
      return selectedData;
    }

  };

  const formatWeekday = (date) => {
    return `${date.toLocaleDateString("en-US", {
      day: "numeric",
      month: "short",
      year: "numeric",
    })} (${date.toLocaleDateString("en-US", { weekday: "short" })})`;
  };

  useEffect(() => {
    setSelectedTime(selectedTime);
    setSelectedDate(selectedDate);
    setDateSelected(dateSelected)
  }, [selectedTime, selectedDate, dateSelected]);

  const dateData = [
    { date: todayDateString },
    { date: formatTodayTomorrow(new Date(), 1) },
    { date: formatWeekday(getWeekDates()[2]) },
    { date: formatWeekday(getWeekDates()[3]) },
    { date: formatWeekday(getWeekDates()[4]) },
    { date: formatWeekday(getWeekDates()[5]) },
    { date: formatWeekday(getWeekDates()[6]) },
  ];


  const showSlotTiming = async (day, date) => {
    let payload = {
      weekday: day,
      selectedDate: date,
    };
    let resp = await actions.fetchSlotTimings(payload, locId);
    if (resp?.success) {
      const newData = organizeSelectedData();
      if (newData.length > 0) {
        setisLocationTimezonePresent(resp?.resp?.isLocationTimezonePresent)
        setTimeZone(resp?.resp?.timeZone)
        setDefTime(resp?.resp?.list)
        localStorage.setItem("timeZoneString", `${resp?.resp?.isLocationTimezonePresent ? "Business location" : "Business"} Timezone: ${resp?.resp?.timeZone}`)
      } else {
        setTimeZone(resp?.resp?.timeZone)
        setDefTime(resp?.resp?.list)
        setDefTime(resp?.resp?.list)
        localStorage.setItem("timeZoneString", `${resp?.resp?.isLocationTimezonePresent ? "Business location" : "Business"} Timezone: ${resp?.resp?.timeZone}`)

      }
    } else {
      setDefTime([])
    }
  };


  useEffect(() => {
    const formatDate = moment(dateSelected).format().split("T")[0];
    const selectedDay = moment(dateSelected).format("dddd").toUpperCase();
    showSlotTiming(selectedDay, formatDate);
  }, [dateSelected, locId]);

  return (
    <div>
      <Row>
        {showCalendar ? (
          <div style={{ display: "flex", width: "100%", flexDirection: "column" }}>
            <Button
              size="large"
              onClick={handleIconClick}
              style={{ border: "none" }}
              icon={
                <img
                  src={calendarOutlineIcon}
                  style={{ height: "24px", width: "24px" }}
                  alt="calendarOutlineIcon"
                />
              }
            />
            <Calendar
              onChange={(date) => handleDateChange(date)}
              minDate={new Date()}
              className={"slotBook_calendar"}
            />
          </div>
        ) : (
          <Row style={{ width: "100%" }}>
            <Col span={24}>
                  {props.title && 
                  <>
                    <Row style={{ paddingTop: "12px", paddingBottom: "12px"}}>
                      <Col
                        span={16}
                        md={16}
                        xl={16}
                        xs={24}
                        sm={24}
                        style={{
                          color: "#3A3A3A",
                          fontWeight: 500,
                          fontSize: "20px",
                          padding: "0px 24px",
                        }}
                      >
                        <span>Appointment Booking For :</span> {props.title}
                      </Col>
                      <Col
                        span={8}
                        md={8}
                        xl={8}
                        xs={24}
                        sm={24}
                        style={{
                          padding: isMobile() && "12px 24px 0px 24px",
                          paddingRight: "24px",
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        {props.select}
                      </Col>
                    </Row>
                    <Divider className="customize_divider2" />
                  </>
                      }
              <Row>
                <Col
                  span={3}
                  align={"start"}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Button
                    size="large"
                    onClick={handleIconClick}
                    style={{ border: "none", margin: "12px 0px 0px 0px" }}
                    icon={
                      <img
                        src={calendarOutlineIcon}
                        style={{ height: "24px", width: "24px" }}
                        alt="calendarOutlineIcon"
                      />
                    }
                  />
                </Col>
                <Col
                  span={1}
                  style={{
                    borderRight: "1px solid #d3d6e1",
                    boxShadow: "1px 0px 2px #d3d6e1 inset",
                    border: "none",
                  }}
                ></Col>
                <Col span={20}>
                  <ScrollMenu scrollContainerClassName="overFlowHidden">
                    <motion.div
                      transition={{ duration: 0.3 }}
                      style={{ margin: ".4rem 0rem ", padding: "12px 0px" }}
                    >
                      <Space size={"small"}>
                        <Col xl={1}></Col>
                        {dateData.map((item, index) => {
                          const dateWithoutYear = `${item.date.slice(
                            0,
                            item.date.lastIndexOf(",")
                          )} ${item.date.slice(item.date.lastIndexOf("("))}`;
                          return (
                            <Col xl={5}>
                              <Button
                                onClick={() => handleDateChange(item.date)}
                                className="slotDate"
                                style={{
                                  display: "inline-flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  borderRadius: "5px",
                                  backgroundColor:
                                    dateSelected === item.date
                                      ? "#50B85A"
                                      : "",
                                  color:
                                    dateSelected === item.date
                                      ? "#fff"
                                      : "",
                                  border:
                                    dateSelected === item.date ? "#50B85A" : "",
                                }}
                              >
                                {dateWithoutYear}
                              </Button>
                            </Col>
                          );
                        })}
                      </Space>
                    </motion.div>
                  </ScrollMenu>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </Row>
      <Divider
        className="customize_divider"
        style={{ margin: "0px 0px 4px 0px" }}
      />
      {timeZone && <Col style={{ fontWeight: "bold", margin: "0px 0px 24px 24px" }}>
        {`${isLocationTimezonePresent ? "Business location" : "Business"} Timezone: ${timeZone}`}
      </Col>}
      <Row
        gutter={[12, 12]} className="slotBook_row">
        {defTime?.length > 0 && defTime ?
          defTime?.map((data) => {
            const isSelected =
              selectedTime &&
              selectedTime.startTime === data?.startTime &&
              selectedTime.endTime === data?.endTime;
            return (
              <>
                {props.checkout ? (
                  !isMobile() ?
                    <Col span={6} xs={12} sm={8} md={8} lg={6} xl={8}>
                      <Button
                        onClick={() => {
                          handleTimeChange(
                            data?.startTime,
                            data?.endTime,
                            data?.availableAppointments
                          );
                        }}
                        disabled={data?.availableAppointments <= 0 || findArrayLength(props.appointments, props.locId, data?.startTime, data?.endTime, dateSelected) === data?.availableAppointments}
                        className={`slotTiming ${isSelected ? "selectedButton" : ""
                          }`}
                        style={{
                          display: "inline-flex",
                          justifyContent: "center",
                          alignItems: "center",
                          position: "relative",
                          borderRadius: "5px",
                          color: isSelected ? "#fff" : (data.availableAppointments === 0 ? "#D3D6E1" : ""),
                          backgroundColor: isSelected ? "#50B85A" : (data.availableAppointments === 0 ? "#fff" : ""),
                          border: isSelected ? "#50B85A" : (data.availableAppointments === 0 ? "1px solid #D3D6E1" : ""),
                        }}
                      >

                        {data?.availableAppointments - (findArrayLength(props.appointments, props.locId, data?.startTime, data?.endTime, dateSelected) !== undefined ? findArrayLength(props.appointments, props.locId, data?.startTime, data?.endTime, dateSelected) : 0) > 0 &&
                          < span style={{ borderRadius: "50%", background: '#257CDB', height: "20px", width: "20px", position: "absolute", top: "-10px", right: "-8px", color: "#ffff" }}>
                            {data?.availableAppointments - (findArrayLength(props.appointments, props.locId, data?.startTime, data?.endTime, dateSelected) !== undefined ? findArrayLength(props.appointments, props.locId, data?.startTime, data?.endTime, dateSelected) : 0)}
                          </span>
                        }{`${moment(data?.startTime.slice(0, 5), "HH:mm").format('hh:mm A')} - ${moment(data?.endTime.slice(
                          0,
                          5
                        ), "HH:mm").format('hh:mm A')} `}
                      </Button>
                    </Col>
                    :
                    <Space size={"small"} direction="horizontal">
                      <Col xs={10}>
                        <Button
                          onClick={() => {
                            handleTimeChange(
                              data?.startTime,
                              data?.endTime,
                              data?.availableAppointments
                            );
                          }}
                          disabled={data?.availableAppointments <= 0 || findArrayLength(props.appointments, props.locId, data?.startTime, data?.endTime, dateSelected) === data?.availableAppointments}
                          className={`slotTiming ${isSelected ? "selectedButton" : ""
                            }`}
                          style={{
                            display: "inline-flex",
                            justifyContent: "center",
                            position: "relative",
                            alignItems: "center",
                            borderRadius: "5px",
                            color: isSelected ? "#fff" : (data.availableAppointments === 0 ? "#D3D6E1" : ""),
                            backgroundColor: isSelected ? "#50B85A" : (data.availableAppointments === 0 ? "#fff" : ""),
                            border: isSelected ? "#50B85A" : (data.availableAppointments === 0 ? "1px solid #D3D6E1" : ""),
                          }}
                        >
                          {data?.availableAppointments - (findArrayLength(props.appointments, props.locId, data?.startTime, data?.endTime, dateSelected) !== undefined ? findArrayLength(props.appointments, props.locId, data?.startTime, data?.endTime, dateSelected) : 0) > 0 &&
                            <span style={{ borderRadius: "50%", background: '#257CDB', height: "20px", width: "20px", position: "absolute", top: "-10px", right: "-8px", color: "#ffff" }}>
                              {data?.availableAppointments - (findArrayLength(props.appointments, props.locId, data?.startTime, data?.endTime, dateSelected) !== undefined ? findArrayLength(props.appointments, props.locId, data?.startTime, data?.endTime, dateSelected) : 0)}
                            </span>
                          }
                          {`${moment(data?.startTime.slice(0, 5), "HH:mm").format('hh:mm A')} - ${moment(data?.endTime.slice(
                            0,
                            5
                          ), "HH:mm").format('hh:mm A')}${data?.availableAppointments} `}
                        </Button>
                      </Col>
                    </Space>
                ) : (
                  <Space size={"small"} direction="vertical">
                    <Col sm={12} xs={12} xl={4} md={6} xxl={4} lg={4}>
                      <Button
                        onClick={() => {
                          handleTimeChange(
                            data?.startTime,
                            data?.endTime,
                            data?.availableAppointments
                          );
                        }}

                        disabled={data?.availableAppointments <= 0 || findArrayLength(props.appointments, props.locId, data?.startTime, data?.endTime, dateSelected) === data?.availableAppointments}
                        className={`slotTiming ${isSelected ? "selectedButton" : ""
                          }`}
                        style={{
                          position: "relative",
                          display: "inline-flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "5px",
                          color: isSelected ? "#fff" : (data.availableAppointments === 0 ? "#D3D6E1" : ""),
                          backgroundColor: isSelected ? "#50B85A" : (data.availableAppointments === 0 ? "#fff" : ""),
                          border: isSelected ? "#50B85A" : (data.availableAppointments === 0 ? "1px solid #D3D6E1" : ""),
                        }}
                      >
                        {data?.availableAppointments - (findArrayLength(props.appointments, props.locId, data?.startTime, data?.endTime, dateSelected) !== undefined ? findArrayLength(props.appointments, props.locId, data?.startTime, data?.endTime, dateSelected) : 0) > 0 &&
                          <span style={{ borderRadius: "50%", background: '#257CDB', height: "20px", width: "20px", position: "absolute", top: "-10px", right: "-8px", color: "#ffff" }}>
                            {data?.availableAppointments - (findArrayLength(props.appointments, props.locId, data?.startTime, data?.endTime, dateSelected) !== undefined ? findArrayLength(props.appointments, props.locId, data?.startTime, data?.endTime, dateSelected) : 0)}
                          </span>
                        }
                        {`${moment(data?.startTime.slice(0, 5), "HH:mm").format('hh:mm A')} - ${moment(data?.endTime.slice(
                          0,
                          5
                        ), "HH:mm").format('hh:mm A')}`}
                      </Button>
                    </Col>
                  </Space>
                )}
              </>
            );
          })
          :
          <Row justify={"center"} align={"middle"} style={{ width: "100%" }}>
            <Col span={24} align={"middle"}>
              No Time Slots Available
            </Col>
          </Row>
        }
      </Row>
      {showMsg && (
        <Row className={props.msgClassName} justify="space-between">
          <Col>{props.msg}</Col>
          <Col>{props.icon}</Col>
        </Row>
      )}
      <Divider className="customize_divider" style={{ marginTop: "0px" }} />
      <Row justify={"space-between"} style={{ padding: "24px" }}>
        <Col style={{ alignSelf: "center", paddingBottom: isMobile() && "12px" }} xs={props.checkout && selectedData.length < props.qty ? 24 : 18} sm={20} md={14}>
          <Row>
            <Col style={{ paddingTop: "3px", paddingRight: "10px" }}>
              <div
                style={{
                  borderRadius: "100%",
                  background: "#257CDB",
                  width: "16px",
                  height: "16px",
                  verticalAlign: "middle",
                  display: "flex",
                }}
              ></div>
            </Col>
            <Col style={{ marginRight: "10px" }}>Available</Col>
            <Col style={{ paddingTop: "3px", paddingRight: "10px" }}>
              <div
                style={{
                  borderRadius: "100%",
                  background: "#D3D6E1",
                  width: "16px",
                  height: "16px",
                }}
              ></div>
            </Col>
            <Col style={{ marginRight: "10px" }}>
              <div>Booked</div>
            </Col>
            <Col style={{ paddingTop: "3px", paddingRight: "10px" }}>
              <div
                style={{
                  borderRadius: "100%",
                  background: "#50B85A",
                  width: "16px",
                  height: "16px",
                }}
              ></div>
            </Col>
            <Col>
              <div>Selected</div>
            </Col>

          </Row>
        </Col>
        {props.cartlength && (
          <Col>
            <Button
              type="primary"
              disabled={props.checkoutDisabled}
              className={props.classNameCheckout}
              onClick={() => props.buttonClick(selectedData)}
            >
              {props.checkout}
            </Button>
          </Col>
        )}
        {props.checkout ?
          selectedData.length < props.qty ? (
            <Col >
              <Button
                type="primary"
                disabled={props.nextDisabled || false}
                className={props.classNameAdd}
                onClick={

                  async () => {
                    let token = await localStorage.getItem("googleAccessToken");

                    if (token === "noToken" || token != null) {
                      const newData = organizeSelectedData();
                      setSelectedData(newData);
                      setSelectedTime(null)
                      props.onNextClick(newData);

                    } else {
                      setConsentVisible(true);
                    }




                  }
                }
              >
                {props.next}
              </Button>
            </Col>
          ) : (
            <Col>
              <Button
                type="primary"
                disabled={props.disabled}
                className={props.classNameAdd}
                onClick={() => {
                  props.addButtonClick(selectedData);
                  setShowMsg(true);
                }}
              >
                {props.add}
              </Button>
            </Col>
          )
          :
          <>
            <Col>
              <Button
                type="primary"
                disabled={selectedTime === null}
                className={props.classNameAdd}
                onClick={async () => {
                  let token = await localStorage.getItem("googleAccessToken");

                  if (token === "noToken" || token != null) {
                    const newData = organizeSelectedData();
                    setSelectedData(newData);
                    props.onNextClick(newData);
                    setSelectedTime(null)
                  } else {
                    setConsentVisible(true);
                  }




                }}
              >
                {props.next}
              </Button>
            </Col>
            {props.later &&
              <Col>
                <Button
                  type="primary"
                  className={props.classNameAdd}
                  onClick={props.laterClick}
                  disabled={props.onLaterDisabled}
                >
                  {props.later}
                </Button>
              </Col>}
          </>
        }
      </Row>

      {infoToken != null && infoToken !== "noToken" &&
        <Row style={{ padding: 5 ,backgroundColor:'yellow'}}>
          <Col>
            <span style={{fontWeight:'bold'}}>
  The Google Calendar permission to create events has been enabled. Now, all selected appointment slots will be automatically added to calendar.
            </span>
          </Col>
        </Row>
      }

      <Modal
        visible={consentVisible}
        onCancel={() => {
          setConsentVisible(false);
        }
        }
        width={700}
        footer={[<Button onClick={() => {
          setConsentVisible(false);
          const newData = organizeSelectedData();
          setSelectedData(newData);
          props.onNextClick(newData);
          setSelectedTime(null)

        }
        }>
          No
        </Button>,
        <Button key="submit" type="primary" onClick={() => {
          setConsentVisible(false)
          signIn()


        }}>
          Yes
        </Button>]}
      >
        <h5 style={{fontSize: "100%", lineHeight: '1.2'}} >Would you prefer to incorporate this appointment into your Google Calendar?</h5>
        <h6 style={{fontSize: "100%", lineHeight: '1.2'}} >Please note that this feature is only compatible with Google Workspace accounts.</h6>

        <div>
        <h5 style={{fontSize: "100%", lineHeight: '1.2'}} >Required Disclosure</h5>
        <p>
            Fyndr webapp/app uses and transfers of information received from Google APIs to any other app will adhere to the 
            <a href="https://developers.google.com/terms/api-services-user-data-policy">Google API Services User Data Policy</a>, 
            including the Limited Use requirements.
        </p>
        
        <h5 style={{fontSize: "100%", lineHeight: '1.2'}} >Justification for Requested Scopes</h5>
        <p>
            <strong>https://www.googleapis.com/auth/calendar:</strong> Fyndr webapp/app will use this scope to display the user's Google Calendar data on the scheduling screen, allowing users to manage their schedules through our app and sync changes with their Google Calendar.
        </p>
        <p>
            <strong>https://www.googleapis.com/auth/calendar.events:</strong> Fyndr webapp/app will use this scope to create, modify, and delete events in the user's Google Calendar, ensuring seamless schedule management within our app.
        </p>
        
        <h5 style={{fontSize: "100%", lineHeight: '1.2'}} >Demo Video</h5>
        <p>
            You can view the demo video of the OAuth consent screen grant process at the following link: <a href="https://www.youtube.com/watch?v=oJu1gi4Q6Ow">OAuth Consent Screen Demo</a>.
        </p>
    </div>
      </Modal>
    </div>
  );
}
export default SlotBooking;