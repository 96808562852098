import { Button, Col, Divider, Input, Row, message, Image } from "antd";
import React, { useEffect, useState } from "react";
import searchIcon from "../../assets/images/searchIcon.svg";
import DateCalendar from "../DateCalendar/DateCalendar";
import { useSelector } from "react-redux";
import GoogleLogin from "react-google-login";
import Loader from "react-spinners/PulseLoader";
import getEnvVars from "../../environment";
import UserActions from "../../actions";
import { gapi } from "gapi-script";
import gmailSyncIcon from "../../assets/images/gmailSyncIcon.png";
import Tour from "reactour";
import HelmetFyndr from "../HelmetFyndr";

const web = getEnvVars();
function AppointmentBusiness() {
  const [isOpen, setIsOpen] = useState(false);

  const [currentStep] = useState(0);
  const tourConfig = [
    {
      selector: '[businessAppointment-Tour="searchInput"]',
      content: `Search for specific category's appointment or a person.`,
    },
    {
      selector: '[businessAppointment-Tour="calendarIcon"]',
      content: `View your bookings on calendar.`,
    },
    {
      selector: '[businessAppointment-Tour="dateSelection"]',
      content: `Select date to check appointments.`,
    },
    {
      selector: '[businessAppointment-Tour="locationDropdown"]',
      content: `Select which store’s appointment you want to check.`,
    },
    {
      selector: '[businessAppointment-Tour="totalcount_capacity"]',
      content: `Your total number of bookings / total capacity.`,
    },
    {
      selector: '[businessAppointment-Tour="slotetails"]',
      content: `View appointment details per slot.`,
    },
    {
      selector: '[businessAppointment-Tour="Indicators"]',
      content: `Border color to indicate the busyness of the slot.`,
    },
  ];
  const [searchText, setSearchText] = useState(null);
  useEffect(() => {}, [searchText]);
  const [isLoading, setIsLoading] = useState(false);
  const {
    app: { googleClientId },
  } = getEnvVars();
  const handleSearchInputChange = (e) => {
    setSearchText(e.target.value);
  };
  const { indv } = useSelector(({ auth }) => auth);

  const CLIENT_ID =
    "283240579326-2jp6j8jvekme9o4ddcsesa8rl81evidf.apps.googleusercontent.com";

  useEffect(() => {

    if (gapi?.auth2 && gapi?.auth2?.getAuthInstance()) {
      // Use the existing instance
      // var authInstance = gapi?.auth2?.getAuthInstance();
      // Now you can use authInstance for authentication
    } else {
      // Initialize gapi.auth2 with your desired options
      gapi?.auth2
        ?.init({
          client_id: CLIENT_ID,
        })
        .then(function (authInstance) {
          // Now you can use authInstance for authentication
        })
        .catch(function (error) {
          console.error("Error initializing Google Auth: ", error);
        });
    }
  }, []);

  const googleSignInSucceded = async (codeResponse) => {
    localStorage.setItem("googleAccessToken", codeResponse?.accessToken);
    getGooglePermission();
  };

  const googleSignInFailed = async (error) => {
    setIsLoading(false);
  };
  const getGooglePermission = async () => {
    let result = await UserActions.GooglePermission();
    if (result.success) {
      setIsLoading(false);
      syncAppointment();
    } else {
      setIsLoading(false);
      localStorage.removeItem("googleAccessToken");
      message.error(result?.msg);
    }
  };

  const syncAppointment = async () => {
    let result = await UserActions.syncAppointment({
      bizId: indv.bizid,
    });
    if (result?.success) {
      message.success(result?.msg);
    } else {
      message.error(result?.msg);
    }
  };

  useEffect(() => {
    const isTourOpen = JSON.parse(localStorage.getItem("tourOpen"));
    if (isTourOpen !== null) {
      setIsOpen(isTourOpen);
    } else {
      localStorage.setItem("tourOpen", true);
      setIsOpen(true);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("tourOpen", isOpen);
  }, [isOpen]);

  const closeTour = () => {
    setIsOpen(false);
  };

  const skipTour = () => {
    setIsOpen(false);
  };
  return (
    <div style={{ padding: "24px" }}>
      <Tour
        steps={tourConfig}
        isOpen={isOpen}
        onRequestClose={closeTour}
        showCloseButton={true}
        showSkipButton={true}
        onAfterOpen={() => setIsOpen(true)}
        onRequestSkip={skipTour}
        getCurrentStep={() => currentStep}
      />
      {
        <HelmetFyndr
          title="Business "
          imgUrl={""}
          url={`${web}/business/appointment`}
          descr="business appointment"
        />
      }
      <Row>
        <Col
          sm={24}
          md={9}
          lg={8}
          xl={13}
          xxl={14}
          className="appointmentBusiness_adminBiz"
        >
          <span className="appointmentBusiness_adminbusiness">Business</span>
          <span className="appointmentBusiness_mytitle">/My Appointment</span>
        </Col>
        <Col
          sm={24}
          md={7}
          lg={8}
          xl={6}
          xxl={4}
          className="appointmentBusiness_synbtn"
        >
          <GoogleLogin
            responseType="token"
            clientId={googleClientId}
            onSuccess={googleSignInSucceded}
            onFailure={googleSignInFailed}
            onAutoLoadFinished={() => setIsLoading(false)}
            scope= "profile https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events"
            cookiePolicy={"single_host_origin"}
            ux_mode={"popup"}
            prompt="select_account"
            render={(renderProps) => (
              <Button
                disabled={isLoading}
                type="primary"
                size="large"
                onClick={() => {
                  setIsLoading(true);
                  renderProps.onClick();
                }}
                className="appointmentBusiness_btnSync"
              >
                <div>
                  <span className="appointmentBusiness_syncText">
                    Sync Gmail
                  </span>
                  <Image
                    preview={false}
                    src={gmailSyncIcon}
                    className="appointmentBusiness_btnSyncIcon"
                    alt="gmailSyncIcon"
                  />
                </div>
                {isLoading && <Loader />}
              </Button>
            )}
          />
        </Col>
        <Col
          sm={24}
          md={8}
          lg={8}
          xl={5}
          xxl={6}
          className="appointmentBusiness_inputSearchAppointment"
        >
          <div businessAppointment-Tour="searchInput">
            <Input
              className="appointmentBusiness_inputSearch"
              suffix={<img src={searchIcon} alt="searchIcon" />}
              placeholder=" Search"
              value={searchText}
              onChange={handleSearchInputChange}
              onPressEnter={(e) => {
                handleSearchInputChange(e);
              }}
            />
          </div>
        </Col>
      </Row>
      <Divider
        className="customize_divider"
        style={{ margin: "12px 0px 0px 0px" }}
      />

      <div className="appointmentBusiness_businessAppointBlock">
        <DateCalendar searchText={searchText} />
      </div>
    </div>
  );
}

export default AppointmentBusiness;
