import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Avatar, Typography, Modal } from "antd";
import Button from "antd-button-color";
import { capitalize } from "../../../common/util.js";
import Edit from "./Edit";
import Images from "../../../assets/images/Images"
import { getRemainingOffer } from "../../../utils/Helper.js";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Theme from "../../../common/Theme.js";
import { Actions } from "../../../common/index.js";
const { Text } = Typography;
const { UPSERT_OFFER } = Actions;

const OffersList = (props) => {
  const {
    cmpn: { offers },
  } = useSelector(({ cmpn }) => cmpn);

  const dispatch = useDispatch();
  const { cmpnType } = props;
  const [started, setStarted] = useState(false);
  const [offersData, setOffersData] = useState(offers);
  const [selected, setSelected] = useState(false);
  const [isNew, setIsNew] = useState(false);

  const startOffer = () => {
    setIsNew(true);
    setSelected(null);
    setStarted(true);

  };

  const offerDone = () => {
    setIsNew(false);
    setSelected(null);
    setStarted(false);
  };
  const editOffer = (offer) => {
    setIsNew(false);
    setSelected(offer);
    setStarted(true);
  };
  const onDragEnd = async (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      offersData,
      result.source.index,
      result.destination.index
    );
    items.forEach((item) => {
      dispatch({ type: UPSERT_OFFER, payload: item });
    });
    setOffersData(items);
  };
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    result.forEach((item, index) => {
      item.displayOrder = index + 1;
    });

    return result;
  };
  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: 8 * 2,
    margin: `0 0 ${8}px 0`,

    // change background colour if dragging
    background: isDragging ? "#f9f9f9" : "#fff",
    boxShadow: isDragging ? `2px 21px 58px -31px ${Theme.blue}` : "",
    border: isDragging ? `1px solid ${Theme.blue}` : "",
    // styles we need to apply on draggables
    ...draggableStyle,
  });
  return (
    <>
      {!started && (
        <div className="container-fluid no-breadcrumb container-mw-xl chapter">
          <article className="article">
            <h2 className="article-title" style={{ marginTop: "-2rem" }}>
              {capitalize(cmpnType)}
            </h2>
            <Row justify="end" style={{ marginBottom: "2rem" }}>
              <Col>
                <Button type="primary" onClick={() => startOffer()}>
                  {`Create ${(cmpnType.charAt(0).toUpperCase() + cmpnType.slice(1)).slice(0, -1)}`}
                </Button>
              </Col>
            </Row>
            <div className="row">
              <div className="col-md-12">
                <div className="box box-default" style={{ overflowY: "scroll" }}>
                  <div className="box-body">

                    {offersData.length > 0 ? <DragDropContext onDragEnd={onDragEnd}>
                      <Droppable droppableId="droppable">
                        {(provided, snapshot) => {
                          return (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              {offersData.sort((a, b) => b.offersAvailable - a.offersAvailable).map((item, index) => {
                                return (
                                  <Draggable
                                    key={item.objid}
                                    draggableId={String(item.objid)}
                                    index={index}
                                  >
                                    {(provided, snapshot) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={getItemStyle(
                                          snapshot.isDragging,
                                          provided.draggableProps.style
                                        )}

                                      >
                                        <div style={{ border: "1px solid black", padding: '2rem', borderRadius: '1rem' }} className="list-style-v1" >
                                          <div className="list-item">
                                            <Avatar
                                              shape="square"
                                              size={{
                                                xs: 24,
                                                sm: 32,
                                                md: 40,
                                                lg: 64,
                                                xl: 80,
                                                xxl: 100,
                                              }}
                                              src={item.thumbnailUri
                                                ? item?.thumbnailUri
                                                : Images.PlaceHolder}
                                              className="list-sep cc-padding"
                                            />
                                            <div className="list-item__body list-sep cc-padding" >
                                              <div className="big">
                                                <Row justify="space-between" >
                                                  <Col>
                                                    <h4>
                                                      <Text disabled={item.status !== "active"}>
                                                        {item.title}
                                                      </Text>
                                                    </h4>
                                                  </Col>
                                                  {item.offerLimit !== null && Number(item.offerLimit) - Number(item.offerSold) === 0 && <Col className="list-item--offersoldout" >
                                                    <Text type="danger" style={{ alignItems: "center" }} strong>Offer Sold Out</Text>
                                                  </Col>}
                                                </Row>
                                              </div>
                                              <div>
                                                <div >
                                                  <Row>
                                                    <Col >
                                                      <Text>
                                                        {" "}
                                                        {item.offerType === "coupons"
                                                          ? item.couponCode
                                                          : <h6 style={{ fontWeight: "normal" }}>
                                                            {item.amount > 0 && <Text delete>{` ${item.currencySymbol}${item.retailPrice} `}</Text>}
                                                            <Text type="success" style={{ paddingLeft: item.amount > 0 ? "0.5rem" : "0rem" }}>{`  ${item?.currencySymbol}${parseFloat(item?.offerPrice)?.toFixed(2)}`} </Text>
                                                          </h6>}
                                                      </Text>
                                                    </Col>
                                                    {item.discountType === "%" && item.amount > 0 && (
                                                      <Col offset={1} className="list-item--offer-discountdiv"  >
                                                        <Text className="list-item--offer-discount" > {`${item.amount}% OFF`}</Text>
                                                      </Col>
                                                    )}
                                                  </Row>
                                                </div>
                                                <div >
                                                  {item.discountType === "flat" && item.amount > 0 && (
                                                    <Text strong>
                                                      {`${item.currencySymbol}${item.amount} OFF`}
                                                    </Text>
                                                  )}
                                                </div>
                                                <Row>
                                                  <Col span={4} md={5} >
                                                    <Text>
                                                      {item.offerType === "events" ? "Sold tickets" : "Sold offer"} : {item.offerSold === undefined ? 0 : item.offerSold}
                                                    </Text>
                                                  </Col>
                                                  {item.offerType === "offers" &&
                                                    <Col span={8} md={10}>

                                                      {(Number(item.offerLimit) !== 0 || item.offerLimit === null) && <Text type="strong">
                                                        Offer Remaining  :{" "}
                                                        {item.offerLimit == null ? "Unlimited" : getRemainingOffer(item.offerLimit, item.offerSold === undefined ? 0 : item.offerSold)}
                                                      </Text>}
                                                    </Col>
                                                  }
                                                  <Col>
                                                    {item.usageLimit !== -1 && (
                                                      <Text>
                                                        {" "}
                                                        Per User Limit: {item.usageLimit}
                                                      </Text>
                                                    )}
                                                  </Col>
                                                </Row>
                                              </div>

                                            </div>
                                            <div className="list-sep cc-padding">
                                              <Button
                                                type="info"
                                                onClick={() => editOffer(item)}
                                                className="list-item--editbutton"
                                              >
                                                Edit
                                              </Button>
                                            </div>
                                          </div>
                                        </div>





                                      </div>
                                    )}
                                  </Draggable>
                                );
                              })}
                              {provided.placeholder}
                            </div>
                          );
                        }}
                      </Droppable>
                    </DragDropContext> :
                      <Text style={{ alignItems: "center", textAlign: 'center' }} strong>{`No ${cmpnType} created yet`}</Text>


                    }

                  </div>
                </div>
              </div>
            </div>
          </article>
        </div>
      )}
      {started && (
        <Modal
          title={isNew ? `Create ${(cmpnType.charAt(0).toUpperCase() + cmpnType.slice(1)).slice(0, -1)}`
            : selected.title}
          footer={null}
          closable={false}
          width={"60%"}
          visible={started}
        >
          <Edit
            visible={started}
            isNew={isNew}
            offer={selected}
            callback={offerDone}
            cmpnType={cmpnType}
          />
        </Modal>
      )}
    </>
  );
};
export default OffersList;