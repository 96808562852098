/* eslint-disable no-useless-escape */
import * as Yup from "yup";

const LoginSchema = Yup.object().shape({
  email: Yup.string().email().required().trim(),
  password: Yup.string().required(),
});

const ResetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .test(
      "PasswordFormat",
      "Password should contain at least 1 uppercase, 1 number,1 lowercase minimum 8 characters",
      function (value) {
        if (value === undefined) {
          return false;
        }

        if (
          value.match(
            RegExp(
              "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[A-Za-zÀ-ȕ0-9(),$-_.,!&*~`^# ]{8,}$"
            )
          )
        ) {
          return true;
        } else {
          return false;
        }
      }
    )
    .required(
      "Password should contain at least 1 uppercase, 1 number,1 lowercase minimum 8 characters"
    ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Passwords must match"),
});

const ChangePasswordSchema = Yup.object().shape({
  password: Yup.string()
    .test(
      "PasswordFormat",
      "Password should contain at least 1 uppercase, 1 number,1 lowercase minimum 8 characters",
      function (value) {
        if (value === undefined) {
          return false;
        }

        if (
          value.match(
            RegExp(
              "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[A-Za-zÀ-ȕ0-9(),$-_.,!&*~`^# ]{8,}$"
            )
          )
        ) {
          return true;
        } else {
          return false;
        }
      }
    )
    .required(
      "Password should contain at least 1 uppercase, 1 number,1 lowercase minimum 8 characters"
    ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Passwords must match"),
    token: Yup.string()
    .matches(/^\d{6}/, "Token too small")
    .required("Token is required"),

});

const ReviewForm = Yup.object().shape({
  review: Yup.string().min(20).required(),
});

const ContactForm = Yup.object().shape({
  from: Yup.string().email().required("Please enter a valid email address"),
  subject: Yup.string().required("Subject is required"),
  message: Yup.string().required("Message is required"),
  name: Yup.string().required("Name is required"),
  phone: Yup.string()
    .matches(/^(\+|\d)[0-9]{7,16}$/, "Please enter valid mobile number")
    .required("Phone is required"),
});

const ContactFormMsg = Yup.object().shape({
  email: Yup.string().required("Please enter a valid email address"),
  subject: Yup.string().required("Subject is required"),
  message: Yup.string().required("Message is required"),
  name: Yup.string().required("Name is required"),
  phone: Yup.string()
    .matches(/^(\+|\d)[0-9]{7,16}$/, "Please enter a valid phone number")
    .required("Phone is required"),
});

const InteractionVenue = Yup.object().shape({
  locName: Yup.string().required("Venue Name can't be blank"),
  ctryCode: Yup.string().matches(
    /^(\+[0-9]{1,3})?$/,
    "Country Code not is not valid"
  ),
  phone: Yup.string()
    .matches(/^([0-9]{10})?$/, "Phone Number must be 10 digits")
    .optional()
    .nullable(),
  postalcode: Yup.string()
    .required("Postal code can't be blank")
    .max(6, "Maximum 6 characters are allowed"),
  addressLine1: Yup.string().required("Address can't be blank"),
  addressLine2: Yup.string().optional(),
  city: Yup.string().required("City can't be blank"),
  state: Yup.string().required(" State can't be blank"),
});

const RegisterForm = Yup.object().shape({
  fname: Yup.string().required("First Name can't be blank").nullable(),
  lname: Yup.string().required("Last Name can't be blank").nullable(),
  bizName: Yup.string().required("Business Name can't be blank").nullable(),
  website: Yup.string()
    .matches(
      /^((?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+)?$/,
      "Please enter a valid URL"
    )
    .optional()
    .nullable(),
  addOnUrl: Yup.string().optional(),
  taxNumber: Yup.string()
    .matches(/^([0-9]{2}-[0-9]{7})?$/, "Valid format is NN-NNNNNNN")
    .optional(),
  phone: Yup.string()
    .required("Phone Number can't be blank")
    .matches(/^[0-9]{10}$/, "Phone Number must be 10 digits"),
  postalcode: Yup.string()
    .required("Postal code can't be blank")
    .max(6, "Maximum 6 characters are allowed"),
  addressLine1: Yup.string().required("Address can't be blank"),
  addressLine2: Yup.string().optional(),
  city: Yup.string().required("City can't  be blank"),
  state: Yup.string().required("State can't be blank"),
  ctryCode: Yup.string()
    .required("Country Code can't be blank")
    .matches(/^\+[0-9]{1,3}$/, "Country Code is not valid"),
});
const RegisterFormAdmin = Yup.object().shape({
  fname: Yup.string().required("First Name can't be blank").nullable(),
  lname: Yup.string().required("Last Name can't be blank").nullable(),
  email: Yup.string().email().required("Please enter a valid email address").matches(/@(nucleusteq\.com|fyndr\.us)$/,"Email domain should be nucleusteq.com or fyndr.us"),
  website: Yup.string()
    .matches(
      /^((?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+)?$/,
      "Please enter a valid URL"
    )
    .optional()
    .nullable(),
  addOnUrl: Yup.string().optional(),
  taxNumber: Yup.string()
    .matches(/^([0-9]{2}-[0-9]{7})?$/, "Valid format is NN-NNNNNNN")
    .optional(),
  phone: Yup.string()
    .required("Phone Number can't be blank")
    .matches(/^[0-9]{10}$/, "Phone Number must be 10 digits"),
  postalcode: Yup.string()
    .required("Postal code can't be blank")
    .max(6, "Maximum 6 characters are allowed"),
  address: Yup.string().optional(),
  street: Yup.string().optional(),
  city: Yup.string().required("City can't  be blank, enter valid postal code"),
  state: Yup.string().required("State can't be blank, enter valid postal code"),
  ctryCode: Yup.string()
    .required("Country Code can't be blank")
    .matches(/^\+[0-9]{1,3}$/, "Country Code is not valid"),
  password: Yup.string().required(),
});

const UpdateDetailsFormAdmin = Yup.object().shape({
  fname: Yup.string().required("First Name can't be blank").nullable(),
  lname: Yup.string().required("Last Name can't be blank").nullable(),
  email: Yup.string().email().required("Please enter a valid email address").matches(/@(nucleusteq\.com|fyndr\.us)$/,"Email domain should be nucleusteq.com or fyndr.us"),
  website: Yup.string()
    .matches(
      /^((?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+)?$/,
      "Please enter a valid URL"
    )
    .optional()
    .nullable(),
  addOnUrl: Yup.string().optional(),
  taxNumber: Yup.string()
    .matches(/^([0-9]{2}-[0-9]{7})?$/, "Valid format is NN-NNNNNNN")
    .optional(),
  phone: Yup.string()
    .required("Phone Number can't be blank")
    .matches(/^[0-9]{10}$/, "Phone Number must be 10 digits"),
  postalcode: Yup.string()
    .required("Postal code can't be blank")
    .max(6, "Maximum 6 characters are allowed"),
  address: Yup.string().optional(),
  street: Yup.string().optional(),
  city: Yup.string().required("City can't  be blank, enter valid postal code"),
  state: Yup.string().required("State can't be blank, enter valid postal code"),
  ctryCode: Yup.string()
    .required("Country Code can't be blank")
    .matches(/^\+[0-9]{1,3}$/, "Country Code is not valid"),
});
const ProfileForm = Yup.object().shape({
  handleName: Yup.string()
    .required("Fyndr Handle can't be blank")
    .min(5, "Fyndr Handle must be at least 5 characters"),
  website: Yup.string()
    .matches(
      /^((?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+)?$/,
      "Please enter a valid URL"
    )
    .optional()
    .nullable(),
  addOnUrl: Yup.string()
    .matches(
      /^((?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+)?$/,
      "Please enter a valid URL"
    )
    .optional()
    .nullable(),
  taxNumber: Yup.string()
    .matches(/^([0-9]{2}-[0-9]{7})?$/, "Valid format is NN-NNNNNNN")
    .optional()
    .nullable(),
  phone: Yup.string()
    .required("Phone Number can't be blank")
    .matches(/^[0-9]{10}$/, "Phone Number must be 10 digits"),
  postalcode: Yup.string()
    .required("Postal code can't be blank")
    .max(6, "Maximum 6 characters are allowed"),
  addressLine1: Yup.string().required("Address can't be blank"),
  addressLine2: Yup.string().optional(),
  city: Yup.string().required("City can't  be blank"),
  state: Yup.string().required("State can't be blank"),
  ctryCode: Yup.string()
    .required("Country Code can't be blank")
    .matches(/^\+[0-9]{1,3}$/, "Country Code not is not valid"),
});

const UserProfileForm = Yup.object().shape({
  handleName: Yup.string()
    .required("Fyndr Handle can't be blank")
    .min(5, "Fyndr Handle must be at least 5 characters"),
  yob: Yup.string()
    .matches(/^([0-9]{4})?$/, "Year of birth must be 4 digits")
    .optional()
    .nullable(),
  ctryCode: Yup.string()
    .matches(/^(\+[0-9]{1,3})?$/, "Country Code not is not valid")
    .optional()
    .nullable(),
  phone: Yup.string()
    .matches(/^([0-9]{10})?$/, "Phone Number must be 10 digits")
    .optional()
    .nullable(),
  postalcode: Yup.string()
    .max(6, "Maximum 6 characters are allowed")
    .required("Postal code can't be blank"),
  addressLine1: Yup.string().optional(),
  addressLine2: Yup.string().optional(),
  city: Yup.string().required("City can't be blank"),
  state: Yup.string().required("State can't be blank"),
});

const UserRegisterForm = Yup.object().shape({
  fname: Yup.string().required("First Name can't be blank").nullable(),
  lname: Yup.string().required("Last Name can't be blank").nullable(),
  ctryCode: Yup.string()
    .matches(/^(\+[0-9]{1,3})?$/, "Country Code not is not valid")
    .optional()
    .nullable(),
  phone: Yup.string()
    .matches(/^[0-9]{10}$/, "Phone Number must be 10 digits")
    .optional()
    .nullable(),
  postalcode: Yup.string()
    .max(6, "Maximum 6 characters are allowed")
    .required("Postal code can't be blank"),
  addressLine1: Yup.string().optional().nullable(),
  addressLine2: Yup.string().optional().nullable(),
  city: Yup.string().required("City can't be blank"),
  state: Yup.string().required("State can't be blank"),
});

const OfferForm = Yup.object().shape({
  title: Yup.string().required("Please enter title"),
  discount: Yup.number().required("Please enter discount"),
  offer: Yup.number().required("Please enter the offer price"),
  retail: Yup.number().required("Please enter the retail price"),
});
const CatalogueForm = Yup.object().shape({
  name: Yup.string().required("Please enter category name"),
});
const ItemPriceForm = Yup.object().shape({
  price: Yup.number().required("Please enter price"),
});
const FormSchema = {
  LoginSchema,
  ReviewForm,
  ContactForm,
  InteractionVenue,
  ProfileForm,
  RegisterForm,
  UserProfileForm,
  UserRegisterForm,
  ChangePasswordSchema,
  ResetPasswordSchema,
  OfferForm,
  CatalogueForm,
  ItemPriceForm,
  RegisterFormAdmin,
  UpdateDetailsFormAdmin,
  ContactFormMsg
};

export default FormSchema;
