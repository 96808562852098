/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import { Row, Col, Form, Input, Select, Typography, Button, message } from "antd";
import countryList from "react-select-country-list";
import ReactFlagsSelect from "react-flags-select";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import { FormSchema } from "../../common";
import UserActions from "../../actions";

import { postalPattern } from "../../common/util";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import actions from "../../actions";
import { encryptPassword } from "../../utils/Helper";
const FormItem = Form.Item;

const AddUserModalContent = ({
  closeModal,
  formValues,
  roleDropdown,
  mode
}) => {
  const { Text } = Typography;

  const [country, setCountry] = useState(formValues.country ? formValues.country : "US");
  const [, setIsLoading] = useState(false);
  const [countryError, setCountryError] = useState(null);
  const [loading, setLoading] = useState(false);


  // eslint-disable-next-line no-unused-vars
  const options = useMemo(() => countryList().getData(), []);
  const { RegisterFormAdmin, UpdateDetailsFormAdmin } =
    FormSchema;
  const [form] = Form.useForm();
  const {
    register,
    handleSubmit,
    errors,
    setError,
    clearErrors,
    control,
    getValues,
    setValue,
  } = useForm({
    mode: "onChange",
    shouldUnregister: false,
    resolver: yupResolver(
      mode === "update" ?
        UpdateDetailsFormAdmin :
        RegisterFormAdmin
    ),
  });
  useEffect(() => {
    if (mode === "update") {
      setValue("ctryCode", formValues.country === "US" ? "+1" : "+91");
      checkPostalCode();
    } else {
      setValue("ctryCode", country === "US" ? "+1" : "+91");
    }
  }, [])
  const validatePostalAddress = async (value, country) => {
    const pattern = new RegExp("^" + postalPattern[country] + "$");

    if (pattern.test(value)) return true;
    else return false;
  };
  const [formData, setFormData] = useState({
    fname: formValues.firstName,
    lname: formValues.lastName,
    postalcode: formValues.postalCode,
    address: formValues.address,
    street: formValues.street,
    city: formValues.city,
    state: formValues.state,
    country: formValues.country,
    ctryCode: formValues.ctryCode,
    phone: formValues.phone,
    password: formValues.password,
    email: formValues.email,
    role: formValues.role
  });

  const onFinish = (data) => {

    if (data.city === "" && data.ctryCode === "" && data.email === "" && data.fname === "" && data.lname === "" && data.phone === "" && data.postalcode === "" && data.state === "") {
      message.error("Please fill all mandatory fields")
      return;
    } else {
      if (mode === "update") {
        UpdateEntityUser(data)
      } else {
        AddEntityUser(data);
      }
    }

  };

  const AddEntityUser = async (data) => {
    setLoading(true);
    let passValue = `${btoa(data?.password)}`
    localStorage.setItem("base64Pass", passValue);
    const pwd = Array.from(await encryptPassword(data?.email, data?.password));

    let payload = {
      firstName: data.fname,
      lastName: data.lname,
      country: data.country || country,
      postalCode: data.postalcode,
      countryCode: data.ctryCode,
      phoneNumber: data.phone,
      addressLine1: data.address,
      addressLine2: data.street,
      state: data.state,
      city: data.city,
      email: data.email,
      userRole: data.role || "SUPER_ADMIN",
      pwd: pwd

    };
    let response = await actions.AdminAddUser(payload);


    if (response?.success === true) {
      setLoading(false);
      closeModal(true)
      message.success(response.message)
      localStorage.setItem("base64Pass", null);
    } else {
      setLoading(false);
      message.error(response.msg)
      localStorage.setItem("base64Pass", null);
    }

  }
  const UpdateEntityUser = async (data) => {
    setLoading(true);
    let payload = {
      firstName: data.fname,
      lastName: data.lname,
      country: data.country || country,
      postalCode: data.postalcode,
      countryCode: data.ctryCode,
      phoneNumber: data.phone,
      addressLine1: data.address,
      addressLine2: data.street,
      state: data.state,
      city: data.city,
      email: data.email,
      userRole: data.role || formValues.role,
      objId: formValues.objId
    };
    let response = await actions.AdminUpdateUser(payload);


    if (response?.success === true) {
      setLoading(false);
      closeModal(true)
      message.success(response.message)
    } else {
      setLoading(false);
      message.error(response.msg)

    }

  }


  const checkPostalCode = async () => {
    setIsLoading(true);
    let value = getValues("postalcode");
    let response = await validatePostalAddress(value, country);
    setIsLoading(false);
    if (!response) {
      setError("postalcode", {
        types: {
          required: "This is required",
          minLength: "This is minLength",
        },
        message:
          value.length < 1
            ? "Postal Code can not be blank"
            : "Postal code is Invalid",
      });
      if (mode === "update") {
        setValue("state", "");
        setValue("city", "");
        setFormData({ ...formData, city: "" });
        setFormData({ ...formData, state: "" });

      }
    } else {
      clearErrors("postalcode");
      setValue("postalcode", value);

      const resp = await UserActions.getLocationFromPostalCode(
        `${value},${country}`
      );

      if (resp.success) {
        setValue("state", resp.state);
        setValue("city", resp.city);
        clearErrors("state");
        clearErrors("city");
        setFormData({ ...formData, city: resp.city });
        setFormData({ ...formData, state: resp.state });
      }
    }
  };
  return (
    <>
      <Form
        form={form}
        name="basic"
        initialValues={{ remember: true, ...formData }}
        autoComplete="off"
        style={{ padding: "24px 12px 0 12px" }}
      >
        <Row style={{ width: "100%" }} justify={"center"} align={"center"}>
          <Col span={12}>

            <FormItem

              name="first name"
              rules={[
                {
                  required: true,
                },
              ]}
              help={errors.fname?.message}
              validateStatus={errors.fname ? "error" : "success"}
              className="user_management_form"

            >
              <Controller
                as={
                  <Input
                    type="text"
                    placeholder="First Name"
                  />
                }
                control={control}
                defaultValue={formData.fname}
                className="user_form_input"
                name="fname"
              />
            </FormItem>
          </Col>

          <Col span={12}>

            <FormItem

              name="last name"
              rules={[
                {
                  required: true,
                },
              ]}
              help={errors.lname?.message}
              validateStatus={errors.lname ? "error" : "success"}
              className="user_management_form"

            >
              <Controller
                as={
                  <Input
                    type="text"
                    placeholder="Last Name"
                  />
                }
                control={control}
                defaultValue={formData.lname}
                name="lname"
                className="user_form_input"

              />
            </FormItem>
          </Col>

          <Col span={12}>

            <FormItem

              requiredMark="true"
              name="country"
              rules={[
                {
                  required: true,
                },
              ]}
              className="user_management_form"
            >
              <ReactFlagsSelect

                countries={
                  ["US", "IN"]
                }
                selected={country}
                onSelect={(code) => {
                  code && setCountryError(null);
                  setCountry(code);
                  setValue("ctryCode", code === "US" ? "+1" : "+91");
                  setValue("country", code);
                  setFormData({ ...formData, postalcode: "" });
                  setFormData({ ...formData, city: "" });
                  setFormData({ ...formData, state: "" });
                  setValue("state", "");
                  setValue("city", "");
                  setValue("postalcode", "");
                  form.resetFields()
                 
                }}
              />
              <Text type="danger">{countryError}</Text>
            </FormItem>
          </Col>

          <Col span={12}>

            <FormItem
              name="postal code"
              rules={[
                {
                  required: true,
                },
              ]}
              help={errors.postalcode?.message}
              validateStatus={errors.postalcode ? "error" : "success"}
              className="user_management_form"

            >
              <input
                type="text"
                ref={register}
                placeholder="Postal Code"
                style={{ paddingLeft: "9px" }}
                name="postalcode"
                defaultValue={formData.postalcode}
                onChange={(e) => {
                  setFormData({ ...formData, postalcode: e.target.value });
                  setFormData({ ...formData, city: "" });
                  setFormData({ ...formData, state: "" });
                  setValue("state", "");
                  setValue("city", "");
                }}
                onBlur={checkPostalCode}
                className="user_form_input"
              />
            </FormItem>
          </Col>

          <Col span={12}>
            <FormItem

              name="countryCode"
              rules={[
                {
                  required: false,
                },
              ]}
              help={errors.ctryCode?.message}
              validateStatus={
                errors.ctryCode ? "error" : "success"
              }
              className="user_management_form"
            >
              <Controller
                as={
                  <Input
                    disabled
                    type="text"
                    placeholder="+ 1"
                    className="user_form_input_select"
                  />
                }
                control={control}
                defaultValue={formData.ctryCode}
                name="ctryCode"
              />
            </FormItem>
          </Col>

          <Col span={12}>
            <Form.Item
              name="phone"
              rules={[
                {
                  required: true,
                },
              ]}
              help={errors.phone?.message}
              validateStatus={
                errors.phone ? "error" : "success"
              }
              className="user_management_form"
            >
              <Controller
                as={
                  <Input
                    type="text"
                    placeholder="Phone Number"
                    className="user_form_input"
                  />
                }
                name="phone"
                defaultValue={formData.phone}
                control={control}

              />

            </Form.Item>
          </Col>

          <Col span={12}>


            <FormItem
              name="address"
              className="user_management_form"
            >
              <Controller
                name="address"
                control={control}
                defaultValue={formData.address}
                as={<Input
                  type="text"
                  className="user_form_input"
                  placeholder="Address"
                  name="address"
                  defaultValue={formData.address}
                />}
              />

            </FormItem>
          </Col>

          <Col span={12}>
            <Form.Item
              className="user_management_form"
              name="street"
              rules={[
                {
                  required: true,
                },
              ]}
              help={errors.street?.message}
              validateStatus={errors.street ? "error" : "success"}
            >
              <Controller
                name="street"
                control={control}
                defaultValue={formData.street}
                as={<Input
                  placeholder="Street"
                  name="street"
                  defaultValue={formData.street}
                  className="user_form_input"
                />}
              />

            </Form.Item>
          </Col>

          <Col span={12}>
            <FormItem

              name="state"
              rules={[
                {
                  required: true,
                },
              ]}
              help={errors.state?.message}
              validateStatus={errors.state ? "error" : "success"}
              className="user_management_form"
            >
              <Controller
                name="state"
                control={control}
                defaultValue={formData.state}
                as={
                  <Input
                    disabled
                    type="text"
                    placeholder="State"
                    className="user_form_input_select"
                  />
                }
              />
            </FormItem>

          </Col>

          <Col span={12}>
            <FormItem

              name="city"
              rules={[
                {
                  required: true,
                },
              ]}
              help={errors.city?.message}
              validateStatus={errors.city ? "error" : "success"}
              className="user_management_form"
            >

              <Controller
                name="city"
                control={control}
                className="user_form_input_select"
                defaultValue={formData.city}
                as={
                  <Input
                    disabled
                    type="text"
                    placeholder="City"
                  />
                }
              />
            </FormItem>

          </Col>

          <Col span={12}>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                },
              ]}
              help={errors.email?.message}
              validateStatus={errors.email ? "error" : "success"}
              className="user_management_form"
            >
              <Controller
                name="email"
                control={control}
                className="user_form_input_select"
                defaultValue={formData.email}
                as={
                  <Input
                    placeholder="Email"
                    name="email"
                    type="email"
                  />
                }
              />

            </Form.Item>
          </Col>

          <Col span={12}>
            {mode !== "update" && <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                },
              ]}
              help={errors.password?.message}
              validateStatus={errors.password ? "error" : "success"}
              className="user_management_form"
            >
              <Controller
                as={
                  <Input.Password
                    size="large"
                    placeholder="Password"
                    className="user_form_input"
                  />
                }
                iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                type="password"
                name="password"
                control={control}
                defaultValue=""
              />
            </Form.Item>}

          </Col>

          <Col span={24}>
            <Col span={12}>
              <Form.Item
                name="role"
                help={errors.role?.message}
                validateStatus={errors.role ? "error" : "success"}
                className="user_management_form"
              >
                <Select
                  defaultValue={"FYNDR_SUPPORT"}
                  name="role"
                  options={roleDropdown}
                  value={formData.role}
                  onChange={(selectRole) => {
                    setValue("role", selectRole);
                    setFormData({ ...formData, role: selectRole });
                  }}
                  className="user_form_input_select"
                />
              </Form.Item>
            </Col>
          </Col>

        </Row>

      </Form>
      <Col span={24} style={{ borderTop: "0.1px solid #d9d9d9", padding: "1rem 2rem" }} >
        <Row justify={"end"}>
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={() => {
              handleSubmit(
                (data) => {
                  checkPostalCode()
                  onFinish(data)
                },
                (err) => console.log("err>>>>>", err)
              )();
            }}
            style={{
              backgroundColor: "#257CDB",
              height: "auto",
              fontSize: "1.125rem",
              borderRadius: "5px",
              padding: "10px 20px",
            }}
          >
            Submit
          </Button>
        </Row>
      </Col>


    </>

  );
};

export default AddUserModalContent;
