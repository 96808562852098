import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Iframe from "react-iframe";
import Axios from "axios";
import getEnvVars from "../../environment";
import useEvent from "use-add-event";
import UserActions from "../../actions";

const AddCard = (props) => {
  const { callback } = props;
  const { payment } = getEnvVars();
  const { qrcode } = useSelector(({ auth }) => auth.indv);

  const download = async (uri) => {

    const resp = await Axios.get(uri, {
    });
    return resp;
  };

  const handleMessage = async (event) => {
    if (event.data + "" === "card_added") {
      await UserActions.updateMethods({ qrcode });
      if (callback) callback();
    }
  };


  useEvent('message', handleMessage);
  //listens to the messages sent like this from remot

  useEffect(() => {
    (async () => {
      const resp = await download(`${payment}/secure/card?qrcode=${qrcode}`);
      const iframeX = document.getElementById("frame")?.contentDocument;
      if (iframeX) {
        iframeX.close();
        iframeX.write(resp.data);
        iframeX.close();
      }
    })();
  });

  return (
    <>
      <Iframe
        width="400px"
        height="300px"
        id="frame"
        display="initial"
        loading="auto"
        position="relative"
        frameBorder={0}
      />
    </>
  );
};

export default AddCard;
