/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import "antd/dist/antd.css";
import { Row, Col, Slider, Card } from "antd";
import UserActions from "../../actions";
import { Actions } from "../../common";

import Theme from "../../common/Theme.js";
import { Charts } from "../../components";
import { isMobile } from "../../common/util";
import ContainerWrapper from "../../components/ContainerWrapper";
import Tour from "reactour";
import HelmetFyndr from "../../components/HelmetFyndr.jsx";
import getEnvVars from "../../environment.js";

const web = getEnvVars();
const Interactions = (props) => {
  const { LOCATION_SELECTED } = Actions;

  const { bizVisits, termsAccepted, termsDenied } = useSelector(
    ({ core }) => core.bizEvents
  );

  const {
    indv: {
      bizid,
      email,
      qrid,
      locations,
      days_default,
      subs: { subscribedTo },
    },
    settings: { free_limits },
  } = useSelector(({ auth }) => {
    return auth;
  });

  const dispatch = useDispatch();
  const selectLocation = async (objid, locName, qrcode, qrid) => {
    await dispatch({
      type: LOCATION_SELECTED,
      payload: { location_id: objid, locName, qrcode },
    });

    props.history.push(
      `/account/interaction/viewer/${objid}/${qrcode}/${qrid}`,
      {
        locName,
        type: "loc",
      }
    );
  };
  const [series, setSeries] = useState([
    {
      name: "Terms Accepted",
      data: [],
    },
    {
      name: "Interaction Venues",
      data: [],
    },
    {
      name: "Not Accepted",
      data: [],
    },
  ]);
  const [config, setConfig] = useState({
    chart: {
      type: "bar",
      zoom: {
        enabled: true,
      },
      events: {
        dataPointSelection: function (event, chartContext, config) {
          const { dataPointIndex } = config;
          const { objid, locName, qrCode, qrid } = locations[dataPointIndex];
          selectLocation(objid, locName, qrCode, qrid);
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "30%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: [Theme.lightGreen, Theme.blue, Theme.pink],
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      tickPlacement: "on",
      horizontal: true,
      categories: [],
      labels: {
        hideOverlappingLabels: false,
        rotate: -45,
        rotateAlways: true,
        trim: true,
      },
    },
    yaxis: {
    },

    legend: {
      fontSize: isMobile() ? "12px" : "16px",

      markers: {
        width: isMobile() ? 10 : 20,
        height: isMobile() ? 10 : 20,
        strokeWidth: 0,
        strokeColor: Theme.white,
        fillColors: undefined,
        customHTML: undefined,
        offsetX: 0,
        offsetY: 0,
      },
      itemMargin: {
        horizontal: 60,
        vertical: 10,
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val;
        },
      },
    },
  });
  const [totInt, setTotInt] = useState(null);
  const [totTA, setTotTA] = useState(null);
  const [totTD, setTotTD] = useState(null);
  const [days, setDays] = useState(days_default ? days_default.biz : "30");
  const [max, setMax] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);

  useEffect(() => {
    doRefresh();
    UserActions.getTerms(bizid);
  }, [days]);

  useEffect(() => {
    if (!email) {
      props.history.replace("/");
    }
  }, [email]);

  useEffect(() => {
    let mval = findMax(max, bizVisits, "int");
    mval = findMax(mval, termsAccepted, "ta");
    mval = findMax(mval, termsDenied, "td");
    setMax(mval);
  }, [bizVisits, termsAccepted, termsDenied]);

  useEffect(() => {
    if (Array.isArray(locations) && locations.length > 0) {
      let placeName = [];
      let termsAcceptedLocal = [];
      let termsDeniedLocal = [];
      let interactionVenue = [];
      locations.map((obj) => {
        const { locName } = obj;

        placeName.push(locName);
        if (bizVisits[locName]) {
          interactionVenue.push(bizVisits[locName]);
        } else {
          interactionVenue.push(0);
        }
        if (termsAccepted[locName]) {
          termsAcceptedLocal.push(termsAccepted[locName]);
        } else {
          termsAcceptedLocal.push(0);
        }
        if (termsDenied[locName]) {
          termsDeniedLocal.push(termsDenied[locName]);
        } else {
          termsDeniedLocal.push(0);
        }
        return null;
      });

      setConfig({ ...config, xaxis: { categories: placeName } });
      setSeries([
        {
          name: "Interaction",
          data: interactionVenue,
        },
        {
          name: "Terms Accepted",
          data: termsAcceptedLocal,
        },

        {
          name: "Not Accepted",
          data: termsDeniedLocal,
        },
      ]);
    }
    const labels = document.querySelectorAll("tspan");

    labels.forEach((item, index) => {
      item.addEventListener("click", (event) => {
        const { objid, locName, qrCode, qrid } = locations[index];
        selectLocation(objid, locName, qrCode, qrid);
      });
    });
  }, [locations, bizVisits, termsAccepted, termsDenied]);

  const doRefresh = async () => {
    UserActions.getBizInteractions({
      qrId: qrid + "",
      interval: Number(days),
      traversal: "biz",
    });
  };

  const findMax = (max, obj, type) => {
    let tot = 0;
    // eslint-disable-next-line no-unused-vars
    for (const [key, value] of Object.entries(obj)) {
      max = max > parseInt(value) ? max : parseInt(value);
      tot += value;
    }
    if (type === "int") setTotInt(tot);
    else if (type === "ta") setTotTA(tot);
    else setTotTD(tot);
    return max;
  };

  useEffect(() => {
    const isTourOpen = JSON.parse(localStorage.getItem("tourOpenInteractions"));
    if (isTourOpen !== null) {
      setIsOpen(isTourOpen);
    } else {
      localStorage.setItem("tourOpenInteractions", JSON.stringify(true));
      setIsOpen(true);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("tourOpenInteractions", JSON.stringify(isOpen));
  }, [isOpen]);

  const tourConfig = [
    {
      selector:'[interactionsTour="box"]',
      content:"This part displays the total number of interaction venues, check-ins, as well as accepted and not accepted terms."
    },
    {
      selector:'[interactionsTour="slider-days"]',
      content:"You can toggle between past 30 days and see the interactions graph in that time period."
    },
    {
      selector:'[interactionsTour="graph"]',
      content:"This graph will show you average number of customers who interacted with various store locations",
    },
  ]



  const closeTour = () => {
    setIsOpen(false);
  };

  const skipTour = () => {
    setIsOpen(false);
  };

  return (
     <ContainerWrapper title={"Interactions"}>
      <Tour
      steps={tourConfig}
      isOpen={isOpen}
      onRequestClose={closeTour}
      showCloseButton={true}
      showSkipButton={true}
      onAfterOpen={() => setIsOpen(true)}
      onRequestSkip={skipTour}
      getCurrentStep={() => currentStep}
      />
      {<HelmetFyndr
            title="Interactions"
            imgUrl={""}
            url={`${web}/interactions`}
            descr="Interactions"
          />}
          <Col align="center" span={22}>
            <Row gutter={20} align="middle">
              <Col xs={24} md={12} span={12}>
                <Card interactionsTour="box">
                  <Row align="middle" gutter={15}>
                    <Col span={24}>
                      <Row >
                        <Col span={12}>
                          <div>
                            <h6
                              style={{ color: "#9B8DFD" }}
                              className="dashboard_body--h8"
                            >
                              {Array.isArray(locations) ? locations.length : ""}
                            </h6>
                            <span>Interaction Venues</span>

                            <h6
                              style={{
                                color: "#3F9AF7",
                                marginTop: "3rem",
                              }}
                              className="dashboard_body--h8"
                            >
                              {totTA}
                            </h6>
                            <span>Terms Accepted</span>
                          </div>
                        </Col>
                        <Col span={12}>
                          <div>
                            <h6
                              style={{ color: "#1DCFC2" }}
                              className="dashboard_body--h8"
                            >
                              {totInt}
                            </h6>
                            <span>Total Check-in</span>

                            <h6
                              style={{
                                color: "#FF879C",
                                marginTop: "3rem",
                              }}
                              className="dashboard_body--h8"
                            >
                              {totTD}
                            </h6>
                            <span>Not Accepted</span>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </Col>

              <Col xs={24} md={12} align="center" span={12} interactionsTour="slider-days">
                <h6>Past {days} Days</h6>
                <Slider
                  defaultValue={days}
                  max={30}
                  disabled={false}
                  style={{ color: "#3F9AF7" }}
                  onAfterChange={(value) => {
                    setDays(value);
                  }}
                />
              </Col>
            </Row>

            <Card interactionsTour="graph" style={{ marginTop: "2rem", marginBottom: "2rem"}}>
              <Charts config={config} series={series} />
            </Card>
          </Col>
    </ContainerWrapper>
  );
};

export default Interactions;
