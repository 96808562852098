/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Row, Col, Button, Radio, Modal, message } from "antd"
import { useSelector } from "react-redux";
import { Theme } from "../../common";
import AddCard from "../../pages/Payment/AddCard";
import UserActions from "../../actions";
import { PlusOutlined } from "@ant-design/icons";
import CustomizedModal from '../CustomizedModal/CustomizedModal';
import tickGif from "../../assets/images/tickGif.gif"
export default function UpdatePay({ defaultPaymentId, paymentSubscription, onPaymentUpdated }) {
    const { cards, qrcode } = useSelector(({ auth }) => auth.indv);
    const [, setNewCard] = useState(
        !cards || cards.length === 0 ? true : false
    );

    const [selectedCard, setSelectedCard] = useState(defaultPaymentId ? defaultPaymentId : paymentSubscription?.defaultPaymentId);
    const [visible, setVisible] = useState(false);
    const [myCards, setMyCards] = useState(cards.filter((card) => defaultPaymentId ? card.id !== defaultPaymentId : card.id !== paymentSubscription?.defaultPaymentId));
    const [currentUse, setCurrentUse] = useState(cards.find((card) => defaultPaymentId !== null ? card.id === defaultPaymentId : card.id === paymentSubscription?.defaultPaymentId));
    const [updatedPaymentVisible, setUpdatedPaymentVisible] = useState(false);
    const [isCardSelected, setIsCardSelected] = useState(false);

    useEffect(() => {
        setSelectedCard(selectedCard)
    }, [selectedCard]);

    const onChange = (value) => {
        setIsCardSelected(true)
        setSelectedCard(value.target.value);
    };


    useEffect(() => {
        setMyCards(cards.filter((card) => card.id !== defaultPaymentId && card.id !== paymentSubscription?.defaultPaymentId));
    }, [cards])

    const UpdatePayMethod = async () => {
        let resp = await UserActions.updatePayMethods({
            subscriptionId: paymentSubscription?.id,
            qrcode: qrcode,
            cardId: selectedCard
        });
        if (resp.success) {
            setCurrentUse(cards.find((card) => card.id === resp?.data?.subscription_default_payment));
            setMyCards(resp?.data?.card.filter((card) => card.id !== resp?.data?.subscription_default_payment))
            setUpdatedPaymentVisible(true);
            onPaymentUpdated(resp?.data);
        } else {
            message.error(resp?.msg);
        }
    }

    return (
        <div style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            flexDirection: "column"
        }}>
            <Col xs={24} sm={24} md={16} xl={12} className='featuredSubs_card'>
                <Radio.Group
                    style={{ minWidth: "100%" }}
                    value={selectedCard}
                    onChange={onChange}
                >
                    {currentUse &&
                        <>
                            <h5 style={{
                                fontSize: "14px",
                                fontWeight: "400",
                                lineHeight: "20px"

                            }}>Currently Using</h5>
                            <Row
                                style={{
                                    border: "1px solid #D3D6E1",
                                    padding: "0.3rem",
                                    paddingTop: "0.5rem",
                                    borderRadius: "5px",
                                    margin: "0.5rem 0rem",
                                }}
                                align="middle"
                                justify="space-between"
                            >
                                <Col>
                                    <i
                                        style={{
                                            color: Theme.blue,
                                            fontSize: "28px",
                                        }}
                                        className={`fa fa-cc-${currentUse.brand}`}
                                        aria-hidden="true"
                                    ></i>
                                </Col>
                                <Col align="middle">
                                    <h6
                                        style={{
                                            color: Theme.green
                                        }}
                                    >
                                        {`**** **** **** ${currentUse.last4}`}
                                    </h6>
                                </Col>
                                <Col align="middle">
                                    <Radio value={currentUse.id}></Radio>
                                </Col>
                            </Row>
                        </>
                    }
                    {myCards &&
                        myCards.length > 0 && (
                            <div style={{ marginTop: "24px" }}>
                                <h5 style={{
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    lineHeight: "20px",
                                    color: "#7F7F7F"

                                }}>My Cards</h5>
                                {myCards.map((card, index) => {
                                    return (
                                        <>
                                            <Row
                                                key={index}
                                                style={{
                                                    border: "1px solid #D3D6E1",
                                                    padding: "0.3rem",
                                                    paddingTop: "0.5rem",
                                                    borderRadius: "5px",
                                                    margin: "0.5rem 0rem",
                                                }}
                                                align="middle"
                                                justify="space-between"
                                            >
                                                <Col>
                                                    <i
                                                        style={{
                                                            color: Theme.blue,
                                                            fontSize: "28px",
                                                        }}
                                                        className={`fa fa-cc-${card.brand}`}
                                                        aria-hidden="true"
                                                    ></i>
                                                </Col>
                                                <Col align="middle">
                                                    <h6
                                                        style={{
                                                            color: "#000",
                                                        }}
                                                    >
                                                        {`**** **** **** ${card.last4}`}
                                                    </h6>
                                                </Col>
                                                <Col align="middle">
                                                    <Radio value={card.id}></Radio>
                                                </Col>
                                            </Row>
                                        </>
                                    );
                                })}
                            </div>
                        )
                    }
                </Radio.Group>
            </Col>
            <Row style={{ marginTop: "24px" }} justify={"center"} gutter={[0, 12]}>
                <Button
                    style={{
                        padding: "3px 12px",
                        borderRadius: "8px",
                        marginRight: "12px",
                        backgroundColor: "#257CDB",
                        height: "46px",
                    }}
                    size="large"
                    disabled={!isCardSelected}
                    type="primary"
                    onClick={() =>
                        UpdatePayMethod()
                    }
                >
                    Update & Save
                </Button>
                <Button
                    size="large"
                    type="text"
                    onClick={() => {
                        setNewCard(true);
                        setVisible(true)
                    }}
                    style={{
                        fontSize: "16px",
                        fontWeight: "400",
                        lineHeight: "26px",
                        border: "1px solid #257CDB",
                        borderRadius: "8px",
                        height: "46px",
                    }}
                >
                    <span style={{ color: "#257CDB" }}>
                        Add a new card
                    </span>
                    <PlusOutlined
                        style={{
                            fontSize: 16,
                            color: "#257CDB",
                            lineHeight: 0,
                            verticalAlign: "middle"

                        }}
                    />
                </Button>
            </Row>

            <Modal visible={visible}
                title="Add Card"
                style={{ fontSize: "16px" }}
                footer={null}
                onCancel={() => { setVisible(false); setNewCard(false) }}
            >
                <Row justify="center">
                    <AddCard callback={(newCardId) => {
                        setNewCard(false);
                        setVisible(false);
                    }} />
                </Row>
            </Modal>
            <CustomizedModal
                visible={updatedPaymentVisible}
                title={null}
                modalWidth="450px"
                footer={null}
                onCancel={() => setUpdatedPaymentVisible(false)}
                content={<div style={{ padding: "12px 12px 24px 12px" }}>
                    <Col span={24} align="middle" style={{ fontSize: "14px", color: "#3A3A3A", fontWeight: "400", paddingBottom: "24px" }}>Your payment method has been updated successfully!!!</Col>
                    <Col span={24} align="middle">
                        <img src={tickGif} alt='fyndr' height={"100px"} width={"100px"}></img>
                    </Col>
                </div>}
            />
        </div>
    )
}
