/* eslint-disable import/no-anonymous-default-export */

import { Actions } from "../common";

const {
  PAYABLES_FETCHED,
  LOGOUT_FIN,
  RECEIVABLE_UPSERTED,
  RECEIVABLES_FETCHED,
  RECEIVABLES_SEARCHED,
  PAYABLE_UPSERTED,
  INVOICE_PAID,
  VOUCHER_UPDATED,
  PAYABLES_SEARCHED,
  OFFERS_SELECTED,
  CHECKOUT_STARTED,
  ROLE_TYPE
} = Actions;

const INITIAL_STATE = {
  receivables: {},
  payables: {},
  receivableModified: false,
  payableModified: false,
  cartInfo: {},
  voucherUpdated: false,
  payableTxt: "",
  payableCtr: 1,
  receivableTxt: "",
  receivableCtr: 1,
  roleType:null,
};
let receivables, payables, objid;

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOGOUT_FIN:
      return INITIAL_STATE;
    case RECEIVABLE_UPSERTED:
      objid = action.payload.objid;
      receivables = state.receivables;
      receivables[objid] = action.payload;
      return {
        ...state,
        receivables,
        receivableModified: !state.receivableModified,
      };
    case RECEIVABLES_FETCHED:
      receivables = {};
      action.payload.forEach((row) => {
        if (
          (row.channel === "offers") ||
          row.channel !== "offers"
        ) {
          receivables[row.objid] = row;
        }
      });
      return {
        ...state,
        receivables,
        receivableModified: !state.receivableModified,
      };
    case PAYABLE_UPSERTED:
      objid = action.payload.objid;
      payables = state.payables;
      payables[objid] = action.payload;
      return { ...state, payables, payableModified: !state.payableModified };
    case PAYABLES_FETCHED:
      payables = {};
      action.payload.forEach((row) => {
        if (
          (row.channel === "offers" && row.status === "paid") ||
          row.channel !== "offers"
        ) {
          payables[row.objid] = row;
        }
      });
      return { ...state, payables, payableModified: !state.payableModified };
    case INVOICE_PAID:
      payables = state.payables;
      const payable_id = action.payload;
      payables[payable_id] = { ...payables[payable_id], status: "paid" };
      return { ...state, payables, payableModified: !state.payableModified };
    case VOUCHER_UPDATED:
      return { ...state, voucherUpdated: !state.voucherUpdated };
    case OFFERS_SELECTED:
      return { ...state, offersToBuy: action.payload };
      case ROLE_TYPE:
        return { ...state, roleType: action.payload };
  
    case PAYABLES_SEARCHED:
      return {
        ...state,
        payableTxt: action.payload.text,
        payableCtr: action.payload.ctr,
      };
    case RECEIVABLES_SEARCHED:
      return {
        ...state,
        receivableTxt: action.payload.text,
        receivableCtr: action.payload.ctr,
      };
    case CHECKOUT_STARTED:
      return { ...state, cartInfo: action.payload };
    default:
      return state;
  }
}
