/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ContainerWrapper from "../../components/ContainerWrapper"
import "antd/dist/antd.css";
import {
  Row,
  Col,
  Table,
  Input,
  Slider,
  Button,
  Modal,
  Select,
  Divider,
  Checkbox,
  Typography,
  message,
} from "antd";

import {
  getFormattedDtNew,
  getFormattedTmNew,
  parseEvents,
  last14,
} from "../../common/util.js";

import UserActions from "../../actions";
import Theme from "../../common/Theme.js";
import Actions from "../../common/ActionsStrings";
import Loader from "../../components/Loading/Loader";
import { FiSearch } from "react-icons/fi";

const { Text } = Typography;

const Interactions = (props) => {
  const {
    indv: { userTimeZone },
  } = useSelector(({ auth }) => auth);
  const { FILTER_EVENTS } = Actions;
  const {
    indv: {
      bizid,
      days_default: { indv },
      qrid,
    },
    settings: { messages },
  } = useSelector(({ auth }) => auth);

  const { filtered_events, indirect, days, events } = useSelector(
    ({ core }) => core
  );
  const dispatch = useDispatch();

  const columns = [
    {
      title: "Interaction",
      dataIndex: "name",
      key: "name",
      render: (text) => <p>{text}</p>,
    },
    {
      align: "left",
      title: "Time",
      dataIndex: "time",
      key: "time",
      render: (date) => {
        return (
          <>
            <p>{getFormattedDtNew(date, userTimeZone)}</p>
            <p>{getFormattedTmNew(date, userTimeZone)}</p>
          </>
        );
      },
    },
    {
      align: "center",
      title: "Interaction Type",
      dataIndex: "act",
      key: "act",
      render: (item) => {
        return (
          <>
            <i
              style={{ color: Theme.blue }}
              className="fa fa-user"
              aria-hidden="true"
            ></i>{" "}
            &nbsp;
            {item.endsWith("By") ? (
              <i
                style={{ color: Theme.blue }}
                className="fa fa-chevron-left"
                aria-hidden="true"
              ></i>
            ) : (
              <i
                style={{ color: Theme.blue }}
                className="fa fa-chevron-right"
                aria-hidden="true"
              ></i>
            )}{" "}
            &nbsp;
            {item.startsWith("interacted") ? (
              <i
                style={{ color: Theme.blue }}
                className="fa fa-user"
                aria-hidden="true"
              ></i>
            ) : (
              <i
                style={{ color: Theme.blue }}
                className="fa fa-building"
                aria-hidden="true"
              ></i>
            )}
          </>
        );
      },
    },
    {
      title: "Initiated By",
      dataIndex: "by",
      key: "by",
      align: "center",
      width: "20%",
      render: (text, item) => {
        const { name, loc, bizName } = item;
        const act = item.act.toLowerCase();
        let val;
        if (act === "interactedwith" || act === "visited") val = "You";
        else if (act === "interactedby") val = name;
        else if (act === "checkedinby" && loc)
          val = `${bizName} - ${loc.name}, ${loc.city} ${loc.postalCdoe}`;

        return <Text>{val}</Text>;
      },
    },
    {
      title: "Initiated For",
      dataIndex: "for",
      key: "for",
      width: "20%",
      render: (text, item) => {
        const { act, name, loc, bizName } = item;
        let val = act === "interactedwith" ? name : "";
        if (act === "visited" && loc)
          val = `${bizName} - ${loc.name}, ${loc.city} ${loc.postalCode}`;
        else if (act === "visited") val = "You";
        return <Text>{val}</Text>;
      },
    },
    {
      title: "Business Terms",
      dataIndex: "terms_accepted",
      key: "terms_accepted",
      align: "center",
      render: (text, item) => {
        const { terms_accepted, consent_time } = item;
        let val = "";
        if (terms_accepted !== undefined) {
          val =
            terms_accepted === "true"
              ? `Accepted on ${getFormattedTmNew(consent_time, userTimeZone)}`
              : "Not Accepted";
        }
        return <Text>{val}</Text>;
      },
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "Location",
      align: "center",
      render: (text, item) => {
        const { lat, lng } = item;
        return (
          <a
            rel="noopener noreferrer"
            target="_blank"
            href={`https://maps.google.com/?q=${lat},${lng}`}
          >
            <i
              style={{ color: Theme.orange }}
              className="fa fa-map-marker"
              aria-hidden="true"
            ></i>
          </a>
        );
      },
    },
  ];
  const { Option } = Select;
  //States
  const [rcpts, setRcpts] = useState(filtered_events);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [parsedReceipts, setParsedReceipts] = useState(
    parseEvents(selectedRowKeys)
  );

  const [Days, setDays] = useState(indv);

  const [visible, setVisible] = useState(false);
  const [searchTxtInput, setSearchTxt] = useState("");
  const [isSelectActive, setSelectActive] = useState(false);

  const [agree, setAgree] = useState(false);
  let list = [];
  let DayList = last14();

  messages.forEach((row) => {
    list.push({ label: bizid ? row.value : row.label, value: row.value });
  });

  const [msg, setMsg] = useState(list[0].value);
  const [when, setWhen] = useState(DayList[0].value);
  const [finalMsg, setFinalMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  //Effects
  useEffect(() => {
    doRefresh(true);
  }, [Days]);

  useEffect(() => {
    filterEvents();
  }, [searchTxtInput]);

  useEffect(() => {
    formatMsg();
  }, [agree, msg, when]);

  useEffect(() => {
    let tempData = [];
    filtered_events.map((item, index) => {
      item.key = index;
      tempData.push(item);
      return null;
    });

    setRcpts(tempData);
  }, [filtered_events]);

  //   Inner Methods

  const doRefresh = async () => {
    setIsLoading(true);
    await UserActions.getIndvInteractions({
      qrId: qrid + "",
      interval: Number(Days),
      traversal: "full",
    });
    setIsLoading(false);
  };

  const filterEvents = () => {
    dispatch({
      type: FILTER_EVENTS,
      payload: { events, searchTxt: searchTxtInput, days },
    });
  };

  const sendMessage = async () => {
    const resp = await UserActions.sendMsg({
      when,
      msg,
      rcpts: parsedReceipts,
      messages,
    });
    if (resp.success) {
      message.success(resp.msg);
    } else {
      message.error(resp.msg);
    }
  };

  const formatMsg = () => {
    setFinalMsg(
      [
        msg,
        `\nThe approximate date of exposure is ${when}\n\nPlease note that Fyndr does not collect or validate medical records from its users. These messages are solely intended for cautionary purposes in order to minimize the spread of Covid-19.`,
      ].join(" ")
    );
  };

  const onSelectChange = (selectedRowKeys, rows) => {
    setSelectedRowKeys(selectedRowKeys);
    let recp = parseEvents(rows);
    setParsedReceipts(recp);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <ContainerWrapper title={"Interactions"}>
              <Row
                align="middle"
                justify="center"
                style={{ maxWidth: "100%" }}
                gutter={20}
              >
                <Col span={23}>
                  <Row justify="center" gutter={10}>
                    <Col span={12}>
                      <Input
                        size="large"
                        placeholder="Search Interactions"
                        onChange={(e) => {
                          setSearchTxt(e.target.value);
                        }}
                        prefix={<FiSearch className="prefix" />}
                      />
                    </Col>
                    <Col span={12} align="center">
                      <Text strong>Past {Days} Days</Text>
                      <Slider
                        onChange={(value) => {
                          setDays(value);
                        }}
                        defaultValue={Days}
                        max={30}
                        disabled={false}
                      />
                    </Col>
                  </Row>

                  <Table
                    dataSource={rcpts}
                    columns={columns}
                    rowClassName="RowClass"
                    rowSelection={isSelectActive ? rowSelection : null}
                  />
                  <div style={{ padding: "2rem" }}>
                    <Button
                      disabled={
                        isLoading ||
                        (Array.isArray(rcpts) && rcpts.length === 0) ||
                        !Array.isArray(rcpts)
                      }
                      type="primary"
                      onClick={() => {
                        isSelectActive
                          ? setVisible(true)
                          : setSelectActive(true);
                      }}
                    >
                      {isSelectActive ? "Proceed" : "Send Message"}{" "}
                      {isLoading && <Loader />}
                    </Button>
                  </div>
                </Col>
              </Row>

              <Modal
                title="Messaging Center"
                visible={visible}
                centered
                width={900}
                okText={"Send"}
                onOk={() => {
                  if (agree) {
                    sendMessage();
                  } else {
                    message.error(
                      "Please agree to terms and condition to proceed."
                    );
                  }
                }}
                onCancel={() => {
                  setVisible(false);
                }}
                bodyStyle={{ height: "80vh" }}
              >
                <h3 style={{ marginTop: "3rem" }}>
                  We’re committed towards ensuring the safety of our
                  communities. Let your network know how you’re feeling.
                </h3>
                <h4 style={{ color: Theme.blue, marginTop: "2rem" }}>
                  The messages are sent anonymously to ensure your privacy.
                </h4>
                <Row style={{ marginTop: "2rem" }}>
                  <Col span={12}>Total Recipients</Col>
                  <Col span={12}>{parsedReceipts.totalReceivers}</Col>
                </Row>
                <Divider
                  dashed
                  style={{ color: Theme.blue }}
                  orientation="center"
                />
                <Row style={{ marginTop: "2rem" }} gutter={10}>
                  <Col span={18}>
                    <h5>Select Message</h5>
                    <Select
                      defaultValue={list[0].value}
                      style={{ maxWidth: "100%" }}
                      onChange={(msg) => {
                        setMsg(msg);
                      }}
                    >
                      {list.map((item, index) => (
                        <Option
                          style={{ width: "100%", wordBreak: "break-all" }}
                          value={item.value}
                        >
                          {item.label}
                        </Option>
                      ))}
                    </Select>
                  </Col>

                  <Col span={6}>
                    <h5>Approximate date of exposure</h5>
                    <Select
                      defaultValue={DayList[0].value}
                      style={{ maxWidth: "100%" }}
                      onChange={(date) => {
                        setWhen(date);
                      }}
                    >
                      {DayList.map((item, index) => (
                        <Option value={item.value}>{item.label}</Option>
                      ))}
                    </Select>
                  </Col>
                </Row>
                <Row style={{ marginTop: "5rem" }}>
                  <Col span={24}>
                    <p>
                      <Checkbox
                        onChange={(e) => {
                          setAgree(e.target.checked);
                        }}
                      />{" "}
                      &nbsp; I agree with Fyndr's terms of use
                    </p>
                  </Col>
                </Row>

                {agree && (
                  <>
                    <h3>Message Preview</h3>
                    <h5>{finalMsg}</h5>
                  </>
                )}
              </Modal>
    </ContainerWrapper>
  );
};

export default Interactions;
