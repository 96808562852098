
import React from "react";
import { Button, Input, message, Space } from "antd";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import { getRemainingOffer } from "../utils/Helper";

const QuantitySelector = ({
  item,
  cart,
  handleChangeQty,
  getMaxLimit,
  disabled,
  isDisabled,
  openModal, modalVisble,
  readOnly,
  minusClick,
  minusdisabled,
  plusClick,

}) => {

  const handleQtyChange = (value) => {
    const maxLimit = getMaxLimit(item);
    if (modalVisble && value !== 0) {
      openModal();
    }
    if (value <= maxLimit) {
      handleChangeQty(item.objid, value);
    } else {
      let remainingOffers = getRemainingOffer(item.offerLimit,item.offerSold)
      if(remainingOffers === null && item.usageLimit ===-1){
        message.error(`Maximum quantity is ${maxLimit} for this offer. To order more than 1000 please contact customer support for assistance`)
      }
      else{
        message.error(`Maximum quantity is ${maxLimit} for this offer`);
      }

      handleChangeQty(item.objid, maxLimit);
    }
  };

  const handleIncrement = () => {

    if (modalVisble) {
      openModal();
    }
    const currentValue = cart[item.objid]?.qty || 0;
    const newValue = currentValue + 1;

    if (newValue <= getMaxLimit(item)) {
      handleChangeQty(item.objid, newValue);
      plusClick();
    }
  };

  const handleDecrement = () => {
    const currentValue = cart[item.objid]?.qty || 0;
    const newValue = currentValue - 1;
    if (newValue >= 0) {
      
      handleChangeQty(item.objid, newValue);
      minusClick();
    }
  };

  return (
    <Space direction="horizontal">
      <Button
        style={{ padding: "0px", lineHeight: "0" }}
        className={
          cart[item.objid] && cart[item.objid].qty > 0
            ? "qty-button-active"
            : "qty-button"
        }
        onClick={handleDecrement}
        disabled={
          minusdisabled ||
          (item.offerLimit !== null &&
            Number(item.offerLimit) - Number(item.offerSold) === 0)
        }
      >
        <MinusOutlined />
      </Button>

      <Input
        readOnly={readOnly}
        disabled={
          disabled ||
          (item.offerLimit !== null &&
            Number(item.offerLimit) - Number(item.offerSold) === 0)
        }
        mobile={false}
        step={1}
        min={0}
        max={getMaxLimit(item)}
        onChange={(e) => handleQtyChange(Number(e.target.value))}
        className="offer_cards--qty-input"
        value={cart[item.objid]?.qty || 0}
      />

      <Button
        style={{ padding: "0px", lineHeight: "0" }}
        className={isDisabled ? "qty-button" : "qty-button-active"}
        onClick={handleIncrement}
        disabled={
          disabled ||
          (item.offerLimit !== null &&
            Number(item.offerLimit) - Number(item.offerSold) === 0)
        }
      >
        <PlusOutlined />
      </Button>
    </Space>
  );
};

export default QuantitySelector;