/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import {
  Col,
  Row,
  Checkbox,
  Layout,
  Typography,
  Modal,
  Card,
  Space,
  message,
} from "antd";

import Loader from "../../components/Loading/Loader";
import { isTab } from "../../common/util";
import HelmetFyndr from "../../components/HelmetFyndr";
import { MdOutlineCancel } from "react-icons/md";
import { Actions } from "../../common";
import UserActions from "../../actions";
import { useRecoilState } from "recoil";
import { offerPageState } from "../../recoil/Atom";
import GoogleMap from "../../components/GoogleMap";
import filterCan from "../../assets/images/filter-cancel-icon.svg";
import { useHistory } from "react-router-dom";

import { TopMenu, Logo, MarkerGoogle, Footer } from "../../components";
import { parseAddress } from "../../common/util";

import OfferCard from "./OfferCard";
import getEnvVars from "../../environment";
const { Text } = Typography;
const web = getEnvVars();
const MyOffers = (props) => {
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);

  const [showFilter, setShowFilter] = useState(false);

  const { isMasquerade } = useSelector(({ admin }) => admin);
  const [orderBy] = useState("ASC");

  const closeIcon = <MdOutlineCancel className="closeIconBtn" />;
  const modalStyle = { display: "flex", alignItems: "center" };
  const onCancelFilter = (value, index) => {
    const updatedDeals = subTypeDeals.filter((item, i) => i !== index);
    if (updatedDeals.length === 0) {
      setSubTypeDeals(["all"]);
    } else {
      setSubTypeDeals(updatedDeals);
    }
  };

  const { state } = props?.location;
  const locQRId = state?.locQRId;
  const {
    indv,
    indv: { indvid, entityType, address },
  } = useSelector(({ auth }) => auth);
  const { ROLE_FYNDR } = Actions;

  const [offerPageSetting, setOfferPageSetting] =
    useRecoilState(offerPageState);
  const [typeOfDeals] = useState(
    offerPageSetting?.typeOfDeals || "instore"
  );

  const [subTypeDeals, setSubTypeDeals] = useState(
    offerPageSetting?.subTypeDeals || ["all"]
  );

  const [miles] = useState(50);

  const [categories, setCategories] = useState(
    offerPageSetting?.categories || []
  );

  useEffect(() => {
    const state = history.location.state;
    if (state && state.selectedOptions) {
      setSubTypeDeals(state.selectedOptions);
    }
  }, [history]);

  const [, setOpenBottomSheet] = useState(false);
  const [section] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [page] = useState(0);
  const [scrollPage, setScrollPage] = useState(1);

  const [isLast, setIsLast] = useState(true);
  const [pgSize] = useState(55);

  const [searchStr] = useState("");
  const [campns, setCampn] = useState([]);
  const [, setCount] = useState(0);
  const [isfilterOn] = useState(false);
  const [isOption] = useState(true);
  const [categoryList] = useState([]);
  const [lat] = useState(
    offerPageSetting?.lat || address?.lat || 33.6629442
  );
  const [lng] = useState(
    offerPageSetting?.lng || address?.lng || -112.0182329
  );
  const [position] = useState({
    lat: offerPageSetting?.lat || address?.lat || 33.6629442,
    lng: offerPageSetting?.lng || address?.lng || -112.0182329,
  });

  const [addressName] = useState(
    offerPageSetting?.addressName || parseAddress(indv?.address)
  );
  const categoryListArr = categoryList.map((row) => {
    return { value: row.objid, label: row.name };
  });

  

  const onChangeType = (value) => {
    if (value.length > 0) {
      if (value.indexOf("all") === -1) {
        setSubTypeDeals(value);
      } else {
        if (value[value.length - 1] === "all") {
          setSubTypeDeals(["all"]);
        } else {
          let indexcheck = value.indexOf("all");
          value.splice(indexcheck, 1);
          setSubTypeDeals(value);
        }
      }
    } else {
      message.warn("Please select atleast one type");
    }
  };

  const onCancelCategory = (value) => {
    const updatedCategories = categories.filter((item) => item !== value);
    setCategories(updatedCategories);
  };

  const onChangeCategories = (value) => {
    setCategories(value);
  };

  const searchCampaignFunction = async (
    pageNumber = null,
    locQRId = null,
    orderByParam = orderBy
  ) => {
    const response = await UserActions.searchCmpnsLiked({
      page: 0,
      pgSize,
      searchStr: '',
      location:position,
      userId: indvid,
    });
    if (response && response.success) {
      let payload = response.payload;
      let tempCamps = [];
      payload.forEach((item, key) => {
        tempCamps.push(Object.values(item)[0]);
      });
      setIsLast(response.last);
      setCount(response?.count);
      setCampn(tempCamps);

      const isLogin = indv?.indvid ? true : false;
      if (isLogin) {
        //now get the likes of user per campaign and total likes for the business
        const bizId = payload.map(
          (row) => Object.values(row)[0]["biz"]["bizid"]
        );
        await UserActions.fetchBizinfo({
          bizId: [...new Set(bizId)],
        });
      }
    } else {
      setCampn([]);
    }
  };

  const searchCampaignFunction2 = async (
    pageNumber = null,
    locQRId = null,
    orderByParam = orderBy
  ) => {
    const response = await UserActions.searchCmpnsLiked({
      page: 0,
      pgSize,
      searchStr: '',
      location:position,
      userId: indvid,
    });
    setIsLoading(false);
    if (response && response.success) {
      let payload = response.payload;
      let tempCamps = [];
      payload.forEach((item, key) => {
        tempCamps.push(Object.values(item)[0]);
      });
      setIsLast(response.last);
      setCount(response?.count);
      setCampn((prevCamps) => [...prevCamps, ...tempCamps]);

      const isLogin = indv?.indvid ? true : false;
      if (isLogin) {
        //now get the likes of user per campaign and total likes for the business
        const bizId = payload.map(
          (row) => Object.values(row)[0]["biz"]["bizid"]
        );
        await UserActions.fetchBizinfo({
          bizId: [...new Set(bizId)],
        });
      }
    } else {
      setCampn([]);
    }
  };
  const searchCampaignFunctionNew = async (
    pageNumber = null,
    locQRId = null
  ) => {
    const response = await UserActions.searchCmpnsLiked({
      page: pageNumber || page,
      pgSize,
      searchStr: '',
      location:position,
      userId: indvid,
    });
    setIsLoading(false);
    if (response && response.success) {
      let payload = response.payload;
      let tempCamps = [];
      payload.forEach((item, key) => {
        tempCamps.push(Object.values(item)[0]);
      });
      setIsLast(response.last);
      setCampn(tempCamps);

      const isLogin = indv?.indvid ? true : false;
      if (isLogin) {
        //now get the likes of user per campaign and total likes for the business
        const bizId = payload.map(
          (row) => Object.values(row)[0]["biz"]["bizid"]
        );
        await UserActions.fetchBizinfo({
          bizId: [...new Set(bizId)],
        });
      }
    } else {
      setCampn([]);
    }
  };

  useEffect(() => {
    (async () => {
      await setOfferPageSetting({
        ...offerPageSetting,
        typeOfDeals,
        categories,
        miles,
        lat,
        lng,
        addressName,
      });
    })();
  }, [typeOfDeals, categories, indv, lat, lng, miles, addressName, locQRId]);


  useEffect(() => {
    searchCampaignFunction(null, locQRId, orderBy);
  }, [position, typeOfDeals, locQRId, categories]);

  useEffect(()=>{
    setCampn([]);
    setScrollPage(1);
    setIsLast(false);
  }, [])

 
  useEffect(() => {
    (async () => {
      await setOfferPageSetting({
        ...offerPageSetting,
        subTypeDeals,
        searchStr,
      });
    })();
  }, [subTypeDeals, searchStr]);
 

  const listInnerRef = useRef();
  const innerScrollRef = useRef();
  const [isAtBottom, setIsAtBottom] = useState(false);
  const handleScroll2 = () => {
    const { scrollTop, scrollHeight, clientHeight } = innerScrollRef.current;

    if (scrollHeight - scrollTop === clientHeight) {
      setIsAtBottom(true);
    } else {
      setIsAtBottom(false);
    }
  };

  const loadMore = () => {
    if (!isLoading && !isLast) {
      setScrollPage((prevPage) => prevPage + 1);
      searchCampaignFunction2(scrollPage + 1);
    }
  };

  useEffect(() => {
    const innerScroll = innerScrollRef.current;
    innerScroll.addEventListener("scroll", handleScroll2);
    return () => {
      innerScroll.removeEventListener("scroll", handleScroll2);
    };
  }, []);

  useEffect(() => {
    if (isAtBottom) {
      loadMore();
    }
  }, [isAtBottom]);

  return (
    <Layout className="offer--home">
      {
        <HelmetFyndr
          title="My Offers"
          imgUrl={""}
          url={`${web}/myOffers`}
          descr="myOffers"
        />
      }
      <section className={isMasquerade ? "bg-danger-fyndr" : "bg-info"}>
        <div>
          {(section > 50 || !isTab()) && (
            <div
              className="fyndrLogo"
              style={{
                float: isTab() ? "none" : "left",
                position:"absolute",
                zIndex:"9999"
              }}
            >
              <Logo />
            </div>
          )}
          <div
            className={
              section > 50
                ? "shortHeader-gone"
                : isMasquerade
                ? "bg-danger-fyndr"
                : "bg-info"
            }
          >
            {isOption && (
              <Row className="offer--header-row" align="middle">
                <Col
                  offset={isTab() ? 0 : 1}
                  xs={indvid ? 18 : 19}
                  sm={indvid ? 15 : 12}
                  md={indvid ? 6 : 10}
                  lg={indvid ? 10 : 13}
                  xl={indvid ? 12 : 14}
                  xxl={14}
                >
                  <Row align="middle">
                    <Col xs={indvid ? 4 : 4} sm={5} md={0}>
                      <Logo collapsed={true} />
                    </Col>

                    <Col align="right" xs={indvid ? 20 : 20} sm={19} md={0}>
                      {/* location icon on mobile view */}
                      <i
                        onClick={() => {
                          setOpenBottomSheet(true);
                        }}
                        className="fa fa-map-marker"
                        aria-hidden="true"
                        style={{
                          fontSize: "1.5rem",
                          color: "#257CDB",
                          background: "#fff",
                          borderRadius: "100%",
                          padding: "1rem",
                        }}
                      ></i>
                    </Col>
                  </Row>
                </Col>
                <Col
                  xs={0}
                  sm={0}
                  md={entityType !== ROLE_FYNDR && indvid ? 11 : 22}
                  lg={entityType !== ROLE_FYNDR && indvid ? 10 : 22}
                  xl={entityType !== ROLE_FYNDR && indvid ? 10 : 7}
                  xxl={entityType !== ROLE_FYNDR && indvid ? 8 : 7}
                  offset={entityType !== ROLE_FYNDR && indvid ? 1 : 2}
                  className="bizHome_bg-topMenu2"
                  sty
                >
                  <TopMenu {...props} />
                </Col>
                <Col
                  xs={indvid ? 4 : 4}
                  sm={indvid ? 8 : 18}
                  md={0}
                  lg={0}
                  xl={0}
                  xxl={0}
                  offset={indvid ? 1 : 1}
                >
                  <TopMenu menuUp={false} {...props} />
                </Col>
              </Row>
            )}
          </div>
        </div>
      </section>
      <section className="offer_cards--main-card">
        <div
          ref={listInnerRef} 
        >
          <section
            style={{ textAlign: "center", minWidth: "100%" }}
            className={isfilterOn ? `filter__color` : ""}
          >
            <div>
           
              <Row
                justify="center"
                className="offer--main-page"
                gutter={[24, 24]}
              >
                
                <Col
                  align="center"
                  style={{ paddingRight: "0 !important" }}
                  lg={18}
                  xl={18}
                  xxl={18}
                  xs={!isfilterOn ? 24 : 0}
                  sm={!isfilterOn ? 24 : 0}
                  md={18}
                  
                >
                  <div
                    ref={innerScrollRef}
                    className="inner-scroll-offer-card"
                    
                  >
                    {campns.length > 0 ? (
                      <>
                        <Row gutter={[16, 16]}>
                          {campns
                            .filter((item) => item.cmpnType !== "brochure")
                            .map((item, key) => {
                              item.isLogin = indv?.indvid ? true : false;
                              if (
                                (subTypeDeals.includes("all") ||
                                  subTypeDeals.includes(item.cmpnType)) &&
                                (item?.offers?.length > 0 ||
                                  item.cmpnType === "brochure" ||
                                  item.cmpnType === "events")
                              ) {
                                if (isTab()) {
                                  
                                  return (
                                    <>
                                      <Col span={24} key={key} className="offer-card-web">
                                        <OfferCard
                                          history={props.history}
                                          typeOfDeals={typeOfDeals}
                                          item={item}
                                          key={key}
                                          itemKey={key}
                                          callBack={(e) => {
                                            if (e) {
                                              searchCampaignFunctionNew(
                                                null,
                                                locQRId
                                              );
                                            }
                                          }}
                                        />
                                      </Col>
                                    </>
                                  );
                                } else {
                                  return (
                                    <>
                                      <Col span={12} key={key}>
                                        <OfferCard
                                          history={props.history}
                                          typeOfDeals={typeOfDeals}
                                          item={item}
                                          key={key}
                                          itemKey={key}
                                          callBack={(e) => {
                                            if (e) {
                                              searchCampaignFunctionNew(
                                                null,
                                                locQRId
                                              );
                                            }
                                          }}
                                        />
                                      </Col>
                                    </>
                                  );
                                }
                              } else {
                                return null;
                              }
                            })}
                        </Row>
                        
                        {isLoading && (
                          <Col
                            span={24}
                            style={{ textAlign: "center", padding: "20px 0" }}
                          >
                            <Loader />
                          </Col>
                        )}
                        {isLast && <span>End of offers</span>}
                      </>
                    ) : (
                      <span>No saved offers found..</span>
                    )}
                  </div>
                </Col>
              </Row>
            </div>
          </section>
        </div>
      </section>

      <Modal
        visible={showFilter}
        footer={null}
        onCancel={() => {
          setShowFilter(false);
        }}
        closeIcon={closeIcon}
        style={{
          marginTop: "130px",
          zIndex: "100",
        }}
        mask={false}
        centered
      >
        <div>
          <Card className="filterCard">
            <h3>Type of deals</h3>
            {subTypeDeals.map((value, index) => (
              <Space direction="horizontal">
                <div className="filterCardBox">
                  <Text key={index} className="filterCardBoxText">
                    {value.charAt(0).toUpperCase() +
                      value.slice(1).toLowerCase()}
                  </Text>

                  <img
                    src={filterCan}
                    alt="cancel"
                    onClick={() => onCancelFilter(value, index)}
                  />
                </div>
              </Space>
            ))}
            <Checkbox.Group
              style={{ width: "100%" }}
              value={subTypeDeals}
              onChange={onChangeType}
              className="offer--categories offer_cards--offer-filter offer--checkbox"
            >
              <Row>
                <Checkbox value="all"> All</Checkbox>
              </Row>
              <Row className="offer--deals-option">
                <Checkbox value="coupons"> Coupon</Checkbox>
              </Row>
              <Row className="offer--deals-option">
                <Checkbox value="offers"> Offers</Checkbox>
              </Row>
              <Row className="offer--deals-option">
                <Checkbox value="events"> Events</Checkbox>
              </Row>
            </Checkbox.Group>
          </Card>

          <Card className="filterCard">
            <h3 className="offer--deals-category">Category</h3>
            {categories.map((value, index) => (
              <Space direction="horizontal" key={index}>
                <div className="filterCardBox">
                  <Text className="filterCardBoxText">
                    {
                      categoryListArr.find((item) => item.value === value)
                        ?.label
                    }
                  </Text>
                  <img
                    src={filterCan}
                    alt="cancel"
                    onClick={() => onCancelCategory(value)}
                  />
                </div>
              </Space>
            ))}

            <Checkbox.Group
              className="offer--categories offer_cards--offer-filter offer--checkbox"
              value={categories}
              onChange={onChangeCategories}
            >
              {categoryListArr.map((item, key) => (
                <Row className="offer--category-options" key={key}>
                  <Checkbox value={item.value}> {item.label}</Checkbox>
                </Row>
              ))}
            </Checkbox.Group>
          </Card>
        </div>
      </Modal>
      <Modal
        visible={showModal}
        onCancel={() => {
          setShowModal(false);
        }}
        closeIcon={closeIcon}
        footer={null}
        maskClosable={false}
        style={modalStyle}
        width={"90%"}
      >
        <div className="modal_map_view">
          <GoogleMap
            zoom={miles > 50 ? 8 : 11}
            defaultCenter={{
              lat: lat,
              lng: lng,
            }}
          >
         
            {Array.isArray(campns) &&
              campns
                .filter((item) => item.cmpnType !== "brochure")
                .map((item, key) => {
                  return (
                    <MarkerGoogle
                      history={props.history}
                      myKey={key}
                      item={item}
                    />
                  );
                })}
          </GoogleMap>
        </div>
      </Modal>
     
      <Footer/>
    </Layout>
  );
};
export default MyOffers;

