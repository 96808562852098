/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Divider, Table, Col, Row, Button, List } from 'antd'
import UserActions from "../../actions";
import moment from 'moment';
import Loader from '../Loading/Loader';
import CustomizedModal from '../CustomizedModal';
export default function FeaturedHistory(props) {
    const { cmpn_id } = props;
    const [spinnerVisible, setSpinnerVisible] = useState(false);
    const [dataSource, setDataSource] = useState([]);
    const [columns, setColumns] = useState([]);
    const [totalDuration, setTotalDuration] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalLocations, setModalLocations] = useState([]);

    useEffect(() => {
        promotionHistory();
    }, [])

    const showModal = (locations) => {
        setModalLocations(locations);
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setModalLocations([]);
    };

    const capitalizeWords = (str) => {
        return str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
    };
    const formatChannels = (channels) => {
        return channels
            .split(',')
            .filter(channel => channel !== 'in_app')
            .map(channel => channel === 'mobile_push' ? 'Phone' : channel)
            .map(capitalizeWords)
            .join(', ');
    };
    const dynamicColumns = [
        { title: 'Channels', dataIndex: 'Channels', key: 'Channels' },
        {
            title: 'Location', dataIndex: 'Location', key: 'Location', width: 200, render: (text, record) => (
                record.Locations.length > 1 ? (
                    <Button type="link" onClick={() => showModal(record.Locations)}>
                        {text}
                    </Button>
                ) : text
            ),
        },
        { title: 'Start Date', dataIndex: 'startDt', key: 'startDt', width: 120 },
        { title: 'End Date', dataIndex: 'endDt', key: 'endDt', width: 120 },
        { title: 'Duration', dataIndex: 'duration', key: 'duration' },
        { title: 'Promotion Date', dataIndex: 'PromoDate', key: 'PromoDate', width: 200 },
        {
            title: 'Radius', dataIndex: 'radius', key: 'radius', width: 150, render: (text, record) => (
                record.Locations.length > 1 ? (
                    <Button type="link" onClick={() => showModal(record.Locations)}>
                        {text}
                    </Button>
                ) : text
            ),
        },
        {
            title: 'Recipient', dataIndex: 'recipient', key: 'recipient', render: (text, record) => (
                record.Locations.length > 1 ? (
                    <Button type="link" onClick={() => showModal(record.Locations)}>
                        {text}
                    </Button>
                ) : text
            ),
        },
        { title: 'Amount', dataIndex: 'amount', key: 'amount', width: 120 },
    ]

    const promotionHistory = async () => {
        setSpinnerVisible(true);
        const { resp } = await UserActions.fetchPromotions(cmpn_id);

        if (resp && resp.promotions !== null) {
            setTotalDuration(resp.totalDuration);
            setTotalAmount(resp.totalAmount);


            const formattedData = resp.promotions.map((promotion, index) => {
                const locations = Object.values(promotion.searchCriteria.locList);
                const locationSummary = locations.length > 1 ? `${locations.length} Locations` : locations[0]?.addressName?.split(',').filter(part => part.trim() && part.trim() !== 'undefined').join(',');
                const radiusSummary = locations.length > 1 ? `${locations.length} Radius` : `${locations[0]?.radius} Mile`;
                const recipientSummary = locations.length > 1 ? `${locations.length} Recipients` : locations[0]?.total;
                return {
                    key: index.toString(),   
                    Channels: formatChannels(promotion.channel),
                    Location: locationSummary,
                    startDt: promotion.cmpnFeaturedDetails?.startDt ? moment(promotion.cmpnFeaturedDetails.startDt).format("MMM DD, YYYY") : 'N/A',
                    endDt: promotion.cmpnFeaturedDetails?.endDt ? moment(promotion.cmpnFeaturedDetails.endDt).format("MMM DD, YYYY") : 'N/A',
                    duration: promotion.cmpnFeaturedDetails?.duration ? `${promotion.cmpnFeaturedDetails.duration} ${promotion.cmpnFeaturedDetails.duration > 1 ? 'Months' : 'Month'}` : 'N/A',
                    PromoDate: moment(promotion.createDt).format("MMM DD, YYYY"),
                    amount: promotion?.invoice ? `${promotion?.invoice?.currencySymbol || ""}${((promotion?.invoice?.baseAmount || 0) + (promotion?.invoice?.taxAmount || 0) - (promotion?.invoice?.fyndrCash || 0)).toFixed(2)}` : "-",
                    recipient: recipientSummary,
                    radius: radiusSummary,
                    Locations: locations,
                };
            });
            setDataSource(formattedData);
            setSpinnerVisible(false);
            setColumns(dynamicColumns);
        } else {
            setSpinnerVisible(false);
            setDataSource([]);
        }
    };

    return (
        <>
            {spinnerVisible ?
                <Row style={{ height: "20rem", alignContent: "center" }} justify={"center"}>
                    <Loader />
                </Row>
                :
                <>
                    <Table
                        className='featuredSubs_table'
                        dataSource={dataSource} columns={columns}
                        pagination={false}
                    />
                    {(totalAmount > 0 || totalDuration > 0)&&
                        <>
                            <Divider className='featuredSubs_divider' />
                            {totalDuration !== 0 && 
                            <Row justify={"space-between"} style={{ marginBottom: "8px", padding: "0px 10px" }} className='featuredSubs_row'>
                                <Col >
                                    Total Duration
                                </Col>
                                <Col>
                                    {totalDuration} {totalDuration > 1 ? 'Months' : 'Month'}
                                </Col>
                            </Row>
                            }
                            <Row justify={"space-between"} style={{ padding: "0px 10px" }} className='featuredSubs_row'>
                                <Col >
                                    Total Money Spent
                                </Col>
                                <Col>
                                {totalAmount <= 0 ? '-' : `$${totalAmount.toFixed(2)}`}
                                </Col>
                            </Row>
                            <Divider className='featuredSubs_divider' />
                        </>
                    }
                    <CustomizedModal
                        title="All Locations"
                        visible={isModalVisible}
                        onCancel={handleCancel}
                        footer={null}
                        modalWidth="800px"
                        className="location-modal"
                        content={
                            <>
                                <Row style={{ width: '100%', fontWeight: '500', marginBottom: '10px', color: '#257CDB' }}>
                                    <Col span={12}>Locations</Col>
                                    <Col span={2}></Col>
                                    <Col span={4} style={{ textAlign: "right" }}>Radius</Col>
                                    <Col span={2}></Col>
                                    <Col span={4} style={{ textAlign: "right" }}>Recipients</Col>
                                </Row>
                                <List
                                    dataSource={modalLocations}
                                    renderItem={item => (
                                        <List.Item>
                                            <Row style={{ width: '100%', color: '#333333', fontWeight: '400' }}>
                                                <Col span={12}>{item.addressName}</Col>
                                                <Col span={2}></Col>
                                                <Col span={4} style={{ textAlign: "right" }}>{item.radius} Miles</Col>
                                                <Col span={2}></Col>
                                                <Col span={4} style={{ textAlign: "right" }}>{item.total}</Col>
                                            </Row>
                                        </List.Item>
                                    )}
                                />
                            </>
                        }
                    />
                </>

            }

        </>
    )
}