/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Row, Col, Button, Divider, Space } from "antd";
import calendarOutlineIcon from "../../assets/images/calendarOutlineIcon.png";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import { motion } from "framer-motion";
import moment from "moment";
import actions from "../../actions";
import BusinessAppointment from "../BusinessAppointment/BusinessAppointment";
import BookedSlotList from "../BookedSlotlist/BookedSlotList";
import { useSelector } from "react-redux";

function DateCalendar(props) {
  const [showCalendar, setShowCalendar] = useState(false);
  const [showMsg, setShowMsg] = useState(false);
  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedData] = useState([]);
  const [selectedDate, setSelectedDate] = useState([new Date()]);
  const [, setSelectedDay] = useState("");
  const [, setFormatteddate] = useState();
  const [selectedValue, setSelectedValue] = useState("");
  const [locationDropdownData, setLocationDropdownData] = useState([]);

  const { indv } = useSelector(({ auth }) => {
    return auth;
  });

  useEffect(() => {
    locationDataApi();
  }, []);

  const locationDataApi = async () => {
    let res = await actions.fetchCmpnLocation(indv.bizid);

    const resultArray = res.filter((item) => item.isCatalogueAppointmentEnabled || item.isCampaignAppoinmtnetEnabled).map(obj => {
    
      return {
        ...obj,
        value: obj?.objid,
        label: obj?.locName,
      };
    });

    setSelectedValue(resultArray[0].objid);
    setLocationDropdownData(resultArray);
  };
  const handleDropdownChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const formatTodayTomorrow = (date, daysToAdd) => {
    date.setDate(date.getDate() + daysToAdd);
    return `${date.toLocaleDateString("en-US", {
      day: "numeric",
      month: "short",
      year: "numeric",
    })} (${daysToAdd === 0 ? "Today" : "Tomorrow"})`;
  };

  const todayDateString = formatTodayTomorrow(new Date(), 0);
  const [dateSelected, setDateSelected] = useState(todayDateString);

  const [slotData, setSlotData] = useState([]);
  const handleIconClick = () => {
    setShowCalendar(!showCalendar);
  };
  useEffect(() => {
    setTimeout(() => {
      setShowMsg(false);
    }, [8000]);
  }, [showMsg]);

  const appointmentList = async (formatDate, selectedDay) => {
    const payload = {
      selectedDate: formatDate,
      weekday: selectedDay,
      text: props.searchText || null,
      businessId: indv?.bizid,
      locationId: selectedValue
    };

    let resp = await actions.fetchBusinessAppointmentList(payload);
    if (resp?.success) {
      setSlotData(resp.resp);
    }else{
      setSlotData([])
    }
  };

  useEffect(() => {
    if (selectedValue !== "") {

      const formatDate = moment(dateSelected).format().split("T")[0];
      const selectedDay = moment(dateSelected).format("dddd").toUpperCase();
      appointmentList(formatDate, selectedDay);
    }
  }, [props.searchText, dateSelected, selectedValue]);

  const handleDateChange = async (date) => {
    const formatDate = moment(date).format().split("T")[0];
    const selectedDay = moment(date).format("dddd").toUpperCase();

    setDateSelected(date);
    setSelectedDay(selectedDay);
    setFormatteddate(formatDate);
    setSlotData([]);

    if (selectedData.length === props.qty) {
      // message.error("Please go and edit your date on the checkout page");
    }
  };

  const getWeekDates = () => {
    const currentDate = selectedDate ? new Date(selectedDate) : new Date();
    const weekDaysWithDate = [];
    for (let i = 0; i < 7; i++) {
      const nextDate = new Date(currentDate);
      nextDate.setDate(currentDate.getDate() + i);
      weekDaysWithDate.push(new Date(nextDate));
    }
    return weekDaysWithDate;
  };

  const formatWeekday = (date) => {
    return `${date.toLocaleDateString("en-US", {
      day: "numeric",
      month: "short",
      year: "numeric",
    })} (${date.toLocaleDateString("en-US", { weekday: "short" })})`;
  };

  useEffect(() => {
    setSelectedTime(selectedTime);
    setSelectedDate(selectedDate);
    setDateSelected(dateSelected);
  }, [selectedTime, selectedDate, dateSelected]);

  const dateData = [
    { date: todayDateString },
    { date: formatTodayTomorrow(new Date(), 1) },
    { date: formatWeekday(getWeekDates()[2]) },
    { date: formatWeekday(getWeekDates()[3]) },
    { date: formatWeekday(getWeekDates()[4]) },
    { date: formatWeekday(getWeekDates()[5]) },
    { date: formatWeekday(getWeekDates()[6]) },
  ];

  return (
    <div>
      <Row>
        {showCalendar ? (
          <div
            style={{ display: "flex", width: "100%", flexDirection: "column" }}
          >
            <BusinessAppointment locId={selectedValue} buttonClick={handleIconClick} onDateClick={(day, locId) => { setShowCalendar(false); setDateSelected(day); setSelectedValue(locId);}} />
          </div>
        ) : (
          <Row style={{ width: "100%" }}>
            <Col span={24}>
              <Row businessAppointment-Tour="dateSelection">
                <Col
                  span={3}
                  align={"start"}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Button
                    size="large"
                    onClick={handleIconClick}
                    style={{ border: "none", margin: "12px 0px 0px 0px" }}
                    businessAppointment-Tour="calendarIcon"
                    icon={
                      <img
                        src={calendarOutlineIcon}
                        style={{ height: "24px", width: "24px" }}
                        alt="calendarOutlineIcon"
                      />
                    }
                  />
                </Col>
                <Col
                  span={1}
                  style={{
                    borderRight: "1px solid #d3d6e1",
                    boxShadow: "1px 0px 2px #d3d6e1 inset",
                    border: "none",
                  }}
                ></Col>
                <Col span={20}>
                  <ScrollMenu scrollContainerClassName="overFlowHidden">
                    <motion.div
                      transition={{ duration: 0.3 }}
                      style={{ margin: ".4rem 0rem ", padding: "12px 0px" }}
                    >
                      <Space size={"small"}>
                        <Col xl={1}></Col>
                        {dateData.map((item, index) => {
                          const dateWithoutYear = `${item.date.slice(
                            0,
                            item.date.lastIndexOf(",")
                          )} ${item.date.slice(item.date.lastIndexOf("("))}`;
                          const isToday = moment(dateSelected).isSame(moment(), 'day');
                          const isTomorrow = moment(dateSelected).isSame(moment().add(1, 'day'), 'day');
                          let label = dateSelected;
                          if (isToday) {
                            label = `${moment(item.date).format('ll')} (Today)`;
                          } else if (isTomorrow) {
                            label = `${moment(item.date).format('ll')} (Tomorrow)`;
                          } else {
                            label = `${moment(dateSelected).format('ll')} (${moment(dateSelected).format('ddd')})`
                          }
                          return (
                            <Col xl={5}>
                              <Button
                                onClick={() => handleDateChange(item.date)}
                                className="appointmentBusiness_dateSlot"
                                style={{
                                  display: "inline-flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  borderRadius: "5px",
                                  color:
                                    label === item.date
                                      ? "#fff"
                                      : "#7F7F7F",
                                  backgroundColor:
                                    label === item.date ? "#223369" : "",
                                }}
                              >
                                {dateWithoutYear}
                              </Button>
                            </Col>
                          );
                        })}
                      </Space>
                    </motion.div>
                  </ScrollMenu>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </Row>
      {showCalendar ? (
        ""
      ) : (
        <>
          <Divider
            className="customize_divider"
            style={{ margin: "0px 0px 24px 0px" }}
          />
         { slotData.timeZone && <Col style={{ fontWeight: "bold", margin: "0px 0px 24px 24px" }}>
            {`${slotData.isLocationTimezonePresent ? "Business location" : "Business"} Timezone: ${slotData.timeZone}`}
          </Col>}
          <div className="appointmentBusiness_bookingList">
            <Row>
              <Col  sm={24}
                md={8}
                lg={12}
                xl={12}
                xxl={12}
                className="appointmentBusiness_locDropdown">
                <div className="appointmentBusiness_cal">
                  <select
                  businessAppointment-Tour="locationDropdown"
                    id="dropdown"
                    value={selectedValue}
                    onChange={handleDropdownChange}
                    className="appointmentBusiness_dropDown"
                  >
                    {locationDropdownData.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
              </Col>
              <Col sm={24} md={16} lg={12} xl={12} xxl={12} className="appointmentBusiness_totalCount">
               
                  <p  className="appointmentBusiness_appointmentCount" businessAppointment-Tour="totalcount_capacity">Total bookings for the day : {slotData?.bookedAppointmentsForDay ? slotData?.bookedAppointmentsForDay : 0}/{slotData?.totalAppointmentsForDay ? slotData?.totalAppointmentsForDay : 0}</p>
               
              </Col>
              <Divider
                className="customize_divider"
                style={{ margin: "0px 0px 12px 0px" }}
              />
            </Row>
            <BookedSlotList
              bookingDetails={slotData.list}
            />

            <Row className="slotInstruction" businessAppointment-Tour="Indicators">
              <Col
                style={{
                  paddingTop: "3px",
                  paddingRight: "10px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    borderRadius: "100%",
                    background: "#ED0C10",
                    width: "16px",
                    height: "16px",
                    verticalAlign: "middle",
                    display: "flex",
                  }}
                ></div>
              </Col>
              <Col style={{ marginRight: "10px" }}>Slot is full</Col>
              <Col style={{ paddingTop: "3px", paddingRight: "10px" }}>
                <div
                  style={{
                    borderRadius: "100%",
                    background: "#FFC700",
                    width: "16px",
                    height: "16px",
                  }}
                ></div>
              </Col>
              <Col style={{ marginRight: "10px" }}>
                <div>Slot is almost full</div>
              </Col>
              <Col style={{ paddingTop: "3px", paddingRight: "10px" }}>
                <div
                  style={{
                    borderRadius: "100%",
                    background: "#50B85A",
                    width: "16px",
                    height: "16px",
                  }}
                ></div>
              </Col>
              <Col>
                <div>More than half of the bookings available</div>
              </Col>
            </Row>
          </div>
        </>
      )}
    </div>
  );
}

export default DateCalendar;
