import React from "react";
import { Image, Row, Col, Space } from "antd";
import slotTimeIcon from "../../assets/images/slotWatchIcon.png";
import noDataIcon from "../../assets/images/noDataIcon.png";

function BookedSlotList(props) {
  const { bookingDetails } = props;

  if (!bookingDetails || bookingDetails.length === 0) {
    return (
      <div className="appointmentBusiness_noSlotAvilable">
        <div>
          <Image src={noDataIcon} preview={false} alt="noDataIcon" />
          <div className="appointmentBusiness_noSlotMsg">No Bookings</div>
        </div>
      </div>
    );
  }

  const percTotalBooking = (bookedAppointmentsPerSlot, totalAppointmentsPerSlot) => {
    return (bookedAppointmentsPerSlot / totalAppointmentsPerSlot) * 100;
  };

  return (
    <div>
      {bookingDetails.map((timeSlot, index) => {

        const bookingPercentage = percTotalBooking(
          timeSlot?.bookedAppointmentsPerSlot || 0,
          timeSlot?.totalAppointmentsPerSlot || 1
        );

        let conatineBackgroundColor = "";
        let infobackground = "";
        let divborder = "";
        let infoBorder = "";

        if (bookingPercentage < 50) {
          conatineBackgroundColor = "#49BE4D";
          infobackground = "#E6FEE0";
          infoBorder = "1px solid #9EDB90";
          divborder = "1px solid #9EDB90";
        } else if (bookingPercentage >= 50 && bookingPercentage < 80) {
          conatineBackgroundColor = "#FFC107";
          infobackground = "#FFF1BF";
          infoBorder = "1px solid  #FFE27F";
          divborder = "1px solid #FFE27F";
        } else {
          conatineBackgroundColor = "#FF4144";
          infobackground = "#fff4f4";
          infoBorder = "1px solid  #ffb8b9";
          divborder = "1px solid #FFB8B9";
        }

        return (
          <div
            className="appointmentBusiness_listConatiner"
            style={{ border: divborder }}
            key={index}
          >
            <div key={index} businessAppointment-Tour="slotetails">
              <div
                className="appointmentBusiness_listHeader"
                style={{ background: conatineBackgroundColor }}
              >
                <Row>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                    <div className="appointmentBusiness_slotTimeCount">
                      <Image
                        alt="slotTimeIcon"
                        src={slotTimeIcon}
                        className="appointmentBusiness_slotTimeIcon"
                      />
                      <span className="appointmentBusiness_slotTiming">
                        Time: {Object?.keys(timeSlot?.appointments)[0]}
                      </span>{" "}
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} className="appointmentBusiness_slotCount">
                    <span className="appointmentBusiness_slotTiming">
                      Appointments: {timeSlot?.bookedAppointmentsPerSlot}/
                      {timeSlot?.totalAppointmentsPerSlot}
                    </span>
                  </Col>
                </Row>
              </div>
              <div style={{ paddingLeft: "10px",paddingRight:"10px" }}>
                {timeSlot.appointments &&
                timeSlot.appointments[Object.keys(timeSlot.appointments)[0]]
                  ?.length > 0 ? (

                  <>
                    {Object.keys(timeSlot.appointments).map((timeRange) => (
                      <>
                       <div className="overFlowHidden" style={{overflowX:"scroll"}}>
                       <Space>
                        {timeSlot.appointments[timeRange]?.map(
                          (appointment, j) => (
                            
                              <Col
                                span={4}
                                style={{
                                  paddingTop: "12px",
                                  marginBottom: "12px",
                                }}
                              >
                                <div
                                  className="appointmentBusiness_bookingInfoCard"
                                  key={j}
                                  style={{
                                    border: infoBorder,
                                    background: infobackground,
                                  }}
                                >
                                  <div className="appointmentBusiness_storeName">
                                    {appointment?.appointmentTitle}
                                  </div>
                                  <div className="appointmentBusiness_userName">
                                    Name: {appointment?.userName}
                                  </div>
                                  <div className="appointmentBusiness_userPhoneNumber">
                                    Phone: {appointment?.phoneNumber || "N/A"}
                                  </div>
                                </div>
                              </Col>
                          )
                          )}
                          </Space>
                        </div>
                      </>
                    ))}
                    </>
                ) : (
                  <div className="appointmentBusiness_noSlotAvilable">
                    <div>
                      <Image alt="noDataIcon" src={noDataIcon} preview={false} />{" "}
                      <div className="appointmentBusiness_noSlotMsg">
                        No Bookings
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default BookedSlotList;
