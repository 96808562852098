import { Tabs } from "antd";
import React, {useEffect, useState} from "react";
import ForFyndr from "../ForFyndr/ForFyndr";
import ForFyndrIndividual from "../ForFyndrIndividual/ForFyndrIndivual";
import StickyBox from "react-sticky-box";
import actions from "../../actions";
import Download from "../Download/Download";
import Faq from "../Faq/Faq";
const { TabPane } = Tabs;

function AboutUsTabs() {
  const [entity, setEntity] = useState([]);
  const [activeKey, setActiveKey] = useState("2");

  useEffect(() => {
    const hash = window.location.hash;
    if (hash === '#faq') {
      const faqElement = document.getElementById('faq');
      if (faqElement) {
        faqElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);

  useEffect(() => {
    entitiesList();
  },[]);

  const entitiesList = async () => {
    let res = await actions.fetchEntityList();
    setEntity(res?.data);
  }

  const handleTabChange = (key) => {
    setActiveKey(key);
  };

  const renderTabBar = (props, DefaultTabBar) => (
    <StickyBox
      offsetTop={0}
      style={{
        zIndex: 1,
        background: "#fff",
      }}
    >
      <DefaultTabBar {...props} />
    </StickyBox>
  );

  return (
    <>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Tabs
          centered={true}
          className="bankCarousel_tabPane"
          style={{ background: "#fff" }}
          renderTabBar={renderTabBar}
          onChange={handleTabChange}
          activeKey={activeKey}
        >
          <TabPane tab="As a business owner" key="2">
            <StickyBox
              offsetTop={20}
              style={{
                zIndex: 0,
                background: "#fff",
              }}
            >
              <ForFyndr />
              <Download />
              <div id="faq">
              <Faq activeKey={activeKey} entity={entity} />
              </div>
              
            </StickyBox>
          </TabPane>
          <TabPane tab="As an individual" key="1">
            <StickyBox
              offsetTop={20}
              style={{
                zIndex: 0,
                background: "#fff",
              }}
            >
              <ForFyndrIndividual />
              <Download />
              <Faq activeKey={activeKey} entity={entity}/>
            </StickyBox>
          </TabPane>
        </Tabs>
      </div>
    </>
  );
}

export default AboutUsTabs;
