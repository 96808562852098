/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Popover, Badge, List, Card } from "antd";
import actions from "../../../actions";
import { Actions } from "../../../common";
import { useHistory } from "react-router-dom";
import { BellOutlined } from "@ant-design/icons";
import { timeAgo } from "../../../common/util";

const Notification = (props) => {
  const { pendingNotifications } = useSelector(({ core }) => core);

  useEffect(() => {
    doRefresh();
  }, []);

  const doRefresh = async () => {
    setLoader(true);
    const resp = await actions.processNotifications({
      qrId: qrid + "",
      action: "fetch",
    });
    setLoader(false);

    if (resp?.success) {
      setNotifications(resp?.data);
    }
  };
  const { CHECKIN_STARTED} = Actions;
  const [loader, setLoader] = useState(false);
  const [notifications, setNotifications] = useState(false);
  const [visible, setVisible] = useState(false);
  const { qrid } = useSelector(({ auth }) => auth.indv);
  const dispatch = useDispatch();
  let history = useHistory();
  const push = async (id, action_reference, type, category) => {
    if (type === "information") {
      setLoader(true);
      const resp = await actions.processNotifications({
        qrId: qrid,
        action: "notified",
        notificationId: id,
      });
      setLoader(false);
      if (resp.success) {
        setNotifications(resp.data);
      }
    } else if (type === "action-acknowledge" && action_reference) {
      let { interaction } = action_reference;
      interaction["notificationId"] = id;
      await dispatch({ type: CHECKIN_STARTED, payload: interaction });
    }
    if (category === "payment-notification") {
      history.push("/billing/transactions");
    } else if (category === "campaign") {
      const { cmpn_id } = action_reference;
      history.push(`/offer-details/${cmpn_id}`, { cmpn_id, isSolo: true });
    }
    setVisible(false);
  };

  const customLocale = {
    emptyText: "You are all caught up"
  };
  const NotificationTab = (props) => (
    <List
      style={{ maxHeight: "80vh", overflowY: "auto" }}
      itemLayout="horizontal"
      locale={customLocale}
      loading={loader}
      dataSource={notifications}
      renderItem={(item) => {
        const {
          message,
          type,
          created_dt,
          status,
          objid,
          action_reference,
          category,
        } = item;

        return (
          <List.Item

            onClick={() => push(objid, action_reference, type, category)}
          >
            <div className="list-style-v1">
              <div className="list-item">
                <div
                  className={`icon-btn icon-btn-round mr-3 ${
                    status === "pending" ? "bg-warning" : "bg-info"
                  } text-body-reverse`}

                  style={status === "pending" ? {backgroundColor: "#ffc107", marginRight: "0.75rem"} : {backgroundColor: "#17A2B8", marginRight: "0.75rem"}}
                >
                  <BellOutlined style={{ fontSize: "1.5rem" }} />
                </div>
                <div className="list-item__body">
                  <div className="list-item__title">
                    {type.charAt(0).toUpperCase() + type.slice(1)}
                  </div>
                  <div
                    style={{ fontSize: "1rem" ,}}
                    className="list-item__datetime"
                  >
                    {message}
                  </div>

                  <div className="list-item__datetime">
                    {timeAgo(created_dt)}
                  </div>
                </div>
              </div>
            </div>
          </List.Item>
        );
      }}
    />
  );
  const PopoverTabs = (props) => (
    <Card title="Notifications">
      <NotificationTab {...props} />
    </Card>
  );
  const Notifications = (props) => (
    <div
      onDoubleClick={() => {
        setVisible(false);
      }}
      style={{ width: "50vw" }}
      className="app-header-notifications"
    >
      <PopoverTabs {...props} />
    </div>
  );
  const handleNotificationModalClosing= (visible) => {
    setVisible(visible)
  }
  return (
    <Popover
      placement="bottomRight"
      content={<Notifications />}
      trigger="click"
      overlayClassName="app-header-popover"
      style={{ zIndex: 999 }}
      visible={visible}
      onVisibleChange={handleNotificationModalClosing}
    >
      <a className="list-inline-item" style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
        <Badge style={{ zIndex: 990 }} count={pendingNotifications}>
          <BellOutlined
            onClick={() => {
              setVisible(true);
            }}
            style={{ fontSize: "2rem", color: "#fff" }}
          />
        </Badge>
      </a>
    </Popover>
  );
};

export default Notification;
