import {
  fetch_retry_get,
  fetch_retry_post,
  fetch_retry_put,
  fetch_retry_delete,
} from "../network/ApiManager";

import { Actions } from "../common";
import getEnvVars from "../environment";
import { debug } from "./index";
const { base, cmpn } = getEnvVars();
const {
  FETCH_CATEGORIES,
  UPDATE_CATEGORIES,
  UPDATE_ITEM,
  UPDATE_MODIFIER,
  UPDATE_CATALOGUE,
  UPDATE_CATALOGUE_ITEM,
  FETCH_ITEMS,
  FETCH_MODIFIER,
  FETCH_CATALOGUE,
  UPDATE_CATALOGUE_MODIFIER,
  UPDATE_CATALOGUE_LOCATION,
  CATEGORIES_FOUND,
  UPDATE_BIZ_DETAILS
} = Actions;
export const fetchCategories = ({ bizid, pgStart, pgSize }) => async (
  dispatch
) => {
  const endPoint = `${base}/catalogue/categories/fetch/${bizid}?pgStart=${
    pgStart || 0
  }&pgSize=${pgSize || 1000}`;
  debug && console.log("endpoint", endPoint);
  try {
    const response = await fetch_retry_get(endPoint);
    if (response.ok) {
      let resp = await response.json();
      if (resp.categories != null) {
        dispatch({
          type: FETCH_CATEGORIES,
          payload: resp.categories,
        });
      }
    }else{
      dispatch({
        type: FETCH_CATEGORIES,
        payload: [],
      });
    }
  } catch (error) {
    console.log(error);
  }
};
export const fetchCatalogueCategories = ({ bizid, catalogueId }) => async (
  dispatch
) => {
  let endPoint = `${base}/catalogue/categories/fetch/${bizid}/${catalogueId}`;
  try {
    const response = await fetch_retry_get(endPoint);
    let resp = await response.json();
    if (response.ok) {
      return { success: true, payload: resp };
    } else {
      return { success: false, message: resp.message };
    }
  } catch (error) {
    console.log(error);
  }
};
export const fetchItems = ({ bizid, pgStart, pgSize }) => async (dispatch) => {
  const endPoint = `${base}/catalogue/items/fetch/${bizid}?pgStart=${
    pgStart || 0
  }&pgSize=${pgSize || 1000}`;
  debug && console.log("endpoint", endPoint);
  try {
    const response = await fetch_retry_get(endPoint);
    if (response.ok) {
      let resp = await response.json();

      dispatch({
        type: FETCH_ITEMS,
        payload: resp.items,
      });
    }
  } catch (error) {
    console.log(error);
  }
};
export const fetchCatalogue = ({ bizid, pgStart, pgSize }) => async (
  dispatch
) => {
  const endPoint = `${base}/catalogue/catalogues/fetch/${bizid}?pgStart=${
    pgStart || 0
  }&pgSize=${pgSize || 1000}`;
  debug && console.log("endpoint", endPoint);
  try {
    const response = await fetch_retry_get(endPoint);
    if (response?.ok) {
      let resp = await response?.json();
      if (resp?.catalogues != null) {
        dispatch({
          type: FETCH_CATALOGUE,
          payload: resp?.catalogues,
        });
        return { success: true, payload:resp?.catalogues };
    } else {
      return { success: false, message: resp.message };
    }
    }
  } catch (error) {
    console.log(error);
  }
};
export const fetchCatalogueDetails = ({
  bizid,
  catalogueId,
  categoryId,
}) => async (dispatch) => {
  const endPoint = `${base}/catalogue/catalogue/fetch/${bizid}/${categoryId}/${catalogueId}`;
  debug && console.log("endpoint", endPoint);
  try {
    const response = await fetch_retry_get(endPoint);
    let resp = await response.json();
    if (response?.ok) {
      return { success: true, payload: resp };
    } else {
      return { success: false, message: resp?.message };
    }
  } catch (error) {
    return { success: false, message: "Error fetching catalogues" };
  }
};
export const fetchModifier = ({ bizid, pgStart, pgSize }) => async (
  dispatch
) => {
  const endPoint = `${base}/catalogue/modifiers/fetch/${bizid}?pgStart=${
    pgStart || 0
  }&pgSize=${pgSize || 1000}`;
  debug && console.log("endpoint", endPoint);
  try {
    const response = await fetch_retry_get(endPoint);
    if (response.ok) {
      let resp = await response.json();

      dispatch({
        type: FETCH_MODIFIER,
        payload: resp.modifiers,
      });
    }
  } catch (error) {
    console.log(error);
  }
};
export const addCategory = (data) => async (dispatch) => {
  const endpoint = data[0].objid
    ? `${base}/catalogue/category`
    : `${base}/catalogue/categories`;
  try {
    const response = data[0].objid
      ? await fetch_retry_put(endpoint, data[0])
      : await fetch_retry_post(endpoint, data);
    if (response.ok) {
      const resp = await response.json();
      debug && console.log("resp", resp);
      dispatch({
        type: UPDATE_CATEGORIES,
        payload: Array.isArray(resp) ? resp[0] : resp,
      });
      return {
        success: true,
        message: `Category ${data[0].objid ? "updated" : "added"} successfully`,
      };
    }
  } catch (error) {
    return { success: false, message: error.message };
  }
};
export const addItem = (data) => async (dispatch) => {
  const endpoint = data[0].objid
    ? `${base}/catalogue/item`
    : `${base}/catalogue/items`;
  try {
    const response = data[0].objid
      ? await fetch_retry_put(endpoint, data[0])
      : await fetch_retry_post(endpoint, data);
    const resp = await response.json();

    if (response.ok) {
      dispatch({
        type: UPDATE_ITEM,
        payload: Array.isArray(resp) ? resp[0] : resp,
      });
      return {
        success: true,
        message: data[0].objid
          ? "Item updated successfully"
          : "Item added successfully",
      };
    } else {
      return { success: false, message: resp.message };
    }
  } catch (error) {
    return { success: false, message: error.message };
  }
};
export const addModifier = (data) => async (dispatch) => {
  const endpoint = data[0].objid
    ? `${base}/catalogue/modifier`
    : `${base}/catalogue/modifiers`;
  try {
    const response = data[0].objid
      ? await fetch_retry_put(endpoint, data[0])
      : await fetch_retry_post(endpoint, data);
    if (response.ok) {
      const resp = await response.json();
      debug && console.log("resp", resp);
      dispatch({
        type: UPDATE_MODIFIER,
        payload: Array.isArray(resp) ? resp[0] : resp,
      });
      return { success: true, message: "Modifier added successfully" };
    } else {
      return { success: false, message: response.message };
    }
  } catch (error) {
    return { success: false, message: error.message };
  }
};
export const addCatalogue = (data) => async (dispatch) => {
  const endpoint = `${base}/catalogue/catalogue`;
  try {
    const response = data.objid
      ? await fetch_retry_put(endpoint, data)
      : await fetch_retry_post(endpoint, data);
    const resp = await response.json();
    if (response.ok) {
      dispatch({
        type: UPDATE_CATALOGUE,
        payload: resp,
      });
      return { success: true, payload: resp };
    } else {
      return { success: false, message: resp.message };
    }
  } catch (error) {
    return { success: false, message: error.message };
  }
};
export const addCatalogueItem = (data) => async (dispatch) => {
  const endpoint = `${base}/catalogue/catalogue_item`;
  try {
    const response = data.objid
      ? await fetch_retry_put(endpoint, data)
      : await fetch_retry_post(endpoint, data);
    const resp = await response.json();
    if (response.ok) {
      dispatch({
        type: UPDATE_CATALOGUE_ITEM,
        payload: resp,
      });
      return {
        success: true,
        payload: resp,
        message: data.objid
          ? "Item updated successfully"
          : "Item added successfully",
      };
    } else {
      return { success: false, message: resp.message };
    }
  } catch (error) {
    return { success: false, message: error.message };
  }
};
export const addCatalogueModifier = (data) => async (dispatch) => {
  const endpoint = `${base}/catalogue/catalogue_modifier`;
  try {
    const response = data.objid
      ? await fetch_retry_put(endpoint, data)
      : await fetch_retry_post(endpoint, data);
    const resp = await response.json();
    if (response.ok) {
      dispatch({
        type: UPDATE_CATALOGUE_MODIFIER,
        payload: resp,
      });
      return {
        success: true,
        payload: resp,
        message: data.objid
          ? "Modifier updated successfully"
          : "Modifier added successfully",
      };
    } else {
      return { success: false, message: resp.message };
    }
  } catch (error) {
    return { success: false, message: error.message };
  }
};
export const addCatalogueLocations = (data, name) => async (dispatch) => {
  const endpoint = `${base}/catalogue/catalogue_locations`;
  try {
    const response = await fetch_retry_post(endpoint, data);
    if (response.ok) {
      const resp = await response.json();
      dispatch({
        type: UPDATE_CATALOGUE_LOCATION,
        payload: { locations: resp.locations, name: name },
      });
      return {
        success: true,
        payload: resp,
        message: "Locations updated successfully",
      };
    } else {
      let resp = await response.json();

      return { success: false, message: resp.message };
    }
  } catch (error) {
    return { success: false, message: error.message };
  }
};
export const deleteCatalogueModifier = (data) => async (dispatch) => {
  const endpoint = `${base}/catalogue/catalogue_modifier`;
  try {
    const response = await fetch_retry_delete(endpoint, data);
    if (response.ok) {
      const resp = await response.json();
      debug && console.log("resp", resp);

      return {
        success: true,
        message: "Modifier deleted successfully",
      };
    } else {
      return { success: false, message: response.message };
    }
  } catch (error) {
    return { success: false, message: error.message };
  }
};
export const deleteCatalogueItem = (data) => async (dispatch) => {
  const endpoint = `${base}/catalogue/catalogue_item`;
  try {
    const response = await fetch_retry_delete(endpoint, data);
    if (response.ok) {
      const resp = await response.json();
      debug && console.log("resp", resp);
      return {
        success: true,
        message: "Item deleted successfully",
      };
    } else {
      return { success: false, message: response.message };
    }
  } catch (error) {
    return { success: false, message: error.message };
  }
};
export const deleteCategory = (data) => async (dispatch) => {
  const endpoint = `${base}/catalogue/category`;
  try {
    const response = await fetch_retry_delete(endpoint, data);
    debug && console.log("this is reposnse", response);
    if (response.ok) {
      const resp = await response.json();
      debug && console.log("resp response", resp);

      return {
        success: true,
        message: "Category deleted successfully",
      };
    } else {
      const resp = await response.json();

      return { success: false, message: resp.message };
    }
  } catch (error) {
    return { success: false, message: error.message };
  }
};
export const deleteModifier = (data) => async (dispatch) => {
  const endpoint = `${base}/catalogue/modifier`;
  try {
    const response = await fetch_retry_delete(endpoint, data);
    if (response.ok) {
      const resp = await response.json();
      debug && console.log("resp", resp);

      return {
        success: true,
        message: "Modifier deleted successfully",
      };
    } else {
      return { success: false, message: response.message };
    }
  } catch (error) {
    return { success: false, message: error.message };
  }
};
export const deleteItem = (data) => async (dispatch) => {
  const endpoint = `${base}/catalogue/item`;
  try {
    const response = await fetch_retry_delete(endpoint, data);
    if (response.ok) {
      const resp = await response.json();
      debug && console.log("resp", resp);

      return {
        success: true,
        message: "Item deleted successfully",
      };
    } else {
      return { success: false, message: response.message };
    }
  } catch (error) {
    return { success: false, message: error.message };
  }
};
export const updateOrders = ({ bizid, payload }) => async (dispatch) => {
  let endpoint = `${base}/catalogue/catalogue_item_order/${bizid}`;
  try {
    const response = await fetch_retry_put(endpoint, payload);
    const resp = await response.json();
    if (response.ok) {
      debug && console.log("resp", resp);

      return {
        success: true,
        message: "List updated successfully ",
      };
    } else {
      return { success: false, message: resp.message };
    }
  } catch (error) {
    return { success: false, message: error.message };
  }
};
// /identity/locationByQrcode?qrcode
export const fetchLocationByQr = ({ qrcode }) => async (dispatch) => {
  const endPoint = `${base}/identity/locationByQrcode?qrcode=${qrcode}`;
  debug && console.log("endpoint", endPoint);
  try {
    const response = await fetch_retry_get(endPoint);
    let resp = await response.json();
    if (response.ok) {
      return { success: true, payload: resp };
    } else {
      return { success: false, message: resp.message };
    }
  } catch (error) {
    return { success: false, message: "error fetching catalogues" };
  }
};
export const getCatalogueByLoc = ({ locId,buyerQrId }) => async (dispatch) => {
  const endpoint = `${base}/catalogue/categories/fetch_by_location/${locId}`;
  debug && console.log(endpoint);
  try {
    const response = await fetch_retry_get(endpoint);
    if (response.ok) {
      const resp = await response.json();
      debug && console.log("response CATEGORIES_FOUND", resp);
      await dispatch({ type: CATEGORIES_FOUND, payload: { info: resp ,buyerQrId} });
      return { success: true, payload: resp };
    }
  } catch (err) {
    return { success: false };
  }
};
export const fetchCatalogueByQRLocation = ({ qrcode, buyerQrId }) => async (
  dispatch
) => {
  const endPoint = `${base}/catalogue/categories/fetch_by_location_qr/${qrcode}`;
  debug && console.log("endpoint", endPoint);
  try {
    const response = await fetch_retry_get(endPoint);
    debug && console.log("response", response);
    let resp = await response.json();
    debug && console.log("resp", resp);
    if (response.ok) {
      await dispatch({
        type: CATEGORIES_FOUND,
        payload: { id: qrcode, info: resp, buyerQrId },
      });
      return { success: true, payload: resp };
    } else {
      return { success: false, message: resp.message };
    }
  } catch (error) {
    return { success: false, message: "error fetching catalogues" };
  }
};
export const searchCatalogueItems = (data) => async (dispatch) => {
  const { bizid, catalogueId, searchTxt } = data;
  const endpoint = `${base}/catalogue/catalogue_item/search/${bizid}/${catalogueId}/${searchTxt}`;
  debug && console.log(endpoint);
  try {
    const response = await fetch_retry_get(endpoint);
    if (response.ok) {
      const resp = await response.json();
      debug && console.log(resp);
      if (resp.catalogueItems.length > 0) {
        const today = new Date();
        today.setHours(23, 59, 59, 0);
        const items = resp.catalogueItems
          .filter(
            (row) =>
              row.status === "active" &&
              new Date(row.availableFrom).getTime() <= today.getTime()
          )
          .sort((a, b) => (a.slno > b.slno ? 1 : -1));
        return { success: true, items };
      }
      return { success: false };
    }
  } catch (err) {
    console.log(`Exception: ${err}`);
  }
  return { success: false };
};
export const fetchLocOffers = (list) => async (dispatch) => {
  const endpoint = `${cmpn}/campaign/public/offers/${list}`;
  debug && console.log("fetchLocOffers", endpoint);
  try {
    const response = await fetch_retry_get(endpoint);
    const resp = await response.json();

    debug && console.log(resp);
    if (response.ok) {
      return { success: true, resp };
    }
    return {
      success: false,
      msg: resp.message ? resp.message : "Error getting offers",
    };
  } catch ({ message }) {
    return { success: false, msg: message };
  }
};

export const likeBiz = (data) => async (dispatch) => {
  const endpoint = `${base}/identity/biz/review`;
  debug && console.log("likeBiz", endpoint, data);
  try {
    const response = await fetch_retry_put(endpoint, data);
    const resp = await response.json();
    if (response.ok) {
      return { success: true };
    }
    return {
      success: false,
      msg: resp.message ? resp.message : "Error occured while saving",
    };
  } catch ({ message }) {
    return { success: false, msg: message };
  }
};
export const UpdateStoreURL= (payload) => async (dispatch) => {
  const endpoint=`${base}/catalogue/store_url`
  try {
    const response = await fetch_retry_put(endpoint, payload);
    const resp = await response.json();
    if (response.ok) {
      return { success: true, msg: resp.message };
    } else {
      return { success: false, msg: "error editing store url" }
    }
  } catch (error) {
    return { success: false, msg: error.message }
  }
}

export const fetchStoreLoc = (store_url) => async (dispatch) => {
  const endpoint = `${base}/catalogue/fetch_location/${store_url}`;
  try {
    const response = await fetch_retry_get(endpoint);
    const resp = await response.json();
      if (response.ok) {
        await dispatch({
          type: UPDATE_BIZ_DETAILS,
          payload: resp,
        });
        return { success: true, data:resp };

        
      }else{
      return {
        success: false,
        msg:"Error fetching store locations",
      }};
    } catch ({ message }) {
      return { success: false, msg: message };
    }
  };

  export const CatalogueActions = {
    fetchCategories,
    fetchCatalogue,
    fetchItems,
    fetchModifier,
    fetchCatalogueDetails,
    fetchCatalogueCategories,
    addCategory,
    addItem,
    addModifier,
    addCatalogue,
    addCatalogueItem,
    addCatalogueModifier,
    addCatalogueLocations,
    deleteCatalogueModifier,
    deleteCatalogueItem,
    deleteCategory,
    deleteModifier,
    deleteItem,
    updateOrders,
    fetchLocationByQr,
    fetchCatalogueByQRLocation,
    getCatalogueByLoc,
    searchCatalogueItems,
    fetchLocOffers,
    likeBiz,
    UpdateStoreURL,
    fetchStoreLoc
  };
