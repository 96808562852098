/* eslint-disable default-case */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import DropdownComponent from '../../../components/Dashboard/DropdownComponent';
import {
  Row,
  Col,
  Input,
  Breadcrumb,
  Divider,
  Button,
  message,
  Space,
  Table,
  DatePicker
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import actions from '../../../actions';
import { MINIMUM_SEARCH_LIMIT } from '../../../common/config';
import moment from 'moment';
import PulseLoader from "react-spinners/PulseLoader";
import RefundModal from '../../../components/Dispute/RefundModal';
import Comments from '../../../components/Dispute/Comments';
import { useSelector } from 'react-redux';
const { RangePicker } = DatePicker;

export default function DisputeListings() {
  const { indv } = useSelector(({ auth }) => auth);
  const [visible, setVisible] = useState(false);
  const [CommentsVisible, setCommentsVisible] = useState(false);
  const [comments, setComments] = useState("");
  const [startDate, setStartDate] = useState("");
  const [selectedMerchant, setSelectedMerchant] = useState({});
  const [endDate, setEndDate] = useState("");
  const [, setColor] = useState("");
  const [remarks, setRemarks] = useState("");
  const [amount, setAmount] = useState(null);
  const [reason, setReason] = useState("")
  const [, setBgColor] = useState("");
  const [paymentId, setPaymentId] = useState();
  const [actualAmount, setActualAmount] = useState();
  const [disputeId, setDisputeId] = useState();
  const [refundApplicationFee, setRefundApplicationFee] = useState(false);
  const [reverseTransfer, setReverseTransfer] = useState(true);
  const [status, setStatus] = useState([]);
  const [data, setData] = useState([]);
  const [text, setText] = useState("");
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [commentslist, setCommenstList] = useState([]);
  const [, setStatusDropdown] = useState("");
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const statusCol = [
    { value: "INITIATED", label: "Initiated" },
    { value: "CANCELED", label: "Canceled" },
    { value: "DISPUTED", label: "Disputed" },
    {
      value: "SETTLED_WITH_CUSTOMER_PAYMENT",
      label: "Settled With Customer Payment",
      disabled: true,
    },
    { value: "SETTLED_WITHOUT_PAYMENT", label: "Settled Without Payment" },
  ];
  const Reason = [
    { value: "duplicate", label: "Duplicate" },
    { value: "fraudulent", label: "Fraudulent" },
    { value: "requested_by_customer", label: "Requested By Customer" },
    { value: "Other", label: "Other" }
  ]
  const disputeListingsApi = async (text) => {
    setLoading(true)
    const pgstart = text.pgstart || pagination.current
    let response = await actions.disputeListings({
      status: status,
      businessName: text,
      startDate: startDate,
      endDate: endDate,
      pgstart,
      pgSize: pagination.pageSize,
    })
    setPagination({
      ...pagination,
      total: response?.count,
    });
    if (response?.success === true) {
      setLoading(false);
      setData(response?.disputeDetails)

    }
  }
  const refundProcess = async () => {
    let response = await actions.RefundApi({
      disputeId: disputeId,
      refundAmt: amount,
      paymentId: paymentId,
      reason: reason,
      refundApplicationFee: refundApplicationFee,
      reverseTransfer: reverseTransfer,
      remarks: remarks
    })
    if (response?.success === true) {
      disputeListingsApi(text)
      message.success(response?.msg)
      setVisible(false)
    } else {
      message.error(response?.msg)
      setVisible(false)
    }
  }
  const updateDisputeStatus = async (value, disputeId) => {
    let payload = {
      status: value
    }
    let resp = await actions.DisputeStatusUpdate(payload, disputeId);
    if (resp.success === true) {
      disputeListingsApi(text)
      message.success(resp.msg)
    } else {
      message.error(resp.msg)
    }

  }
  const saveComment = async () => {
    let response = await actions.SaveComments({
      disputeId: disputeId,
      userId: indv.indvid,
      comment: comments
    })

    if (response?.success === true) {
      message.success(response.msg);
      setCommentsVisible(false);
    } else {
      message.error(response.msg);
      setCommentsVisible(false);
    }
  }
  const listingOfCommentsApi = async (disputeId) => {
    setIsLoading(true);
    let response = await actions.fetchCommentsForAdmin(disputeId)
    if (response?.success === true) {
      setIsLoading(false);
      setCommenstList(response.data);
    }
  }
  const handleTableChange = (pagination) => {
    setPagination({
      ...pagination
    })
  };
  const handleSearch = (value) => {
    setText(value);
    if (value.length >= MINIMUM_SEARCH_LIMIT) {
      disputeListingsApi(value);
    } else if (value.length === 0) {
      disputeListingsApi("");
    }
  }
  const handleOnRefundClick = () => {
    if (amount === null) {
      message.error("Amount can't be empty")
    }
    else if (amount <= 0) {
      message.error("Amount must be greater than 0")
    }
    else if (amount > actualAmount) {
      message.error("Amount must not be greater than the actual amount")
    }
    else if (reason === "") {
      message.error("Please select any one of the reasons to proceed")
    } else {
      refundProcess()
    }
  }
  const handleOnClick = () => {
    if (comments === "") {
      message.error("Comments can't be empty or null")
    } else {
      saveComment();
    }
  }
  const handleRefundbtnClick = (item) => {
    if (item === "CANCELED" || item === "SETTLED_WITH_CUSTOMER_PAYMENT" || item === "SETTLED_WITHOUT_PAYMENT") {
      message.error("Dispute is already settled or canceled. Please contact Fyndr Support for more information.");
    } else {
      setVisible(true);
    }
  }
  useEffect(() => {
    disputeListingsApi(text);
  }, [status,
    endDate,
    pagination.current,
    pagination.total,
    pagination.pageSize]);

  const getColor = (status) => {
    switch (status) {
      case "INITIATED":
        return "#07C603";
      case "CANCELED":
        return "#C63D03";
      case "DISPUTED":
        return "#6F6F6F";
      case "SETTLED_WITH_CUSTOMER_PAYMENT":
        return "#0000FF";
      case "SETTLED_WITHOUT_PAYMENT":
        return "#9E0F99";
      default:
        return null;
    }
  };
  const getBgColor = (status) => {
    switch (status) {
      case "INITIATED":
        return "#E2FFE5";
      case "CANCELED":
        return "#FFD6D6";
      case "DISPUTED":
        return "#E8E8E8";
      case "SETTLED_WITH_CUSTOMER_PAYMENT":
        return "#E2E2FF";
      case "SETTLED_WITHOUT_PAYMENT":
        return "#FFCBFD";
      default:
        return null;
    }
  };
  const getLabels = (item) => {
    switch (item) {
      case "INITIATED":
        return "Initiated";
      case "SETTLED_WITH_CUSTOMER_PAYMENT":
        return "Settled With Customer Payment";
      case "SETTLED_WITHOUT_PAYMENT":
        return "Settled Without Payment";
      case "CANCELED":
        return "Canceled";
      case "DISPUTED":
        return "Disputed";
    }
  }

  const tableColumn = [
    {
      title: "Merchant Name ",
      dataIndex: "merchantName",
      key: "merchantName",
    },
    {
      title: "Business Name",
      dataIndex: "businessName",
      key: "businessName",
    },
    {
      title: "Invoice ID",
      dataIndex: "invoiceId",
      key: "invoiceId",
    },
    {
      title: "Dispute ID",
      dataIndex: "disputeId",
      key: "disputeId",
    },
    {
      title: "Client Email",
      dataIndex: "buyerEmail",
      key: "buyerEmail",
      width: 200,
    },
    {
      title: "Dispute Reason",
      dataIndex: "disputeReason",
      key: "disputeReason",
      width: 200,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (data, key) => {
        return <p>{data ? `$${data}` : "-"}</p>;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 270,
      render: (item, record) => (
        <DropdownComponent
          bordered={false}
          options={statusCol}
          disabled={
            item === "SETTLED_WITH_CUSTOMER_PAYMENT" ||
            item === "SETTLED_WITHOUT_PAYMENT"
          }
          value={getLabels(item)}
          style={{
            color: getColor(item),
            width: "100%",
            borderRadius: "1rem",
            backgroundColor: getBgColor(item),
          }}
          onChange={(option) => {
            setColor(getColor(option));
            setBgColor(getBgColor(option));
            setStatusDropdown(option);
            updateDisputeStatus(option, record.disputeId);
          }}
        />
      ),
    },
    {
      title: "Actions",
      key: "action",
      render: (item) => (
        <Space>
          <Button
            type='primary'
            style={{
              borderRadius: "2rem",
              padding: ".4rem .5rem .4rem .5rem",
            }}
            onClick={() => {
              handleRefundbtnClick(item.status);
              setPaymentId(item.paymentId);
              setDisputeId(item.disputeId);
              setAmount(item.amount);
              setReason("");
              setReverseTransfer(true);
              setRefundApplicationFee(false);
              setActualAmount(item.amount);
            }}
            key={item}
            disabled={
              item?.status === "SETTLED_WITH_CUSTOMER_PAYMENT" ||
              item?.status === "SETTLED_WITHOUT_PAYMENT"
            }
          >
            Refund
          </Button>
          <Button
            type="link"
            style={{
              borderRadius: "2rem",
              padding: ".4rem .5rem .4rem .5rem"
            }}
            onClick={() => {
              setDisputeId(item.disputeId);
              setCommentsVisible(true);
              setSelectedMerchant(item);
              listingOfCommentsApi(item.disputeId);
            }}
            key={item}
          >
            Comment
          </Button>
        </Space>
      ),
    },
  ];
  return (
    <div className="admin_container no-breadcrumb container-mw-xl chapter">
      <div className="quickLoader">
        <PulseLoader color={"#3F9AF7"} loading={loading} size={10} />
      </div>
      <article className="article">
        <Row justify="space-between">
          <Col>
            <Breadcrumb>
              <Breadcrumb.Item>Admin</Breadcrumb.Item>
              <Breadcrumb.Item>Dispute</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <h2 className="article-title comment_heading ">Dispute</h2>
        <Divider />
        <div style={{ marginBottom: "2rem" }}>
          <Row justify="space-between" gutter={[10, 8]}>
            <Col style={{ width: "70%" }}>
              <Row gutter={[8, 6]}>
                <Col style={{ width: "30%" }} xs={24} sm={24} md={22} lg={8}>
                  <DropdownComponent placeholder="Status" style={{ minWidth: "100%" }} options={statusCol}
                    onChange={(e) => {
                      setStatus(e);
                    }} mode="multiple" />
                </Col>
                <Col style={{ width: "40%" }} xs={24} sm={22} md={22} lg={8}>
                  <Input
                    placeholder="Search for Business"
                    style={{ minWidth: "100%", height: "36px" }}
                    type="search"
                    onPressEnter={(e) => {
                      setPagination({
                        ...pagination,
                        current: 1,
                      });
                      handleSearch(e.target.value);
                    }}
                    suffix={<SearchOutlined />}
                  />
                </Col>
              </Row>
            </Col>
            <Col>
              <RangePicker
                size="middle"
                style={{ height: "36px" }}
                onChange={(e) => {
                  if (e === null) {
                    setStartDate("");
                    setEndDate("");
                  } else {
                    setPagination({
                      ...pagination,
                      current: 1,
                    });
                    setStartDate(moment(e[0]._d).format("YYYY-MM-DD"));
                    setEndDate(moment(e[1]._d).format("YYYY-MM-DD"));
                  }
                }}
                allowClear={true}
              />
            </Col>
          </Row>
        </div>
        <div>
          <Table
            className="admin-table"
            bordered
            columns={tableColumn}
            dataSource={data}
            size="medium"
            pagination={pagination}
            onChange={handleTableChange}
          />
        </div>
        <RefundModal
          visible={visible}
          options={Reason}
          onCancel={() => setVisible(false)}
          actualAmount={actualAmount}
          isChecked={reverseTransfer}
          handleInputChange={(e) => {
            setAmount(e.target.value);
          }}
          onChange={(value) => {
            setReason(value);
          }}
          handleOnClick={() => handleOnRefundClick()}
          handleTextChange={(e) => {
            setRemarks(e.target.value);
          }}
          handleOnChange={(e) => {
            setReverseTransfer(e.target.checked);
          }}
          handleValueChange={(e) => {
            setRefundApplicationFee(e.target.checked);
          }}
        />
        <Comments
          visible={CommentsVisible}
          commentslist={commentslist}
          loading={isLoading}
          title={selectedMerchant.businessName}
          merchantName={selectedMerchant.merchantName}
          onChange={(e) => setComments(e.target.value)}
          onCancel={() => {
            setCommentsVisible(false);
            setCommenstList([])
          }}
          onClick={() => {
            handleOnClick();
          }}
        />
      </article>
    </div>
  );
}
