/* eslint-disable no-dupe-keys */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import {
  Col,
  Row,
  Input,
  Checkbox,
  Layout,
  Typography,
  Radio,
  Slider,
  Modal,
  Button,
  Card,
  Space,
  message,
  Select,
} from "antd";

import Loader from "../../components/Loading/Loader";
import { isTab, isMobile } from "../../common/util";
import HelmetFyndr from "../../components/HelmetFyndr";
import { MdOutlineCancel } from "react-icons/md";
import Sliders from "../../assets/images/sliders.svg";
import filterIcon from "../../assets/images/filter-icon.svg";
import { Actions } from "../../common";
import { Theme } from "../../common";
import UserActions from "../../actions";
import { useRecoilState } from "recoil";
import { offerPageState } from "../../recoil/Atom";
import GoogleMap from "../../components/GoogleMap";
import filterCan from "../../assets/images/filter-cancel-icon.svg";
import searchIcon from "../../assets/images/searchIcon.svg";
import { useHistory } from "react-router-dom";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  geocodeByLatLng,
  getLatLng,
} from "react-google-places-autocomplete";

import { TopMenu, Logo, MarkerGoogle, Footer } from "../../components";
import { parseAddress } from "../../common/util";
import googlelocations from "../../assets/images/googlelocation.svg";

import OfferCard from "./OfferCard";
import { ArrowLeftOutlined } from "@ant-design/icons";
import getEnvVars from "../../environment";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
const { Text } = Typography;
const web = getEnvVars();
const LandingPage = (props) => {
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);

  const [showFilter, setShowFilter] = useState(false);
  const [, setIsDropdownOpen] = useState(false);
  const { isMasquerade } = useSelector(({ admin }) => admin);
  const [orderBy, setOrderBy] = useState("ASC");

  const closeIcon = <MdOutlineCancel className="closeIconBtn" />;
  const modalStyle = { display: "flex", alignItems: "center" };
  const onCancelFilter = (value, index) => {
    const updatedDeals = subTypeDeals.filter((item, i) => i !== index);
    if (updatedDeals.length === 0) {
      setSubTypeDeals(["OFFERS","COUPONS", "EVENTS"]);
    } else {
      setSubTypeDeals(updatedDeals);
    }
  };

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
 
  const { state } = props?.location;
  const locQRId = state?.locQRId || queryParams.get('locQRId');
  const {
    indv,
    indv: { indvid, entityType, address },
  } = useSelector(({ auth }) => auth);
  const { ROLE_FYNDR } = Actions;

  const [dragging, setDragging] = useState(false);
  const handleStyle = dragging
    ? {
      height: "24px",
      width: "24px",
      borderRadius: "80% 15% 55% 50% / 55% 15% 80% 50%",
      background: "#257CDB",
      transform: "rotate(-45deg)",
      border: "6px solid #C4E1FF",
      marginTop: "-8px",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.15)",
    }
    : {
      height: "24px",
      width: "24px",
      backgroundColor: "#257CDB",
      border: "none",
      borderRadius: "50%",
      marginTop: "-10px",
      border: "6px solid #C4E1FF",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.15)",
    };

  const [offerPageSetting, setOfferPageSetting] =
    useRecoilState(offerPageState);
  const [typeOfDeals, setTypeOfDeals] = useState(
    offerPageSetting?.typeOfDeals || "instore"
  );

  const [subTypeDeals, setSubTypeDeals] = useState(
    offerPageSetting?.subTypeDeals || ["all"]
  );

  const [miles, setMiles] = useState(50);

  const [categories, setCategories] = useState(
    offerPageSetting?.categories || []
  );

  useEffect(() => {
    const state = history.location.state;
    if (state && state.selectedOptions) {
      setSubTypeDeals(state.selectedOptions);
    }
  }, [history]);

  const [bottomSheet, setOpenBottomSheet] = useState(false);
  const [section] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [page] = useState(0);
  const [scrollPage, setScrollPage] = useState(1);

  const [isLast, setIsLast] = useState(true);
  const [pgSize] = useState(55);

  const [searchStr, setSearchStr] = useState("");
  const [campns, setCampn] = useState([]);
  const [campnsMap, setCampnMap] = useState([]);
  const [, setCount] = useState(0);
  const [isfilterOn] = useState(false);
  const [isOption] = useState(true);
  const [, setLabel] = useState("Current Location");
  const [categoryList, setCategoryList] = useState([]);
  const [lat, setLat] = useState(
    offerPageSetting?.lat || address?.lat || 33.6629442
  );
  const [lng, setLng] = useState(
    offerPageSetting?.lng || address?.lng || -112.0182329
  );
  const [position, setPosition] = useState({
    lat: offerPageSetting?.lat || address?.lat || 33.6629442,
    lng: offerPageSetting?.lng || address?.lng || -112.0182329,
  });

  const [addressName, setAddressName] = useState(
    offerPageSetting?.addressName || parseAddress(indv?.address)
  );
  const [, setRegion] = useState(null);
  const categoryListArr = categoryList.map((row) => {
    return { value: row.objid, label: row.name };
  });
  useEffect(() => {
    searchCampaignFunctionMap(null, orderBy);

  }, [position, typeOfDeals, locQRId, categories, miles,subTypeDeals])
  const fetchIpAddress = async () => {
    if (navigator.geolocation) {
      try {
        setIsLoading(true);
        await navigator.geolocation.getCurrentPosition(showPosition);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    } else {
      alert("geolocation is not supported");
    }
  };

  const onChangeType = (value) => {
    if (value.length > 0) {
      if (value.indexOf("all") === -1) {
        setSubTypeDeals(value);
      } else {
        if (value[value.length - 1] === "all") {
          setSubTypeDeals(['all']);
        } else {
          let indexcheck = value.indexOf("all");
          value.splice(indexcheck, 1);
          setSubTypeDeals(value);
        }
      }
    } else {
      message.warn("Please select atleast one type");
    }
  };

  const filterCampnsMap = (campnsMap, selectedTypes) => { 
    return campnsMap.filter(item => 
      (selectedTypes.includes(item.cmpnType.toUpperCase()) || selectedTypes.includes('all')) && item.cmpnType !== 'brochure'
    );
  };

  useEffect(() => {
    const originalWarn = console.warn;
    const originalError = console.error;

    console.warn = () => {};
    console.error = () => {};

    return () => {
      console.warn = originalWarn;
      console.error = originalError;
    };
  }, []);

  const changeRadioButtonFunction = (value) => {
    setTypeOfDeals(value.target.value);
    const radioButtons = document.querySelectorAll(".radioStyle");
    radioButtons.forEach((button) => {
      if (button.value === value.target.value) {
        button.classList.add("selected");
      } else {
        button.classList.remove("selected");
      }
    });
  };

  const onCancelCategory = (value) => {
    const updatedCategories = categories.filter((item) => item !== value);
    setCategories(updatedCategories);
  };

  const onChangeCategories = (value) => {
    setCategories(value);
  };

  const searchCampaignFunction = async (
    pageNumber = null,
    locQRId = null,
    orderByParam = orderBy
  ) => {
    setIsLoading(true);
    const response = await UserActions.searchCmpns({
      categories,
      searchStr,
      page: pageNumber || page,
      pgSize,
      distance: miles,
      location: position,
      locQRId,
      indvId: indvid,
      fetchById: locQRId != null ? "locQR" : "none",
      fetchByGoal: typeOfDeals,
      orderBy: orderByParam,
      campaignType: subTypeDeals.length === 1 && subTypeDeals[0] === 'all' ? ["COUPONS", "OFFERS", "EVENTS"] : subTypeDeals,
    });


    setIsLoading(false);
    if (response && response.success) {
      let payload = response.payload;
      let tempCamps = [];
      payload.forEach((item, key) => {
        tempCamps.push(Object.values(item)[0]);
      });
      setIsLast(response.last);
      setCount(response?.count);
      setCampn(tempCamps);


      const isLogin = indv?.indvid ? true : false;
      if (isLogin) {
        //now get the likes of user per campaign and total likes for the business
        const bizId = payload.map(
          (row) => Object.values(row)[0]["biz"]["bizid"]
        );
        await UserActions.fetchBizinfo({
          bizId: [...new Set(bizId)],
        });
      }
    } else {
      setCampn([]);
    }
  };
  const searchCampaignFunctionMap = async (
    orderByParam = orderBy
  ) => {
    setIsLoading(true);
    const response = await UserActions.searchCmpnsMaps({

      categories,
      searchStr,
      distance: miles,
      location: position,
      locQRId,
      indvId: indvid,
      fetchById: locQRId != null ? "locQR" : "none",
      fetchByGoal: typeOfDeals,
      orderBy: orderByParam,
      campaignType: subTypeDeals.length === 1 && subTypeDeals[0] === 'all' ? ["COUPONS", "OFFERS", "EVENTS"] : subTypeDeals,

    });
    setIsLoading(false);
    if (response && response.success) {
      let payload = response.payload;
      let tempCamps = [];
      payload.forEach((item, key) => {
        tempCamps.push(Object.values(item)[0]);
      });
      setCampnMap(tempCamps);
      const isLogin = indv?.indvid ? true : false;
      if (isLogin) {
        //now get the likes of user per campaign and total likes for the business
        const bizId = payload.map(
          (row) => Object.values(row)[0]["biz"]["bizid"]
        );
        await UserActions.fetchBizinfo({
          bizId: [...new Set(bizId)],
        });
      }
    } else {
      setCampnMap([]);
    }
  };

  const searchCampaignFunction2 = async (
    pageNumber = null,
    locQRId = null,
    orderByParam = orderBy
  ) => {
    setIsLoading(true);
    const response = await UserActions.searchCmpns({
      categories,
      searchStr,
      page: pageNumber || page,
      pgSize,
      distance: miles,
      location: position,
      locQRId,
      indvId: indvid,
      fetchById: locQRId != null ? "locQR" : "none",
      fetchByGoal: typeOfDeals,
      orderBy: orderByParam,
      campaignType: subTypeDeals.length === 1 && subTypeDeals[0] === 'all' ? ["COUPONS", "OFFERS", "EVENTS"] : subTypeDeals,

    });
    setIsLoading(false);
    if (response && response.success) {
      let payload = response.payload;
      let tempCamps = [];
      payload.forEach((item, key) => {
        tempCamps.push(Object.values(item)[0]);
      });
      setIsLast(response.last);
      setCount(response?.count);
      setCampn((prevCamps) => [...prevCamps, ...tempCamps]);

      const isLogin = indv?.indvid ? true : false;
      if (isLogin) {
        //now get the likes of user per campaign and total likes for the business
        const bizId = payload.map(
          (row) => Object.values(row)[0]["biz"]["bizid"]
        );
        await UserActions.fetchBizinfo({
          bizId: [...new Set(bizId)],
        });
      }
    } else {
      setCampn([]);
    }
  };
  const searchCampaignFunctionNew = async (
    pageNumber = null,
    locQRId = null
  ) => {
    const response = await UserActions.searchCmpns({
      categories,
      searchStr,
      page: pageNumber || page,
      pgSize,
      distance: miles,
      location: position,
      locQRId,
      indvId: indvid,
      fetchById: locQRId != null ? "locQR" : "none",
      fetchByGoal: typeOfDeals,
      campaignType: subTypeDeals.length === 1 && subTypeDeals[0] === 'all' ? ["COUPONS", "OFFERS", "EVENTS"] : subTypeDeals,
    });
    setIsLoading(false);
    if (response && response.success) {
      let payload = response.payload;
      let tempCamps = [];
      payload.forEach((item, key) => {
        tempCamps.push(Object.values(item)[0]);
      });
      setIsLast(response.last);
      setCampn(tempCamps);

      const isLogin = indv?.indvid ? true : false;
      if (isLogin) {
        //now get the likes of user per campaign and total likes for the business
        const bizId = payload.map(
          (row) => Object.values(row)[0]["biz"]["bizid"]
        );
        await UserActions.fetchBizinfo({
          bizId: [...new Set(bizId)],
        });
      }
    } else {
      setCampn([]);
    }
  };
  function showPosition(position) {
    const newLat = position.coords.latitude;
    const newLng = position.coords.longitude;
    setLat(newLat);
    setLng(newLng);
    setPosition({ lat: newLat, lng: newLng });
    geocodeByLatLng({
      lat: newLat,
      lng: newLng,
    })
      .then((results) => {
        setLabel(results[0]?.formatted_address);
        setAddressName(results[0]?.formatted_address);
        setOfferPageSetting({
          ...offerPageSetting,
          lat: newLat,
          lng: newLng,
          addressName: results[0]?.formatted_address,
        });
      })
      .catch((error) => console.error(error));
  }

  const renderNoDataFound = () => {
    return (
      <div className="offer--render-nodata">
        <h5>No offers found ...</h5>
      </div>
    );
  };

  const getCoordinates = async (value) => {
    let geoResult = await geocodeByAddress(value.value.description);
    let coordinates = await getLatLng(geoResult[0]);
    setLat(coordinates.lat);
    setLng(coordinates.lng);
    setPosition({ lat: coordinates.lat, lng: coordinates.lng });
    setLabel(value.label);
    setAddressName(value.label);
  };
  useEffect(() => {
    (async () => {
      await setOfferPageSetting({
        ...offerPageSetting,
        typeOfDeals,
        categories,
        miles,
        lat,
        lng,
        addressName,
      });
    })();
  }, [typeOfDeals, categories, indv, lat, lng, miles, addressName, locQRId]);

  useEffect(() => {
    setCampn([]);
    setScrollPage(1);
    setIsLast(false);
    searchCampaignFunction(null, locQRId, orderBy);
  }, [position, typeOfDeals, locQRId, categories, miles, subTypeDeals]);

  useEffect(() => {
    getCampnList();
  }, []);
  useEffect(() => {
    (async () => {
      await setOfferPageSetting({
        ...offerPageSetting,
        subTypeDeals,
        searchStr,
      });
    })();
  }, [subTypeDeals, searchStr]);
  let newobj = {};
  const result = [];
  const getCampnList = async () => {
    let response = await UserActions.fetchcmpnCategoriesList();
    if (response !== undefined) {
      for (let value of response) {
        newobj = { ...value };
        result.push(newobj);
      }
      const newArrayOfObj = result.map(({ 0: objid, 1: name, ...rest }) => ({
        objid,
        name,
        ...rest,
      }));
      setCategoryList(newArrayOfObj);
    }
  };

  const listInnerRef = useRef();
  const innerScrollRef = useRef();
  const [isAtBottom, setIsAtBottom] = useState(false);
  const handleScroll2 = () => {
    const { scrollTop, scrollHeight, clientHeight } = innerScrollRef.current;

    if (scrollHeight - scrollTop <= clientHeight + 20) {
      setIsAtBottom(true);
    } else {
      setIsAtBottom(false);
    }
  };

  const loadMore = () => {
    if (!isLoading && !isLast) {
      setScrollPage((prevPage) => prevPage + 1);
      searchCampaignFunction2(scrollPage + 1);
    }
  };

  useEffect(() => {
    const innerScroll = innerScrollRef.current;
    innerScroll.addEventListener("scroll", handleScroll2);
    return () => {
      innerScroll.removeEventListener("scroll", handleScroll2);
    };
  }, []);

  useEffect(() => {
    if (isAtBottom) {
      loadMore();
    }
  }, [isAtBottom]);

  const truncateAddress = (address) => {
    return address.length > 25 ? address.slice(0, 25) + "..." : address;
  };

  const mapRef = useRef(null);
  const handleBoundsChanged = async () => {
    const map = mapRef.current;

    if (map) {
      const bounds = map.getBounds();
      if (bounds) {
        const center = bounds.getCenter();
        const latt = center.lat();
        const lngg = center.lng();

        const regionInfo = await getRegionFromLatLng(latt, lngg);
        setRegion(regionInfo);
        setLat(latt);
        setLng(lngg);
        setPosition({ lat: latt, lng: lngg });
      }
    }
  };


  const getRegionFromLatLng = async (lat, lng) => {
    const geocoder = new window.google.maps.Geocoder();
    return new Promise((resolve, reject) => {
      geocoder.geocode({ location: { lat, lng } }, (results, status) => {
        if (status === "OK") {
          const regionData = results[0].address_components;
          resolve(regionData.map(component => component.long_name).join(", "));
        } else {
          reject(`Geocoder failed due to: ${status}`);
        }
      });
    });
  };



  return (
    <Layout className="offer--home">
      {
        <HelmetFyndr
          title="Offers & Events"
          imgUrl={""}
          url={`${web}/offers`}
          descr="offers"
        />
      }
      <section className={isMasquerade ? "bg-danger-fyndr" : "bg-info"}>
        <div>
          {(section > 50 || !isTab()) && (
            <div
              className="fyndrLogo"
              style={{
                float: isTab() ? "none" : "left",
                position: "absolute",
                zIndex: "9999"
              }}
            >
              <Logo />
            </div>
          )}
          <div
            className={
              section > 50
                ? "shortHeader-gone"
                : isMasquerade
                  ? "bg-danger-fyndr"
                  : "bg-info"
            }
          >
            {isOption && (
              <Row className="offer--header-row" align="middle">
                <Col
                  offset={isTab() ? 0 : 1}
                  xs={indvid ? 18 : 19}
                  sm={indvid ? 15 : 12}
                  md={indvid ? 6 : 10}
                  lg={indvid ? 10 : 13}
                  xl={indvid ? 12 : 14}
                  xxl={14}
                >
                  <Row align="middle">
                    <Col xs={indvid ? 4 : 4} sm={5} md={0}>
                      <Logo collapsed={true} />
                    </Col>

                    <Col align="right" xs={indvid ? 20 : 20} sm={19} md={0}>
                      {/* location icon on mobile view */}
                      <i
                        onClick={() => {
                          setOpenBottomSheet(true);
                        }}
                        className="fa fa-map-marker"
                        aria-hidden="true"
                        style={{
                          fontSize: "1.5rem",
                          color: "#257CDB",
                          background: "#fff",
                          borderRadius: "100%",
                          padding: "1rem",
                        }}
                      ></i>
                    </Col>
                  </Row>
                </Col>
                <Col
                  xs={0}
                  sm={0}
                  md={entityType !== ROLE_FYNDR && indvid ? 11 : 22}
                  lg={entityType !== ROLE_FYNDR && indvid ? 10 : 22}
                  xl={entityType !== ROLE_FYNDR && indvid ? 10 : 7}
                  xxl={entityType !== ROLE_FYNDR && indvid ? 8 : 7}
                  offset={entityType !== ROLE_FYNDR && indvid ? 1 : 2}
                  className="bizHome_bg-topMenu2"
                  sty
                >
                  <TopMenu {...props} />
                </Col>
                <Col
                  xs={indvid ? 4 : 4}
                  sm={indvid ? 8 : 18}
                  md={0}
                  lg={0}
                  xl={0}
                  xxl={0}
                  offset={indvid ? 1 : 1}
                >
                  <TopMenu menuUp={false} {...props} />
                </Col>
              </Row>
            )}
          </div>
        </div>
      </section>
      <section className="offer_cards--main-card">
        <div
          ref={listInnerRef}
        >
          <section
            style={{ textAlign: "center", minWidth: "100%" }}
            className={isfilterOn ? `filter__color` : ""}
          >
            <div>
              <Row
                justify="center"
                className="offer--buttons"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  border: "none",
                }}
              >
                <Col
                  xs={24}
                  lg={2}
                  xl={2}
                  xxl={2}
                  md={3}
                  sm={3}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      if (locQRId) {
                        props.history.goBack();
                      } else {
                        props.history.replace("/");
                      }
                    }}
                    className="offer_cards--go-back-btn"
                  >
                    <ArrowLeftOutlined style={{ verticalAlign: "middle" }} /> Go
                    Back
                  </span>
                </Col>
                <Col xs={21} sm={21} md={0}>
                  {/* header search box */}
                  <Input
                    className="offer--search-input"
                    value={searchStr}
                    onChange={async (event) => {
                      await setSearchStr(event.target.value);
                    }}
                    onPressEnter={() => searchCampaignFunction()}
                    suffix={<img src={searchIcon} alt="searchIcon" />}
                    placeholder="Search"
                  />
                </Col>
                <Col xs={3} sm={3} md={0}>
                  <img
                    src={filterIcon}
                    alt="filter-icon"
                    style={{
                      margin: "8px 0px 0px 15px",
                    }}
                    onClick={() => setShowFilter(true)}
                  />
                </Col>

                <Col xs={0} sm={0} md={4} lg={5}>
                  <GooglePlacesAutocomplete
                    selectProps={{
                      onChange: (value) => {
                        if (
                          value.value === "myLocation" ||
                          value.label === "Current Location"
                        ) {
                          fetchIpAddress();
                        } else {
                          getCoordinates(value);
                        }
                      },
                      defaultOptions: [
                        {
                          label: `Current Location`,
                          value: `${addressName}`,
                        },
                      ],
                      placeholder: `${addressName ? truncateAddress(addressName) : "Location"}`,
                      styles: {
                        dropdownIndicator: (base) => ({
                          ...base,
                          backgroundImage: `url(${googlelocations})`,
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                          backgroundSize: "contain",
                          cursor: "pointer",
                          width: "24px",
                          height: "46px",
                          margin: "0px 12px",
                          transition: "transform 0.5s ease-in-out",
                        }),
                      },
                      className: "offer--location-input",
                      onMenuOpen: () => setIsDropdownOpen(true),
                      onMenuClose: () => setIsDropdownOpen(false),
                    }}
                  />
                </Col>

                <Col
                  xs={24}
                  sm={24}
                  md={7}
                  lg={8}
                  xl={6}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Radio.Group
                    value={typeOfDeals}
                    onChange={changeRadioButtonFunction}
                    size={isTab() ? "small" : "large"}
                    buttonStyle="solid"
                    className="radioGroupStyle"
                  >
                    <Radio.Button
                      className="radioStyle"
                      value="instore"
                      style={{
                        backgroundColor:
                          typeOfDeals === "instore" ? "#223369" : "#fff",
                        color: typeOfDeals === "instore" ? "#fff" : "#223369",
                        borderRadius: "10px",
                        border: "none",
                        height: "44px",
                      }}
                    >
                      <p className="radioTextStyle">Local</p>
                    </Radio.Button>
                    <Radio.Button
                      className="radioStyle"
                      value="online"
                      style={{
                        backgroundColor:
                          typeOfDeals === "online" ? "#223369" : "#fff",
                        color: typeOfDeals === "online" ? "#fff" : "#223369",
                        borderRadius: "10px",
                        border: "none",
                        height: "44px",
                      }}
                    >
                      <p className="radioTextStyle">Online</p>
                    </Radio.Button>
                  </Radio.Group>
                </Col>

                <Col xs={24} sm={24} md={4} lg={5} xl={6}>
                  <Select
                    onChange={(e) => {
                      setOrderBy(e);
                      searchCampaignFunction(null, null, e);
                    }}
                    className="reviewrating_filterBTN2"
                    defaultValue={{
                      value: "ASC",
                      label: "Distance : Closest to Furthest",
                    }}
                    options={[
                      {
                        value: "DESC",
                        label: "Distance : Furthest to Closest",
                      },
                      {
                        value: "ASC",
                        label: "Distance : Closest to Furthest",
                      },
                    ]}
                    suffixIcon={<img src={Sliders} alt="Sliders" />}
                    style={{
                      fontWeight: 400,
                      fontSize: "14px",
                      color: "#223369",
                      border: "1px solid #D3D6E1",
                      background:
                        typeOfDeals !== "online" ? "#FFFFFF" : "#ececec",
                      borderRadius: "10px",
                      height: "46px",
                    }}
                    placeholder="Sort By Distance"
                  />
                </Col>

                <Col xs={24} sm={24} md={0} className="offer--range">

                  {!isMobile() && (
                    <Row>
                      <Col span={12}>
                        <span className="rangeStyle">
                          {" "}
                          {`Range: ${miles} miles`}{" "}
                        </span>
                      </Col>
                      <Col span={12} align={"right"}>
                        <Button
                          type="primary"
                          className="dealsBtn"
                          onClick={() => setShowModal(true)}
                        >
                          Deals on map
                        </Button>
                      </Col>
                    </Row>
                  )}
                  <div style={{ paddingTop: "1.8rem" }}>
                    <Slider
                      style={{ marginBottom: "42px" }}
                      railStyle={{
                        backgroundColor: "#223369",
                        height: ".2rem",
                      }}
                      trackStyle={{
                        backgroundColor: "#223369",
                        height: ".2rem",
                      }}
                      handleStyle={handleStyle}
                      defaultValue={miles}
                      min={20}
                      max={100}
                      marks={{
                        20: {
                          style: {
                            paddingTop: "10px",
                            paddingLeft: "50px",
                            color: "#223369",
                          },
                          label: "20 Miles",
                        },

                        100: {
                          style: {
                            paddingTop: "10px",
                            paddingRight: "50px",
                            whiteSpace: "nowrap",
                            color: "#223369",
                          },
                          label: "100 Miles",
                        },
                      }}
                      onAfterChange={(value) => {
                        setMiles(value);
                        setDragging(false);
                      }}
                      onChange={() => {
                        setDragging(true);
                      }}
                    />
                  </div>
                  {isMobile() && (
                    <Row>
                      <Col span={12}>
                        <h3 className="rangeStyle">
                          {" "}
                          {`Range: ${miles} miles`}{" "}
                        </h3>
                      </Col>
                      <Col span={12} align={"right"}>
                        <Button
                          type="primary"
                          className="dealsBtn"
                          onClick={() => setShowModal(true)}
                        >
                          Deals on map
                        </Button>
                      </Col>
                    </Row>
                  )}
                </Col>

                <Col xs={0} sm={0} md={3} lg={4} xl={5}>
                  {/* header search box */}
                  <Input
                    className="offer--search-input"
                    value={searchStr}
                    onChange={async (event) => {
                      await setSearchStr(event.target.value);
                    }}
                    onPressEnter={() => searchCampaignFunction()}
                    suffix={<img src={searchIcon} alt="searchIcon" />}
                    placeholder="Search"
                  />
                </Col>
              </Row>

              <Row
                justify="center"
                className="offer--main-page"
                gutter={[24, 24]}
                ref={innerScrollRef}
              >
                <Col
                  xs={isfilterOn ? 24 : 0}
                  sm={isfilterOn ? 24 : 0}
                  md={6}
                  lg={6}
                  xl={6}
                  xxl={6}
                  className="offer--main-page-col"
                >
                  {/* offer page search deals map */}
                  <Row className="offer--search-deals">
                    <Col className="offer--search-col" style={{ textAlign: "left" }} xs={0} sm={0} md={24}>
                      <span style={{
                        textAlign: "left",
                        fontFamily: "Roboto",
                        fontSize: "18px",
                        fontWeight: "500",
                        lineHeight: "21.09px",
                        color: "#3A3A3A"

                      }}>Deals on map</span>

                      <div className="map_view_new">
                        <GoogleMap
                          zoom={miles > 50 ? 6 : 8}
                          defaultCenter={{
                            lat: lat,
                            lng: lng,
                          }}
                          onBoundsChanged={(e) => handleBoundsChanged(e)}
                          mapRef={mapRef} 
                        >
                              {Array.isArray(campnsMap) && (
                                    filterCampnsMap(campnsMap, subTypeDeals).map((item, key) => {
                                      return (
                                        <MarkerGoogle
                                          history={props.history}
                                          myKey={key}
                                          item={item} 
                                        />
                                      );
                                    })
                                  )}
                        </GoogleMap>
                      </div>

                      {/*  Range is shown after the map */}
                      {typeOfDeals !== "online" && (
                        <div className="offer--range">
                          <h5> {`Range (${miles} miles)`} </h5>
                          <div style={{ marginTop: "0.5em" }}>
                            <Slider
                              railStyle={{
                                backgroundColor: "#223369",
                                height: ".2rem",
                              }}
                              trackStyle={{
                                backgroundColor: "#223369",
                                height: ".2rem",
                              }}
                              handleStyle={handleStyle}
                              defaultValue={miles}
                              min={20}
                              max={100}
                              marks={{
                                20: {
                                  style: {
                                    paddingTop: "10px",
                                    paddingLeft: "50px",
                                    whiteSpace: "nowrap",
                                  },
                                  label: "20 Miles",
                                },

                                100: {
                                  style: {
                                    paddingTop: "10px",
                                    paddingRight: "50px",
                                    whiteSpace: "nowrap",
                                  },
                                  label: "100 Miles",
                                },
                              }}
                              onAfterChange={(value) => {
                                setMiles(value);
                                setDragging(false);
                              }}
                              onChange={() => {
                                setDragging(true);
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </Col>
                  </Row>

                  <h5 style={{
                    textAlign: "left",
                    fontFamily: "Roboto",
                    fontSize: "18px",
                    fontWeight: "500",
                    lineHeight: "21.09px",
                    color: "#3A3A3A"

                  }}>Type of deals</h5>
                  <Checkbox.Group
                    style={{ width: "100%" }}
                    value={subTypeDeals}
                    onChange={onChangeType}
                    className="offer--checkbox"
                  >
                    <Row>
                      <Checkbox value="all"> All</Checkbox>
                    </Row>
                    <Row className="offer--deals-option">
                      <Checkbox value="COUPONS"> Coupon</Checkbox>
                    </Row>
                    <Row className="offer--deals-option">
                      <Checkbox value="OFFERS"> Offers</Checkbox>
                    </Row>
                    <Row className="offer--deals-option">
                      <Checkbox value="EVENTS"> Events</Checkbox>
                    </Row>
                  </Checkbox.Group>

                  <h5 style={{
                    textAlign: "left",
                    fontFamily: "Roboto",
                    fontSize: "18px",
                    fontWeight: "500",
                    lineHeight: "21.09px",
                    marginTop: "12px",
                    color: "#3A3A3A"
                  }}>Category</h5>
                  <Checkbox.Group
                    style={{ width: "100%" }}
                    defaultValue={categories}
                    onChange={onChangeCategories}
                    className="offer--checkbox"
                  >
                    {categoryListArr.map((item, key) => (
                      <Row
                        className={key === 0 ? "" : "offer--category-options"}
                        key={key}
                      >
                        <Checkbox value={item.value}> {item.label}</Checkbox>
                      </Row>
                    ))}
                  </Checkbox.Group>
                </Col>

                <Col
                  align="center"
                  style={{ paddingRight: "0 !important" }}
                  lg={18}
                  xl={18}
                  xxl={18}
                  xs={!isfilterOn ? 24 : 0}
                  sm={!isfilterOn ? 24 : 0}
                  md={18}

                >
                  <div
                    // ref={innerScrollRef}
                    className="inner-scroll-offer-card"

                  >
                    {campns.length > 0 ? (
                      <>
                        <Row gutter={[16, 16]}>
                          {campns
                            .map((item, key) => {
                              item.isLogin = indv?.indvid ? true : false;
                                if (isTab()) {

                                  return (
                                    <>
                                      <Col span={24} key={key} className="offer-card-web">
                                        <OfferCard
                                          history={props.history}
                                          typeOfDeals={typeOfDeals}
                                          item={item}
                                          key={key}
                                          itemKey={key}
                                          callBack={(e) => {
                                            if (e) {
                                              searchCampaignFunctionNew(
                                                null,
                                                locQRId
                                              );
                                            }
                                          }}
                                        />
                                      </Col>
                                    </>
                                  );
                                } else {
                                  return (
                                    <>
                                      <Col span={12} key={key}>
                                        <OfferCard
                                          history={props.history}
                                          typeOfDeals={typeOfDeals}
                                          item={item}
                                          key={key}
                                          itemKey={key}
                                          locQRId={locQRId}
                                          callBack={(e) => {
                                            if (e) {
                                              searchCampaignFunctionNew(
                                                null,
                                                locQRId
                                              );
                                            }
                                          }}
                                        />
                                      </Col>
                                    </>
                                  );
                                }
                            })}
                        </Row>

                        {isLoading && (
                          <Col
                            span={24}
                            style={{ textAlign: "center", padding: "20px 0" }}
                          >
                            <Loader />
                          </Col>
                        )}
                        {isLast && <span>End of offers</span>}
                      </>
                    ) : (
                      isLoading ? <Loader /> : renderNoDataFound()
                    )}
                  </div>
                </Col>
              </Row>
            </div>
          </section>
        </div>
      </section>

      <Modal
        visible={showFilter}
        footer={null}
        onCancel={() => {
          setShowFilter(false);
        }}
        closeIcon={closeIcon}
        style={{
          marginTop: "130px",
          zIndex: "100",
        }}
        mask={false}
        centered
      >
        <div>
          {/* Mobile view */}
          <Card className="filterCard">
            <h3>Type of deals</h3>
            {subTypeDeals.map((value, index) => (
              <Space direction="horizontal">
                <div className="filterCardBox">
                  <Text key={index} className="filterCardBoxText">
                    {value.charAt(0).toUpperCase() +
                      value.slice(1).toLowerCase()}
                  </Text>

                  <img
                    src={filterCan}
                    alt="cancel"
                    onClick={() => onCancelFilter(value, index)}
                  />
                </div>
              </Space>
            ))}
            <Checkbox.Group
              style={{ width: "100%" }}
              value={subTypeDeals}
              onChange={onChangeType}
              className="offer--categories offer_cards--offer-filter offer--checkbox"
            >
              <Row>
                <Checkbox value="all"> All</Checkbox>
              </Row>
              <Row className="offer--deals-option">
                <Checkbox value="COUPONS"> Coupon</Checkbox>
              </Row>
              <Row className="offer--deals-option">
                <Checkbox value="OFFERS"> Offers</Checkbox>
              </Row>
              <Row className="offer--deals-option">
                <Checkbox value="EVENTS"> Events</Checkbox>
              </Row>
            </Checkbox.Group>
          </Card>

          <Card className="filterCard">
            <h3 className="offer--deals-category">Category</h3>
            {categories.map((value, index) => (
              <Space direction="horizontal" key={index}>
                <div className="filterCardBox">
                  <Text className="filterCardBoxText">
                    {
                      categoryListArr.find((item) => item.value === value)
                        ?.label
                    }
                  </Text>
                  <img
                    src={filterCan}
                    alt="cancel"
                    onClick={() => onCancelCategory(value)}
                  />
                </div>
              </Space>
            ))}

            <Checkbox.Group
              className="offer--categories offer_cards--offer-filter offer--checkbox"
              value={categories}
              onChange={onChangeCategories}
            >
              {categoryListArr.map((item, key) => (
                <Row className="offer--category-options" key={key}>
                  <Checkbox value={item.value}> {item.label}</Checkbox>
                </Row>
              ))}
            </Checkbox.Group>
          </Card>
        </div>
      </Modal>
      <Modal
        visible={showModal}
        onCancel={() => {
          setShowModal(false);
        }}
        closeIcon={closeIcon}
        footer={null}
        maskClosable={false}
        style={modalStyle}
        width={"90%"}
      >
        <div className="modal_map_view">
          <GoogleMap
            zoom={miles > 50 ? 8 : 11}
            defaultCenter={{
              lat: lat,
              lng: lng,
            }}
            onBoundsChanged={(e) => handleBoundsChanged(e)}
            mapRef={mapRef}
          >

            {Array.isArray(campnsMap) && (
              filterCampnsMap(campnsMap, subTypeDeals).map((item, key) => {
                return (
                  <MarkerGoogle
                    history={props.history}
                    myKey={key}
                    item={item}
                  />
                );
              })
            )}
          </GoogleMap>
        </div>
      </Modal>
      {/* clicking on small location icon on mobile view */}
      <Modal
        visible={bottomSheet}
        footer={null}
        onCancel={() => {
          setOpenBottomSheet(false);
        }}
        closeIcon={closeIcon}
        centered
      >
        <div className="offer--location-modal">
          <h2 style={{ fontWeight: "600" }}>Please select the location</h2>
          <GooglePlacesAutocomplete
            selectProps={{
              onChange: (value) => {
                if (
                  value.value === "myLocation" ||
                  value.label === "Current Location"
                ) {
                  fetchIpAddress();
                } else {
                  getCoordinates(value);
                }
              },
              styles: {
                input: (provided) => ({
                  ...provided,
                  borderColor: Theme.blue,
                }),
              },
              defaultOptions: [
                {
                  label: "Current Location",
                  value: "myLocation",
                },
              ],
              placeholder: "Location",
            }}
          />
        </div>
      </Modal>
      <Footer />
    </Layout>
  );
};
export default LandingPage;