/* eslint-disable no-mixed-operators */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import Resizer from "react-image-file-resizer";

import { useSelector, useDispatch } from "react-redux";
import {
  Typography,
  Input,
  Radio,
  Switch,
  Space,
  message,
  Form,
  Row,
  Popover,
  Col,
  Modal
} from "antd";
import Button from "antd-button-color";
import Actions from "../../../common/ActionsStrings";
import { decodeValidity, getFormattedDt, getCroppedImg } from "../../../common/util.js";
import { AiFillInfoCircle } from "react-icons/ai";
import { Uploader } from "../../../components";
import { Theme } from "../../../common";
import Crop from "../../../components/Crop.jsx";

const { UPSERT_OFFER } = Actions;

const { Text } = Typography;
const TAXCONTENT = (
  <div style={{ maxWidth: "40vw" }}>
    <ol>
      <li>
        <p>
          <span style={{ fontWeight: "bold" }}>Standard Tax -</span>
          Fyndr allows the Businesses to define the tax percentage for the
          offers/goods/services. If you choose Standard Tax , Fyndr will
          calculate the tax based on the services/goods/offers are rendered. The
          responsibility of defining the correct tax % is solely the Business’s
          responsibility
        </p>
      </li>
      <li>
        <p>
          <span style={{ fontWeight: "bold" }}>Custom Tax -</span>
          Businesses can define the Tax percentage for the offers, services and
          goods sold. The sole responsibility of collecting appropriate tax and
          reporting to the state and federal authorities lies with the business.
        </p>
      </li>
    </ol>
  </div>
);
const OfferEdit = (props) => {
  const { cmpnType } = props;

  const { offer, callback, isNew } = props;
  const { indv, endDate } = useSelector(({ auth }) => auth);
  const { collapsedCreateCampaign } = useSelector(({ settings }) => settings);
  const {
    cmpn
  } = useSelector(({ cmpn }) => cmpn);

  const titleRef = useRef(null);
  const discountRef = useRef(null);
  const couponRef = useRef(null);
  const perUserLimitRef = useRef(null);
  const retailPriceRef = useRef(null);
  const offerPriceRef = useRef(null);
  const offerLimitRef = useRef(null);
  const taxRef = useRef(null);
  const [taxError, setTaxError] = useState(false);
  const [enableDiscount, setEnableDiscount] = useState(false);
  const [fileType, setFileType] = useState("");
  const [isCrop, setIsCrop] = useState(false);
  const [modalVisible, setModalVisible] = useState(false)
  const [toggleState, setToggleState] = useState(
    offer?.offerLimit === 0 ||
      offer?.offerLimit == null ? true : false
  );
  const [payload, setPayload] = useState({
    title: offer?.title,
    amount: offer?.amount,
    discountType: (offer && offer.discountType) || "%",
    couponCode: offer?.couponCode,
    retailPrice: offer?.retailPrice,
    offerPrice: offer?.offerPrice,
    offersAvailable:
      offer?.offersAvailable == null ? null : Number(offer?.offersAvailable),
    offerLimit: offer?.offerLimit == null ? null : Number(offer?.offerLimit),
    offerSold: offer?.offerSold,
    img: offer?.img,
    thumbnail: offer?.thumbnail,
    imgUri: offer?.imgUri,
    thumbnailUri: offer?.thumbnailUri,
    currency: (offer && offer.currency) || indv.currency,
    currencySymbol: (offer && offer.currencySymbol) || indv.currencySymbol,
    status: offer && offer.status !== "active" ? false : true,
    offerType: cmpnType,
    usageLimit: offer && offer.usageLimit !== -1 ? offer.usageLimit : "",
    validPeriod: offer ? decodeValidity(offer.validityPeriod).period : "CMPN",
    validNo: offer ? decodeValidity(offer.validityPeriod).no : null,
    isUnlimited: offer && offer.usageLimit === -1 ? true : false,
    isBookingEnabled: offer?.isBookingEnabled,
    stdTax:
      offer && offer.stdTax !== null && offer.stdTax !== undefined
        ? offer.stdTax
        : true,
    taxPercent: offer?.taxPercent || null,
    displayOrder: offer?.displayOrder
  });
  const [errors] = useState({
    title: null,
    discount: null,
    offer: null,
    retail: null,
    offersAvailable: null,
    offerLimit: null,
  });
  const [amtValue, setAmtValue] = useState(null);

  const dispatch = useDispatch();

  const FormItem = Form.Item;

  useEffect(() => {
    setPayload({
      title: offer?.title,
      amount: offer?.amount,
      discountType: (offer && offer.discountType) || "%",
      couponCode: offer?.couponCode,
      retailPrice: offer?.retailPrice,
      offerPrice: offer?.offerPrice,
      offersAvailable:
        offer?.offersAvailable == null ? null : Number(offer?.offersAvailable),
      offerLimit: (offer?.offerLimit === null || offer?.offerLimit === undefined) ? null : offer?.offerLimit,
      offerSold: offer?.offerSold,
      img: offer?.img,
      thumbnail: offer?.thumbnail,
      imgUri: offer?.imgUri,
      thumbnailUri: offer?.thumbnailUri,
      currency: (offer && offer.currency) || indv.currency,
      currencySymbol: (offer && offer.currencySymbol) || indv.currencySymbol,
      status: offer && offer.status !== "active" ? false : true,
      offerType: cmpnType,
      usageLimit: offer && offer.usageLimit !== -1 ? offer.usageLimit : "",
      validPeriod: offer ? decodeValidity(offer.validityPeriod).period : "CMPN",
      validNo: offer ? decodeValidity(offer.validityPeriod).no : null,
      isUnlimited: offer && offer.usageLimit === -1 ? true : false,
      stdTax:
        offer && offer.stdTax !== null && offer.stdTax !== undefined
          ? offer.stdTax
          : true,
      taxPercent: offer?.taxPercent || null,
      isBookingEnabled: offer?.isBookingEnabled,
      displayOrder: offer?.displayOrder
    });
  }, [offer, indv.currency, indv.currencySymbol, cmpnType]);

  const [isActive, setIsActive] = useState(payload.status === "active");
  const [toggle, setToggle] = useState(false)

  useEffect(() => {
    setIsActive(payload.status);
    setToggle(payload.isBookingEnabled)
    setEnableDiscount(payload?.amount || amtValue ? true : false)
  }, [payload.status, payload.isBookingEnabled]);

  useEffect(() => {
    if (parseFloat(payload.retailPrice) > 0) {
      calcOfferPrice(
        parseFloat(payload.retailPrice),
        payload.amount,
        payload.discountType
      );
    }
  }, [
    payload.retailPrice,
    payload.amount,
    payload.offersAvailable,
    payload.offerLimit,
    payload.discountType,
  ]);

  useEffect(() => {
    if (!enableDiscount && cmpnType === "events") {
      setAttrib(
        "amount",
        0,
      );
    }
    if (payload?.amount !== null && cmpnType === "events") {
      setAttrib(
        "amount",
        payload?.amount,
      );
    }

  }, [])

  const setAttrib = async (key, value) => {
    if (key === "multi") {
      await setPayload({ ...payload, ...value });
    } else {
      await setPayload({ ...payload, [key]: value });
    }
    return null;
  };

  const calcOfferPrice = (rprice, amt, type) => {
    const oPrice =
      type === "%"
        ? Math.round(rprice * (1 - parseFloat(amt) / 100), 2)
        : rprice - parseFloat(amt);

    if (!isNaN(oPrice)) {
      setAttrib("offerPrice", oPrice);
    }
    if (oPrice < 0) message.error("Offer Price can't be Negative");
  };
  const save = async () => {

    const {
      amount,
      couponCode,
      currency,
      currencySymbol,
      discountType,
      isUnlimited,
      offerPrice,
      offersAvailable,
      offerLimit,
      offerSold,
      retailPrice,
      title,
      usageLimit,
      img,
      thumbnail,
      imgUri,
      thumbnailUri,
      validNo,
      validPeriod,
      displayOrder
    } = payload;
    if (title === undefined || title?.length < 1) {
      message.error("Please enter title for the offer");
      return;
    }

    if (cmpnType !== "events" && amount === undefined || amount?.length < 1) {
      message.error("Discount can not be empty");
      return;
    }
    if (cmpnType !== "events" && amount === undefined || amount < 0) {
      message.error("Discount can not be negative");
      return;
    }
    if (cmpnType === "events" && enableDiscount === true && amount === undefined || amount?.length < 1) {
      message.error("Discount can not be empty");
      return;
    }
    if (cmpnType === "events" && enableDiscount === true && amount === undefined || amount < 0) {
      message.error("Discount can not be negative");
      return;
    }
    if (!toggleState) {
      if ((offerLimitRef?.current?.input?.value === undefined || offerLimitRef?.current?.input?.value?.length < 1) && cmpnType === "offers") {
        message.error("offer limit can not be empty");
        return;
      }
    }
    if (offerLimit === 0 && collapsedCreateCampaign === "createCampaign") {
      message.error("offer limit can not be 0")
      return;
    }

    if (
      (retailPrice === undefined || retailPrice?.length < 1) &&
      cmpnType === "offers"
    ) {
      message.error("Retail Price can not be empty");
      return;
    }
    if ((payload.retailPrice === undefined || payload.retailPrice <= 0) && cmpnType === "offers") {
      message.error("Retail Price can not be zero or negative");
      return;
    }
    if ((offerLimitRef?.current?.input?.value === undefined || offerLimitRef?.current?.input?.value < 0) && cmpnType === "offers") {
      message.error("Offer limit can not be negative");
      return;
    }
    if (
      (couponCode === undefined || couponCode?.length < 1) &&
      cmpnType === "coupons"
    ) {
      message.error("Coupon Code  can not  be empty");
      return;
    }
    if (payload.stdTax !== true) {
      const value = taxRef?.current?.input?.value;
      if (value === undefined || value === null || value.length < 1) {
        setTaxError({
          status: true,
          message: "Please enter the tax percentage or enable standard tax",
        });
        return;
      }
      let regex = new RegExp(/^(?:100(?:\.0(?:0)?)?|\d{1,2}(?:\.\d{1,2})?)$/);

      if (!regex.test(value)) {
        setTaxError({
          status: true,
          message: "Tax percentage should be between 0 to 100",
        });
        return;
      }
      if (value > 100 || value < 0) {
        setTaxError({
          status: true,
          message: "Tax percentage should be between 0 to 100",
        });
        return;
      }
      setTaxError({ status: false, message: null });
    }
    if (
      isUnlimited === false &&
      usageLimit?.length < 1
    ) {
      message.error(
        "Please enter usage limit or please set PER USER LIMIT to unlimited "
      );
      return;
    } else if (usageLimit < 0) {
      message.error("usage limit can not be less than 0")
      return;
    }
    let offerObject = {
      objid: (offer && offer.objid) || new Date().getTime(),
      title,
      amount,
      discountType,
      couponCode: couponCode && couponCode.trim(),
      retailPrice,
      offerPrice,
      offersAvailable,
      offerLimit,
      offerSold,
      img,
      thumbnail,
      imgUri,
      thumbnailUri,
      currency,
      currencySymbol,
      status: isActive ? "active" : "inactive",
      isNew,
      offerType: cmpnType,
      usageLimit: isUnlimited ? -1 : usageLimit,
      validityPeriod:
        validPeriod === "CMPN" ? validPeriod : validNo + validPeriod,
      stdTax: payload.stdTax,
      taxPercent: taxRef?.current?.input?.value,
      isBookingEnabled: toggle ? true : false,
      displayOrder
    };
    await dispatch({ type: UPSERT_OFFER, payload: offerObject });
    callback();
  };

  const layout = {
    labelCol: {
      sm: 12,
      md: 7,
      lg: 5
    },
    wrapperCol: {
      span: 20,
    },
  };

  const tailLayout = {
    wrapperCol: {
      offset: 3,
      span: 20,
    },
  };

  const resize = (file, width, height) => {
    return new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        width,
        height,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });
  };

  const processImage = async (img, file) => {
    const thumbnail = await resize(file, 200, 200);
    setAttrib("multi", {
      img: img.substring(img.indexOf("base64,") + 7),
      thumbnail: thumbnail.substring(thumbnail.indexOf("base64,") + 7),
      imgUri: img,
      thumbnailUri: thumbnail,
    });
  };

  const saveCroppedImage = async (croppedAreaPixels,rotation) => {
    try {
      const croppedBase64 = await getCroppedImg(payload.imgUri, croppedAreaPixels, fileType,rotation);
      const imgBase64 = croppedBase64.substring(croppedBase64.indexOf('base64,') + 7);
      setAttrib("multi", {
        img: imgBase64,
        thumbnail: imgBase64,
        imgUri: croppedBase64,
        thumbnailUri: croppedBase64,
      });
      setIsCrop(false);
      setModalVisible(false);
    } catch (error) {
      console.error('Error saving cropped image:', error);
    }
  };

  return (
    <article className="article">
      <div className="row">
        <div className="col-md-12">
          <div className="box box-default">
            <div className="box-body">
              <Form {...layout}>
                <FormItem
                  className="offers-labels"
                  label="Title"
                  help={errors.title ? "Title is required" : null}
                  validateStatus={errors.title ? "error" : "success"}
                >
                  <Input
                    placeholder="Title"
                    ref={titleRef}
                    onBlur={(text) => {
                      let titleValue = titleRef?.current?.state?.value;
                      setAttrib(
                        "title",
                        titleValue ? titleValue : text.target.value
                      );
                    }}
                    defaultValue={payload.title}
                  />
                </FormItem>

                {cmpnType !== "offers" && cmpnType !== "events" && (<FormItem
                  className="offers-labels"
                  label="Discount"
                  help={errors.discount ? "Discount is required field" : null}
                  validateStatus={errors.discount ? "error" : "success"}
                >
                  <Space direction="horizontal">
                    <Input
                      defaultValue={payload.amount}
                      type="number"
                      placeholder="Discount"
                      ref={discountRef}
                      onBlur={(text) => {
                        let titleValue = discountRef?.current?.input?.value;
                        setAttrib(
                          "amount",
                          titleValue ? titleValue : text.target.value
                        );
                      }}
                    />

                    <Radio.Group
                      onChange={(e) => {
                        setAttrib("discountType", e.target.value);
                      }}
                      value={payload.discountType}
                    >
                      <Radio value="%">%</Radio>
                      <Radio value="flat">{payload.currencySymbol}</Radio>
                    </Radio.Group>
                  </Space>
                </FormItem>)}
                {cmpnType === "coupons" && (
                  <FormItem className="offers-labels" label="Coupon Code">
                    <Input
                      ref={couponRef}
                      onChange={(e) => {
                        setAttrib(
                          "couponCode",
                          e.target.value.replace(/[.\W]+/g, "").toUpperCase()
                        );
                      }}
                      placeholder={"Coupon Code"}
                      defaultValue={payload.couponCode}
                      value={payload.couponCode}
                    />
                  </FormItem>
                )}

                {cmpnType === "offers" && (
                  <>
                    <FormItem
                      className="offers-labels"
                      label={`Retail Price ${payload.currencySymbol}`}
                      help={
                        errors.retail ? "Retail price is required field" : null
                      }
                      validateStatus={errors.retail ? "error" : "success"}
                    >
                      <Input
                        type="number"
                        ref={retailPriceRef}
                        placeholder={"Retail Price"}
                        min={0}
                        defaultValue={payload.retailPrice}
                        onBlur={(text) => {
                          let titleValue =
                            retailPriceRef?.current?.state?.value;
                          setAttrib(
                            "retailPrice",
                            titleValue ? titleValue : text.target.value
                          );

                        }}
                      />
                    </FormItem>
                    <FormItem
                      className="offers-labels"
                      label="Discount"
                      help={errors.discount ? "Discount is required field" : null}
                      validateStatus={errors.discount ? "error" : "success"}
                    >
                      <Space direction="horizontal">
                        <Input
                          defaultValue={payload.amount}
                          type="number"
                          placeholder="Discount"
                          ref={discountRef}
                          onBlur={(text) => {
                            let titleValue = discountRef?.current?.input?.value;

                            setAttrib(
                              "amount",
                              titleValue ? titleValue : text.target.value
                            );
                          }}
                        />

                        <Radio.Group
                          onChange={(e) => {
                            setAttrib("discountType", e.target.value);
                          }}
                          value={payload.discountType}
                        >
                          <Radio value="%">%</Radio>
                          <Radio value="flat">{payload.currencySymbol}</Radio>
                        </Radio.Group>
                      </Space>
                    </FormItem>
                    <FormItem
                      className="offers-labels"
                      label={`Offer Price ${payload.currencySymbol}`}
                      help={
                        errors.offer
                          ? "Offer price should be more than zero"
                          : null
                      }
                      validateStatus={errors.offer ? "error" : "success"}
                    >
                      <Input
                        disabled
                        type="number"
                        ref={offerPriceRef}
                        onBlur={(e) => {
                          setAttrib(
                            "offerPrice",
                            offerPriceRef.current.state.value.toFixed(2)
                          );
                        }}
                        placeholder={"Offer Price"}
                        defaultValue={payload?.offerPrice?.toFixed(2)}
                        value={payload.offerPrice === undefined ? 0 : payload?.offerPrice?.toFixed(2)}
                      />
                    </FormItem>

                    
                    <FormItem
                      className="offers-labels"
                      label="Offer Limit"
                    >
                      <Row align="middle">
                        <Col span={8}>
                          <Input
                            type="number"
                            ref={offerLimitRef}
                            disabled={toggleState ? true : false}
                            placeholder={"Total offer"}
                            defaultValue={
                              payload?.offerLimit == null ? "" : payload?.offerLimit - payload?.offerSold
                            }
                            min={0}
                            onChange={(text) => {
                              if (text.target.value.length > 0) {
                                setToggleState(false)
                              } else {
                                setToggleState(true)
                              }
                              
                              setAttrib(
                                "offerLimit",
                                (Number(offerLimitRef?.current?.input?.value) + (offer?.offerSold ? Number(offer.offerSold) : 0))
                              );
                            }}
                          />
                        </Col>

                        <Switch
                          checkedChildren="Unlimited"
                          style={{ marginLeft: '0.5rem' }}

                          unCheckedChildren="Limited"
                          checked={toggleState}
                          onChange={(checked) => {
                            setToggleState(checked)

                            if (checked === true) {
                              setAttrib("offerLimit", null)
                            }
                          }}
                        />
                        <Col>
                          {!isNew && <Row justify="space">

                            <text style={{ textAlign: 'center', marginLeft: '0.5rem' }}>
                              {`Sold Offer: ${payload.offerSold === undefined ? 0 : offer.offerSold}`}
                            </text>
                            <text style={{ textAlign: "center", marginLeft: '1rem' }}>
                              {`Total offers: ${payload?.offerLimit === null || toggleState ? 'Unlimited' : payload?.offerLimit
                                }`}
                            </text>
                            {!toggleState && payload.offerLimit !== null && Number(payload.offerLimit) - Number(payload.offerSold) === 0 && <Col >
                              <Text type="danger" style={{ alignItems: "center", marginLeft: '0.5rem' }} strong>Offer Sold Out</Text>
                            </Col>}
                          </Row>}


                        </Col>
                      </Row>
                    </FormItem>
                    

                    <FormItem className="offers-labels" label="Standard Tax">
                      <Space>
                        <Switch
                          checked={payload.stdTax}
                          onChange={(value) => {
                            if (value) {
                              setTaxError({ status: false, message: null });
                            }
                            setPayload({ ...payload, stdTax: value });
                          }}
                        />
                        <Popover
                          placement="rightBottom"
                          title="Standard Tax"
                          content={TAXCONTENT}
                        >
                          <AiFillInfoCircle
                            style={{ color: Theme.blue, fontSize: "1.5rem" }}
                          />
                        </Popover>
                      </Space>
                    </FormItem>
                    <FormItem
                      className="offers-labels"
                      help={taxError.message}
                      validateStatus={taxError.status ? "error" : "success"}
                      label="Tax Percentage"
                    >
                      <Input
                        ref={taxRef}
                        placeholder={"Tax percentage"}
                        type="number"
                        disabled={payload.stdTax}
                        defaultValue={payload.taxPercent}
                        onChange={(e) => {
                          setPayload({ ...payload, taxPercent: e.target.value });

                        }}
                      />
                    </FormItem>
                  </>
                )}
                {cmpnType === "events" && (
                  <>
                    <FormItem
                      className="offers-labels"
                      label={`Retail Price ${payload.currencySymbol}`}
                      help={
                        errors.retail ? "Retail price is required field" : null
                      }
                      validateStatus={errors.retail ? "error" : "success"}
                    >
                      <Input
                        type="number"
                        ref={retailPriceRef}
                        placeholder={"Retail Price"}
                        min={0}
                        defaultValue={payload.retailPrice}
                        onChange={(text) => {
                          if (!enableDiscount) {
                            setAttrib(
                              "offerPrice",
                              retailPriceRef?.current?.input?.value
                            );
                          }

                        }}
                        onBlur={(text) => {
                          let titleValue =
                            retailPriceRef?.current?.input?.value;
                          setAttrib(
                            "retailPrice",
                            titleValue ? titleValue : text.target.value
                          );

                        }}
                      />
                    </FormItem>
                    <FormItem
                      className="offers-labels"
                      label="Discount"
                    >
                      <Switch
                        checkedChildren="Yes"
                        style={{ marginLeft: '0.5rem' }}

                        unCheckedChildren="No"
                        checked={enableDiscount}
                        onChange={(checked) => {
                          setEnableDiscount(checked)
                          if (!checked) {
                            setAttrib(
                              "offerPrice",
                              retailPriceRef?.current?.input?.value
                            );
                            setAttrib(
                              "amount",
                              0,
                            );

                          } else {

                            calcOfferPrice(
                              parseFloat(payload.retailPrice),
                              payload.amount,
                              payload.discountType
                            );
                          }


                        }}
                      />
                    </FormItem>
                    {enableDiscount && <FormItem
                      className="offers-labels"
                      label="Discount"
                      help={errors.discount ? "Discount is required field" : null}
                      validateStatus={errors.discount ? "error" : "success"}
                    >
                      <Space direction="horizontal">
                        <Input
                          defaultValue={payload.amount || amtValue}
                          type="number"
                          placeholder="Discount"
                          ref={discountRef}
                          onBlur={(text) => {
                            setAmtValue(discountRef?.current?.input?.value)

                            setAttrib(
                              "amount",
                              text?.target?.value
                            );



                          }}
                        />

                        <Radio.Group
                          onChange={(e) => {
                            setAttrib("discountType", e.target.value);
                          }}
                          value={payload.discountType}
                        >
                          <Radio value="%">%</Radio>
                          <Radio value="flat">{payload.currencySymbol}</Radio>
                        </Radio.Group>
                      </Space>
                    </FormItem>}
                    <FormItem
                      className="offers-labels"
                      label={`Offer Price ${payload.currencySymbol}`}
                      help={
                        errors.offer
                          ? "Offer price should be more than zero"
                          : null
                      }
                      validateStatus={errors.offer ? "error" : "success"}
                    >
                      <Input
                        disabled
                        type="number"
                        ref={offerPriceRef}
                        onBlur={(e) => {
                          setAttrib(
                            "offerPrice",
                            offerPriceRef.current.state.value.toFixed(2)
                          );
                        }}
                        placeholder={"Offer Price"}
                        defaultValue={parseFloat(payload?.offerPrice)?.toFixed(2)}
                        value={payload.offerPrice === undefined ? 0 : parseFloat(payload?.offerPrice)?.toFixed(2)}
                      />
                    </FormItem>

                    <FormItem
                      className="offers-labels"
                      label="Offer Limit"
                    >
                      <Row align="middle">
                        <Col span={8}>
                          <Input
                            type="number"
                            ref={offerLimitRef}
                            disabled={toggleState ? true : false}
                            placeholder={"Total offer"}
                            defaultValue={
                              payload?.offerLimit == null ? "" : payload?.offerLimit - payload?.offerSold
                            }
                            min={0}
                            onChange={(text) => {
                              if (text.target.value.length > 0) {
                                setToggleState(false)
                              } else {
                                setToggleState(true)
                              }
                             
                              setAttrib(
                                "offerLimit",
                                (Number(offerLimitRef?.current?.input?.value) + (offer?.offerSold ? Number(offer.offerSold) : 0))
                              );
                            }}
                          />
                        </Col>

                        <Switch
                          checkedChildren="Unlimited"
                          style={{ marginLeft: '0.5rem' }}

                          unCheckedChildren="Limited"
                          checked={toggleState}
                          onChange={(checked) => {
                            setToggleState(checked)

                            if (checked === true) {
                              setAttrib("offerLimit", null)
                            }
                          }}
                        />
                        <Col>
                          {!isNew && <Row justify="space">

                            <text style={{ textAlign: 'center', marginLeft: '0.5rem' }}>
                              {`Sold Offer: ${payload.offerSold === undefined ? 0 : offer.offerSold}`}
                            </text>
                            <text style={{ textAlign: "center", marginLeft: '1rem' }}>
                              {`Total offers: ${payload?.offerLimit === null || toggleState ? 'Unlimited' : payload?.offerLimit
                                }`}
                            </text>
                            {!toggleState && payload.offerLimit !== null && Number(payload.offerLimit) - Number(payload.offerSold) === 0 && <Col >
                              <Text type="danger" style={{ alignItems: "center", marginLeft: '0.5rem' }} strong>Offer Sold Out</Text>
                            </Col>}
                          </Row>}


                        </Col>
                      </Row>
                    </FormItem>
                  
                    <FormItem className="offers-labels" label="Standard Tax">
                      <Space>
                        <Switch
                          checked={payload.stdTax}
                          onChange={(value) => {
                            if (value) {
                              setTaxError({ status: false, message: null });

                            }
                            setPayload({ ...payload, stdTax: value });
                          }}
                        />
                        <Popover
                          placement="rightBottom"
                          title="Standard Tax"
                          content={TAXCONTENT}
                        >
                          <AiFillInfoCircle
                            style={{ color: Theme.blue, fontSize: "1.5rem" }}
                          />
                        </Popover>
                      </Space>
                    </FormItem>
                    <FormItem
                      className="offers-labels"
                      help={taxError.message}
                      validateStatus={taxError.status ? "error" : "success"}
                      label="Tax Percentage"
                    >
                      <Input
                        ref={taxRef}
                        placeholder={"Tax percentage"}
                        type="number"
                        disabled={payload.stdTax}
                        defaultValue={payload.taxPercent}
                        onChange={(e) => {
                          setPayload({ ...payload, taxPercent: e.target.value });

                        }}
                      />
                    </FormItem>
                  </>
                )}
                <FormItem
                  className="offers-labels"
                  label="Per User Limit"
                >
                  <Space type="horizontal">
                    <Input
                      type="number"
                      placeholder="Per User Limit"
                      disabled={payload.isUnlimited ? true : false}
                      ref={perUserLimitRef}
                      value={
                        payload.usageLimit >= 0 ? payload.usageLimit : null
                      }
                      min={0}
                      onChange={(e) => {
                        setAttrib("multi", {
                          usageLimit: e.target.value,
                          isUnlimited: e.target.value.length ? false : true,
                        });
                      }}
                    />

                    <Switch
                      checkedChildren="Unlimited"
                      unCheckedChildren="Limited"
                      checked={payload.isUnlimited}
                      onChange={(checked) => {
                        if (checked) {
                          setAttrib("multi", {
                            usageLimit: "null",
                            isUnlimited: checked,
                          });
                        } else {
                          setAttrib("multi", {
                            usageLimit: payload.usageLimit,
                            isUnlimited: false,
                          });
                        }
                      }}
                    />
                  </Space>
                </FormItem>

                {cmpnType === "events" && (
                  <FormItem {...tailLayout}>
                    <Text>Offer validity period (from date of purchase)</Text>
                    <Space direction="horizontal">

                      {cmpnType !== "events" && <Input
                        disabled={payload.validPeriod === "CMPN" ? true : false}
                        value={payload.validNo}
                        onChange={(e) => setAttrib("validNo", e.target.value)}
                        placeholder="#"
                        style={{ width: "70px", height: "40px" }}
                      />
                      }
                      <Radio.Group
                        value={payload.validPeriod}
                        size="large"
                        onChange={(e) => {
                          if (e.target.value === "CMPN") {
                            setAttrib("validNo", null);
                          }
                          setAttrib("validPeriod", e.target.value);

                        }}
                      >
                        {cmpnType !== "events" &&
                          <>
                            <Radio.Button value="D">Days</Radio.Button>
                            <Radio.Button value="W">Weeks</Radio.Button>
                            <Radio.Button value="M">Months</Radio.Button>
                          </>
                        }
                        {cmpnType === "events" &&
                          <Radio.Button value="CMPN">
                            {` Valid until ${getFormattedDt(endDate || cmpn?.endDt)}`}
                          </Radio.Button>
                        }
                      </Radio.Group>
                    </Space>
                  </FormItem>
                )}
                {cmpnType === "offers" && (
                  <FormItem {...tailLayout}>
                    <Text>Offer validity period (from date of purchase)</Text>
                    <Space direction="horizontal">

                      <Input
                        disabled={payload.validPeriod === "CMPN" ? true : false}
                        value={payload.validNo}
                        onChange={(e) => setAttrib("validNo", e.target.value)}
                        placeholder="#"
                        style={{ width: "70px", height: "40px" }}
                      />

                      <Radio.Group
                        value={payload.validPeriod}
                        size="large"
                        onChange={(e) => {
                          if (e.target.value === "CMPN") {
                            setAttrib("validNo", null);
                          }
                          setAttrib("validPeriod", e.target.value);
                        }}
                      >

                        <Radio.Button value="D">Days</Radio.Button>
                        <Radio.Button value="W">Weeks</Radio.Button>
                        <Radio.Button value="M">Months</Radio.Button>
                        <Radio.Button value="CMPN">
                          {` Valid until ${getFormattedDt(endDate || cmpn?.endDt)}`}
                        </Radio.Button>

                      </Radio.Group>
                    </Space>
                  </FormItem>
                )}
                <FormItem {...tailLayout}>
                  <Space>
                 {payload.imgUri &&
                    <div style={{ position: "relative", display: "inline-block" }}>
                      <img
                        alt=""
                        src={payload.imgUri}
                        style={{
                          width: "200px",
                          objectFit: "contain",
                          maxHeight: "100px",
                          marginRight: "20px",
                        }}
                      />
                      <i
                        onClick={() => {
                          setIsCrop(true);
                          setModalVisible(true)

                        }}
                        style={{
                          position: "absolute",
                          top: "-5px",
                          right: "20px",
                          background: Theme.blue,
                          height: "1.8rem",
                          width: "1.8rem",
                          borderRadius: "50%",
                          color: "white",
                          cursor: "pointer",
                          textAlign: "center",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        className="fa fa-crop"
                        aria-hidden="true"
                      />
                    </div>
}
                    <Uploader
                      allowedTypes={["image"]}
                      imgUrl={payload.imgUri}
                      aspect={2 / 1}
                      callback={({ img, file, type }) => {
                        setFileType(type);
                        processImage(img, file)
                      }}
                    />
                  </Space>
                </FormItem>

                <Row
                  style={{ width: "100%" }}
                  gutter={20}
                  align="middle"
                  justify="end"
                >
                  <Col>
                    {!isNew && (
                      <Switch
                        checkedChildren="Active"
                        unCheckedChildren="Inactive"
                        checked={isActive}
                        onChange={(checked) => {
                          setIsActive(checked);
                        }}
                      />
                    )}
                  </Col>
                  {cmpnType === "offers" && (
                    <Col>
                      <Text>Business Appointment: </Text>
                      <Switch
                        checkedChildren="Active"
                        unCheckedChildren="Inactive"
                        checked={toggle}
                        onChange={(checked) => {
                          setToggle(checked);
                        }}
                      />
                    </Col>
                  )
                  }
                  <Col>
                    <Button size="large" type="primary" onClick={() => {
                      save();
                    }}>
                      {" "}
                      Save{" "}
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      size="large"
                      type="warning"
                      onClick={() => {
                        callback();
                      }}
                    >
                      Cancel
                    </Button>
                  </Col>
                </Row>

                <Modal
                  visible={modalVisible}
                  onCancel={() => {
                    setModalVisible(false);
                    setIsCrop(false)
                  }
                  }
                  onOk={() => setModalVisible(false)}
                  footer={isCrop && null}
                  width="40%"
                  title="Crop Image"

                >
                  <Crop
                    src={payload.imgUri}
                    handleSaveClick={(croppedAreaPixels,rotation) => saveCroppedImage(croppedAreaPixels,rotation)}
                  />
                </Modal>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </article >
  );
};

export default OfferEdit;

