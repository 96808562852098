import React from 'react';
import BizInfo from './BizInfo';
import { Row, Col, Image, Typography } from 'antd';
import gift from "../assets/images/giftBox.svg";
import Globe from '../assets/images/offer-globe.svg';
import Loc from "../assets/images/invoiceloc.svg"
import {
  GlobalOutlined,
} from "@ant-design/icons";

const { Text } = Typography;

const BizCard = (props) => {
  const { businessLogo, businessAddress, businessName, businessWebsite, businessAddonUrl, reviewRatings, channel, gifteeDetails, invoiceID } = props;
  return (

    <Row justify={"space-between"}>
      <Col span={16} >
        <Row gutter={[12]}>
          {businessLogo &&
            <Col span={11}>
              <Image src={businessLogo} alt='businessLogo' loading='lazy' preview={false} style={{borderRadius: "10px" ,aspectRatio:"2/1"}} height={75}/>
            </Col>
          }
          <Col span={businessLogo ? 13 : 14} >
            <Text className='invoice_text' style={{ fontSize: "16px" }}>{businessName}</Text>
            {channel !== "cmpn_promo" &&
              <Row justify={"end"}>{reviewRatings}</Row>
            }
          </Col>
        </Row>
        {(channel !== "cmpn_promo" && channel !== "promo") &&
          <>
            <Row>
              {businessWebsite && (
                <BizInfo link={businessWebsite} icon={<img src={Globe} className="act" alt='Globe' />} />
              )}
            </Row>
            {businessAddonUrl &&
              <Row>
                <BizInfo link={businessAddonUrl} icon={<GlobalOutlined className="act" />} />
              </Row>
            }
            <Row>
              {businessAddress &&
                <BizInfo label={businessAddress} icon={<img src={Loc} className="act" alt='Loc'/>} />
              }
            </Row>
          </>
        }
      </Col >

      <Col span={8} style={{ alignSelf: "start", textAlign: "end" }}>
        <Row justify={"end"}>
          <Col span={24}>
            <Text className='invoice_text' style={{ color: "#666666" }}>Invoice ID:{invoiceID}</Text>
          </Col>
        </Row>
        <Row justify={"end"}>
          <Col span={24}>
            {(channel === "offers" || channel === "offer_appointment") && gifteeDetails !== null &&
              <Image alt='gift' src={gift} width={"101px"} height={"101px"} style={{ objectFit: 'contain' }} preview={false}/>
            }

          </Col>
        </Row>
      </Col >
    </Row >
  )
}

export default BizCard