import React from "react";
import { Row, Col } from "antd";

import PropTypes from "prop-types";
import { isMobile } from "../common/util";
const ContainerWrapper = (props) => {
  return (
    <div className="container-fluid no-breadcrumb container-mw-xl chapter" style={{minHeight:"30rem" ,
      paddingLeft: isMobile() ? "10px" : "30px",
      paddingRight: isMobile() ? "10px" : "30px",
      marginLeft: "auto",
      marginRight: "auto",
     }} >
      <article className="article">
        <Row justify={"space-between"}
          style={props.headerOption && {
            marginBottom: "2rem",
            height:isMobile() ? "45px" : "32px",
          }}>
          <Col style={{ alignSelf: "center" }}>
            <h2
              className="article-title-container"
              style={{ marginBottom: !props.headerOption && "2rem" }}
            >
              {props.title}
            </h2></Col>
          {props.headerOption && (
            <Col>
              {props.headerOption}
            </Col>
          )}
        </Row>

        <div className="row" >
          <div className="col-md-12">
            <div className="box box-default" >
              <div className={!props.featured && "box-body"}>{props.children}</div>
            </div>
          </div>
        </div>
      </article>
    </div >
  );
};
ContainerWrapper.propTypes = {
  title: PropTypes.string,
  headerOption: PropTypes.node,
  children: PropTypes.any,
};
export default ContainerWrapper;
