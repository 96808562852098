/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo } from "react";
import {
  Breadcrumb,
  Button,
  Col,
  Divider,
  Input,
  Row,
  Table,
  Modal,
  Form,
  message,
  Typography,
} from "antd";
import { useLocation } from "react-router-dom";
import { MINIMUM_SEARCH_LIMIT } from "../../../common/config";
import { SearchOutlined } from "@ant-design/icons";
import DropdownComponent from "../../../components/Dashboard/DropdownComponent";
import closeBtn from "../../../assets/images/closeBtn.svg";
import PulseLoader from "react-spinners/PulseLoader";
import countryList from "react-select-country-list";
import { useSelector, useDispatch } from "react-redux";
import "react-phone-input-2/lib/bootstrap.css";
import { City, State } from "country-state-city";
import actions from "../../../actions";
import AddUserModalContent from "../../../components/AddUserEntityModal/AddUserModalContent";
import { Actions } from "../../../common";
import { encryptPassword } from "../../../utils/Helper";
const { SUPER_ADMIN } = Actions;

export default function UserManagement(props) {
  const location = useLocation();
  // eslint-disable-next-line no-unused-vars
  const { state: { userType } = {} } = location;
  const [country, setCountry] = useState([]);
  const [mode, setMode] = useState("");
  const [, setSelectedCountry] = useState([]);
  const [countryCode, setCountryCode] = useState([]);
  const [visible, setModalVisible] = useState(false);
  const [updateVisible, setUpdateVisible] = useState(false);
  const [deleteVisible, setDeleteVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState([]);
  const [, setSelectedState] = useState([]);
  const [roleDropdown, setRoleDropdown] = useState([]);
  const [role, setRole] = useState([]);
  const [city, setCity] = useState([]);
  const [data, setData] = useState([]);
  const [errorMessage] = useState();
  const [text, setText] = useState("");
  const [userRole, setUserRole] = useState([]);
  const [deleteUserId, setDeleteUserId] = useState(null);
  const [updatePassErr, setUpdatePassErr] = useState(null)
  const [updatePass, setUpdatePass] = useState("");
  const [email, setEmail] = useState("");
  const [objId, setObjId] = useState("");
  const [updatePassVisible, setUpdatePassVisible] = useState(false);

  const { Text } = Typography;

  const {
    indv: { entityRole },
  } = useSelector(({ auth }) => auth);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    sortOrder: "ascend",
  });
  const [form] = Form.useForm();
  // eslint-disable-next-line no-unused-vars
  const options = useMemo(() => countryList().getData(), []);
  const dispatch = useDispatch();

  const closeIcon = <img src={closeBtn} alt="closeBtn" />;

  const handleSearch = (value) => {
    setText(value);
    if (value.length >= MINIMUM_SEARCH_LIMIT) {
      AdminSearchFunction(value);
    } else if (value.length === 0) {
      AdminSearchFunction("");
    }

    setPagination({
      ...pagination,
      current: 1,
    });
  };

  const UpdatePassFunction = async () => {
    setLoading(true);
    const pwd = Array.from(await encryptPassword(email, updatePass));
    let passValue = `${btoa(updatePass)}`
    localStorage.setItem("base64Pass", passValue);
    let payload = {
      pwd: pwd,
      objId: objId
    };
    let response = await actions.AdminUpdateUserPass(payload);


    if (response?.success === true) {
      setLoading(false);
      setUpdatePassVisible(false)
      message.success(response.message)
      localStorage.setItem("base64Pass", null);
    } else {
      setLoading(false);
      message.error(response.msg)
      localStorage.setItem("base64Pass", null);

    }
  }

  const AdminSearchFunction = async (text) => {
    setLoading(true);
    if (entityRole === SUPER_ADMIN) {
      dispatch({ type: SUPER_ADMIN, payload: entityRole });
    }
    const pgstart = text.pgstart || pagination.current;
    const userRolesArray = userRole ? userRole : [];
    const payload = {
      userRoles: userRolesArray,
      text: text,
      pgstart,
      pgSize: pagination.pageSize,
    };

    let response = await actions.AdminUserManagement(payload);

    setPagination({
      ...pagination,
      total: response?.count,
    });

    if (response?.success === true) {
      setLoading(false);
      setData(response?.users);
    } else {
      setLoading(false);
    }
  };
  const getRoleName = (value) => {
    switch (value) {
      case "SUPER_ADMIN":
        return "Super Admin"
      case "FYNDR_MANAGER":
        return "Manager"

      case "FYNDR_SUPPORT":
        return "Support"
      default:
        break;
    }
  }
  const AdminRolesEntity = async () => {
    let response = await actions.AdminUserEntity();
    if (response?.success === true) {
      const newArrayOfObj = response?.resp?.map(({
        roles: value,
        ...rest
      }) => ({
        value,
        ...rest
      }));
      let res = newArrayOfObj.map(v => Object.assign(v, { label: getRoleName(v.value) }))
      setRoleDropdown(res)
    } else {
      setLoading(false);
    }
  };

  const DeleteUserFunction = async (userId) => {
    let response = await actions.DeleteUser(userId);

    if (response?.success === true) {
      message.success(response?.msg);
      setDeleteVisible(false);
      AdminSearchFunction(text);
    } else {
      message.error(response?.msg);
      setDeleteVisible(false);
    }
  };
  useEffect(() => {
    AdminRolesEntity()
  }, [])
  useEffect(() => {
    AdminSearchFunction(text);
  }, [userRole, pagination.current, pagination.total, pagination.pageSize]);

  const handleMenuClick = (action, record) => {
    if (action === "update") {
      setFormValues({
        firstName: record.firstName,
        lastName: record.lastName,
        country: record.country,
        postalCode: record.postalCode,
        countryCode: record.countryCode,
        phone: record.phone,
        address: record.address1,
        street: record.address2,
        email: record.userEmail,
        state: record.state,
        city: record.city,
        password: "",
        role: record.userRole,
        objId: record.userId
      });
      setUpdateVisible(true);
    } else if (action === "delete") {
      setDeleteVisible(true);
      setDeleteUserId(record.userId);
    }
  };

  const tableColumn = [
    {
      title: "Name",
      align: "left",
      render: (data, key) => `${data?.firstName} ${data?.lastName}`,
    },
    {
      title: "Role",
      align: "left",
      render: (data, key) => {
        return (
          <p>
            {data?.userRole === "SUPER_ADMIN"
              ? "Super Admin"
              : data?.userRole === "FYNDR_MANAGER"
                ? "Manager"
                : data?.userRole === "FYNDR_SUPPORT"
                  ? "Support"
                  : ""}
          </p>
        );
      },
    },
    {
      title: "Email",
      dataIndex: "userEmail",
      key: "userEmail",
      align: "left",
      width: 260,
    },

    {
      title: "Address",
      align: "left",
      render: (data, key) => {
        const address1 = data?.address1 || "";
        const address2 = data?.address2 || "";
        const city = data?.city || "";

        const addressComponents = [address1, address2, city].filter(
          Boolean
        );

        const formattedAddress = addressComponents.join(", ");

        return <p>{formattedAddress}</p>;
      },
    },

    {
      title: "Phone",
      width: 140,
      align: "center",

      render: (data, key) => {
        return (
          <p>
            {data?.countryCode && data?.phone ? `${data.countryCode}` : ""}
            {data?.phone ? data.phone : "-"}
          </p>
        );
      },
    },
    {
      title: "Actions",
      key: "action",
      align: "left",
      render: (data, key) => (
        <Col>
          <Col>
            <Button type="link" onClick={(e) => {
              e.preventDefault();
              handleMenuClick("update", data)
              setUpdateVisible(true);
              setMode("update");
            }}>
              Update
            </Button>
          </Col>
          <Col>
            <Button type="link" onClick={(e) => {
              e.preventDefault();
              setObjId(data?.userId)
              setEmail(data?.userEmail)
              setUpdatePassVisible(true);
            }

            }>
              Update Password
            </Button>
          </Col>
          {data.userRole !== "SUPER_ADMIN" && <Col>
            <Button style={{ color: 'red' }} type="link" onClick={(e) => {
              e.preventDefault();
              handleMenuClick("delete", data);
            }}>
              Delete
            </Button>
          </Col>}

        </Col>
      ),
    },
  ];

  const handleTableChange = (pagination) => {
    setPagination({
      ...pagination,
    });
  };

  const [formValues, setFormValues] = useState({
    firstName: "",
    countryCode: "",
    country: "",
    address: "",
    city: "",
    password: "",
    lastName: "",
    phone: "",
    postalCode: "",
    street: "",
    email: "",
    state: "",
    role: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };



  const handleCountryChange = (selectedCountry) => {
    setCountry(selectedCountry.value);
    setSelectedCountry(selectedCountry.label);

    const statesData = State.getStatesOfCountry(selectedCountry.value);
    setFormValues({
      ...formValues,
      country: selectedCountry.label,
      state: "",
      city: "",
    });
    setState(statesData);
    setCity([]);
  };

  const handlePostalCodeChange = (e) => {
    setFormValues({ ...formValues, postalCode: e.target.value });
    setFormValues({ ...formValues, city: "" });
    setFormValues({ ...formValues, state: "" });

  }

  const handleStateChange = (selectState) => {
    setSelectedState(selectState.name);
    const citiesData = City.getCitiesOfState(country, selectState.isoCode);

    setFormValues({
      ...formValues,
      state: selectState.name,
      city: "",
    });
    setCity(citiesData);
  };

  const handleCityChange = (selectCity) => {
    setCity([selectCity]);
    setFormValues({
      ...formValues,
      city: selectCity,
    });
  };

  const handleRoleChange = (selectRole) => {
    setRole([selectRole]);
    setFormValues({
      ...formValues,
      role: selectRole,
    });
  };

  return (
    <div className="admin_container no-breadcrumb container-mw-xl chapter">
      <div className="quickLoader">
        <PulseLoader color={"#3F9AF7"} loading={loading} size={10} />
      </div>
      <article className="article">
        <Row justify="space-between">
          <Col>
            <Breadcrumb>
              <Breadcrumb.Item>Admin</Breadcrumb.Item>
              <Breadcrumb.Item>Management</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <h2
          className="article-title"
          style={{
            marginBottom: "-2rem",
            marginTop: "-2rem",
            paddingTop: "3rem",
            paddingBottom: "2rem",
          }}
        >
          Management
        </h2>
        <Divider />
        <div style={{ marginBottom: "2rem" }}>
          <Row style={{ marginTop: "1rem" }}>
            <Col style={{ width: "100%" }}>
              <Row style={{ width: "100%" }} gutter={[0, 10]}>
                <Col style={{ width: "30%" }}>
                  <Input
                    placeholder="Search"
                    type="search"
                    suffix={<SearchOutlined />}
                    style={{
                      height: "36px",
                      borderRadius: "5px",
                      border: "1px solid #D3D6E1",
                    }}
                    onPressEnter={(e) => {
                      setPagination({
                        ...pagination,
                        current: 1,
                      });
                      handleSearch(e.target.value);
                    }}
                  />
                </Col>
                <Col style={{ width: "30%", paddingLeft: "12px" }}>
                  <DropdownComponent
                    onChange={(e) => {
                      setPagination({
                        ...pagination,
                        current: 1,
                      });
                      setUserRole(e);
                    }}
                    newclassnmae={"roleDropDown"}
                    placeholder="Role"
                    value={userRole}
                    options={roleDropdown}
                    style={{
                      minWidth: "100%",
                      borderRadius: "5px",
                      border: "1px solid #D3D6E1",
                    }}
                    mode="multiple"
                  />
                </Col>
                <Col style={{ width: "40%" }} align={"right"}>
                  <Button
                    type="primary"
                    style={{
                      backgroundColor: "#257CDB",
                      height: "auto",
                      fontSize: "1.125rem",
                      borderRadius: "5px",
                      padding: "10px 20px",
                    }}
                    onClick={() => {
                      setModalVisible(true);
                      setFormValues({
                        firstName: "",
                        countryCode: "",
                        address: "",
                        password: "",
                        lastName: "",
                        phone: "",
                        postalCode: "",
                        street: "",
                        email: "",
                      });
                    }}
                  >
                    Add user
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div>
          <Table
            className="admin-table"
            bordered
            pagination={pagination}
            columns={tableColumn}
            dataSource={data}
            size="medium"
            onChange={handleTableChange}
          />
        </div>
      </article>
      <Modal
        destroyOnClose={true}
        title="Add User"
        centered
        visible={visible}
        closeIcon={closeIcon}
        width={"700px"}
        className="allReviewComments"
        footer={false}
        onCancel={() => {
          setModalVisible(false);
          form.resetFields();
        }}
      >
        <div className="modal-div">
          <Row style={{ width: "100%" }}>
            <AddUserModalContent
              formValues={formValues}
              setFormValues={setFormValues}
              handleInputChange={handleInputChange}
              handleCountryChange={handleCountryChange}
              handleStateChange={handleStateChange}
              handleCityChange={handleCityChange}
              handleRoleChange={handleRoleChange}
              country={country}
              countryCode={countryCode}
              state={state}
              city={city}
              roleDropdown={roleDropdown}
              setCountryCode={setCountryCode}
              role={role}
              closeModal={(e) => {
                if (e) {
                  setModalVisible(false)
                  AdminSearchFunction(text);
                }


              }

              }
            />

          </Row>
        </div>
      </Modal>
      <Modal
        destroyOnClose={true}
        title="Update"
        centered
        visible={updateVisible}
        closeIcon={closeIcon}
        width={"700px"}
        className="allReviewComments"
        footer={false}
        onCancel={() => {
          setUpdateVisible(false);
          form.resetFields();
        }}

      >
        <div className="modal-div">
          <Row style={{ width: "100%" }}>
            <AddUserModalContent
              mode={mode}
              formValues={formValues}
              setFormValues={setFormValues}
              handleInputChange={handleInputChange}
              handlePostalCodeChange={handlePostalCodeChange}
              handleCountryChange={handleCountryChange}
              handleStateChange={handleStateChange}
              handleCityChange={handleCityChange}
              handleRoleChange={handleRoleChange}
              country={country}
              countryCode={countryCode}
              state={state}
              city={city}
              roleDropdown={roleDropdown}
              setCountryCode={setCountryCode}
              role={role}
              closeModal={(e) => {
                if (e) {
                  setUpdateVisible(false)
                  AdminSearchFunction(text);
                }

              }}
            />
            <Row justify={"center"} align={"middle"} style={{ width: "100%" }}>
              {errorMessage && (
                <p style={{ color: "red", width: "100%", textAlign: "center" }}>
                  {errorMessage}
                </p>
              )}
            </Row>
          </Row>
        </div>
      </Modal>
      <Modal
        title="Delete"
        centered
        width={400}
        closeIcon={closeIcon}
        visible={deleteVisible}
        className="allReviewComments"
        footer={[
          <div>
            <Button
              key="light"
              type="primary"
              onClick={() => setDeleteVisible(false)}
              style={{
                border: "1px solid #257CDB",
                background: "#fff",
                color: "#257CDB",
                height: "auto",
                fontSize: "1.125rem",
                borderRadius: "5px",
                padding: "10px 20px",
              }}
            >
              No
            </Button>
            <Button
              key="danger"
              type="primary"
              onClick={() => {
                DeleteUserFunction(deleteUserId);
              }}
              style={{
                backgroundColor: "#ED0C10",
                border: "none",
                color: "#fff",
                height: "auto",
                fontSize: "1.125rem",
                borderRadius: "5px",
                padding: "10px 20px",
              }}
            >
              Yes
            </Button>
          </div>,
        ]}
        onCancel={() => setDeleteVisible(false)}
      >
        <div
          className="modal-div"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "24px",
            color: "#4E5C87",
            fontSize: "20px",
            lineHeight: "125%",
            fontWeight: "400",
            height: "25vh",
            textAlign: "center",
          }}
        >
          Are you sure you want to delete this user?
        </div>
      </Modal>

      <Modal
        destroyOnClose={true}
        title="Update Password"
        centered
        visible={updatePassVisible}
        closeIcon={closeIcon}
        width={"400px"}
        className="allReviewComments"
        onCancel={() => {
          setUpdatePassVisible(false);
          setUpdatePassErr(null)
        }}
        onOk={() => {
          if (updatePass.length > 0) {
            UpdatePassFunction()
          } else {
            setUpdatePassErr("please enter password")
          }

        }}
      >
        <Col style={{ height: "10rem", display: 'flex' }} className="user_management_form">
          <Col style={{ alignSelf: 'center' }} span={24}>
            <Input.Password
              size="large"
              placeholder="Password"
              className="user_form_input"
              type="password"
              onChange={(e) => {
                setUpdatePass(e.target.value)
                setUpdatePassErr(null)
              }}
            />
            {updatePassErr &&
              <Text type="danger">{updatePassErr}</Text>

            }

          </Col>

        </Col>


      </Modal>
    </div>
  );
}
