import React from 'react'
import { Theme } from '../../common'
import TextArea from "antd/lib/input/TextArea";
import DropdownComponent from '../Dashboard/DropdownComponent'
import { Modal,Row,Col,Button,Space,Input,Popover,Checkbox,Divider } from 'antd'
export default function RefundModal(props) {
    return (
      <div>
        <Modal
          title="Refund Payment"
          centered
          closable={false}
          destroyOnClose
          footer={null}
          visible={props.visible}
          className="admin-modal"
        >
          <div className="modal-div">
            <Row
              justify={"center"}
              style={{ color: "#595959", fontSize: "3 rem" }}
            >
              <Row>Refunds take 5-10 days to appear on a customer’s</Row>
              <Row>
                statement. Strip’s fees for the original payment won’t be
              </Row>
              <Row>
                {" "}
                returned, but there are no additional fees for the refund.
              </Row>
            </Row>
            <Row justify={"center"}>
              <Button type="link">Learn more.</Button>
            </Row>
            <Divider />
            <Row justify={"space-evenly"}>
              <Col>
                <h5 style={{fontSize: "100%", lineHeight: '1.2'}}>Refund</h5>
              </Col>
              <Col style={{ width: "60%" }}>
                <Row>
                  <Space>
                    <Input
                      type="number"
                      style={{ borderRadius: ".5rem" }}
                      defaultValue={props.actualAmount}
                      suffix="USD"
                      onChange={props.handleInputChange}
                    ></Input>
                    <Popover title="">
                      <i
                        style={{ color: Theme.blue }}
                        className="fa fa-info-circle"
                        aria-hidden="true"
                      />
                    </Popover>
                  </Space>
                </Row>
                <br />
                <Row>
                  <Space>
                    <Checkbox
                      value="Reverse the associated transfer"
                      checked={props.isChecked}
                      onChange={props.handleOnChange}
                    >
                      {" "}
                      Reverse the associated transfer
                    </Checkbox>
                    <Popover title="">
                      <i
                        style={{ color: Theme.blue }}
                        className="fa fa-info-circle"
                        aria-hidden="true"
                      />
                    </Popover>
                  </Space>
                </Row>
                <Row>
                  <Space>
                    <Checkbox
                      value="Refund the application fee"
                      onChange={props.handleValueChange}
                    >
                      {" "}
                      Refund the application fee
                    </Checkbox>
                    <Popover title="">
                      <i
                        style={{ color: Theme.blue }}
                        className="fa fa-info-circle"
                        aria-hidden="true"
                      />
                    </Popover>
                  </Space>
                </Row>
              </Col>
            </Row>
            <br />
            <Row justify={"space-evenly"}>
              <Col>
                <h5 style={{fontSize: "100%", lineHeight: '1.2'}}>Reason</h5>
              </Col>
              <Col style={{ width: "60%" }}>
                <DropdownComponent
                  style={{ width: "100%" }}
                  placeholder="select reason"
                  options={props.options}
                  onChange={props.onChange}
                />
                <br />
                <TextArea
                  placeholder="Add more details about this refund."
                  onChange={props.handleTextChange}
                  style={{ borderRadius: ".5rem" }}
                />
              </Col>
            </Row>
            <Divider />
            <div>
              <Row justify={"end"}>
                <Space>
                  <Button
                    style={{ borderRadius: ".5rem" }}
                    onClick={props.onCancel}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    style={{ borderRadius: ".5rem" }}
                    onClick={props.handleOnClick}
                  >
                    Refund
                  </Button>
                </Space>
              </Row>
            </div>
          </div>
        </Modal>
      </div>
    );
}
