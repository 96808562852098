/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import ContainerWrapper from "../../components/ContainerWrapper";
import Loader from "../../components/Loading/Loader";
import { Row, Col, Table, Space, Button, Modal, Input, Popover } from "antd";
import PieChart from "../../components/PieChart/PieChart";
import actions from "../../actions";
import moment from "moment";
import { useSelector } from "react-redux";
import DropdownComponent from "../../components/Dashboard/DropdownComponent";
import VoucherUpdate from "../Voucher";
import {
  SearchOutlined,
  ArrowDownOutlined,
  ArrowUpOutlined,
} from "@ant-design/icons";
import { MINIMUM_SEARCH_LIMIT } from "../../common/config";
import HelmetFyndr from "../../components/HelmetFyndr";
import getEnvVars from "../../environment";

const web = getEnvVars();
export default function RedemeedUnredemeed() {
  const {
    indv: { userTimeZone },
  } = useSelector(({ auth }) => auth);
  const { voucherUpdated } = useSelector(({ fin }) => fin);
  const { indv } = useSelector(({ auth }) => auth);
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [text, setText] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [data, setData] = useState({});
  const [voucherVisible, setVoucherVisible] = useState(false);
  const [voucherData, setVoucherData] = useState();
  const [sortBy, setSortBy] = useState("");
  const [orderByPurchaseDate, setOrderByPurchaseDate] = useState(1);
  const [orderByValidTill, setOrderByValidTill] = useState(1);

  const getContant = (orderBy) => {
    switch (orderBy) {
      case 1:
        return `Click to sort ascending`;
      case 3:
        return `Click to cancel sorting`;
      case 2:
        return `Click to sort descending`;
      default:
        break;
    }
  };
  const tableColumn = [
    {
      title: "Name ",
      dataIndex: "buyerName",
      key: "buyerName",
    },
    {
      title: "Offers Purchased",
      dataIndex: "offerTitle",
      key: "offerTitle",
    },
    {
      title: "Status",
      dataIndex: "redeemptionStatus",
      key: "redeemptionStatus",
      render: (data, key) => {
        return (
          <p>
            {data
              ? data === "partially-redeemed"
                ? "Partially Redeemed"
                : data.charAt(0).toUpperCase() + data.slice(1).toLowerCase()
              : "-"}
          </p>
        );
      },
    },
    {
      title: "Phone Number",
      dataIndex: "buyerPhone",
      key: "buyerPhone",
      align: "center",
      render: (data, key) => {
        return <p>{data ? data : "-"}</p>;
      },
    },
    {
      title: "Date Redeemed",
      dataIndex: "redemptionDt",
      key: "redemptionDt",
      render: (data, key) => {
        return <p>{data ? moment.tz(data, userTimeZone).utc().format("LL") : "N/A"}</p>
      },
    },
    {
      title: (
        <Popover content={getContant(orderByPurchaseDate)}>
          <div
            onClick={() => {
              setPagination({
                ...pagination,
                current: 1,
              });
              setOrderByPurchaseDate((prevStep) =>
                prevStep === 3 ? 1 : prevStep + 1
              );
              setSortBy("PURCHASEDATE");
            }}
            style={{ display: "flex", alignItems: "center" }}
          >
            Purchase Date
            <ArrowUpOutlined
              style={{
                color: orderByPurchaseDate === 2 && "#2DCE89",
                height: 20,
                width: 20,
              }}
            />{" "}
            <ArrowDownOutlined
              style={{
                color: orderByPurchaseDate === 3 && "#2DCE89",
                height: 20,
                width: 20,
              }}
            />
          </div>
        </Popover>
      ),
      dataIndex: "invoiceDt",
      key: "invoiceDt",
      render: (data, key) => {
        return <p>{data ? moment.tz(data, userTimeZone).utc().format("LL") : "N/A"}</p>
      },
    },
    {
      title: (
        <Popover content={getContant(orderByValidTill)}>
          <div
            onClick={() => {
              setPagination({
                ...pagination,
                current: 1,
              });
              setOrderByValidTill((prevStep) =>
                prevStep === 3 ? 1 : prevStep + 1
              );
              setSortBy("VALIDTILL");
            }}
            style={{ display: "flex", alignItems: "center" }}
          >
            Valid Till
            <ArrowUpOutlined
              style={{
                color: orderByValidTill === 2 && "#2DCE89",
                height: 20,
                width: 20,
              }}
            />{" "}
            <ArrowDownOutlined
              style={{
                color: orderByValidTill === 3 && "#2DCE89",
                height: 20,
                width: 20,
              }}
            />
          </div>
        </Popover>
      ),
      dataIndex: "validTill",
      key: "validTill",
      render: (data, key) => {
        return <p>{data ? moment.tz(data,userTimeZone).utc().format("LL") : "N/A"}</p>
      },
    },
    {
      title: "Time of Redemption",
      dataIndex: "redemptionTime",
      key: "redemptionTime",
      width: 100,
      render: (data, key) => {
        return (
          <p>
            {data ? moment.tz(`1970-01-01 ${data}`, 'YYYY-MM-DD HH:mm', 'UTC').tz(userTimeZone).format('HH:mm A'): "N/A"}
          </p>
        );
      },
    },
    {
      title: "Voucher Code",
      dataIndex: "voucherCode",
      key: "voucherCode",
    },
    {
      title: "Action",
      render: (_, record, index) => {
        return (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Button
              type="link"
              onClick={() => {
                setVoucherVisible(true);
                setVoucherData(record);
              }}
            >
              View
            </Button>
          </div>
        );
      },
    },
  ];
  const fetchOffferReport = async (searchValue) => {
    const pgstart = searchValue.pgstart || pagination.current;
    let orderBy;
    if (sortBy === "PURCHASEDATE") {
      orderBy =
        orderByPurchaseDate === 2
          ? "ASC"
          : orderByPurchaseDate === 3
            ? "DESC"
            : "";
      setOrderByValidTill(1);
    } else {
      orderBy =
        orderByValidTill === 2 ? "ASC" : orderByValidTill === 3 ? "DESC" : "";
      setOrderByPurchaseDate(1);
    }
    setIsLoading(true);
    let response = await actions.fetchOfferReport({
      businessId: indv.bizid,
      pgstart,
      pgSize: pagination.pageSize,
      redemptionStatusList: text,
      orderBy: orderBy,
      sortBy: sortBy,
      text: searchValue,
    });

    setPagination({
      ...pagination,
      total: response?.data?.count,
    });
    if (response?.success === true) {
      setIsLoading(false);
      setData(response?.data);
    } else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchOffferReport(searchValue);
  }, [
    pagination.current,
    pagination.total,
    pagination.pageSize,
    text,
    voucherUpdated,
    orderByValidTill,
    orderByPurchaseDate,
  ]);

  const handleSearch = (value) => {
    setSearchValue(value);
    if (value.length >= MINIMUM_SEARCH_LIMIT) {
      fetchOffferReport(value);
    } else if (value.length === 0) {
      fetchOffferReport("");
    }
  };

  const handleTableChange = (pagination) => {
    setPagination({
      ...pagination,
    });
  };
  const chartData = {
    datasets: [
      {
        data: [
          data?.unredeemedOffersCount,
          data?.redeemedOffersCount,
          data?.partiallyRedeemedOffersCount,
        ],
        backgroundColor: ["#5196E2", "#999999", "#EAF2FC"],
        hoverBackgroundColor: ["#5196E2", "#999999", "#EAF2FC"],
      },
    ],
  };
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const dataset = context.dataset.data;
            const total = dataset.reduce((acc, value) => acc + value, 0);
            const currentValue = dataset[context.dataIndex];
            const percentage = ((currentValue / total) * 100).toFixed(2) + "%";
            return percentage;
          },
        },
      },
    },
    onClick: (elems, chart) => {
      if (chart.length > 0 && chart[0].index !== undefined) {
        setPagination({
          ...pagination,
          current: 1,
        });
        const newValue =
          chart[0].index === 1
            ? "redeemed"
            : chart[0].index === 0
              ? "unused"
              : "partially-redeemed";
        if (text.includes(newValue)) {
          setText((prevText) => prevText.filter((value) => value !== newValue));
        } else {
          setText((prevText) => [...prevText, newValue]);
        }
      }
    },
  };

  const Status = [
    { value: "redeemed", label: "Redeemed" },
    { value: "unused", label: "Unused" },
    { value: "partially-redeemed", label: "Partially Redeemed" },
  ];

  return (
    <>
      <ContainerWrapper
        title={"Offer Summary"}
        headerOption={
          <Row>
            <Space direction="horizontal">{isLoading && <Loader />}</Space>
          </Row>
        }
      >
        {
          <HelmetFyndr
            title="Business "
            imgUrl={""}
            url={`${web}/account/offers_summary`}
            descr="account offers_summary"
          />
        }
        <Row className="redeem">
          {data && data?.purchasedOffersCount !== 0 && (
            <Col
              style={{
                background: "#F4F8FD",
                boxShadow: "0px 4px 4px 0px #0000001A",
                marginRight: "24px",
                padding: "24px",
                borderRadius: "10px",
              }}
              xl={6}
              md={8}
            >
              <PieChart
                height={150}
                width={150}
                options={options}
                data={chartData}
              />
            </Col>
          )}
          <Col
            xl={data?.purchasedOffersCount === 0 ? 19 : 12}
            md={12}
            style={{ alignSelf: data?.purchasedOffersCount !== 0 && "end" }}
          >
            <Row style={{ lineHeight: "20px", fontSize: "16px" }}>
              Total Offers Sold : {data?.purchasedOffersCount}
            </Row>
            <Row style={{ lineHeight: "20px", fontSize: "16px" }}>
              Total Unused Offers : {data?.unredeemedOffersCount}
            </Row>
            <Row style={{ lineHeight: "20px", fontSize: "16px" }}>
              Total Redeemed Offers : {data?.redeemedOffersCount}
            </Row>
            <Row style={{ lineHeight: "20px", fontSize: "16px" }}>
              Total Partially Redeemed Offers :{" "}
              {data?.partiallyRedeemedOffersCount}
            </Row>
          </Col>
          <Col
            xl={5}
            md={8}
            style={{ alignSelf: data?.purchasedOffersCount !== 0 && "end" }}
          >
            {data && data?.purchasedOffersCount !== 0 && (
              <>
                <Row>
                  <span
                    style={{ background: "#5196E2" }}
                    className="redeem_span"
                  ></span>
                  Offers Unused
                </Row>
                <Row>
                  <span
                    style={{ background: "#999999" }}
                    className="redeem_span"
                  ></span>
                  Offers Redeemed
                </Row>
                <Row>
                  <span
                    style={{
                      background: "#EAF2FC",
                      border: "1px solid #D3D6E1",
                    }}
                    className="redeem_span"
                  ></span>
                  Offers Partially Redeemed
                </Row>
              </>
            )}
          </Col>
        </Row>
        <Row gutter={[12, 0]}>
          <Col span={6}>
            <DropdownComponent
              onChange={(e) => {
                setPagination({
                  ...pagination,
                  current: 1,
                });
                setText(e);
              }}
              placeholder="Status"
              value={text}
              options={Status}
              style={{
                width: "100%",
                borderRadius: "5px",
                paddingBottom: "24px",
              }}
              mode="multiple"
            />
          </Col>
          <Col span={7}>
            <Input
              placeholder="Search by voucher code, name & phone no."
              type="search"
              onPressEnter={(e) => {
                setPagination({
                  ...pagination,
                  current: 1,
                });
                handleSearch(e.target.value);
              }}
              suffix={<SearchOutlined />}
            />
          </Col>
        </Row>
        <Table
          columns={tableColumn}
          pagination={pagination}
          bordered
          className="redeem_table"
          dataSource={data.listOfferPurchasedOutDTO}
          onChange={handleTableChange}
        />
      </ContainerWrapper>
      <Modal
        title="Voucher"
        visible={voucherVisible}
        footer={null}
        onCancel={() => setVoucherVisible(false)}
        width={450}
      >
        <Row>
          <VoucherUpdate
            offer={voucherData}
            type={"receivable"}
            callback={() => setVoucherVisible(false)}
          />
        </Row>
      </Modal>
    </>
  );
}
