import React, { Suspense } from "react";
import { Provider } from "react-redux";

import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "../store";
import Loader from "./Loading/Loader";

// eslint-disable-next-line no-unused-vars
import AppLayout from "./AppLayout/";
import "../main.scss";
import RootMain from "./RootMain";
const Root = () => {
  return (

    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Suspense maxDuration={500} fallback={<Loader />}>
         <RootMain/>
        </Suspense>
      </PersistGate>
    </Provider>
  );
};

export default Root;
