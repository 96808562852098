import React, { useState } from "react";
import { Tabs, Row, Col, Button } from "antd";
import getEnvVars from "../../environment";
import { useEffect } from "react";

const { TabPane } = Tabs;
const { web } = getEnvVars();

function callback(key) {
  console.log(key);
}
const PreviewContainer = (props) => {
    const[cmpnData , setCmpnData] = useState([]);
    const isPreview = true;

    useEffect(() => {
      const channel = new BroadcastChannel("preview_channel");
  
      channel.onmessage = async (event) => {
        const receivedData = event.data;
        setCmpnData(receivedData);
      };
  
      return () => {
        channel.close();
      };
    }, []); 
    
    const url = `${web}/offer-details/${cmpnData.biz?.bizName.replace(
        /[.\W]+/g,
        "-"
      )}/${cmpnData.qrCode}?isPreview=${isPreview}`;



  return (
    <Tabs
      size="large"
      onChange={callback}
      centered
      type="card"
      tabBarExtraContent={{
        right: (
          <Button
            onClick={() => window.close()}
            style={{ marginTop: ".2rem" }}
            size="large"
            type="primary"
          >
            close
          </Button>
        ),
      }}
    >
      <TabPane style={{ height: "100vh" }} tab="Web View" key="1">
        <iframe
          width={"100%"}
          height={"100%"}
          src={url}
          title="Fyndr"
        ></iframe>
      </TabPane>
      <TabPane
        style={{ height: "100%", textAlign: "center", width: "100%" }}
        tab="Mobile View"
        key="2"
      >
        <Row justify="center">
          <Col style={{ height: "90vh", overflowY: "hidden" }} span={24}>
            <iframe
              frameBorder={0}
              style={{ overflowY: "hidden" }}
              width={"400 px"}
              height={"100%"}
              src={url}
              title="Fyndr"
            ></iframe>
          </Col>
        </Row>
      </TabPane>
    </Tabs>
  );
};
export default PreviewContainer;
