/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Table,
  Input,
  Slider,
  Button,
  Modal,
  Select,
  Divider,
  Checkbox,
  message,
  Alert,
  Typography,
  Card,
} from "antd";

import { FiSearch } from "react-icons/fi";
import { useSelector, useDispatch } from "react-redux";
import ContainerWrapper from "../../components/ContainerWrapper"
import {
  getFormattedDtNew,
  getFormattedTmNew,
  parseEvents,
  last14,
} from "../../common/util.js";

import UserActions from "../../actions";
import Theme from "../../common/Theme.js";
import Actions from "../../common/ActionsStrings";
import UserAction from "../../actions";
const { Text } = Typography;
const {CART_INVOICED}=Actions;

const LocationViewer = (props) => {
  const {
    indv: { userTimeZone },
  } = useSelector(({ auth }) => auth);
  const { history } = props;
  const {
    location: {
      state: { locName },
    },
    match: {
      params: { qrid },
    },
  } = props;

  const { FILTER_EVENTS } = Actions;
  const {
    indv: { bizid, merchantAllowed, chargesEnabled, locations },
    settings: { messages },
  } = useSelector(({ auth }) => auth);

  const { location_id } = useSelector(({ auth }) => auth.activeLocation);

  const { filtered_events, indirect, days } = useSelector(({ core }) => core);

  const dispatch = useDispatch();
  const { visitors, interacts, count } = indirect;
  const current_location =
    (locations && locations.filter((row) => row.objid === location_id)[0]) ||
    {};

  const createInvoice = (interaction) => {
    history.push("/invoice", { mode: "create", interaction, current_location });
  };
  const loadCart = async (interaction) => {
    const resp = await UserAction.getCatalogueByLoc({ locId: location_id, buyerQrId: interaction.qrId,
    });

    props.history.push(`/catalogue/store/${resp?.payload?.catalogue?.url}`, {
      catalogueDetail: resp.payload,
      fromBizAccount: true,

    });
    dispatch({
      type:CART_INVOICED
    })
    


  };
  const columns = [
    {
      title: "Interaction",
      dataIndex: "name",
      key: "name",
    },
    {
      align: "left",
      title: "Time",
      dataIndex: "time",
      key: "time",
      render: (date) => {
        return (
          <>
            {getFormattedDtNew(date, userTimeZone)} {getFormattedTmNew(date, userTimeZone)}
          </>
        );
      },
    },
    {
      align: "center",
      title: "Interaction Type",
      dataIndex: "act",
      key: "act",
      render: (item) => {
        return (
          <>
            <i
              style={{ color: Theme.blue }}
              className="fa fa-user"
              aria-hidden="true"
            ></i>{" "}
            &nbsp;
            {item.endsWith("By") ? (
              <i
                style={{ color: Theme.blue }}
                className="fa fa-chevron-left"
                aria-hidden="true"
              ></i>
            ) : (
              <i
                style={{ color: Theme.blue }}
                className="fa fa-chevron-right"
                aria-hidden="true"
              ></i>
            )}{" "}
            &nbsp;
            {item.startsWith("interacted") ? (
              <i
                style={{ color: Theme.blue }}
                className="fa fa-user"
                aria-hidden="true"
              ></i>
            ) : (
              <i
                style={{ color: Theme.blue }}
                className="fa fa-building"
                aria-hidden="true"
              ></i>
            )}
          </>
        );
      },
    },
    {
      title: "Initiated By",
      dataIndex: "by",
      key: "by",
      align: "center",
      render: (text, item) => {
        const { name, loc, bizName } = item;
        const act = item.act.toLowerCase();
        let val = act === "interactedby" ? name : "";
        if (act === "interactedwith") {
          val = "You";
        } else if (act === "checkedinby" && loc) {
          val = `${bizName} - ${loc.name}, ${loc.city} ${loc.postalCdoe}`;
        } else if (act === "visited") {
          val = name;
        } else if (act === "checkedinby") {
          val = "You";
        }
        return <Text>{val}</Text>;
      },
    },
    {
      title: "Initiated For",
      dataIndex: "for",
      key: "for",
      render: (text, item) => {
        const { name, loc, bizName } = item;
        const act = item.act.toLowerCase();

        let val = act === "interactedwith" ? name : "";
        if (act === "interactedby") val = "You";
        else if (act === "visited" && loc)
          val = `${bizName} - ${loc.name}, ${loc.city} ${loc.postalCode}`;
        else if (act === "checkedinby") val = name;
        else if (act === "visited") val = "You";
        return <Text>{val}</Text>;
      },
    },
    {
      title: "Business Terms",
      dataIndex: "terms_accepted",
      key: "terms_accepted",
      align: "center",
      render: (text, item) => {
        const { terms_accepted, consent_time } = item;
        let val = "";
        if (terms_accepted !== undefined) {
          val =
            terms_accepted === "true"
              ? `Accepted on ${getFormattedTmNew(consent_time, userTimeZone)}`
              : "Not Accepted";
        }
        return <Text>{val}</Text>;
      },
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "Location",
      align: "center",
      render: (text, item) => {
        const { lat, lng } = item;
        return (
          <a
            target="_blank"
            href={`https://maps.google.com/?q=${lat},${lng}`}
            rel="noopener noreferrer"
          >
            <i
              style={{ color: Theme.orange }}
              className="fa fa-map-marker"
              aria-hidden="true"
            ></i>
          </a>
        );
      },
    },
    {
      title: "",
      dataIndex: "",
      key: "",
      align: "center",
      render: (text, item) => {
        return (
          <>
            {merchantAllowed && chargesEnabled && (
              <>
              <Button
                onClick={() => {
                  createInvoice(item);
                }}
              >
                Create invoice
              </Button>

              </>
            )}

          </>
        );
      },
    },
    {
      title: "",
      dataIndex: "",
      key: "",
      align: "center",
      render: (text, item) => {
        return (
          <>

            {merchantAllowed && chargesEnabled && (
              <Button
                onClick={() => {
                  loadCart(item);
                }}
              >
                Create Invoice from Catalogue
              </Button>
            )}
          </>
        );
      },
    },
  ];

  const { Option } = Select;

  const [events, setEvents] = useState(filtered_events);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [parsedEvents, setParsedEvents] = useState(
    parseEvents(selectedRowKeys)
  );
  const loc = 30;
  const [Days, setDays] = useState(loc);

  const [visible, setVisible] = useState(false);

  const [searchTxtInput, setSearchTxt] = useState("");
  const [isSelectActive, setSelectActive] = useState(false);

  const [agree, setAgree] = useState(false);

  let list = [];
  let DayList = last14();

  messages.forEach((row) => {
    list.push({ label: bizid ? row.value : row.label, value: row.value });
  });

  const [msg, setMsg] = useState(list[0].value);
  const [when, setWhen] = useState(DayList[0].value);
  const [finalMsg, setFinalMsg] = useState("");

  //Effects
  useEffect(() => {
    doRefresh(true);
  }, [Days]);

  useEffect(() => {
    filterEvents();
  }, [searchTxtInput]);

  useEffect(() => {
    formatMsg();
  }, [agree, msg, when]);

  useEffect(() => {
    let tempData = [];
    filtered_events.map((item, index) => {
      item.key = index;
      tempData.push(item);
      return null;
    });
    setEvents(tempData);
  }, [filtered_events]);

  const doRefresh = async () => {
    await UserActions.getLocInteractions({
      qrId: qrid + "",
      interval: Days,
      traversal: "location",
    });
  };

  const filterEvents = () => {
    dispatch({
      type: FILTER_EVENTS,
      payload: { events, searchTxt: searchTxtInput, days },
    });
  };

  const sendmessage = async () => {
    const resp = await UserActions.sendMsg({
      when,
      msg,
      rcpts: parsedEvents,
      sender: qrid + "",
      messages,
    });
    if (resp.success) {
      message.success(resp.msg);
      setVisible(false);
    } else {
      message.error(resp.msg);
      setVisible(false);
    }
  };

  const formatMsg = () => {
    setFinalMsg(
      [
        msg,
        `\nThe approximate date of exposure is ${when}\n\nPlease note that Fyndr does not collect or validate medical records from its users. These messages are solely intended for cautionary purposes in order to minimize the spread of Covid-19.`,
      ].join(" ")
    );
  };

  const onSelectChange = (selectedRowKeys, rows) => {
    setSelectedRowKeys(selectedRowKeys);
    setParsedEvents(parseEvents(rows));
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <ContainerWrapper title={locName}>
              <Card
                extra={
                  <Button type="link" onClick={() => history.goBack()}>
                    Go Back
                  </Button>
                }
              >
                <Row gutter={20} justify="center">
                  <Col span={22}>
                    <Row gutter={10}>
                      <Col span={12}>
                        <Input
                          size="large"
                          placeholder="Search Interactions"
                          onChange={(e) => {
                            setSearchTxt(e.target.value);
                          }}
                          prefix={<FiSearch className="prefix" />}
                        />
                      </Col>
                      <Col align="center" span={12}>
                        <h6>Past {Days} Days</h6>
                        <Slider
                          defaultValue={Days}
                          max={30}
                          disabled={false}
                          onAfterChange={(value) => {
                            setDays(value);
                          }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        span="24"
                        style={{ paddingTop: "2rem", paddingBottom: "2rem" }}
                        align="center"
                      >
                        <Alert
                          description={`In last ${loc} days there were total ${count}
                                interactions here. The visitor(s) interacted with
                                ${interacts} more person ${interacts > 1 ? "s" : ""
                            } & the
                                place(s) they visited had ${visitors} more visitor(s).
                            `}
                          type="warning"
                        />
                      </Col>
                    </Row>
                    <Table
                      dataSource={events}
                      columns={columns}
                      rowClassName="RowClass"
                      rowSelection={isSelectActive ? rowSelection : null}
                    />

                    <Col span={24} align="center">
                      <Button
                        type="primary"
                        htmlType="submit"
                        onClick={() => {
                          isSelectActive
                            ? setVisible(true)
                            : setSelectActive(true);
                        }}
                      >
                        {isSelectActive ? "Proceed" : "Send Message"}
                      </Button>
                    </Col>
                  </Col>
                </Row>
              </Card>
              <Modal
                title="Messaging Center"
                visible={visible}
                centered
                width={900}
                okText={"Send"}
                onOk={() => {
                  if (agree) {
                    sendmessage();
                  } else {
                    message.error(
                      "Please agree to terms and condition to proceed."
                    );
                  }
                }}
                onCancel={() => {
                  setVisible(false);
                }}
                bodyStyle={{ height: "80vh" }}
              >
                <h6 style={{ marginTop: "3rem" }}>
                  We’re committed towards ensuring the safety of our
                  communities. Let your network know how you’re feeling.
                </h6>
                <h5>
                  The messages are sent anonymously to ensure your privacy.
                </h5>
                <Row style={{ marginTop: "2rem" }}>
                  <Col span={12}>Total Recipients</Col>
                  <Col span={12}>{parsedEvents.totalReceivers}</Col>
                </Row>
                <Divider
                  dashed
                  style={{ color: Theme.blue }}
                  orientation="center"
                />
                <Row style={{ marginTop: "2rem" }} gutter={10}>
                  <Col>
                    <h6>Select Message</h6>
                    <Select
                      defaultValue={list[0].value}
                      style={{ maxWidth: "100%" }}
                      onChange={(msg) => {
                        setMsg(msg);
                      }}
                    >
                      {list.map((item, index) => (
                        <Option
                          style={{ width: "100%", wordBreak: "break-all" }}
                          value={item.value}
                        >
                          {item.label}
                        </Option>
                      ))}
                    </Select>
                  </Col>
                </Row>
                <Row gutter={10}>
                  <Col style={{ marginTop: "2rem" }}>
                    <h6>Approximate date of exposure</h6>
                    <Select
                      defaultValue={DayList[0].value}
                      style={{ maxWidth: "100%" }}
                      onChange={(date) => {
                        setWhen(date);
                      }}
                    >
                      {DayList.map((item, index) => (
                        <Option value={item.value}>{item.label}</Option>
                      ))}
                    </Select>
                  </Col>
                </Row>
                <Row style={{ marginTop: "5rem" }}>
                  <Col span={24}>
                    <p>
                      <Checkbox
                        onChange={(e) => {
                          setAgree(e.target.checked);
                        }}
                      />{" "}
                      &nbsp; I agree with Fyndr's terms of use
                    </p>
                  </Col>
                </Row>

                {agree && (
                  <>
                    <h3>Message Preview</h3>
                    <h5>{finalMsg}</h5>
                  </>
                )}
              </Modal>
    </ContainerWrapper>
  );
};
export default LocationViewer;
