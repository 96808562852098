import React, { useEffect, useState } from "react";
import { Form, Input, Row, Col, Button, Card, Select } from "antd";
import "../../sass/styles/fyndr/_gift.scss";
import backbtn from "../../assets/images/blackBackArrow.svg";

const ReceiverForm = (props) => {
  const { initialData, onClose, onClick, mode } = props;
  const [formData, setFormData] = useState(initialData || {});
  const [countryCode, setCountryCode] = useState(initialData?.countryCode || "+1");

  useEffect(() => {
    setFormData(initialData || {});
  }, [initialData]);

  const handleFinish = (values) => {
    const formDataWithCountryCode = {
      ...values,
      countryCode: countryCode,
      phoneNumber: values.phoneNumber,
    };
    setFormData(formDataWithCountryCode);
    props.callback(formDataWithCountryCode);
  };

  const handleClose = () => {
    if (mode === "update") {
      onClose();
    } else {
      onClick();
    }
  };

  return (
    <>
      <Row justify="center">
        <Col xs={24} md={24}>
          <Card
            style={{ background: "#F4F8FD" }}
            className="card-receiver"
            title={
              <span
                style={{
                  fontFamily: "Roboto",
                  fontSize: "20px",
                  fontWeight: 500,
                  lineHeight: "27.64px",
                  textAlign: "left",
                  color: "#4D4D4D",
                }}
              >
                <img
                  src={backbtn}
                  onClick={handleClose}
                  style={{ cursor: "pointer", marginRight: "2px" }}
                  alt="backbtn"
                />{" "}
                Receiver's Details
              </span>
            }
          >
            <div className="form-container">
              <Form
                name="basic"
                initialValues={{ ...formData }}
                onFinish={handleFinish}
              >
                <Form.Item
                  name="name"
                  rules={[
                    { required: true, message: "Please enter Receiver's name!" },
                  ]}
                  style={{ marginBottom: "30px" }}
                >
                  <div style={{ position: "relative" }}>
                    <p
                      style={{
                        position: "absolute",
                        top: -10,
                        left: "10px",
                        backgroundColor: "white",
                        padding: "0 4px",
                        zIndex: 999,
                      }}
                    >
                      Receiver's Name *
                    </p>
                    <Input
                      defaultValue={formData?.name}
                      placeholder="Enter Receiver's name"
                      style={{ height: "40px", borderRadius: "8px" }}
                    />
                  </div>
                </Form.Item>

                <Form.Item
                  name="email"
                  rules={[
                    { required: true, message: "Please enter Receiver's email!" },
                    {
                      type: "email",
                      message: "Please enter a valid email address!",
                    },
                  ]}
                  style={{ marginBottom: "30px" }}
                >
                  <div style={{ position: "relative" }}>
                    <p
                      style={{
                        position: "absolute",
                        top: -10,
                        left: "10px",
                        backgroundColor: "white",
                        padding: "0 4px",
                        zIndex: 999,
                      }}
                    >
                      Receiver's Email *
                    </p>
                    <Input
                      defaultValue={formData?.email}
                      placeholder="Enter Receiver's email"
                      style={{ height: "40px", borderRadius: "8px" }}
                    />
                  </div>
                </Form.Item>
                <Form.Item
                  name="phoneNumber"
                  style={{ marginBottom: "30px" }}
                  rules={[
                    {
                      pattern: /^[0-9]{10}$/,
                      message: "Enter a valid phone number",
                    },
                  ]}
                >
                  <div
                    style={{
                      position: "relative",
                      height: "46px",
                    }}
                  >
                    <p
                      style={{
                        position: "absolute",
                        top: -10,
                        left: "10px",
                        backgroundColor: "white",
                        padding: "0 4px",
                        zIndex: 999,
                      }}
                    >
                      <span>Receiver's Phone</span>
                    </p>

                    <Select
                      defaultValue={formData.countryCode ? formData.countryCode : '+1'}
                      onChange={(value) => setCountryCode(value)}
                      className="country-code-dropdown"
                      style={{
                        width: "58px",
                        position: "absolute",
                        top: "0px",
                        left: 0,
                        height: "33px",
                        border: "none",
                        borderRadius: "8px",
                        marginRight: "8px",
                        zIndex: "1",
                      }}
                    >
                      <Select.Option value="+1">+1</Select.Option>
                      <Select.Option value="+91">+91</Select.Option>
                      <Select.Option value="+61">+61</Select.Option>
                      <Select.Option value="+44">+44</Select.Option>
                      <Select.Option value="+64">+64</Select.Option>
                    </Select>

                    <Input
                      defaultValue={formData?.phoneNumber}
                      style={{
                        height: "40px",
                        borderRadius: "8px",
                        paddingLeft: "64px",
                      }}
                      placeholder="Enter Receiver's phone number"
                    />
                  </div>
                </Form.Item>

                <Form.Item name="message">
                  <div style={{ position: "relative" }}>
                    <p
                      style={{
                        position: "absolute",
                        top: -10,
                        left: "10px",
                        backgroundColor: "white",
                        padding: "0 4px",
                        zIndex: 999,
                      }}
                    >
                      Add Message
                    </p>
                    <div
                      style={{
                        border: "1.5px solid #D3D6E1",
                        paddingTop: "12px",
                        borderRadius: "8px",
                      }}
                    >
                      <Input.TextArea
                        defaultValue={formData?.message}
                        placeholder="Write a message"
                        style={{
                          height: "50px",
                          padding: "0px 12px 12px 12px",
                          lineHeight: "150%",
                          border: "none",
                        }}
                        className="custom-input"
                      />
                    </div>
                  </div>
                </Form.Item>

                <Form.Item style={{ textAlign: "center" }}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="submit-button"
                    onClick={() => handleFinish}
                  >
                    Proceed
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default ReceiverForm;
