/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from "react";
import{ compose, withProps } from "recompose";
import getEnvVars from "../environment";

import {
  withGoogleMap,
  GoogleMap
} from "react-google-maps";

const { app: {GOOGLE_API_KEY}} = getEnvVars();

const Map = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&libraries=places&callback=Function.prototype`,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `100%`, width: "100%" }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withGoogleMap
)((props) => {
  const { zoom, defaultCenter , onBoundsChanged , mapRef} = props;
  const ZOOM_OUT_LIMIT = 4;
  const searchButtonRef = useRef(null);

  useEffect(() => {
    if (mapRef?.current && window.google) {
      const map = mapRef?.current.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED;
      
      if(!searchButtonRef.current) {
      const controlButtonDiv = document.createElement('div');
      controlButtonDiv.style.margin = '10px';
      controlButtonDiv.style.fontSize = '14px';
      controlButtonDiv.style.padding = '10px';
      controlButtonDiv.style.backgroundColor = '#257CDB';
      controlButtonDiv.style.border = '1px solid #ccc';
      controlButtonDiv.style.cursor = 'pointer';
      controlButtonDiv.style.color = '#fff';
      controlButtonDiv.innerHTML = 'Search';
      controlButtonDiv.id = "searchButton";

      searchButtonRef.current = controlButtonDiv;

      controlButtonDiv.addEventListener('click', onBoundsChanged);
      map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(controlButtonDiv);
    }

      const handleZoomChanged = () => {
        const currentZoom = map.getZoom(); 
        if (searchButtonRef.current) {
          searchButtonRef.current.style.display = currentZoom <= ZOOM_OUT_LIMIT ? "none" : "block";
        }
      };

      handleZoomChanged();

      window.google.maps.event.addListener(map, "zoom_changed", handleZoomChanged);

      return () => {
        if (map && handleZoomChanged) {
          window.google.maps.event.clearListeners(map, "zoom_changed");
        }
      };
    }
  }, [mapRef]);


  

  return (    
    <GoogleMap
      defaultZoom={zoom}
      zoom={zoom}
      center={defaultCenter}
      defaultCenter={defaultCenter}
      ref={mapRef}
    >
      {props.children}
    </GoogleMap>
  );
});
    
  
export default Map;

Map.defaultProps = {
  defaultCenter: { lat: 33.6629442, lng: -112.0182329 },
  list: [],
};

