/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { List, Row, message, Space, Modal } from "antd";
import Button from "antd-button-color";
import Actions from "../../common/ActionsStrings";
import Edit from "./Edit";
import Loader from "../../components/Loading/Loader";
import { ImQrcode } from "react-icons/im";
import UserActions from "../../actions";
import { Theme } from "../../common";
import Axios from "axios";
import getEnvVars from "../../environment";
import { QRCode } from "react-qrcode-logo";
import { FiEdit } from "react-icons/fi";
import { RiUserReceived2Line } from "react-icons/ri";
import ContainerWrapper from "../../components/ContainerWrapper";
import ConfirmationModal from "../../components/ConfirmationModal";
import HelmetFyndr from "../../components/HelmetFyndr";
const { base } = getEnvVars();

const ManageVenue = (props) => {
  const { locations, qrLogo, bizName, bizid, qrPrefix } = useSelector(
    ({ auth }) => auth.indv
  );
  const { LOCATION_SELECTED, LOCATION_EDITED } = Actions;
  const [started, setStarted] = useState();
  const [isEdit, setIsEdit] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [details, setDetails] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [locSelected, setLocSelected] = useState(null);
  const [deleteVisible, setdeleteVisible] = useState(false);
  const dispatch = useDispatch();

  const download = async () => {
    const uri = `${base}/identity/getLocationPDF?bizid=${bizid}`;
    const secureToken = await localStorage.getItem("secureToken");
    let fileDownload = require("js-file-download");
    setIsLoading2(true);
    const resp = await Axios.get(uri, {
      headers: {
        "X-Fyndr-Auth-Token": secureToken,
      },
      responseType: "blob",
    });
    setIsLoading2(false);
    fileDownload(resp.data, `${bizName}.pdf`);
  };

  const selectLocation = async (item, type) => {
    const { objid, locName, qrCode, qrid } = item;
    await dispatch({
      type: LOCATION_SELECTED,
      payload: { location_id: objid, locName, qrcode: qrCode },
    });
    if (type === "view") {
      props.history.push(
        `/account/interaction/viewer/${objid}/${qrCode}/${qrid}`,
        {
          locName,
          type: "loc",
        }
      );
    } else {
      setIsLoading(true);
      const resp = await UserActions.getLocation(objid);
      if (resp && resp.success) {
        setIsEdit(true);
        setDetails(resp.payload);
        setStarted(true);
      } else {
        message.error("Error getting location details..");
      }
      setIsLoading(false);
    }
  };
  let location = locations;
  const handleDeleteLoc = async (objid) => {
    let payload = {
      objid: objid,
      bizid,
    };
    let resp = await UserActions.deleteLocation(payload);
    if (resp?.success) {
      message.success(resp.message);
      location = locations.filter((item) => item?.objid !== objid);
      dispatch({ type: LOCATION_EDITED, payload: location });
      setdeleteVisible(false);
    } else {
      message.error(resp.message);
      setdeleteVisible(false);
    }
  };

  return (
    <>
      {!started && (
        <ContainerWrapper
          title={"Manage Locations"}
          headerOption={
            <Row>
              <Space direction="horizontal">
                {isLoading && <Loader />}
                <Button type="info" onClick={download} disabled={isLoading2}>
                  Download Venue QRs {isLoading2 && <Loader />}
                </Button>
                <Button
                  type="primary"
                  onClick={() => {
                    setStarted(true);
                    setIsEdit(false);
                  }}
                >
                  Add Venue
                </Button>
              </Space>
            </Row>
          }
        >
          {
            <HelmetFyndr
              title="Business "
              imgUrl={""}
              url={`${base}/account/venue/manage`}
              descr="account venue manage"
            />
          }
          <List
            itemLayout="horizontal"
            dataSource={location || []}
            renderItem={(row) => (
              <List.Item>
                <div className="list-style-v1">
                  <div className="list-item">
                    <div
                      className={`icon-btn icon-btn-round mr-3`}
                      onClick={() => {
                        setModalVisible(true);
                        setLocSelected(row);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <ImQrcode style={{ fontSize: 20, color: Theme.blue }} />
                    </div>
                    <div className="list-item__body list-sep cc-padding">
                      <div className="big">
                        <h5>{row.locName}</h5>
                      </div>
                    </div>
                    <div className="list-sep cc-padding">
                      <RiUserReceived2Line
                        onClick={() => selectLocation(row, "view")}
                        style={{
                          fontSize: "22px",
                          color: Theme.blue,
                          marginRight: "1rem",
                          cursor: "pointer",
                        }}
                      />
                      <FiEdit
                        onClick={() => selectLocation(row, "edit")}
                        style={{
                          fontSize: "22px",
                          color: Theme.blue,
                          cursor: "pointer",
                          marginRight: "1rem",
                        }}
                      />
                      <i
                        style={{
                          fontSize: "22px",
                          color: Theme.red,
                          cursor: "pointer",
                        }}
                        className="fas fa-trash-alt"
                        onClick={() => {
                          setLocSelected(row);
                          setdeleteVisible(true);
                        }}
                      ></i>
                    </div>
                  </div>
                </div>
              </List.Item>
            )}
          />
          <Modal
            onCancel={() => setModalVisible(false)}
            footer={null}
            visible={modalVisible}
            title={locSelected && locSelected.locName}
          >
            <Row
              justify="center"
              style={{ marginTop: "1rem", marginBottom: "3rem" }}
            >
              <QRCode
                value={`${qrPrefix}?id=${locSelected && locSelected?.qrCode}`}
                size={200}
                logoWidth={50}
                logoImage={qrLogo}
              />
            </Row>
          </Modal>
          <ConfirmationModal
            title={`Delete Location ${locSelected?.locName}`}
            visible={deleteVisible}
            description={"Are you sure you want to proceed ?"}
            onConfirm={() => handleDeleteLoc(locSelected?.objid)}
            onCancel={() => setdeleteVisible(false)}
          />
        </ContainerWrapper>
      )}
      {started && (
        <Edit
          callback={() => setStarted(false)}
          started={started}
          data={details}
          isEdit={isEdit}
        />
      )}
    </>
  );
};
export default ManageVenue;
