import React from "react";
import { Route, BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import LoadingOverlay from 'react-loading-overlay';


import AppLayout from "./AppLayout";
import "../main.scss";
import ScrollToTop from "./ScrollToTop";
import { Theme } from "../common";
import { useSelector } from "react-redux";

LoadingOverlay.propTypes = undefined
const RootMain = () => {
  const { loader } = useSelector(({ loader }) => loader);

  return (

    <LoadingOverlay
      active={loader}
      spinner
      styles={{
        overlay: (base) => ({
          ...base,
          background: Theme.overlay,
        }),
        spinner:(base)=>({
          ...base,
          background: Theme.overlay,

        })
      }}
    >
      <RecoilRoot>
        <BrowserRouter>
          <ScrollToTop>

            <Route path="/" component={AppLayout} />

          </ScrollToTop>
        </BrowserRouter>
      </RecoilRoot>
    </LoadingOverlay>

  );
};

export default RootMain;
