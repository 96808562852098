import { Button, Card, Col, Image, Input, message, Row } from "antd";
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import "../../sass/styles/fyndr/_emailUnsubscription.scss";
import happy from "../../assets/images/Happy-GIF-unscreen.gif";
import actions from "../../actions";
import sad from "../../assets/images/Sad-GIF-unscreen.gif";
import emailUnsubscribe from "../../assets/images/SadImage.png";
import unsubscribeImg from  "../../assets/images/emailUnSubscribe.png";
import { setEmailSubscribe } from "../../actions/emailSubscribedActions";
import { isMobile } from "../../common/util";
import { useDispatch } from "react-redux"

const EmailUnsubscription = () => {
  const history = useHistory();
  const [hoveredButton, setHoveredButton] = useState(null);
  const [unsubscribeClicked, setUnsubscribeClicked] = useState(false);
  const [pageVisible, setPageVisible] = useState(false);
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();

function useQueryParams() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  return {
      bizId: params.get('bizId'),
  };
}

const { bizId } = useQueryParams();

 const emailUnsubscription = async(type) => {
    const payload = {
      email: email,
      entity : type,
      bizid : bizId
    }

  let res = await actions.emailUnsubscribe(payload);

  if(res?.success) {
      setPageVisible(true);
      if(type === "FYNDR"){
         dispatch(setEmailSubscribe(false));
      }
  } else {
    message.error(res?.msg)
  }
 }

  const getImage = () => {
    switch (hoveredButton) {
      case "keep":
        return happy;
      case "unsubscribe":
        return sad;
      default:
        return emailUnsubscribe;
    }
  };

  const getText = () => {
    switch (hoveredButton) {
      case "keep":
        return (
          <>
            Excellent choice!!!{" "}
            <span role="img" aria-label="partying face">
              🥳
            </span>
          </>
        );
      case "unsubscribe":
        return (
          <>
            Are you sure about unsubscribing?
            <span role="img" aria-label="sad emoji">
              🥺
            </span>
          </>
        );
      default:
        return (
          <>
            Are you sure about unsubscribing?
            <span role="img" aria-label="sad emoji">
              🥺
            </span>
          </>
        );
    }
  };

  const getSubdetails = () => {
    switch (hoveredButton) {
      case "keep":
        return "We will keep you posted now...";
      case "unsubscribe":
        return "If you unsubscribe now, you might miss exciting deals and offers.";
      default:
        return "If you unsubscribe now, you might miss exciting deals and offers.";
    }
  };

  const handleUnsubscribeClick = () => {
    setUnsubscribeClicked(true);
  };

  const handleSubscription = () => {
    history.push("/");
  }

  return (
    <>
    { !pageVisible && (
    <Card className="email-card">
      <Row justify="center">
        <Col>
        <div className="email-image-wrapper">
          <Image className="email-image" src={getImage()} preview={false} alt="email-img" />
        </div>
        </Col>
      </Row>
      <Row justify="center">
        <Col>
          <h2 className="email-description">{getText()}</h2>
        </Col>
      </Row>
      <Row justify="center">
        <Col>
          <p className="email-subdetails">{getSubdetails()}</p>
        </Col>
      </Row>
      <Row justify="center" style={{ marginBottom:"12px" }}>
        <Col>
          <Button
            type="primary"
            onMouseEnter={() => setHoveredButton("keep")}
            onMouseLeave={() => setHoveredButton(null)}
            onClick={handleSubscription}
          >
            Keep Subscription
          </Button>
        </Col>
        </Row>
        {!unsubscribeClicked && (
          <>
          <Row justify="center">
          <Col>
            <Button
              className="email-btn"
              style={{ width: isMobile() ? "270px" : "435px" , height: isMobile() ? "40px" :"46px" }}
              onMouseEnter={() => setHoveredButton("unsubscribe")}
              onMouseLeave={() => setHoveredButton(null)}
              onClick={handleUnsubscribeClick}
            >
              Unsubscribe
            </Button>
          </Col>
          </Row>
          </>
        )}
        {unsubscribeClicked && (
          <>
          <Row justify="center">
            <Col>
                <Input 
                  placeholder="Enter your email Address"
                  type="email"
                  className="email-unsubscribe-input"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
            </Col>       
          </Row>
          <Row justify="center" gutter={[8, 8]}>
        <Col>
          <Button
            className="email-btn"
            onMouseEnter={() => setHoveredButton("unsubscribe")}
            onMouseLeave={() => setHoveredButton(null)}  
            onClick={() => emailUnsubscription("BUSINESS")}          
          >
            Unsubscribe from Business
          </Button>
        </Col>
        <Col>
          <Button
            className="email-btn"
            onMouseEnter={() => setHoveredButton("unsubscribe")}
            onMouseLeave={() => setHoveredButton(null)}
            onClick={() => emailUnsubscription("FYNDR")}
          >
            Unsubscribe from Fyndr Offers
          </Button>
        </Col>
        </Row>
        </>
        )}
    </Card>
  )}
  {pageVisible && (
    <Card className="email-card">
       <Row justify="center">
         <Col>
          <div className="email-image-wrapper">
            <Image className="email-image" src={unsubscribeImg} preview={false} alt="email-img" />
          </div>
          </Col>
       </Row>
       <Row justify="center">
        <Col>
          <h2 className="email-description">We will no longer send you any promotional emails.</h2>
        </Col>
      </Row>
      <Row justify="center">
        <Col>
          <p className="email-subdetails2">If you have any questions or wish to resubscribe in the future, please contact our support.</p>
        </Col>
      </Row>
      <Row justify="center">
        <Col>
          <p className="email-subdetails2">Thank you for being a valued member of our community.</p>
        </Col>
      </Row>   
    </Card>
  )}
  </>
  );
};

export default EmailUnsubscription;
