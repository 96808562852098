/* eslint-disable import/no-anonymous-default-export */
import { Actions } from "../common";
const { CONTINUE_ON_WEB, LOG_OUT } = Actions;

const initialState = {
  browseOnWeb: false,
};

export default function (state = initialState, action) {
  if (action.type === "undefined") {
    return state;
  }
  switch (action.type) {
    case CONTINUE_ON_WEB: {
      return { ...state, browseOnWeb: action.payload };
    }
    case LOG_OUT: {
      var myItem = localStorage.getItem('tourOpen');
      var myItem2 = localStorage.getItem('tourOpenDashboard');
      var navTour = localStorage.getItem('tourSideNav')
      var interactionsTour = localStorage.getItem('tourOpenInteractions');
      var indicatorTour = localStorage.getItem("tourOpenIndicators")


     localStorage.clear();
     localStorage.setItem('tourOpen',myItem);
     localStorage.setItem('tourOpenDashboard',myItem2);
     localStorage.setItem('tourSideNav', navTour)
     localStorage.setItem('tourOpenInteractions', interactionsTour)
     localStorage.getItem('tourOpenIndicators',indicatorTour)


      return initialState;
    }
    default: {
      return state;
    }
  }
}
