import React, { useState ,Suspense} from "react";
import { Layout } from "antd";
import { Switch, Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { Actions } from "../../../common";
import Linking from "../../../pages/Linking/index";
import UploadLogo from "../../../pages/UploadLogo";
import VenueEdit from "../../../pages/Venue/Edit";
import Login from "../../../pages/Login";
import LocationViewer from "../../../pages/Interaction/Location";
import BusinessTerms from "../../../pages/BusinessTerms";
import Report from "../../../pages/Report";
import UpdateProfile from "../../.../../../pages/Profile";

import OffersHome from "../../../pages/Offer";

import OfferDetails from "../../../pages/Offer/OfferDetail";
import ManageVenue from "../../../pages/Venue";
import businessAppointment from "../../AppointmentBusiness/AppointmentBusiness";
import PaymentMethods from "../../../pages/Payment";
import Registration from "../../../pages/Registration";
import StripeConnect from "../../../pages/StripeConnect";
import Transactions from "../../../pages/Transactions";
import InteractionsIndv from "../../../pages/Interaction/Indv";
import Invoice from "../../../pages/Invoice";
import CampaignCenter from "../../../pages/Campaign";
import CampaignPromo from "../../../pages/CampaignPromo";
import CampaignPreview from "../../../pages/Campaign/Preview";
import Categories from "../../../pages/Category";
import Items from "../../../pages/Item";
import Modifier from "../../../pages/Modifier";
import Collections from "../../../pages/Catalogue";
import CataloguePageUrl from "../../../pages/CataloguePreviewUrl";
import BizHome from "../../../pages/BusinessHomePage";
import Search from "../../../pages/BizHome/Search";
import AdminDashboard from "../../../pages/Admin/Dashboard";
import AdminCustomer from "../../../pages/Admin/Customer";
import CampaignStatisticss from "../../../pages/Admin/Campaign";
import Revenue from "../../../pages/Admin/Revenue";
import PlatformVariables from "../../../pages/Admin/PlatformVariables";
import DisputeListings from "../../../pages/Admin/DisputeListings/index";
import DisputeForBiz from "../../../pages/DisputeForBiz";
import AppInformation from "../../../pages/Admin/info";
import Review from "../../../components/Review";
import CommentsReports from "../../../pages/Admin/Comments&Reports";
import WalletSection from "../../../pages/Wallet/index";
import Interactions from "../../../pages/Interaction/Business";
import UserManagement from "../../../pages/Admin/UserManagement";
import RedemeedUnredemeed from "../../../pages/RedemeedUnredemeed";
import NotFoundPage from "../../PageNotFound";
import AboutUs from "../../../pages/AboutUs"
import SkeletonLoader from "../../../pages/CataloguePreviewUrl/SkeletonLoader";
import MyOffers from "../../../pages/MyOffers";
import PromoCode from "../../../pages/Admin/PromoCode";
import PromoRegistration from "../../../pages/Admin/PromoCode/PromoRegistration";
import PromoPromotion from "../../../pages/Admin/PromoCode/PromoPromotion";
import AllPromo from "../../../pages/AllPromo";
import EmailUnsubscription from "../../../pages/EmailUnsubscription";
import RegistrationRecords from "../../../pages/Admin/RegistrationRecords";

const ChangePassword = React.lazy(() =>
  import("../../../pages/ChangePassword")
);

const { Content } = Layout;
const { ROLE_FYNDR } = Actions;
const verifyPath = (path, role) => {
  switch (role) {
    case ROLE_FYNDR: {
      let isAllowed = [
        "/admin/dashboard",
        "/admin/customers",
        "/admin/management",
        "/admin/dashboard/campaign",
        "/admin/dashboard/revenue",
        "/admin/dispute",
        "/admin/platformvariable",
        "/admin/info",
        "/admin/comments&reports",
        "/admin/promo",
        "/admin/promo/promotion",
        "/admin/promo/registration",
        "/admin/registration-records"
      ].includes(path);
      return { isAllowed, redirectPath: "/admin/dashboard" };
    }
    default: {
      return { isAllowed: true, redirectPath: path };
    }
  }
};

const PrivateRouteNavigator = ({
  path,
  role,
  isOpen,
  component: Component,
  ...rest
}) => {
  const { isAllowed, redirectPath } = verifyPath(path, role);

  //which role

  switch (role) {
    case ROLE_FYNDR: {
      //check if the given path is allowed  for the role
      return isAllowed === true ? (
        <Component isPrivate={true} isOpen={isOpen} {...rest} />
      ) : (
        <Redirect
          to={{
            pathname: redirectPath,
          }}
        />
      );
    }
    default: {
      return <Component isPrivate={true} isOpen={isOpen} {...rest} />;
    }
  }
};

const PrivateRoute = ({ path, component: Component, ...rest }) => {
  const { email, entityType } = useSelector(({ auth }) => {
    return auth.indv ? auth.indv : auth;
  });
  const [isOpen] = useState();

  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <React.Fragment>
            {email ? (
              <PrivateRouteNavigator
                component={Component}
                isPrivate={true}
                isOpen={isOpen}
                role={entityType}
                path={path}
                {...props}
              />
            ) : (
              <Redirect
                to={{
                  pathname: "/",
                }}
              />
            )}
          </React.Fragment>
        );
      }}
    />
  );
};


const Home = React.lazy(() => import('../../../pages/BizHome'))

const AppContent = () => {
  const { indv } = useSelector(({ auth }) => {
    return auth;
  });
  return (
    <Content id="app-content">
        <Suspense fallback={<div><SkeletonLoader /></div>}>
      <Switch>
        <Route exact path="/aboutUs" component={AboutUs} />
        <Route exact path="/allpromo" component={AllPromo}/>
        <Route exact path="/offers" component={OffersHome} />
        <Route exact path="/myOffers" component={MyOffers} />
        <Route exact path="/email-unsubscription" component={EmailUnsubscription}/>
        <Route
          exact
          path="/offer-details/:bizName/:qrCode"
          component={OfferDetails}
        />
        <Route exact path="/register" component={Registration} />
        <Route exact path="/qr/" component={BizHome} />
        <Route exact path="/invoice-pay/:qrCode" component={Linking} />
        <Route exact path="/voucher/:qrCode" component={Linking} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/register/profile" component={UpdateProfile} />
        <Route exact path="/app-download" component={Linking} />
        <PrivateRoute exact path="/wallet" component={WalletSection} />
        <PrivateRoute
          exact
          path="/interactions"
          component={indv?.biz ? Interactions : InteractionsIndv}
        />

        <PrivateRoute
          exact
          path="/account/business_terms"
          component={BusinessTerms}
        />
        <PrivateRoute
          exact
          path="/account/upload_logo/:type"
          component={UploadLogo}
        />
        <PrivateRoute exact path="/account/venue/edit" component={VenueEdit} />
        <PrivateRoute
          exact
          path="/account/venue/manage"
          component={ManageVenue}
        />
        <PrivateRoute
          exact
          path="/account/offers_summary"
          component={RedemeedUnredemeed}
        />

        <PrivateRoute
          exact
          path="/business/appointment/"
          component={businessAppointment}
        />
        <PrivateRoute
          exact
          path="/preferences/Profile"
          component={UpdateProfile}
        />
        <PrivateRoute
          exact
          path="/preferences/Password"
          component={ChangePassword}
        />
        <Route exact path="/reset-password" component={ChangePassword} />
        <PrivateRoute exact path="/preview" component={CampaignPreview} />
        <PrivateRoute
          exact
          path="/account/interaction/viewer/:objid/:qrcode/:qrid"
          component={LocationViewer}
        />
        <PrivateRoute exact path="/reports/terms" component={Report} />
        <PrivateRoute exact path="/campaign" component={CampaignCenter} />
        <PrivateRoute exact path="/campaign/promo" component={CampaignPromo} />
        <PrivateRoute exact path="/invoice" component={Invoice} />
        <PrivateRoute
          exact
          path="/billing/payment_methods"
          component={PaymentMethods}
        />
        <PrivateRoute
          exact
          path="/merchant/stripe_connect"
          component={StripeConnect}
        />
        <PrivateRoute
          exact
          path="/billing/transactions"
          component={Transactions}
        />
        <PrivateRoute exact path="/dispute" component={DisputeForBiz} />
        <Route
          path="/external"
          component={(props) => {
            const { path } = props.location.state;

            if (path.indexOf("http") >= 0) {
              window.open(`${props.location.state.path}`, "_blank");
            } else {
              window.open(`https://${props.location.state.path}`, "_blank");
            }

            return props.location.state.qr ? (
              <Redirect to={`/offer-details/${props.location.state.qr}`} />
            ) : (
              <Redirect to="/" />
            );
          }}
        />

        <PrivateRoute
          exact
          path="/catalogue/categories"
          component={Categories}
        />
        <PrivateRoute exact path="/catalogue/items" component={Items} />
        <PrivateRoute exact path="/catalogue/modifiers" component={Modifier} />
        <PrivateRoute exact path="/catalogue/list" component={Collections} />
        <Route
          exact
          path="/merchant/stripe_connect/:connect_status"
          component={StripeConnect}
        />
        <Route
          exact
          path="/catalogue/store/:url"
          component={CataloguePageUrl}
        />
        <Route exact path="/reviews/:commentId" component={Review} />

          <Route exact path="/" component={Home} />

        <Route exact path="/offer-listing" component={Search} />
        <PrivateRoute
          exact
          path="/admin/dashboard"
          component={AdminDashboard}
        />
        <PrivateRoute
          exact
          path="/admin/customers"
          component={(props) => <AdminCustomer {...props} />}
        />
        <PrivateRoute
          exact
          path="/admin/management"
          component={(props) => <UserManagement {...props} />}
        />
        <PrivateRoute
          exact
          path="/admin/promo"
          component={(props) => <PromoCode {...props} />}
        />
        <PrivateRoute
        exact
        path="/admin/promo/registration"
        component={(props) => <PromoRegistration {...props} />}
        />
        <PrivateRoute
        exact
        path="/admin/promo/promotion"
        component={(props) => <PromoPromotion {...props} />}
        />
        <PrivateRoute
        exact
        path="/admin/registration-records"
        component={(props) => <RegistrationRecords {...props} />}
        />
        <PrivateRoute
          exact
          path="/admin/dashboard/campaign"
          component={CampaignStatisticss}
        />
        <PrivateRoute
          exact
          path="/admin/dashboard/revenue"
          component={Revenue}
        />
        <PrivateRoute exact path="/admin/dispute" component={DisputeListings} />
        <PrivateRoute
          exact
          path="/admin/platformvariable"
          component={PlatformVariables}
        />
        <PrivateRoute
          exact
          path="/admin/comments&reports"
          component={CommentsReports}
        />
        <PrivateRoute exact path="/admin/info" component={AppInformation} />

        <Route component={NotFoundPage} />
      </Switch>
      </Suspense>

    </Content>
  );
};

const Router = () => <AppContent />;

export default Router;
