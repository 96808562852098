/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Tabs,
  Collapse,
  Button,
  Modal,
  Input,
  Select,
  message,
  Divider,
  Radio,
} from "antd";
import { useSelector, useDispatch } from "react-redux";
import UserActions from "../../actions";
import { Actions } from "../../common";
import Theme from "../../common/Theme";
import { FiEdit, FiCheckSquare, FiSquare } from "react-icons/fi";
import { getFormattedDtNew, getFormattedTmNew, parseAddress } from "../../common/util.js";
import Preview from "./Preview";
import LoadingOverlay from "react-loading-overlay";
import ContainerWrapper from "../../components/ContainerWrapper";
import HelmetFyndr from "../../components/HelmetFyndr.jsx";
import getEnvVars from "../../environment.js";

const web = getEnvVars();
const BusinessTerms = (props) => {
  const [, setOpen] = useState(props.isOpen);
  const { terms_list } = useSelector(({ core }) => {
    return core;
  });

  const {
    terms,
    terms: { draft, active, review },
    indv: { bizid, qrcode, address, userTimeZone },
    settings,
  } = useSelector(({ auth }) => {
    return auth;
  });

  const { Panel } = Collapse;
  const { TabPane } = Tabs;
  const { Option } = Select;
  const { TERMS_INIT, TERM_EDITED, SET_TERMS_LIST } = Actions;
  const dispatch = useDispatch();
  const [isLoading] = useState(false);
  const [selectedClause, setSelectedClause] = useState(terms_list);
  const [forceRender, setForceRender] = useState(false);
  const [isModalVisible, setModalVisible] = useState(false);
  const [content, setContent] = useState("");
  const [title, setTitle] = useState("");
  const [id, setID] = useState(null);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [previewModal, setPreviewModal] = useState(false);
  const [currentTab, setTab] = useState("1");
  const currentActiveId = terms ? (terms.active ? terms.active.objid : 0) : 0;
  const [presentment, setPresentment] = useState(
    draft ? draft.presentment : ""
  );
  const [displayTerms, setDisplayTerms] = useState(
    draft ? draft.displayTerms : false
  );
  const [displayTermsActive, setDisplayTermsActive] = useState(
    active ? active.displayTerms : false
  );
  const [storage, setStorage] = useState(draft ? draft.storage : "");
  const [storageActive, setStorageActive] = useState(
    active ? active.storage : "365"
  );
  const [presentmentActive, setPresentmentActive] = useState(
    active ? active.presentment : "30"
  );

  const storage_props = [
    { label: "Select", value: "" },
    { label: "1 Year", value: "365" },
    { label: "180 days", value: "180" },
    { label: "60 days", value: "60" },
  ];

  const presentment_props = [
    { label: "Select", value: "" },
    { label: "Just Once", value: "once" },
    { label: "First time and after 30 days", value: "30" },
    { label: "Every time", value: "always" },
  ];

  function callback(key) {}
  function callbackTab(key) {
    setTab(key);
  }
  const handleCheck = async (id, title, content, val) => {
    let tempData = selectedClause;
    let data = tempData;
    setSelectedClause(data);
    setForceRender(!forceRender);
    await dispatch({
      type: TERM_EDITED,
      payload: {
        id: id ? id : Date.now(),
        title,
        content,
        selected: val,
        editable: true,
      },
    });
  };
  const update = async () => {
    const resp = await UserActions.updateTerms({
      objid: active ? active.objid : null,
      qrCode: qrcode,
      presentment: presentmentActive,
      consentStorage: storageActive,
      address: parseAddress(address),
      displayTerms: displayTermsActive,
      bizid,
      title: "terms",
      termsContent: selectedClause,
    });
    (await resp.success) ? message.success(resp.msg) : message.error(resp.msg);
    if (resp.success) {
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  };

  const renderDisplayTerm = (type) => {
    return (
      <Radio.Group
        onChange={(e) =>
          type === "Active"
            ? setDisplayTermsActive(e.target.value)
            : setDisplayTerms(e.target.value)
        }
        value={type === "Active" ? displayTermsActive : displayTerms}
      >
        <Radio value={true}>Show full content during Check-in</Radio>
        <Radio value={false}>Show as link during Check-in</Radio>
      </Radio.Group>
    );
  };
  const handleSubmit = async () => {
    if (!terms.draft) {
      //revised terms
      if (
        (presentment.length <= 0 ||
          storage.length <= 0 ||
          selectedClause.length <= 0) &&
        currentTab !== "1"
      ) {
        message.error("Please fill all the details before proceeding");
        return;
      }

      const resp = await UserActions.createTerms({
        data: {
          qrCode: qrcode,
          termsContent: selectedClause,
          title: "terms",
          termsType: "json",
          presentment,
          consentStorage: storage,
          bizid,
          address: parseAddress(address),
          displayTerms,
        },
        currentActiveId,
      });

      resp.success ? message.success(resp.msg) : message.error(resp.msg);
      if (resp.success) {
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } else {
      //first time

      const resp = await UserActions.updateTerms({
        objid: terms.draft.objid,
        qrCode: qrcode,
        termsContent: selectedClause,
        title: "terms",
        presentment,
        consentStorage: storage,
        bizid,
        address: parseAddress(address),
        displayTerms,
      });
      resp.success ? message.success(resp.msg) : message.error(resp.msg);
      if (resp.success) {
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
      
    }
  };
  const saveTerms = async (id) => {
    await dispatch({
      type: TERM_EDITED,
      payload: {
        id: id ? id : Date.now(),
        title,
        content,
        selected: true,
        editable: true,
      },
    });
  };

  const setTerms = async () => {
    if (active) {
      await dispatch({ type: SET_TERMS_LIST, payload: active.list });
      setPreviewModal(true);
    }
    if (review) {
      await dispatch({ type: SET_TERMS_LIST, payload: review.list });
      setPreviewModal(true);
    }
  };

  useEffect(() => {
    setOpen(props.isOpen);
  }, [props.isOpen]);
  useEffect(() => {
    setSelectedClause(terms_list);
  }, [terms_list]);
  useEffect(() => {
    UserActions.getTerms(bizid);
    if (terms_list.length === 0 && settings.terms && !active) {
      const terms_with_flag = [];
      settings.terms.forEach((row) => {
        terms_with_flag.push({ ...row, selected: false });
      });
      dispatch({ type: TERMS_INIT, payload: terms_with_flag });
    }
  }, []);
  useEffect(() => {
    setForceRender(!forceRender);
  }, [terms]);

  useEffect(() => {
    if (active) {
      setStorageActive(active.storage);
      setPresentmentActive(active.presentment);
      dispatch({ type: SET_TERMS_LIST, payload: active.list });
    }
    if (review) {
      dispatch({ type: SET_TERMS_LIST, payload: review.list });
    }
    if (draft) {
      setStorage(draft.storage);
      setPresentment(draft.presentment);
    }
  }, [currentTab, terms]);
  return (
    <LoadingOverlay
      active={isLoading}
      spinner
      text=""
      styles={{
        overlay: (base) => ({
          ...base,
          background: Theme.overlay,
        }),
      }}
    >
      <ContainerWrapper title={"Business Terms"}>
        {
          <HelmetFyndr
            title="Business "
            imgUrl={""}
            url={`${web}/account/business_terms`}
            descr="account business_terms"
          />
        }
        <Tabs onChange={callbackTab}>
          {terms && terms.active && (
            <TabPane tab="Active terms" key="1">
              <h5> Updated On: {active ? active.updatedDt : ""}</h5>
              <Row style={{ marginTop: "2rem" }} gutter={20}>
                <Col span={12}>
                  <h6>T&C Presentment</h6>
                  <Select
                    className="bussiness_select-tc"
                    defaultValue={presentmentActive}
                    value={presentmentActive}
                    onChange={(msg) => {
                      setPresentmentActive(msg);
                    }}
                  >
                    {/* options to select in T&C Presentment*/}
                    {presentment_props.map((item, index) => (
                      <Option
                        className="bussiness_tc-options"
                        value={item.value}
                      >
                        {item.label}
                      </Option>
                    ))}
                  </Select>
                </Col>
                <Col span={12}>
                  <h6>T&C Consent Storage</h6>
                  <Select
                    defaultValue={storageActive}
                    value={storageActive}
                    className="bussiness_select-tc"
                    onChange={(msg) => {
                      setStorageActive(msg);
                    }}
                  >
                    {storage_props.map((item, index) => (
                      <Option
                        className="bussiness_tc-options"
                        value={item.value}
                      >
                        {item.label}
                      </Option>
                    ))}
                  </Select>
                </Col>
              </Row>
              <Row className="bussiness_check-in">
                <Col span={24}>{renderDisplayTerm("Active")}</Col>
              </Row>
              <Row className="bussiness_btn" align="middle" justify="center">
                <Col align="center" span={6}>
                  <Button onClick={() => update()} type="primary">
                    Submit
                  </Button>
                </Col>
                <Col align="center" span={6}>
                  <Button onClick={() => setTerms()} type="primary">
                    Preview
                  </Button>
                </Col>
              </Row>
            </TabPane>
          )}
          {terms && terms.active && !terms.draft && !terms.review && (
            <TabPane tab="Revise terms" key="2">
              <Row
                className="bussiness_revise-terms"
                align="middle"
                justify="center"
              >
                <Col justify="center" align="left" span={20}>
                  <h5>Select From Available Clauses</h5>
                </Col>
                <Col justify="center" align="right">
                  <Button
                    onClick={() => setModalVisible(true)}
                    type="primary"
                    size="middle"
                  >
                    Add new Clause
                  </Button>
                </Col>
              </Row>

              <div>
                <Collapse
                  accordion
                  onChange={callback}
                  expandIconPosition={"left"}
                >
                  {selectedClause.map((item, index) => {
                    return (
                      <Panel
                        header={item.title}
                        key={index}
                      >
                        <div>
                          <Row>
                            <Col span={22}>{item.content}</Col>
                            <Col span={2}>
                              <Row gutter={20}>
                                <Col>
                                  <div>
                                    {item.selected ? (
                                      <FiCheckSquare
                                        onClick={() =>
                                          handleCheck(
                                            item.id,
                                            item.title,
                                            item.content,
                                            false
                                          )
                                        }
                                        style={{
                                          fontSize: "1.5rem",
                                          color: Theme.blue,
                                        }}
                                        className="fa fa-check-circle pointer"
                                        aria-hidden="true"
                                      />
                                    ) : (
                                      <FiSquare
                                        onClick={() =>
                                          handleCheck(
                                            item.id,
                                            item.title,
                                            item.content,
                                            true
                                          )
                                        }
                                        style={{
                                          fontSize: "1.5rem",
                                          color: Theme.blue,
                                        }}
                                        className="fa fa-circle-o pointer"
                                        aria-hidden="true"
                                      />
                                    )}
                                  </div>
                                </Col>
                                <Col>
                                  <FiEdit
                                    onClick={async () => {
                                      setID(item.id);
                                      setTitle(item.title);
                                      setContent(item.content);
                                      setModalVisible(true);
                                    }}
                                    style={{
                                      fontSize: "1.5rem",
                                      color: Theme.blue,
                                      cursor: "pointer",
                                    }}
                                    aria-hidden="true"
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </div>
                      </Panel>
                    );
                  })}
                </Collapse>
              </div>
            </TabPane>
          )}
          {(!terms.active || terms.draft || terms.review) && (
            <TabPane
              tab={
                terms.review
                  ? "Revise Terms"
                  : terms.draft
                  ? "Revise Terms"
                  : !terms.active
                  ? "Add Terms of Service"
                  : ""
              }
              key="3"
            >
              <Row
                style={{ marginBottom: "2rem" }}
                align="middle"
                justify="center"
              >
                <Col justify="center" align="left" span={24}>
                  {draft && (
                    <h4>
                      Updated Date <span>{draft.updatedDt}</span>
                    </h4>
                  )}
                  {draft?.remark && (
                    <Collapse
                      accordion
                      expandIconPosition={"left"}
                    >
                      <Panel
                        header={
                          <h6 style={{ color: Theme.pink }}>Review Comments</h6>
                        }
                        key={1}
                      >
                        {draft &&
                          draft.remark.map((item, key) => {
                            return (
                              <div key={key}>
                                <Row>
                                  <Col span={5}>
                                    <h6>Rejected By:</h6>
                                  </Col>
                                  <Col span={19}>
                                    <h6>{item.email}</h6>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col span={5}>
                                    <h6>Comment:</h6>
                                  </Col>
                                  <Col span={19}>
                                    <p>{item.text}</p>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col span={5}>
                                    <h6>Date:</h6>
                                  </Col>
                                  <Col span={19}>
                                    <h6>
                                      {getFormattedDtNew(item.updatedDt, userTimeZone)}
                                      <span>
                                        {getFormattedTmNew(item.updatedDt, userTimeZone)}
                                      </span>
                                    </h6>
                                  </Col>
                                </Row>
                                <br />
                                <Divider type="horizontal" />
                              </div>
                            );
                          })}
                      </Panel>
                    </Collapse>
                  )}
                </Col>
              </Row>

              <Row
                style={{ marginBottom: "2rem" }}
                align="middle"
                justify="center"
              >
                <Col justify="center" align="left" span={20}>
                  <h4>Select From Available Clauses</h4>
                </Col>
                <Col justify="center" align="right">
                  <Button
                    onClick={() => setModalVisible(true)}
                    type="primary"
                    size="middle"
                  >
                    Add new Clause
                  </Button>
                </Col>
              </Row>

              <div>
                <Collapse
                  accordion
                  onChange={callback}
                  expandIconPosition={"left"}
                >
                  {selectedClause.map((item, index) => {
                    return (
                      <Panel
                        header={item.title}
                        key={index}
                      >
                        <div>
                          <Row>
                            <Col span={22}>{item.content}</Col>
                            <Col span={2}>
                              <Row gutter={20}>
                                <Col>
                                  <div>
                                    {item.selected ? (
                                      <FiCheckSquare
                                        onClick={() =>
                                          handleCheck(
                                            item.id,
                                            item.title,
                                            item.content,
                                            false
                                          )
                                        }
                                        style={{
                                          fontSize: "1rem",
                                          color: Theme.blue,
                                          cursor: "pointer",
                                        }}
                                        aria-hidden="true"
                                      />
                                    ) : (
                                      <FiSquare
                                        onClick={() =>
                                          handleCheck(
                                            item.id,
                                            item.title,
                                            item.content,
                                            true
                                          )
                                        }
                                        style={{
                                          fontSize: "1rem",
                                          cursor: "pointer",
                                          color: Theme.blue,
                                        }}
                                        className="fa fa-circle-o pointer"
                                        aria-hidden="true"
                                      />
                                    )}
                                  </div>
                                </Col>
                                <Col>
                                  <FiEdit
                                    onClick={async () => {
                                      setID(item.id);
                                      setTitle(item.title);
                                      setContent(item.content);
                                      setModalVisible(true);
                                    }}
                                    style={{
                                      fontSize: "1rem",
                                      color: Theme.blue,
                                      cursor: "pointer",
                                    }}
                                    aria-hidden="true"
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </div>
                      </Panel>
                    );
                  })}
                </Collapse>
              </div>
              <div
                className="bussiness_tc-conditional"
              >
                <Row gutter={20}>
                  <Col span={12}>
                    <h6>T&C Presentment</h6>
                    <Select
                      defaultValue={presentment}
                      className="bussiness_select-tc"
                      onChange={(msg) => {
                        setPresentment(msg);
                      }}
                    >
                      {presentment_props.map((item, index) => (
                        <Option
                          className="bussiness_tc-options"
                          value={item.value}
                        >
                          {item.label}
                        </Option>
                      ))}
                    </Select>
                  </Col>
                  <Col span={12}>
                    <h6>T&C Consent Storage</h6>
                    <Select
                      defaultValue={storage}
                      className="bussiness_select-tc"
                      onChange={(msg) => {
                        setStorage(msg);
                      }}
                    >
                      {storage_props.map((item, index) => (
                        <Option
                          className="bussiness_tc-options"
                          value={item.value}
                        >
                          {item.label}
                        </Option>
                      ))}
                    </Select>
                  </Col>
                </Row>
                <Row style={{ paddingTop: "2rem" }}>
                  <Col span={24}>{renderDisplayTerm()}</Col>
                </Row>
              </div>
              <div
                className="bussiness_buttons_conditional"
              >
                <Row align="middle" justify="center">
                  <Col align="center" span={6}>
                    <Button
                      onClick={() => {
                        if (storage !== "" && presentment !== "") {
                          handleSubmit();
                        } else {
                          message.error(
                            "Please select storage and presentment"
                          );
                        }
                      }}
                      type="primary"
                    >
                      Submit
                    </Button>
                  </Col>
                  <Col align="center" span={6}>
                    <Button
                      onClick={() => setPreviewModal(true)}
                      type="primary"
                    >
                      Preview
                    </Button>
                  </Col>
                </Row>
              </div>
            </TabPane>
          )}
        </Tabs>

        {(currentTab === "2" || currentTab === "3") && (
          <div className="box-body py-5">
            <Row gutter={20}>
              <Col span={12}>
                <h6>T&C Presentment</h6>
                <Select
                  defaultValue={presentment}
                  className="bussiness_select-tc"
                  onChange={(msg) => {
                    setPresentment(msg);
                  }}
                >
                  {presentment_props.map((item, index) => (
                    <Option className="bussiness_tc-options" value={item.value}>
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </Col>
              <Col span={12}>
                <h6>T&C Consent Storage</h6>
                <Select
                  defaultValue={storage}
                  className="bussiness_select-tc"
                  onChange={(msg) => {
                    setStorage(msg);
                  }}
                >
                  {storage_props.map((item, index) => (
                    <Option className="bussiness_tc-options" value={item.value}>
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </Col>
            </Row>
            <Row style={{ paddingTop: "2rem" }}>
              <Col span={20}>{renderDisplayTerm("Active")}</Col>
            </Row>
          </div>
        )}

        {(currentTab === "2" || currentTab === "3") && (
          <div className="box-body py-5">
            <Row align="middle" justify="center">
              <Col align="center" span={6}>
                <Button onClick={() => handleSubmit()} type="primary">
                  Submit
                </Button>
              </Col>
              <Col align="center" span={6}>
                <Button onClick={() => setPreviewModal(true)} type="primary">
                  Preview
                </Button>
              </Col>
            </Row>
          </div>
        )}
        <Modal
          centered
          title="Add Clause"
          visible={isModalVisible}
          onOk={() => {
            if (title.length <= 0 || content.length <= 0) {
              message.error("Title or Content can not be empty. ");
              return;
            }
            setConfirmLoading(true);
            saveTerms(id);
            setTimeout(() => {
              setConfirmLoading(false);
              setModalVisible(false);
              setContent("");
              setTitle("");
              setID("");
            }, 1500);
          }}
          confirmLoading={confirmLoading}
          onCancel={() => setModalVisible(false)}
        >
          <Input
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
            title="Title"
            placeholder={"Title"}
            style={{ marginBottom: "2rem" }}
          />
          <textarea
            value={content}
            onChange={(e) => {
              setContent(e.target.value);
            }}
            style={{
              background: "#fff",
              overflow: "hidden",
              borderRadius: 5,
              height: 'auto'
            }}
            className="form-control  "
            rows={15}
            placeholder="Content"
            defaultValue={""}
          />
        </Modal>
        <Preview
          callBack={(value) => {
            setPreviewModal(value);
          }}
          visible={previewModal}
        />
      </ContainerWrapper>
    </LoadingOverlay>
  );
};

export default BusinessTerms;
