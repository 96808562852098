/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Checkbox,
  Typography,
  message,
  Input,
  Col,
  Space,
  notification,
  Form,
  Popover,
  Radio,
  DatePicker,
  TimePicker,
  Switch,
  Modal,
} from "antd";
import Button from "antd-button-color";
import UserActions from "../../actions";
import { Actions } from "../../common";
import { Theme } from "../../common";
import CardPay from "../Payment/CardPay";
import Loader from "../../components/Loading/Loader";
import { CheckCircleOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import moment from "moment";
import calIcon from "../../assets/images/CalIcon.png";
import clockIcon from "../../assets/images/clockIcon.png";
import actions from "../../actions";
import PurchasePopup from "../Offer/PurchasePopup";

const { Text } = Typography;
const { CHECKOUT_STARTED, ROLE_MANAGER, ROLE_SUPPORT } = Actions;
const PromoPay = (props) => {
  const history = useHistory();
  const { paymentSubscription } = history.location.state;
  const {
    title,
    total_recipients,
    criteria,
    cmpn_id,
    onPurchase,
    featured,
    cancelSubs,
    callback,
  } = props;

  const {
    roleType,
    indv,
    indv: {
      qrcode,
      qrid,
      address: { postalCode, country },
      bizName,
    },
    settingsPlatform: {
      pmt_fee: { promo_fee },
      platform_acct: {
        business_name,
        business_country,
        bizid,
        merchant_id,
        currency,
        currency_symbol,
      },
      featuredSubscription,
    },
  } = useSelector(({ auth }) => auth);
  const { isMasquerade } = useSelector(({ admin }) => admin);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [spinnerVisible, setSpinnerVisible] = useState(false);
  const [email, setEmail] = useState(indv.email);
  const [emailError, setEmailError] = useState(null);
  const [subject, setSubject] = useState(`${bizName} offers: ${title}`);
  const [subjectError, setSubjectError] = useState(null);
  const [subScription, setSubscription] = useState(null);
  const [subScriptionMonth, setSubscriptionMonth] = useState(null);
  const [scheduleTime, setScheduleTime] = useState(new Date());
  const [minTime, setMinTime] = useState();
  const [taxRate, setTaxRate] = useState(0);
  const [taxAmount, setTaxAmount] = useState(0);
  const [total, setTotal] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [createdInvoice, setCreatedInvoice] = useState(null);
  const [popupVisible, setPopupVisible] = useState(false);
  const [payCard, setpayCard] = useState(null);
  const [isSubscriptionEnabled, setIsSubscriptionEnabled] = useState(
    cancelSubs === true
      ? false
      : paymentSubscription === null || paymentSubscription === undefined
      ? false
      : true
  );
  const [channels, setChannels] = useState({
    in_app: true,
    email: false,
    mobile_push: false,
    featured: false,
  });
  const [list, setList] = useState([]);
  const [showCard, setShowCard] = useState(false);
  const [scheduleTimeError, setScheduleTimeError] = useState(null);
  const [endDate, setEndDate] = useState(null);
  useEffect(() => {
    const calcFee = (type) => {
      const { base, free_count, per_email, per_push } = promo_fee;
      let fee = base;

      const chargeable = Number(total_recipients) - Number(free_count);
      if (chargeable > 0) {
        if (type === "email") {
          fee += chargeable * Number(per_email);
        } else if (type === "mobile_push") {
          fee += chargeable * Number(per_push);
        }
      }
      return fee;
    };
    let tlist = [
      { key: "fyndr", txt: "Fyndr website hosting" },
      { key: "fyndr", txt: "Fyndr mobile app hosting" },
      { key: "email", txt: "Email", price: 0 },
      { key: "mobile_push", txt: "Mobile notification", price: 0 },
    ];
    tlist = tlist.map((row) => ({ ...row, price: calcFee(row.key) }));
    setList(tlist);
    const today = new Date();
    const after30 = new Date(today.getTime() + 30 * 60000).toISOString();
    setMinTime(after30);
    setScheduleTime(after30);
  }, [promo_fee, total_recipients]);

  const dispatch = useDispatch();

  const getTaxRate = async () => {
    setSpinnerVisible(true);
    const { success, data } = await UserActions.getTax({
      postalCode,
      country,
    });
    setSpinnerVisible(false);
    if (success) {
      setTaxRate(data.taxRate);
      return data.taxRate;
    } else {
      message.error("Taxes could not be obtained");
    }
  };

  const setPrice = (key) => {
    let clist = channels;
    clist[key] =
      key === "featured" && subScription !== null ? clist[key] : !clist[key];
    setChannels(clist);
    calcTotal(clist);
  };

  const calcTotal = async (clist) => {
    let tot = 0;
    let tax = 0;
    list.forEach((row) => {
      if (clist[row.key] === true) {
        tot += Number(row.price);
      }
    });
    if (subScription !== null) {
      tot += Number(subScription);
    }
    let rate = taxRate ? taxRate : await getTaxRate();
    tax = tot * Number(rate);
    setTotal(tot.toFixed(2));
    setTaxAmount(tax.toFixed(2));
    setGrandTotal((tot + tax).toFixed(2));
  };

  const showSuccess = (props ,createdInvoice, paymentMethod) => {
    if(isMasquerade) {
    const key = `open${Date.now()}`;
    notification.open({
      placement: "topRight",
      style: { zIndex: 9999 },
      message: "Congratulations !! Campaign promotion is on it's way..",
      description: null,
      duration: 3,
      key,
      btn : null,
      onClose: () => onPurchase(),
      icon: <CheckCircleOutlined style={{ color: Theme.green }} />,
    });
  }
  else{
    setCreatedInvoice(createdInvoice);
    setpayCard(paymentMethod);
    setPopupVisible(true);
  }
  };

  const handlePopupClose = () => {
    setPopupVisible(false);
    history.push("/campaign");
  };

  const handleOk = async () => {
    setIsModalOpen(false);
    await adminFeature2();
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onProceed = async () => {
    if (!scheduleTime) {
      setScheduleTimeError("Please select promotion time");
    } else if (grandTotal > 0) {
      await persistChanges();
      setShowCard(true);
    } else {
      message.warning("Please select promotion channel/s");
    }
  };

  const adminFeaturePopup = async () => {
    if (!scheduleTime) {
      setScheduleTimeError("Please select promotion time");
    } else if (grandTotal <= 0) {
      message.warning("Please select promotion channel/s");
    } else {
      setIsModalOpen(true);
    }
  };

  const adminFeature2 = async () => {
    let promo_channels = [];
    for (const [key, value] of Object.entries(channels)) {
      if (value === true) promo_channels.push(key);
    }
    let promo_channels_str = promo_channels.join(",");
    const campaignFeaturedDetails = {
      duration: subScriptionMonth,
      featuredStartDt: scheduleTime,
      featuredEndDt: endDate,
    };
    const payload = {
      cmpnId: cmpn_id,
      searchCriteria: criteria,
      channel: promo_channels_str,
      scheduleTime,
      campaignFeaturedDetails:
        subScriptionMonth === null ? null : campaignFeaturedDetails,
    };
    setSpinnerVisible(true);
    let resp = await actions.AdminFeatureCampaign(payload);

    if (resp.success === true) {
      showSuccess();
      if (callback) {
        callback();
      }
    } else {
      message.error(resp.msg);
    }
    setSpinnerVisible(false);
  };

  const persistChanges = async () => {
    let promo_channels = [];
    for (const [key, value] of Object.entries(channels)) {
      if (value === true) promo_channels.push(key);
    }

    const cartInfo = {
      bizid,
      merchantId: merchant_id,
      baseAmount: total,
      currency,
      currencySymbol: currency_symbol,
      taxAmount: taxAmount,
      taxRate,
      invoiceDetails: {
        title: subject,
        cmpn_id,
        business_name,
        business_country,
        criteria,
        schedule_time: scheduleTime,
        promo_channels: promo_channels.join(","),
        featured_start_date: scheduleTime,
        featured_end_date: endDate,
        duration: subScriptionMonth,
      },
    };

    await dispatch({ type: CHECKOUT_STARTED, payload: cartInfo });
  };
  useEffect(() => {
    dateCalculation(subScriptionMonth);
    if (subScription !== null) {
      calcTotal(channels);
    }
    calcTotal(channels);
  }, [scheduleTime, subScriptionMonth, subScription, endDate]);

  const handleConfirm = (dt_tm) => {
    const now = new Date();
    setScheduleTimeError(null);
    if (dt_tm < now.getTime()) {
      setScheduleTimeError("Selected Time is in the past");
    } else {
      setScheduleTimeError(null);
    }
    setScheduleTime(dt_tm);
    dateCalculation(subScriptionMonth);
  };

  const dateCalculation = (intValue) => {
    if (intValue) {
      const startD = new Date(scheduleTime);
      let endD;
      if (intValue === 1) {
        endD = new Date(startD.getTime());
        endD.setMonth(endD.getMonth() + 1);
      } else if (intValue === 3) {
        endD = new Date(startD.getTime());
        endD.setMonth(endD.getMonth() + 3);
      } else if (intValue === 6) {
        endD = new Date(startD.getTime());
        endD.setMonth(endD.getMonth() + 6);
      }
      setEndDate(endD?.toISOString());
    }
  };

  const handleChangeEmail = (value) => {
    setEmail(value.trim());
    setEmailError(null);
    const mailformat =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (value.match(mailformat)) {
      return true;
    } else {
      setEmailError("Email address is not valid");
    }
  };

  const handleChangeSubject = (value) => {
    setSubject(value);
    setSubjectError(null);
    if (!value) setSubjectError("Subject is required");
  };

  const sendPreviewEmail = async () => {
    handleChangeEmail(email);
    if (email && !emailError) {
      setSpinnerVisible(true);
      const resp = await UserActions.sendPreview({
        bizid: indv.bizid,
        cmpn_id,
        email,
        subject,
      });
      if (resp.success) {
        message.success("Email sent, please check your mailbox");
        setSpinnerVisible(false);
      } else {
        if (resp?.message?.includes("undefined")) {
          message.error("Cmpn not found");
          setSpinnerVisible(false);
        } else {
          message.error(resp?.message);
          setSpinnerVisible(false);
        }
      }
      setSpinnerVisible(false);
    }
  };
  const sendPreviewMobile = async () => {
    setSpinnerVisible(true);
    const resp = await UserActions.sendPreview({
      bizid: indv.bizid,
      cmpn_id,
      qrcode,
      qrid,
      subject,
    });
    if (resp?.success) {
      message.success("Notification sent");
      setSpinnerVisible(false);
    } else {
      message.error(resp?.message);
      setSpinnerVisible(false);
    }

    setSpinnerVisible(false);
  };

  const layout = {
    labelCol: {
      span: 5,
    },
    wrapperCol: {
      span: 16,
    },
  };
  
  const cancelSelection = () => {
    setSubscription(null);
    setSubscriptionMonth(null);
    setEndDate(null);
    setIsSubscriptionEnabled(false);
    let updatedChannels = { ...channels };
    delete updatedChannels.featured;
    setChannels(updatedChannels);
  };
  return (
    <div>
      <div className="promotion_modalPop">
        <Row style={{ marginBottom: "24px" }}>
          <Col span={12}>
            <DatePicker
              onChange={handleConfirm}
              value={moment(scheduleTime)}
              allowClear={false}
              minDate={minTime}
              required={true}
              suffixIcon={
                <img
                  src={calIcon}
                  alt="Calendar"
                  className="promotion_calendar"
                />
              }
              className="promotion_datePicker"
            />
          </Col>
          <Col
            span={12}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            {" "}
            <TimePicker
              onChange={handleConfirm}
              format="h:mm A"
              value={moment(scheduleTime)}
              allowClear={false}
              minDate={minTime}
              required={true}
              suffixIcon={
                <img
                  src={clockIcon}
                  className="promotion_calendar"
                  alt="clock"
                />
              }
              className="promotion_datePicker"
            />
          </Col>
        </Row>

        {scheduleTimeError && <Text type="danger">{scheduleTimeError}</Text>}
        <div style={{ paddingBottom: "12px" }}>
          <Text className="promotion_target">Select Target Channel</Text>
        </div>
        {list.length > 0 &&
          list.map((row) => {
            return (
              <Row style={{ padding: "5px" }} justify="space-between">
                <Col span={12}>
                  <Checkbox
                    disabled={row.key === "fyndr"}
                    checked={row.key === "fyndr" || channels[row.key]}
                    onChange={() => setPrice(row.key)}
                  >
                    <Text className="promotion_fyndrCheck">{row.txt}</Text>
                  </Checkbox>
                </Col>
                <Col>
                  {row.key === "fyndr" && <Text type="success">FREE</Text>}
                  {row.key !== "fyndr" && (
                    <Text className="promotion_fyndrcharge">{`${currency_symbol}${Number(
                      row.price
                    ).toFixed(2)}`}</Text>
                  )}
                </Col>
              </Row>
            );
          })}

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "24px",
          }}
        >
          <Text className="promotion_recipients">{`Total Recipients: ${total_recipients}`}</Text>
        </div>
      </div>

      {!featured && (
        <div className="promotion_featuredCharges">
          <Row style={{ paddingBottom: "12px" }} justify={"space-between"}>
            <Col>
              <Text
                style={{
                  fontSize: "18px",
                  fontWeight: "500",
                  lineHeight: "27px",
                }}
              >
                {" "}
                Fyndr Featured
              </Text>
              <Popover content={"Fyndr Featured"}>
                <i
                  style={{ color: Theme.blue, marginLeft: ".5rem" }}
                  className="fa fa-info-circle"
                  aria-hidden="true"
                />
              </Popover>
            </Col>
          </Row>

          <Row gutter={[12, 12]} style={{ marginBottom: "12px" }}>
            {featuredSubscription.length > 0 && (
              <>
                {featuredSubscription.map((item) => {
                  return (
                    <Col span={8}>
                      <Radio
                        checked={item?.value === subScription ? true : false}
                        onChange={(e) => {
                          setSubscription(item.value);
                          setPrice("featured", item.value);
                          const intValue = parseInt(item.month);
                          setSubscriptionMonth(intValue);
                          dateCalculation(intValue);
                        }}
                      >
                        <Text className="promotion_monthsSubs">
                          {item.month}{" "}
                        </Text>
                      </Radio>

                      <Text className="promotion_priceSubs">{`${currency_symbol}${item.value}`}</Text>
                    </Col>
                  );
                })}
              </>
            )}
          </Row>
          {!isMasquerade && (
            <Row>
              <Text className="promotion_fyndrCheck">Auto Renew :</Text>
              <Switch
                title="Auto Renew"
                defaultChecked={isSubscriptionEnabled}
                disabled={subScription === null}
                onChange={(e) => setIsSubscriptionEnabled(e)}
                style={{
                  marginRight: "0.1rem",
                  marginLeft: "0.1rem",
                  marginTop: "2px",
                }}
                size="small"
                checkedChildren="Yes"
                unCheckedChildren="No"
              />
            </Row>
          )}
          <div style={{ textAlign: "center", marginTop: "24px" }}>
            <Button onClick={cancelSelection}>Cancel</Button>
          </div>
        </div>
      )}
      {grandTotal > 0 && (
        <>
          <Row style={{ margin: 10 }}>
            <Col span={12}>
              <Text strong>Promotion Cost:</Text>
            </Col>
            <Col
              span={12}
              style={{ justifyContent: "flex-end", display: "flex" }}
            >
              <Text strong>
                {total > 0 ? `${currency_symbol}${total}` : ""}
              </Text>
            </Col>
          </Row>
          <Row style={{ margin: 10 }}>
            <Col span={12}>
              <Text strong>Tax:</Text>
            </Col>
            <Col
              span={12}
              style={{ justifyContent: "flex-end", display: "flex" }}
            >
              <Text strong>
                {taxAmount > 0 ? `${currency_symbol}${taxAmount}` : ""}
              </Text>
            </Col>
          </Row>
          <Row style={{ margin: 10 }}>
            <Col span={12}>
              <Text strong>Total:</Text>
            </Col>
            <Col
              span={12}
              style={{ justifyContent: "flex-end", display: "flex" }}
            >
              <Text strong>
                {grandTotal > 0 ? `${currency_symbol}${grandTotal}` : ""}
              </Text>
            </Col>
          </Row>
        </>
      )}
      {!showCard && (
        <div>
          <Form {...layout}>
            <Row>
              <Col span={24}>
                <div style={{ marginTop: "24px" }}>
                  <Text
                    style={{
                      fontSize: "18px",
                      fontWeight: "500",
                      color: "#4D4D4D",
                    }}
                  >
                    Promotion Preview
                  </Text>
                </div>
                <div style={{ marginBottom: "24px" }}>
                  <Text
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "#999999",
                    }}
                  >
                    Send a copy of the promotion to yourself
                  </Text>
                </div>

                <Row>
                  <Col span={24}>
                    <div className="input-container">
                      <Input
                        defaultValue={subject}
                        placeholder="Subject"
                        onChange={(e) => handleChangeSubject(e.target.value)}
                      />
                      <label className="input-label">Subject</label>
                      <Text type="danger">{subjectError}</Text>
                    </div>
                  </Col>
                  <Col
                    span={24}
                    style={{ marginTop: "24px", marginBottom: "24px" }}
                  >
                    <div className="input-container">
                      <Input
                        defaultValue={email}
                        placeholder="Email"
                        onChange={(e) => handleChangeEmail(e.target.value)}
                      />
                      <label className="input-label">Email Address</label>
                      <Text type="danger">{emailError}</Text>
                    </div>
                  </Col>
                </Row>

                <Row justify={"center"}>
                  <Space>
                    <Col>
                      <Button
                        type="info"
                        onClick={sendPreviewEmail}
                        disabled={spinnerVisible}
                        className="promotion_EmailSender"
                      >
                        Send Email
                      </Button>
                    </Col>

                    <Col>
                      <Button
                        type="info"
                        onClick={sendPreviewMobile}
                        disabled={spinnerVisible}
                        className="promotion_mobileNoti"
                      >
                        Send Mobile Notification
                      </Button>
                    </Col>
                  </Space>
                </Row>

                <Row justify={"center"} style={{ marginTop: "24px" }}>
                  <Col>
                    <Button
                      className="promotion_payBtn"
                      size="large"
                      type="primary"
                      onClick={!isMasquerade ? onProceed : adminFeaturePopup}
                      disabled={
                        !isMasquerade
                          ? spinnerVisible ||
                            roleType === ROLE_SUPPORT ||
                            roleType === ROLE_MANAGER
                          : spinnerVisible
                      }
                    >
                      {!isMasquerade ? "Proceed to Pay" : "Promote"}
                    </Button>

                    {spinnerVisible && <Loader />}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </div>
      )}
      <Modal
        className="admin-confirm-modal"
        open={isModalOpen}
        footer={[
          <Button
            className="promotion_payBtn"
            onClick={handleOk}
            type="primary"
          >
            Yes
          </Button>,
          <Button
            className="promotion_payBtn"
            style={{ backgroundColor: "#fff", color: "#257CDB" }}
            onClick={handleCancel}
          >
            No
          </Button>,
        ]}
        onCancel={handleCancel}
      >
        <p style={{ fontWeight: "500" }}>
          Any of the stated charges won't apply as you are promoting the
          campaign as a Fyndr admin.
        </p>
        <p>Are you sure to promote this campaign?</p>
      </Modal>

      {showCard && (
        <div style={{ width: "100%" }}>
          <CardPay
            total={grandTotal}
            params={{ channel: "cmpn_promo" }}
            callback={(formData, invoiceId, additionalCheckbox, createdInvoice, paymentMethod) => {
              showSuccess(props , createdInvoice , paymentMethod)}
            }
            isSubscriptionEnabled={isSubscriptionEnabled}
          />
        </div>
      )}

      {popupVisible && (
          <PurchasePopup
            visible={popupVisible}
            onCancel={handlePopupClose}
            createdInvoice={createdInvoice}
            payCard={payCard}
            destroyOnClose={true}
            list={list}
            subScription={subScription}
            isSubscriptionEnabled={isSubscriptionEnabled}

          />
        )}
    </div>
  );
};

export default PromoPay;
