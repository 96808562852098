/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Row, Col, Card, Divider } from "antd";
import actions from "../../actions";
import PulseLoader from "react-spinners/PulseLoader";
import { Link } from 'react-router-dom';
import ApiEndpoints from "../../common/ApiEndpoints";


const CardComponent = (props) => {
    const [data, setData] = useState([])
    useEffect(() => {
        totalCampaign();
    }, [])

    const totalCampaign = async () => {
        let response = await actions.AdminDashboardStatistics(props.url);
        if (response.success === true) {
            setData(response.data);
        }
    }
    const getLables = (item, title) => {
        switch (item) {
            case "active":
                return "Total Active Campaign";

            case "online":
                return "Online";

            case "instore":
                return "In-Store";

            case "all":
                return "Online & In-Store";

            case "user":
                return <Link to={{
                    pathname: "/admin/customers",
                    
                }} className="card-row-text" >Total Enrollments</Link>;

            case "customer":
                return <Link to={{
                    pathname: "/admin/customers",
                    state: { userType: ["INDIVIDUAL"], userStatus:["ACTIVE"] }
                }} className="card-row-text" >Total Individuals</Link>;

            case "merchant":
                return <Link to={{
                    pathname: "/admin/customers",
                    state: { userType: ["BUSINESS"] , userStatus:["ACTIVE"]}
                }} className="card-row-text" >Total Business</Link>;
            case "totalRevenue":
                return "Total Revenue";

            case "catalogueRevenue":
                return "Catalogue Revenue";

            case "interactionRevenue":
                return "Interaction Revenue";

            case "eventsRevenue":
                return "Events Revenue";


            case "offerRevenue":
                return "Offer Revenue";

            case "campaignPromotionRevenue":
                return "Promotional Revenue";
            case "fyndrCash":
                return "Fyndr Cash";

            default:
                break;
        }
    }
    const getCategorySymbol = (data) => {
        let symbolName = data.filter((item) => item.includes("currencySymbol"))
        return symbolName[0][1];
    }
    return (
        <Card
            className={props.title === "Revenue" ? "card-revenue" : "card"}
            title={<h4 className="card-title">{props.title}</h4>}
        >
            <Divider className={props.title === "Revenue" ? "card-divider-revenue" : "card-divider"} />
            <span className="card-row-text">{props.value}</span>
            {/* eslint-disable-next-line no-whitespace-before-property */}
            {data.length > 0 ? data?.filter((item) => !item.includes("currencySymbol") && !item.includes("currency") && !item.includes("promotionalRevenue")) ?.map((item, key) => {
                
                return (
                    
                    < div key={key}>
                        <Row adminDashBoard-tour={`${props.title}-row-${key}`}   data-tour="card-row" justify="space-between" style={{ paddingBottom: "1rem" }} >
                            <Col span={12}>
                                <Row ><span className="card-row-text">{getLables(item[0], props.title)}</span></Row>
                            </Col>
                            <Col span={10} style={{ display: "flex", justifyContent: "right" }}>
                                <Row>
                                    <span className="card-row-text">{props.url === ApiEndpoints.revenueStatistics ? `${getCategorySymbol(data)}${item[1].toFixed(2)}` : item[1]}</span>
                                    </Row>
                            </Col>
                        </Row>

                    </div>
                )
            }) :
                <div>

                    <PulseLoader color={"#3F9AF7"} loading={true} size={10} />
                </div>}
        </Card>
    );
};
export default CardComponent;