/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Col, List, message, Row, Space } from "antd";
import Button from "antd-button-color";
import Loader from "../../components/Loading/Loader";
import { useSelector } from "react-redux";

import { Theme } from "../../common";
import { Container as ContainerWrapper, ListItem } from "../../components";
import Edit from "./Edit";
import UserActions from "../../actions";
import LoadingOverlay from "react-loading-overlay";
import HelmetFyndr from "../../components/HelmetFyndr";
import getEnvVars from "../../environment";

const web = getEnvVars();
const Modifiers = (props) => {
  const { modifiers } = useSelector(({ catalogue }) => {
    return catalogue;
  });
  const {
    indv: { bizid },
  } = useSelector(({ auth }) => auth);

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(modifiers);
  const [showedit, setShowEdit] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const getModifier = async () => {
    setIsLoading(true);
    await UserActions.fetchModifier({ bizid });
    setIsLoading(false);
  };
  useEffect(() => {
    getModifier();
  }, []);
  useEffect(() => {
    modifiers.sort((a, b) => a.modName.localeCompare(b.modName));

    setData(modifiers);
  }, [modifiers]);

  const handleDeleteItem = async (item) => {
    let dispatchPayload = {
      objid: item.objid,
      bizid,
    };
    setIsLoading(true);
    let resp = await UserActions.deleteModifier(dispatchPayload);
    setIsLoading(false);
    resp.success ? message.success(resp.message) : message.error(resp.message);
    if (resp.success) {
      getModifier();
    }
  };
  return (
    <>
      <LoadingOverlay
        active={isLoading}
        spinner
        text=""
        styles={{
          overlay: (base) => ({
            ...base,
            background: Theme.overlay,
          }),
        }}
      >
        {showedit ? (
          <Edit
            isEdit={isEdit}
            item={selectedItem}
            callback={(value) => setShowEdit(value)}
          />
        ) : (
          <ContainerWrapper
            headerOption={
              <Row>
                <Space direction="horizontal">
                  <Col span={3}>{isLoading && <Loader />}</Col>
                  <Col span={3}>
                    <Button
                      type="primary"
                      onClick={() => {
                        setShowEdit(true);
                        setSelectedItem(null);
                        setIsEdit(false);
                      }}
                    >
                      Add Modifiers
                    </Button>
                  </Col>
                </Space>
              </Row>
            }
            title={"Modifiers"}
          >
            {
              <HelmetFyndr
                title="Store"
                imgUrl={""}
                url={`${web}/catalogue/modifiers`}
                descr="catalogue modifiers"
              />
            }
            <List
              itemLayout="horizontal"
              dataSource={data}
              renderItem={(item, key) => {
                item.name = item.modName;
                return (
                  <ListItem
                    onClick={() => {
                      setSelectedItem(item);
                      setShowEdit(true);
                      setIsEdit(true);
                    }}
                    item={item}
                    deletePress={() => handleDeleteItem(item)}
                  />
                );
              }}
            />
          </ContainerWrapper>
        )}
      </LoadingOverlay>
    </>
  );
};

export default Modifiers;
